import { usePaginatedService, useServiceItem } from '@koverse/react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import pluralize from 'pluralize';
import React from 'react';

interface Props {
  groupId: string;
}

interface ListProps {
  datasetId: string;
}

const DatasetName = ({ datasetId }: ListProps): React.ReactElement => {
  const {
    item: dataset,
  } = useServiceItem('datasets', datasetId);

  return (
    <ListItem
      dense
      disablePadding
      aria-label="dataset name"
    >
      <ListItemText
        sx={{ wordWrap: 'break-word' }}
        primary={!dataset
          ? <Skeleton width={200} aria-label="placeholder-while-loading" />
          : dataset.name
        }
      />

    </ListItem>
  );
};

const DatasetAccessList = ({ groupId }: Props): React.ReactElement | null => {
  const [open, setOpen] = React.useState<boolean>(false);
  const {
    items: permissions,
    total,
  } = usePaginatedService('dataset-permissions', {
    groupId,
    $limit: -1,
  });

  const handleClick = () => setOpen(!open);

  if (total === 0) {
    return null;
  }

  return (
    <Box>
      <Typography gutterBottom>
        {`This group has access to ${total} ${pluralize('dataset', total)}.`}
      </Typography>
      <Button
        onClick={handleClick}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
      >
        View Details
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ pl: 1, maxHeight: 200, overflowY: 'auto' }} dense disablePadding>
          {permissions.map((d, index) => <DatasetName key={index} datasetId={d.datasetId} />)}
        </List>
      </Collapse>
    </Box>
  );
};

export default DatasetAccessList;
