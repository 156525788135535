import React, { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import TextAreaField from '../../TextAreaField';

type Inputs = {
  token: string;
  name: string;
  description: string;
};

const AttributeFields = (): React.ReactElement => {
  const { t } = useTranslation();
  const {
    errors,
    formState,
    register,
    setValue,
    trigger,
    watch,
  } = useFormContext<Inputs>();

  const { isSubmitting } = formState;

  return (
    <>
      <TextField
        InputLabelProps={{
          htmlFor: 'name',
        }}
        id="name"
        margin="normal"
        label={t('forms.labels.attributeName')}
        name="name"
        variant="outlined"
        fullWidth
        error={!!errors.name}
        helperText={(errors.name && errors.name.message) || ''}
        disabled={isSubmitting}
        inputRef={register({
          required: 'Required',
        })}
      />
      <TextField
        InputLabelProps={{
          htmlFor: 'token',
        }}
        id="token"
        margin="normal"
        label={t('forms.labels.attributeToken')}
        name="token"
        variant="outlined"
        fullWidth
        error={!!errors.token}
        helperText={(errors.token && errors.token.message) || ''}
        disabled={isSubmitting}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setValue('token', e.target.value.toUpperCase());
          trigger();
        }}
        inputRef={register({
          required: 'Required',
        })}
      />
      <TextAreaField
        name="description"
        label={`${t('forms.labels.description')} (${t('forms.labels.optional')})`}
        maxLength={255}
        {...{ errors, register, isSubmitting, watch, setValue }}
      />
    </>
  );
};

export default AttributeFields;
