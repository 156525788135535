import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

interface Props extends DialogProps {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const ConfirmationDialog = (props: Props): React.ReactElement => {
  const {
    onClose,
    onConfirm,
    open,
    title,
    children,
    cancelButtonText = 'Cancel',
    confirmButtonText = 'Okay',
    ...rest
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle>{title}</DialogTitle>
      {children && (
        <DialogContent>{children}</DialogContent>
      )}
      <DialogActions>
        <Button
          type="button"
          color="primary"
          onClick={() => onClose()}
        >
          {cancelButtonText}
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => onConfirm()}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
