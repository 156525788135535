import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { useForm, FieldError } from 'react-hook-form';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

type Inputs = {
  firstName: string;
  lastName: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const EditName = ({
  open,
  onClose,
}: Props): React.ReactElement => {
  const { client, user } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const {
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
  });

  const { isDirty, isValid, isSubmitting } = formState;

  const handleOnClose = () => {
    setSubmissionError(null);
    onClose();
  };

  const onSubmit = async (data: Inputs) => {
    try {
      await client.service('users').patch(user.id, {
        firstName: data.firstName,
        lastName: data.lastName,
      });
      enqueueSnackbar(t('pages.account.editName.successToast'), {
        variant: 'success',
      });
      reset();
      handleOnClose();
    } catch (e) {
      if (isArray(get(e, 'errors'))) {
        const errorList = get(e, 'errors') || [];
        errorList.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          setError(path, {
            type: 'manual',
            message: error.message,
          });
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleOnClose} aria-labelledby="form-dialog-title" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">{t('pages.account.editName.title')}</DialogTitle>
        <DialogContent>
          <TextField
            InputLabelProps={{
              htmlFor: 'firstName',
            }}
            id="firstName"
            margin='normal'
            label={t('forms.labels.firstName')}
            name="firstName"
            variant="outlined"
            fullWidth
            error={!!errors.firstName}
            helperText={(errors.firstName && errors.firstName.message) || ''}
            disabled={isSubmitting}
            inputRef={register({ required: t('forms.validation.required') as string })}
          />
          <TextField
            InputLabelProps={{
              htmlFor: 'lastName',
            }}
            id="lastName"
            margin='normal'
            label={t('forms.labels.lastName')}
            name="lastName"
            variant="outlined"
            fullWidth
            type={'text'}
            error={!!errors.lastName}
            helperText={(errors.lastName && errors.lastName.message) || ''}
            disabled={isSubmitting}
            inputRef={register({ required: t('forms.validation.required') as string  })}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                width: '100%',
                mt: 2,
              }}
            >
              {submissionError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <Box>
              <Button
                sx={{ mr: 2 }}
                color="primary"
                onClick={onClose}
              >
                {t('forms.cancel')}
              </Button>
              <Button
                disabled={!isDirty || !isValid}
                type="submit"
                color="primary"
                variant="contained"
              >
                {t('forms.saveChanges')}
              </Button>
            </Box>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditName;
