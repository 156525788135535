import config from './config';

type Cancel = {
  promise: Promise<unknown>,
  cancel: () => void,
}

export const makeCancelable = (promise: Promise<unknown>): Cancel => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
      error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

interface DeleteRecordsRequestParams {
  datasetId: string;
  deleteAll?: boolean;
  documentDelete: boolean;
  query: string;
  recordIds: string[];
}

export const deleteRecordsRequest = ({
  datasetId,
  deleteAll,
  documentDelete,
  query,
  recordIds,
}: DeleteRecordsRequestParams) => {
  const baseUrl = `//${config.api}/storage/datasets/${datasetId}`;
  const deleteType = documentDelete ? 'deleteDocuments' : 'deleteRecords';
  const queryDelete = (query && deleteAll) || documentDelete;
  const url = `${baseUrl}/${deleteType}${queryDelete ? '/query' : ''}`;

  const idPayload = deleteAll
    ? { recordIds: [], deleteAll: true }
    : { recordIds, deleteAll: false };

  const payload = queryDelete ? { query } : idPayload;

  return { url, payload };
};
