import React from 'react';
import AvatarGroup from '@mui/material/AvatarGroup';
import TableCell from '@mui/material/TableCell';
import UserAvatar from '../UserAvatar';
import { User } from '../../declarations';

interface Props {
  users: User[];
}

const UsersCell = ({
  users,
}: Props): React.ReactElement => {

  if (users.length === 0) {
    return <TableCell sx={{ height: 77 }} />;
  }

  return (
    <TableCell align="left">
      <AvatarGroup
        aria-label="user-avatars"
        max={4}
        sx={{
          '&.MuiAvatarGroup-root .MuiAvatar-root': {
            borderColor: 'action.hover',
          },
          justifyContent: 'flex-end',
        }}
      >
        {users.map((user: User) => (
          <UserAvatar key={user.id} user={user} />
        ))}
      </AvatarGroup>
    </TableCell>
  );
};

export default React.memo(UsersCell);
