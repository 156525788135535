import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import SearchSelectProvider from '../SearchSelectProvider';
import DatasetData from './DatasetData';
import { Dataset, Job } from '../../declarations';

interface Props {
  dataset: Dataset;
  disableDataActions: boolean;
  jobs: Job[];
  openUploadDrawer: () => void;
}

const DatasetDetails = ({
  dataset,
  disableDataActions,
  jobs,
  openUploadDrawer,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation();
  const hasSchema = !isEmpty(dataset.schema);
  const userCanImportData = get(dataset, 'currentUserPermissions.write', false);

  if (hasSchema) {
    return (
      <SearchSelectProvider>
        <DatasetData
          dataset={dataset}
          openUploadDrawer={openUploadDrawer}
          disableDataActions={disableDataActions}
        />
      </SearchSelectProvider>
    );
  }

  // Upload zero state
  return jobs.length === 0 && !hasSchema ? (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ p: 8 }}
    >
      <TimelineOutlinedIcon
        sx={{
          '&.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
            fontSize: 80,
          },
          mb: 2,
        }}
      />
      <Typography variant="h6" gutterBottom>{t('pages.dataset.zeroState.title')}</Typography>
      {userCanImportData && (
        <>
          <Typography color="textSecondary">
            {t('pages.dataset.zeroState.subheader')}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            sx={{ mt: 3 }}
            onClick={openUploadDrawer}
          >
            {t('common.importData')}
          </Button>
        </>
      )}
    </Stack>
  ) : null;
};

export default DatasetDetails;
