import { useKoverse, usePaginatedService } from '@koverse/react';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import ApplicationDetailCard from '../components/ApplicationDetailCard';

const Applications = (): React.ReactElement => {
  const { user, client, workspace } = useKoverse();
  const isAdmin = user.role === 'admin';

  const paginatedServiceProps = usePaginatedService('applications', {
    $sort: { name: 1 },
    workspaceId: workspace.id,
    ...(isAdmin ? {} : { userId: user.id }),
  });

  const {
    items,
    loading,
    fetchList,
  } = paginatedServiceProps;

  React.useEffect(() => {
    client.service('applications').on('created', fetchList);
    client.service('applications').on('removed', fetchList);
    client.service('applications').on('patched', fetchList);
    client.service('applications').on('updated', fetchList);

    return function cleanUp() {
      client.service('applications').off('created', fetchList);
      client.service('applications').off('removed', fetchList);
      client.service('applications').off('patched', fetchList);
      client.service('applications').off('updated', fetchList);
    };
  }, [client, fetchList]);

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Route
          path={`/applications/:id?`}
          exact
          render={({ match, ...props }) => (
            <ApplicationDetailCard
              {...props}
              applicationId={match.params.id as string}
            />
          )}
        />
        {items.length === 0 && (
          <Redirect to={`/applications`} />
        )}
        {!loading && !!items.length && (
          <Redirect to={`/applications/${items[0].id}`} />
        )}
      </Grid>
    </Grid>
  );
};

export default Applications;
