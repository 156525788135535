import { useServiceItem } from '@koverse/react';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import upperFirst from 'lodash/upperFirst';
import pluralize from 'pluralize';
import React from 'react';
import { TransferResourceRequest } from '../../declarations';

interface Props {
  request: TransferResourceRequest;
  outgoing: boolean;
  handleAction: (id: string, status: string) => void;
  handleDelete: (id: string) => void;
  handleResend: (request: TransferResourceRequest) => void;
  submitting: Record<string, boolean> | null;
}

const TransferRequestCard = ({
  request,
  outgoing,
  handleAction,
  handleDelete,
  handleResend,
  submitting,
}: Props): React.ReactElement => {
  const id = outgoing ? request.recipientUserId : request.userId;

  const {
    item: user,
  } = useServiceItem('users', id);

  const {
    item: resource,
  } = useServiceItem(request.resourceType, request.resourceId);

  const renderTitle = () => {
    if (!!resource) {
      return outgoing
        ? (
          <>
            <Typography display="inline">
              {`Transfer ${pluralize(request.resourceType, 1)} owner: `}
            </Typography>
            <Typography
              display="inline"
              sx={{
                color: theme => request.status === 'declined'
                  ? theme.palette.error.main
                  : 'inherit',
              }}
            >
              {upperFirst(request.status)}
            </Typography>
          </>
        )
        : (
          <>
            <Typography display="inline">
              {`Request to be the new owner of this ${pluralize(request.resourceType, 1)}: `}
            </Typography>
            <Typography display="inline">
              <strong>{`${resource.name}.`}</strong>
            </Typography>
          </>
        );
    }
    return <Typography><Skeleton /></Typography>;
  };

  const renderMessage = () => {
    if (!!user) {
      return outgoing
        ? (
          <>
            <Typography display="inline">{`Request sent to `}</Typography>
            <Typography display="inline"><strong>{`${user.displayName}.`}</strong></Typography>
          </>
        )
        : (
          <>
            <Typography display="inline">{`Requested by `}</Typography>
            <Typography display="inline"><strong>{`${user.displayName}, ${user.email}.`}</strong></Typography>
          </>
        );
    }
    return <Typography><Skeleton /></Typography>;
  };

  const outgoingActions = request.status !== 'declined'
    ? (
      <>
        <Button
          color="inherit"
          size="small"
          sx={{ mr: 1 }}
          onClick={() => handleAction(request.id, 'revoked')}
        >
          Revoke
        </Button>
        <Button
          color="inherit"
          size="small"
          onClick={() => handleResend(request)}
        >
          Resend
        </Button>
      </>
    ) : (
      <Button
        color="inherit"
        size="small"
        sx={{ mr: 1 }}
        onClick={() => handleDelete(request.id)}
      >
        Dismiss
      </Button>
    );

  const incomingActions = (
    <>
      <Button
        color="inherit"
        size="small"
        sx={{ mr: 1 }}
        onClick={() => handleAction(request.id, 'declined')}
      >
        Decline
      </Button>
      <Button
        color="primary"
        size="small"
        onClick={() => handleAction(request.id, 'accepted')}
      >
        Accept
      </Button>
    </>
  );

  return (
    <Alert
      severity="info"
      variant="filled"
      sx={{
        alignItems: 'center',
        borderRadius: 0,
        backgroundColor: 'action.disabledBackground',
        mb: 1,
      }}
      action={
        !!submitting && submitting[request.id]
          ? <CircularProgress size={30} />
          : (
            <Stack direction="row">
              {outgoing ? outgoingActions : incomingActions}
            </Stack>
          )
      }
    >
      <AlertTitle>
        {renderTitle()}
      </AlertTitle>
      <em>{renderMessage()}</em>
    </Alert>
  );
};

export default TransferRequestCard;
