import React from 'react';
import queryString, { ParsedQuery } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

interface UseQueryParams {
  setQueryParam: (key: string, value: unknown, push?: boolean) => void;
  queryParams: ParsedQuery;
}

const useQueryParams = (): UseQueryParams => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const queryParams: ParsedQuery = React.useMemo(() => queryString.parse(search), [search]);
  const setQueryParam = React.useCallback((key: string, value: unknown, push = true) => {
    const queryStr = queryString.stringify({
      ...queryParams,
      [key]: value,
    });
    if (push) {
      history.push(`${pathname}?${queryStr}`);
    } else {
      history.replace(`${pathname}?${queryStr}`);
    }
  }, [history, pathname, queryParams]);
  return {
    setQueryParam,
    queryParams,
  };
};

export default useQueryParams;
