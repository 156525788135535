import { useKoverse } from '@koverse/react';
import { Alert, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { get } from 'lodash';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import config from '../../../config';
import { ThirdPartyApplication } from '../../../declarations';
import { ApplicationDetails, ApplicationUrls } from '../../ApplicationForm';
import ApplicationSecrets from '../../ApplicationSecrets';

interface Props {
  open: boolean;
  onClose: () => void;
}

type RedirectInput = {
  url: string;
}

type Inputs = {
  name: string;
  url: string;
  description: string;
  redirectUrl: RedirectInput[];
};

const CreateApplication = ({ open, onClose }: Props): React.ReactElement => {
  const steps = ['Application Name', 'Application URLs'];
  const history = useHistory();
  const [application, setApplication] = useState<null | ThirdPartyApplication>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [savedFields, setSavedFields] = useState({});
  const [submissionError, setSubmissionError] = useState('');
  const methods = useForm<Inputs>({
    mode: 'onChange',
  });
  const { client, workspace } = useKoverse();
  const {
    handleSubmit,
    formState,
    reset,
  } = methods;

  const onSubmit = async (data: Inputs) => {
    if (activeStep < (steps.length - 1)) {
      setSavedFields({
        ...savedFields,
        ...data,
      });
      setActiveStep(activeStep + 1);
    } else {
      try {
        const app = await client.service('applications').create({
          ...savedFields,
          ...data,
          redirectUrl: data.redirectUrl.map(d => d.url).join(','),
          workspaceId: workspace.id,
        });
        setApplication(app);

      } catch (e) {
        if (e instanceof Error) {
          const errors = get(e, 'errors', []);
          setSubmissionError(errors.length ? errors[0] : e.message);
        } else {
          setSubmissionError('There was a problem creating the application');
        }
      }
    }
  };

  const close = () => {
    onClose();
    history.push(`/applications/${application?.id}`);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    reset(savedFields);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
    >
      {application ? (
        <>
          <DialogContent>
            <Typography variant="h5" gutterBottom>Your OAuth application has been created!</Typography>
            <Typography variant="body2" color="text.secondary" component="p" sx={{ mb: 2 }}>
              Here are the client id and client secret needed to complete your next steps. You can access these secrets at any time by navigating to the applications page.
            </Typography>
            <Typography variant="caption" color="text.secondary" component="p" sx={{ mb: 4 }}>
              Visit our <Link href="https://documentation.koverse.com/docs/applications/creating-applications/" target="_blank" sx={{ textDecoration: 'none' }}>Documentation</Link> for additional information on creating an OAuth application
            </Typography>
            <ApplicationSecrets application={application} hidden />
          </DialogContent>
          <DialogActions sx={{ m: 2 }}>
            <Button variant="contained" onClick={close} >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stepper activeStep={activeStep} sx={{ m: 2, mb: 4 }}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === 0 && (
              <FormProvider {...methods}>
                <Typography variant="h5" gutterBottom>Create an OAuth application</Typography>
                <Typography color="text.secondary" gutterBottom>Make server-to-server interactions by creating an access token from the OAuth 2.0 auth servers and gain access to KDP data to be used in external applications.</Typography>
                <Typography variant="body2" color="text.secondary" component="p" sx={{ mb: 4 }}>
                    Visit our <Link href={`//${config.documentationDomain}/docs/applications/creating-applications/`} target="_blank" rel="noreferrer noopener">documentation</Link> for additional information on creating an OAuth application
                </Typography>
                <ApplicationDetails />
              </FormProvider>
            )}
            {activeStep === 1 && (
              <FormProvider {...methods}>
                <Typography variant="h5" gutterBottom>Enter required application URLs</Typography>
                <Typography variant="body2" color="text.secondary" component="p" sx={{ mb: 4 }}>
                    Visit our <Link href={`//${config.documentationDomain}/docs/applications/creating-applications/`} target="_blank" rel="noreferrer noopener">documentation</Link> for additional information on creating an OAuth application
                </Typography>
                <ApplicationUrls />
              </FormProvider>
            )}
            {submissionError && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{
                  mt: 3,
                }}
              >
                {submissionError}
              </Alert>
            )}
          </DialogContent>
          <DialogActions sx={{ m: 2 }}>
            {activeStep === 0 ? (
              <Button onClick={onClose}>Cancel</Button>
            ) : (
              <Button onClick={handleBack}>Back</Button>
            )}
            <Button
              variant="contained"
              type="submit"
              disabled={!formState.isValid}
            >
              {activeStep < (steps.length - 1) ? 'Next Step' : 'Create Application'}</Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default CreateApplication;
