import React from 'react';
import get from 'lodash/get';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useTranslation, Trans } from 'react-i18next';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Collaborators from './Collaborators';
import InviteUsersForm from './InviteUsersForm';
import { WorkspaceInvitation } from '../../../declarations';

interface Props {
  open: boolean;
  onClose: () => void;
}

const InviteUser = ({ open, onClose }: Props): React.ReactElement => {
  const { client, workspace } = useKoverse();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [invitationToRevoke, setInvitationToRevoke] = React.useState<WorkspaceInvitation | null>(null);

  const handleRevokeInvitation = async () => {
    if (!!invitationToRevoke) {
      try {
        await client.service('workspace-invitations').remove(invitationToRevoke.id);
        enqueueSnackbar(t('dialogs.invitations.revokeSuccessToast'), {
          variant: 'success',
        });
        setInvitationToRevoke(null);
      } catch (e) {
        enqueueSnackbar(get(e, 'message', e), { variant: 'error' });
        setInvitationToRevoke(null);
      }
    }
  };

  const renderInvitations = (
    <>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {t('dialogs.invitations.title', { workspace: get(workspace, 'name', 'your workspace') })}
          <IconButton onClick={onClose} aria-label="close-dialog">
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <InviteUsersForm />
        <Divider sx={{ my: 2 }} />
        <Collaborators setInvitationToRevoke={setInvitationToRevoke} />
      </DialogContent>
    </>
  );

  const renderConfirmRevokeInvitation = (
    <>
      <DialogTitle>
        {t('dialogs.invitations.revoke.title')}
      </DialogTitle>
      <DialogContent>
        <Trans
          i18nKey='dialogs.invitations.revoke.subheader'
          values={{ user: get(invitationToRevoke, 'email') }}
          components={{ color: <Typography color="primary" display="inline" /> }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => setInvitationToRevoke(null)}
        >
          {t('forms.cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleRevokeInvitation}
        >
          {t('forms.revoke')}
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="md">
      {invitationToRevoke
        ? renderConfirmRevokeInvitation
        : renderInvitations}
    </Dialog>
  );
};

export default InviteUser;
