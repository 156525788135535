import { useKoverse } from '@koverse/react';
import DeleteIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { User } from '../../../declarations';
import UserAvatar from '../../UserAvatar';
import UserSearch from './UserSearch';

interface Props {
  owner?: User;
  loading: boolean;
}

const UserFields = ({ owner, loading }: Props): React.ReactElement | null => {
  const { user: currentUser } = useKoverse();
  const { t } = useTranslation();
  const {
    control,
    register,
  } = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'users',
    keyName: 'field_id',
  });

  const currentIds = React.useMemo(() => {
    if (!!owner) {
      return [...fields.map(f => f.id), owner.id];
    }
  }, [fields, owner]);

  const onSelect = (user: User | null) => {
    if (!!user) {
      append(user);
    }
  };

  if (!owner) {
    return null;
  }

  return (
    <Box>
      <UserSearch onSelect={onSelect} currentIds={currentIds} disabled={loading} />
      <Box sx={{ my: 1, pr: 5, maxHeight: 500, overflow: 'auto' }}>
        <ListItem
          sx={{
            backgroundColor: 'action.disabledBackground',
            height: theme => theme.spacing(7.5),
            my: 1,
          }}
        >
          <ListItemAvatar><UserAvatar user={owner} /></ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary={`${owner.displayName} ${owner.id === currentUser.id ? '(you)' : ''}`}
            secondary={owner.email}
          />
          <Typography
            component="label"
            color="textSecondary"
            sx={{ mr: 7 }}
          >
            Group leader
          </Typography>
        </ListItem>
        {!!fields.length && fields.map((field, index) => {
          return (
            <Stack
              key={field.field_id}
              direction="row"
              alignContent="center"
              justifyContent="center"
              sx={{ mb: 1 }}
            >
              <ListItem
                sx={{
                  backgroundColor: 'action.disabledBackground',
                  height: theme => theme.spacing(7.5),
                }}
              >
                <ListItemAvatar><UserAvatar user={field as User} /></ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  secondaryTypographyProps={{ noWrap: true }}
                  primary={`${field.displayName} ${field.id === currentUser.id ? '(you)' : ''}`}
                  secondary={field.email}
                />
                <input name={`users[${index}].id`} defaultValue={field.id} ref={register()} hidden />
                {field.membershipId && (
                  <input
                    name={`users[${index}].membershipId`}
                    defaultValue={field.membershipId}
                    ref={register()}
                    hidden
                  />
                )}
                <Controller
                  render={(props) => (
                    <FormControl
                      variant="standard"
                      sx={{ minWidth: 150 }}
                    >
                      <Select
                        {...props}
                        label={t('forms.labels.role')}
                        margin="none"
                        disabled={loading || currentUser.id === field.id}
                      >
                        <MenuItem value="member">Group member</MenuItem>
                        <MenuItem value="admin">Group leader</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  name={`users[${index}].groupRole`}
                  control={control}
                  defaultValue={field.groupRole || 'member'}
                  rules={{
                    required: t('forms.validation.required') as string,
                  }}
                />
              </ListItem>
              <IconButton
                aria-label="remove"
                size="small"
                sx={{
                  alignSelf: 'center',
                  mr: -4.5,
                  ml: 1,
                }}
                onClick={() => remove(index)}
                disabled={loading || currentUser.id === field.id}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          );
        })}
      </Box>
    </Box>
  );
};

export default UserFields;
