import React from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Box,
  Divider,
} from '@mui/material';
import { NavLink, useRouteMatch } from 'react-router-dom';
import AttributesIcon from '@mui/icons-material/EditAttributesOutlined';
import ActiveAttributesIcon from '@mui/icons-material/EditAttributes';
import GroupsIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ActiveGroupsIcon from '@mui/icons-material/People';
import UsersIcon from '@mui/icons-material/PersonOutline';
import ActiveUsersIcon from '@mui/icons-material/Person';
import { useKoverse } from '@koverse/react';

function WorkspaceDetailSettingsMenu(): React.ReactElement {
  const { user, theme } = useKoverse();
  const attributesRoute = useRouteMatch('/attributes');
  const groupsRoute = useRouteMatch('/groups');
  const usersRoute = useRouteMatch('/users');
  const isAdmin = user.role === 'admin';
  return (
    <Box>
      <Divider />
      <ListItem
        sx={{
          pt: 1.5,
          pl: 4.3,
        }}
      >
        <ListItemText primary="WORKSPACE SHORTCUTS" />
      </ListItem>
      {isAdmin && (
        <ListItemButton
          sx={{
            pl: 4.3,
          }}
          component={NavLink}
          to={`/users`}
          activeStyle={{
            background: theme.palette.action.selected,
          }}
        >
          <ListItemIcon>
            {usersRoute ? <ActiveUsersIcon /> : <UsersIcon />}
          </ListItemIcon>
          <ListItemText
            primary="Users"
          />
        </ListItemButton>
      )}
      <ListItemButton
        sx={{
          pl: 4.3,
        }}
        component={NavLink}
        to={`/groups`}
        activeStyle={{
          background: theme.palette.action.selected,
        }}
      >
        <ListItemIcon>
          {groupsRoute ? <ActiveGroupsIcon /> : <GroupsIcon />}
        </ListItemIcon>
        <ListItemText
          primary="Groups"
        />
      </ListItemButton>
      {isAdmin && (
        <ListItemButton
          sx={{
            pl: 4.3,
          }}
          component={NavLink}
          to={`/attributes`}
          activeStyle={{
            background: theme.palette.action.selected,
          }}
        >
          <ListItemIcon>
            {attributesRoute ? <ActiveAttributesIcon /> : <AttributesIcon />}
          </ListItemIcon>
          <ListItemText
            primary="Attributes"
          />
        </ListItemButton>
      )}
    </Box>
  );
}

export default WorkspaceDetailSettingsMenu;
