import React, { useMemo, useState, Dispatch, SetStateAction } from 'react';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { times, get } from 'lodash';
import { useKoverse } from '@koverse/react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardPreview from '../CardPreview';
import SetupPayment from '../SetupPayment';
import SubscriptionLevelButton from './SubscriptionLevelButton';
import useSubscription from '../../hooks/useSubscription';
import useSubscriptionLevels from '../../hooks/useSubscriptionLevels';
import { StripeHandlerEvent, Subscription } from '../../declarations';

interface Props {
  handleBack: () => void;
  paymentMethod: undefined | Subscription['default_payment_method'];
  setPaymentMethod: Dispatch<SetStateAction<undefined | Subscription['default_payment_method']>>;
}

type PaymentState = {
  stripe?: Stripe;
  elements?: StripeElements;
  cardComplete: boolean;
};

const CreateSubscription = ({
  handleBack,
  paymentMethod,
  setPaymentMethod,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { user } = useKoverse();
  const {
    createSubscription,
    subscription,
    selectedSubscriptionLevelKey,
    setSelectedSubscriptionLevelKey,
  } = useSubscription();
  const { loading, subscriptionLevels } = useSubscriptionLevels();
  const [payment, setPayment] = useState<PaymentState>({ cardComplete: false });
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined);
  let disabled = !selectedSubscriptionLevelKey;

  const subscriptionLevelKeys = useMemo(() => {
    return Object.keys(subscriptionLevels).filter(key => key !== 'onPrem');
  }, [subscriptionLevels]);

  const paymentRequired = useMemo(() => {
    if (selectedSubscriptionLevelKey === 'starter' && !user.usedFreeTrial) {
      return false;
    }
    return !!selectedSubscriptionLevelKey;
  }, [selectedSubscriptionLevelKey, user]);

  if (paymentRequired && !payment.cardComplete && !paymentMethod) {
    disabled = true;
  }

  const next = async () => {
    if (!selectedSubscriptionLevelKey) {
      setErrorMessage('Select a plan');
    }
    const { elements, stripe } = payment;
    if (!elements || !stripe) {
      setErrorMessage('There is a problem with the form, please refresh the page.');
    }
    setSubmitting(true);
    setErrorMessage(undefined);
    let paymentMethodId = paymentMethod?.id;
    if (paymentRequired && !paymentMethod) {
      const res = await stripe?.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements: elements as StripeElements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.href}?stripeRedirect=true`,
        },
      });

      if (res?.error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(res?.error.message);
        setSubmitting(false);
        return;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        paymentMethodId = res?.setupIntent?.payment_method;
      }
    }
    try {
      await createSubscription({
        subscriptionLevel: selectedSubscriptionLevelKey as string,
        paymentMethod: paymentRequired ? paymentMethodId : undefined,
      });
    } catch (e: any) {
      setErrorMessage(`${e}`);
      setSubmitting(false);
    }
  };

  const handlePaymentChange = (e: StripeHandlerEvent, stripe: Stripe, elements: StripeElements) => {
    setPayment({
      stripe,
      elements,
      cardComplete: e.complete,
    });
  };

  React.useEffect(() => {
    setErrorMessage(undefined);
    const metadataSubscriptionLevel = get(subscription, 'metadata.subscriptionLevel');
    if (!selectedSubscriptionLevelKey && metadataSubscriptionLevel) {
      setSelectedSubscriptionLevelKey(metadataSubscriptionLevel);
    }
  }, [selectedSubscriptionLevelKey, setSelectedSubscriptionLevelKey, subscription]);

  return (
    <Box>
      <Box sx={{ px: 8, pt: 5, pb: 8, alignItems: 'start' }}>
        <Typography variant="h6" align="left" sx={{ mb: 2 }}>
          {t('pages.initialization.createSubscription.title')}
        </Typography>
        <Grid container spacing={2}>
          {loading && times(3, (i) => (
            <Grid item xs={4} key={i}>
              <Skeleton
                variant="rectangular"
                height={118}
                aria-label="placeholder-while-loading-buttons"
              />
            </Grid>
          ))}
          {!loading && subscriptionLevelKeys.map((key) => (
            <Grid item xs={4} key={key}>
              <SubscriptionLevelButton
                subscriptionLevel={subscriptionLevels[key]}
                subscriptionLevelKey={key}
                selectedSubscriptionLevelKey={selectedSubscriptionLevelKey}
                setSelectedSubscriptionLevelKey={setSelectedSubscriptionLevelKey}
              />
            </Grid>
          ))}
          <Grid item>
            <Link underline="none" href="https://koverse.com/pricing" target="_blank noreferrer">
              <Stack direction="row" alignContent="center">
                <Typography variant="caption" sx={{ lineHeight: 1 }}>
                  {t('pages.initialization.createSubscription.link')}
                </Typography>
                <OpenInNewIcon sx={{ fontSize: 12, ml: 0.5 }} />
              </Stack>
            </Link>
          </Grid>
        </Grid>
        <Collapse in={paymentRequired && !!paymentMethod?.card}>
          <Box sx={{ mt: 4 }}>
            {paymentMethod && paymentMethod?.card && (
              <CardPreview card={paymentMethod.card} onEdit={() => { setPaymentMethod(undefined); }} />
            )}
          </Box>
        </Collapse>
        <Collapse in={paymentRequired && !paymentMethod}>
          <Box sx={{ mt: 4 }}>
            <SetupPayment onChange={handlePaymentChange} />
          </Box>
        </Collapse>
        {errorMessage && (
          <Alert
            severity="error"
            variant="outlined"
            sx={{
              width: '100%',
            }}
          >
            {errorMessage}
          </Alert>
        )}
        <Box sx={{ textAlign: 'right', mt: 4 }}>
          {submitting ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                onClick={handleBack}
              >
                {t('common.back')}
              </Button>
              <Button
                variant="contained"
                onClick={next}
                sx={{ ml: 1 }}
                disabled={disabled}
              >
                {t('common.next')}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateSubscription;
