import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const SecurityFields = (): React.ReactElement => {
  const {
    control,
    errors,
    formState,
    setValue,
    trigger,
  } = useFormContext();
  const { isSubmitting, touched } = formState;

  const hasFieldError = get(touched, 'accessControlLabel.securityLabelInfo.fields')
    && !!get(errors, 'accessControlLabel.securityLabelInfo.fields');

  const hasReplacementFieldError = get(touched, 'accessControlLabel.securityLabelInfo.replacementString')
    && !!get(errors, 'accessControlLabel.securityLabelInfo.replacementString');

  const ifEmpty = useWatch({
    control,
    name: 'accessControlLabel.securityLabelInfo.ifEmpty',
    defaultValue: '',
  });

  React.useEffect(() => {
    trigger();

    if (ifEmpty !== 'replace') {
      setValue('accessControlLabel.securityLabelInfo.replacementString' as any, '');
    }
  }, [ifEmpty, setValue, trigger]);

  return (
    <>
      <Grid item>
        <FormControl
          fullWidth
          sx={{ maxWidth: 620 }}
        >
          <FormLabel>Identify the field that contains your labels</FormLabel>
          <Controller
            as={TextField}
            margin="normal"
            name="accessControlLabel.securityLabelInfo.fields"
            id="parser-label-fields"
            control={control}
            variant="outlined"
            label="Enter the field that contains your labels"
            rules={{
              required: 'Required',
            }}
            error={hasFieldError}
            helperText={
              hasFieldError
              && get(errors, 'accessControlLabel.securityLabelInfo.fields.message')
            }
            defaultValue=""
            disabled={isSubmitting}
          />
        </FormControl>
      </Grid>
      <Grid item><Divider sx={{ maxWidth: 620 }} /></Grid>
      <Grid item>
        <Typography sx={{ mb: 2 }}>2. How do you want to treat unlabeled records in your data?</Typography>
        <FormControl
          fullWidth
          disabled={isSubmitting}
        >
          <Controller
            as={
              <RadioGroup aria-label="if-values-are-empty">
                <FormControlLabel
                  value="remove"
                  control={<Radio color="primary" />}
                  label="Do not import records without labels."
                />
                <FormControlLabel
                  value="ignore"
                  control={<Radio color="primary" />}
                  label="Import the records anyway. Do not apply ABAC."
                />
                <Typography sx={{ ml: 4 }} variant="body2">
                  Users with access to this dataset can view records without labels.
                </Typography>
                <FormControlLabel
                  value="replace"
                  control={<Radio color="primary" />}
                  label="Provide a new label:"
                />
              </RadioGroup>
            }
            name="accessControlLabel.securityLabelInfo.ifEmpty"
            control={control}
            rules={{
              required: 'Required',
            }}
            defaultValue={null}
          />
        </FormControl>
        <FormControl
          fullWidth
          margin='normal'
        >
          <Box component='span'
            sx={{
              marginLeft: (theme) => theme.spacing(4),
            }}
          >
            <Controller
              as={TextField}
              name="accessControlLabel.securityLabelInfo.replacementString"
              control={control}
              variant="outlined"
              size="small"
              placeholder="Enter label"
              aria-label="empty-field-replacement-string"
              aria-placeholder="Enter label"
              disabled={ifEmpty !== 'replace' || isSubmitting}
              error={hasReplacementFieldError}
              helperText={
                (hasReplacementFieldError && get(errors, 'accessControlLabel.securityLabelInfo.replacementString.message'))
                || ''
              }
              defaultValue=""
              style={{ width: '355px' }}
              rules={(ifEmpty === 'replace')
                ? { required: 'Required' }
                : undefined
              }
            />
          </Box>
        </FormControl>
      </Grid>
    </>
  );
};

export default SecurityFields;
