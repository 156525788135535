import GroupsIcon from '@mui/icons-material/PeopleOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  numSelected: number;
  openCreateGroup: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

const UsersTableToolbar = ({
  numSelected,
  openCreateGroup,
  query,
  setQuery,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mt: 3,
        px: 2,
        py: 1,
        ...(numSelected > 0 && {
          bgcolor: theme => theme.palette.action.selected,
        }),
      }}
    >
      {numSelected === 0 && (
        <TextField
          hiddenLabel
          id="search-users"
          variant="filled"
          size="small"
          margin="none"
          sx={{
            width: 400,
            '& .MuiFilledInput-root.MuiFilledInput-underline': {
              borderRadius: '4px',
            },
            [`& .MuiFilledInput-root.MuiFilledInput-underline:before,
                .MuiFilledInput-root.MuiFilledInput-underline:hover:before,
                .MuiFilledInput-root.MuiFilledInput-underline:after`]: {
              border: 'none',
            },
          }}
          placeholder={t('forms.placeholders.search')}
          value={query}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      {numSelected > 0 && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ height: 40 }}
        >
          <Typography
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} selected
          </Typography>
          <Button
            onClick={() => openCreateGroup(true)}
            startIcon={<GroupsIcon />}
          >
            Create Group
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default UsersTableToolbar;
