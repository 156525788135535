import React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import config from '../../config';
import { ArrowForwardIos } from '@mui/icons-material';
import { Session } from '../../components/ActiveSessionsProvider';
import useActiveSessions from '../../hooks/useActiveSessions';
import { groupBy } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { useServiceItem } from '@koverse/react';
import { Workspace } from '../../declarations';
import Skeleton from '@mui/material/Skeleton';

const ActiveSession = ({ session, ...rest }: { session: Session }) => {
  const {
    item,
    initialized,
  } = useServiceItem('workspaces', session.workspaceId);
  const workspace = item as Workspace;
  return (
    <ListItemButton
      key={session.workspaceId}
      component="a"
      href={`https://${session.workspaceId}.${config.domain}/login?email=${session.email}`}
      {...rest}
    >
      <ListItemText
        primary={!initialized ? <Skeleton sx={{ maxWidth: '300px' }} /> : workspace?.name || session.workspaceId}
        primaryTypographyProps={{ variant: 'h6' }}
      />
      <ArrowForwardIos />
    </ListItemButton>
  );
};

function ActiveSessions(): React.ReactElement {

  const { activeSessions } = useActiveSessions();
  const emails = Object.entries(groupBy(activeSessions, 'email'));
  return (
    <Stack sx={{ width: '100%' }} alignItems="center">
      <Typography variant="h6" sx={{ mb: 2 }}>You are already signed in to...</Typography>
      {emails.map(([email, sessions]) => (
        <Paper key={email} sx={{ mb: 2, width: '100%' }}>
          <Typography
            sx={{
              p: 2,
            }}
            color="textSecondary"
          >
            Workspaces for {email}
          </Typography>
          <List>
            {sessions.map((session) => (
              <ActiveSession session={session} key={session.workspaceId} />
            ))}
          </List>
        </Paper>
      ))}
      <Typography>
        {'Don\'t see the workspace you\'re looking for? Try '}
        <Link component={RouterLink} to="/login?sessions=false">
          entering your email address.
        </Link>
      </Typography>
    </Stack>
  );
}

export default ActiveSessions;
