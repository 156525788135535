import React, { SyntheticEvent, useEffect, useState, useCallback } from 'react';
import { useKoverse } from '@koverse/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import has from 'lodash/has';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ParameterGroup from './ParameterGroup';
import StaticIpList from '../Dialogs/StaticIp';
import { SourceType } from '../../declarations';

type Inputs = {
  sourceType: SourceType | undefined;
};

interface Props {
  onCancel: () => void;
  onNext: () => void;
}

const ConfigurationForm = ({ onNext, onCancel }: Props): React.ReactElement => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const form = useFormContext<Inputs>();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [sourceTypes, setSourceTypes] = useState<SourceType[]>([]);
  const [staticIps, setStaticIps] = useState<string[]>([]);
  const sourceType = form.getValues('sourceType');

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await client.service('source-types').find();
      const staticIps = await client.service('static-ips').find();

      setSourceTypes(data);
      setStaticIps(staticIps);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(`There was an error: ${e}`, {
        variant: 'error',
      });
    }

  }, [client, enqueueSnackbar]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <Grid item>
        <LinearProgress />
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Stack>
          <Controller
            render={(props) => (
              <Autocomplete
                {...props}
                onChange={(e: SyntheticEvent, v: SourceType | null) => {
                  form.setValue('sourceType', v);
                  form.trigger();
                }}
                options={sourceTypes}
                sx={{ mb: 3 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Source"
                  />
                )}
                getOptionLabel={(opt) => opt.name}
              />
            )}
            defaultValue={null}
            rules={{ required: true }}
            name="sourceType"
            control={form.control}
          />
          <Box
            sx={{
              backgroundColor: 'action.disabledBackground',
              p: 1,
              borderRadius: 1,
              mb: 2,
            }}
          >
            <Stack
              direction="row"
              alignItems="start"
              justifyContent="space-between"
            >
              <Box>
                <Typography
                  variant="caption"
                >
                  {t('pages.dataset.sourceConfiguration.staticIp.header')}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  {t('pages.dataset.sourceConfiguration.staticIp.subheader')}
                </Typography>
              </Box>
              <Button
                size="small"
                onClick={() => setOpen(true)}
              >
                {t('pages.dataset.sourceConfiguration.staticIp.cta')}</Button>
            </Stack>
          </Box>
        </Stack>
        <Stack spacing={2}>
          {sourceType && (
            <>
              <Typography variant="h6">{sourceType.name}</Typography>
              {sourceType.documentationLink && (
                <Typography variant="caption">
                  View our&nbsp;
                  <Link
                    aria-label={`${sourceType.name} documentation link`}
                    href={sourceType.documentationLink}
                    underline="none"
                    target="_blank"
                    rel="noreferrer"
                  >
                      documentation&nbsp;
                  </Link>
                  {`for additional information on connecting to ${sourceType.name}`}
                </Typography>
              )}
              {sourceType.description && (
                <Typography color="textSecondary" variant="body2">{sourceType.description}</Typography>
              )}
              <Divider variant="middle" />
            </>
          )}
          {sourceType && sourceType.parameterGroups.map((pg, i) => (
            <ParameterGroup key={i} parameterGroup={pg} />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2, textAlign: 'right' }}>
        <Button
          color="primary"
          sx={{ mr: 2 }}
          aria-label="cancel"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          aria-label="next"
          disabled={
            !sourceType
            || has(form.errors, 'sourceType')
            || has(form.errors, 'params')
          }
          onClick={onNext}
        >
          Next
        </Button>
      </Grid>
      <StaticIpList
        open={open}
        onClose={() => setOpen(false)}
        ipList={staticIps}
      />
    </>
  );
};

export default ConfigurationForm;
