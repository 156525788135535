import { useKoverse } from '@koverse/react';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import config from '../../config';
import { DatasetAccess, ThirdPartyApplication } from '../../declarations';
import ApplicationAccess from './ApplicationAccess';
import ApplicationPrivacy from './ApplicationPrivacy';
import ApplicationUrls from './ApplicationUrls';

type RedirectInput = {
  url: string;
}

interface Field {
  id: string;
}

interface AccessField extends Field {
  datasetId: string;
  action: string;
}

interface UserField extends Field {
  userId: string;
}

type Inputs = {
  name: string;
  url: string;
  description: string;
  redirectUrl: RedirectInput[];
  requiredDatasetAccess: DatasetAccess[];
  accessFields: AccessField[];
  type: string;
  restrictAccess: boolean;
  enableDatasetAccess: boolean;
  enableAllowedUsers: boolean;
  allowedUsers: UserField[];
  visible: boolean;
};

interface ApplicationFormProps {
  application: ThirdPartyApplication;
}

const ApplicationForm = ({
  application,
}: ApplicationFormProps): React.ReactElement | null => {
  const { enqueueSnackbar } = useSnackbar();
  const { client } = useKoverse();
  const applicationsService = client.service('applications');

  const getDefaultValues = (app: ThirdPartyApplication) => ({
    name: app.name,
    url: app.url,
    description: app.description,
    redirectUrl: get(app, 'redirectUrl', '').split(',').map((url: string) => ({ url })),
    accessFields: app.requiredDatasetAccess,
    type: app.type,
    restrictAccess: app?.allowedUsers?.length || app?.requiredDatasetAccess?.length ? true : false,
    enableDatasetAccess: !!app?.requiredDatasetAccess?.length,
    enableAllowedUsers: !!app?.allowedUsers?.length,
    allowedUsers: get(app, 'allowedUsers', []).map((userId: string) => ({ userId })),
    visible: app.visible,
  });

  const methods = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: getDefaultValues(application),
  });
  const {
    formState,
    handleSubmit,
    reset,
    setError,
  } = methods;
  const { isSubmitting, isDirty } = formState;

  const onSubmit = async (data: Inputs) => {
    if (data.enableAllowedUsers && !data?.allowedUsers) {
      setError('allowedUsers', {
        message: 'Required',
      });
      throw new Error('Validation Error');
    }
    try {
      const patchData = {
        ...data,
        redirectUrl: data.redirectUrl.map(d => d.url).join(','),
        requiredDatasetAccess: data.requiredDatasetAccess
          ? data.requiredDatasetAccess.map((d: DatasetAccess) => ({
            action: get(d, 'action'),
            datasetId: get(d, 'dataset.id'),
          }))
          : [],
        allowedUsers: data.allowedUsers ? data.allowedUsers.map(d => d.userId) : [],
      };

      const result = await applicationsService.patch(application.id, patchData);
      reset(getDefaultValues(result), { isValid: true });

      enqueueSnackbar('Saved changes', {
        variant: 'success',
      });
    } catch (error) {
      reset();
      enqueueSnackbar(`There was an error updating the application: ${get(error, 'message', error)}`, {
        variant: 'error',
      });
    }
  };

  if (!application) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body2" color="text.secondary" component="p" sx={{ mb: 2 }}>
          Visit our <Link href={`//${config.documentationDomain}/docs/applications/creating-applications/`} target="_blank" rel="noreferrer noopener" sx={{ textDecoration: 'none' }}>Documentation</Link> for additional information on creating an OAuth application
        </Typography>
        <ApplicationUrls />
        <Divider />
        <ApplicationAccess />
        <Divider />
        <ApplicationPrivacy />
        <Box
          sx={{
            textAlign: 'right',
          }}
        >
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              aria-label="update-application-button"
              disabled={!isDirty}
              type="submit"
              color="primary"
              variant="contained"
            >
              Save Changes
            </Button>
          )}
        </Box>
      </form>
    </FormProvider>
  );
};

export default ApplicationForm;
