import React from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useFormContext, useWatch } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Select from './Select';
import Text from './Text';
import Checkbox from './Checkbox';
import { Parameter } from '../../declarations';
import ParameterFieldArray from './ParameterFieldArray';
import RadioGroup from './RadioGroup';

interface Props {
  parameter: Parameter;
  name?: string;
  disabled?: boolean;
}

type Value = string | boolean | number;

type Inputs = {
  [key: string]: Value;
};

type ShowIf = {
  name: 'string',
  value: Value;
}

const ParameterInput = ({
  parameter,
  name,
  disabled = false,
}: Props): React.ReactElement | null => {
  const { trigger, control } = useFormContext();

  const inputValues: Inputs | undefined = useWatch({
    control,
    name: 'params',
  });

  const showInputParameter = React.useMemo(() => {

    const expectedValues = get(parameter, 'showIf') as unknown as Array<ShowIf>;

    if (!!inputValues && expectedValues) {
      const matchedValues = expectedValues.filter((d: ShowIf) => {
        if (parameter.type === 'text' && !d.value) {
          return !!inputValues[d.name];
        }
        return inputValues[d.name] === d.value;
      });

      return isEqual(matchedValues, expectedValues);
    }
    return true;
  }, [parameter, inputValues]);

  React.useEffect(() => {
    trigger();
  }, [trigger, showInputParameter]);

  if (!showInputParameter) {
    return null;
  }

  switch (parameter.type) {
    case 'text':
    case 'number':
    case 'password':
      return <Text parameter={parameter} name={name} disabled={disabled} />;
    case 'select':
      return <Select parameter={parameter} name={name} disabled={disabled} />;
    case 'checkbox':
      return <Checkbox parameter={parameter} name={name} disabled={disabled} />;
    case 'fieldArray':
      return <ParameterFieldArray parameter={parameter} />;
    case 'radioGroup':
      return <RadioGroup parameter={parameter} disabled={disabled} />;
    default:
      return <Typography>Not Implemented</Typography>;
  }
};

export default ParameterInput;
