import React, { createContext, useContext } from 'react';
import createPersistedState from 'use-persisted-state';

type Mode = 'dark' | 'light';

const useThemeModeState = createPersistedState('theme-mode');

const useNavDrawerState = createPersistedState('nav-drawer');

const initialContext = {
  mode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light' as Mode,
  setMode: () => undefined,

  navDrawerOpen: true,
  setNavDrawerOpen: () => undefined,
};

export interface AppStateProviderOptions {
  children?: React.ReactNode;
}

export interface AppStateContextInterface {
  mode: Mode;
  setMode: (mode: Mode) => void;
  navDrawerOpen: boolean;
  setNavDrawerOpen: (open: boolean) => void;
}

export const AppStateContext = createContext<AppStateContextInterface>(initialContext);

export const useAppState = (): AppStateContextInterface => useContext(AppStateContext);

export const AppStateProvider = ({
  children,
}: AppStateProviderOptions): JSX.Element => {
  const [mode, setMode] = useThemeModeState(initialContext.mode);
  const [navDrawerOpen, setNavDrawerOpen] = useNavDrawerState(initialContext.navDrawerOpen);

  const value = {
    ...initialContext,
    mode: mode as Mode,
    setMode,
    navDrawerOpen: navDrawerOpen as boolean,
    setNavDrawerOpen: (open: boolean) => { setNavDrawerOpen(open); },
  };

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
