import React from 'react';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';

interface VideoPlayerProps {
  videoUrl: string;
}

const VideoPlayer = ({ videoUrl }: VideoPlayerProps): React.ReactElement => {
  const [playerReady, setPlayerReady] = React.useState<boolean>(false);

  return videoUrl ? (
    <Box
      sx={{
        mt: 3,
        position: 'relative',
        paddingTop: '56.25%',
        background: t => playerReady ? t.palette.common.black : 'inherit',
      }}
    >
      <ReactPlayer
        url={videoUrl}
        controls
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        height="100%"
        width="100%"
        aria-label='Video Player'
        onReady={() => {
          setPlayerReady(true);
        }}
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 8,
      }}
    >
      <ErrorIcon sx={{ color: theme => theme.palette.text.secondary, mb: 3 }} />
      <Typography color="textSecondary" gutterBottom>
        There was an issue viewing this file.
      </Typography>
      <Typography color="textSecondary" sx={{ mb: 4 }}>
        Download to view it on your device.
      </Typography>
    </Box>
  );
};

export default VideoPlayer;
