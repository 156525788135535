import React from 'react';
import Box from '@mui/material/Box';
import ApproveInvoice from './ApproveInvoice';
import CreateSubscription from './CreateSubscription';
import useSubscription from '../../hooks/useSubscription';
import { Subscription } from '../../declarations';

interface Props {
  handleBack: () => void;
  handleNext: () => void;
}

const ConfirmSubscriptionLevel = ({ handleBack, handleNext }: Props): React.ReactElement => {
  const { subscription } = useSubscription();
  const [paymentMethod, setPaymentMethod] = React.useState<undefined | Subscription['default_payment_method']>(subscription?.default_payment_method);

  const canceled = !!subscription?.canceled_at;

  return (
    <Box>
      {(!subscription || canceled) && (
        <CreateSubscription
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          handleBack={handleBack}
        />
      )}
      {subscription && !canceled && (
        <ApproveInvoice
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          handleNext={handleNext}
        />
      )}
    </Box>
  );
};

export default ConfirmSubscriptionLevel;
