import React from 'react';
import get from 'lodash/get';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextAreaField from '../../TextAreaField';
import { Dataset } from '../../../declarations';

type Inputs = {
  name: string;
  description: string;
};

interface Props {
  open: boolean;
  onClose: (dataset?: Dataset) => void;
  values?: Inputs | null;
}

const CreateDataset = ({ open, onClose, values }: Props): React.ReactElement => {
  const { client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const datasetService = client.service('datasets');
  const [submissionError, setSubmissionError] = React.useState(null);
  const history = useHistory();
  const {
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
  } = useForm<Inputs>({
    mode: 'onChange',
  });

  const { isDirty, isValid, isSubmitting } = formState;

  React.useEffect(() => {
    if (values) {
      reset(values);
    }
  }, [values, reset]);

  const onSubmit = async (data: Inputs) => {
    try {
      const dataset = await datasetService.create({
        ...data,
        workspaceId: workspace.id,
      });
      enqueueSnackbar(`Created ${dataset.name}`, {
        variant: 'success',
      });
      if (!values) {
        history.push(`/datasets/${dataset.id}`);
        onClose();
      } else {
        onClose(dataset);
      }
    } catch (e) {
      setSubmissionError(get(e, 'message', null));
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create a new dataset</DialogTitle>
        <DialogContent>
          <TextField
            InputLabelProps={{
              htmlFor: 'name',
            }}
            id="name"
            label="Dataset Name"
            name="name"
            variant="outlined"
            fullWidth
            error={!!errors.name}
            helperText={(errors.name && errors.name.message) || ''}
            disabled={isSubmitting}
            inputRef={register({
              required: 'Required',
            })}
            sx={{
              mb: 2,
              mt: (theme) => theme.spacing(.7),
            }}
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
            }}
          />
          <TextAreaField
            name="description"
            label="Dataset Description"
            maxLength={255}
            {...{ errors, register, isSubmitting, watch, setValue }}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                width: '100%',
                mb: 2,
              }}
            >{submissionError}</Alert>
          )}
        </DialogContent>
        <DialogActions>
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <Button
                type="button"
                color="primary"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button
                disabled={!isDirty || !isValid}
                type="submit"
                color="primary"
                variant="contained"
              >
                Create
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateDataset;
