import SearchIcon from '@mui/icons-material/Search';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { ServerError } from '../../declarations';
import { FeatureToggle } from '../FeatureToggle';

interface Props {
  search?: string;
  error?: ServerError;
}

const DOCUMENTATION_HREF = 'https://documentation.koverse.com/en/3.9/usage/indexsearchguide.html';

const NoSearchResults = ({
  search,
  error,
}: Props): React.ReactElement | null => {
  const [serverError, setServerError] = React.useState<ServerError | null>(null);
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (error) {
      setServerError(error);
    }
  }, [error]);

  if (!search) {
    return null;
  }

  const documentationLink = (
    <Link
      underline="none"
      sx={{ cursor: 'pointer' }}
      href={DOCUMENTATION_HREF}
      target="_blank"
      rel="noopener"
    >
      search documentation
    </Link>
  );

  const indexesLink = (
    <Link
      component={RouterLink}
      underline="none"
      sx={{ cursor: 'pointer' }}
      to={`${pathname}/index-settings`}
    >
      index settings
    </Link>
  );

  const renderServerError = (data: ServerError, search?: string): React.ReactElement => {
    switch (data.name) {
      case 'LuceneParseError':
      case 'SqlParseError':
      case 'QueryError':
        return (
          <>
            <SearchIcon fontSize="large" sx={{ mt: 4, mb: 1 }} />
            <Typography color="textSecondary">{`No matching results for "${search}"`}</Typography>
            <Typography color="testPrimary" sx={{ fontWeight: 'bold' }}>{data.message}</Typography>
          </>
        );
      case 'MissingIndexError':
        return (
          <>
            <SearchIcon fontSize="large" sx={{ mt: 4, mb: 1 }} />
            <Typography color="textSecondary">{`No matching results for "${search}"`}</Typography>
            <Typography color="textPrimary" sx={{ fontWeight: 'bold' }}>{data.message}</Typography>
            <Typography display="block" color="textSecondary">
              View your {indexesLink} to manage your indexes.
            </Typography>
          </>
        );
      default:
        return (
          <>
            <SearchIcon fontSize="large" sx={{ mt: 4, mb: 1 }} />
            <Typography color="textSecondary">{`No matching results for "${search}"`}</Typography>
            <Typography color="textPrimary" sx={{ fontWeight: 'bold' }}>There was an error fetching records</Typography>
          </>
        );
    }
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        minHeight: 300,
      }}
    >
      {serverError && renderServerError(serverError, search)}
      {!serverError && (
        <>
          <SearchIcon fontSize="large" sx={{ mt: 4, mb: 1 }} />
          <Typography color="textSecondary">{`No matching results for "${search}"`}</Typography>
          <Typography color="textSecondary">Please check your spelling or try different key words.</Typography>
          <FeatureToggle feature="showSupportLink">
            <Typography color="textSecondary">
              See our {documentationLink} for more information.
            </Typography>
          </FeatureToggle>
        </>
      )}
    </Stack>
  );
};

export default NoSearchResults;
