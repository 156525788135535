import Grid from '@mui/material/Grid';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { User } from '../../declarations';
import UserSearch from './UserSearch';

const ApplicationUsers = (): React.ReactElement | null => {
  const { clearErrors, control } = useFormContext();
  const {
    fields: allowedUserFields,
    append: appendUserField,
    remove: removeUserField,
  } = useFieldArray({
    control,
    name: 'allowedUsers',
  });

  const handleOnChange = (users: User | User[] | null) => {
    if (!Array.isArray(users) || users === null) {
      return;
    }
    const existingUserIds = allowedUserFields.map(f => f.userId);
    const newUserIds = users.map(u => u.id);
    const indexesToRemove = existingUserIds.map((id, i) => {
      return !newUserIds.includes(id) ? i : null;
    });
    const userIdsToAdd = newUserIds.filter(id => !existingUserIds.includes(id));

    indexesToRemove.forEach((index) => {
      if (index !== null) {
        removeUserField(index);
      }
    });
    userIdsToAdd.forEach(userId => {
      appendUserField({ userId });
    });
    clearErrors('allowedUsers');
  };

  return (
    <Grid item xs={12} sm={6}>
      <UserSearch
        onChange={handleOnChange}
        currentIds={allowedUserFields.map(field => field.userId)}
        size='small'
        AutocompleteProps={{ multiple: true }}
      />
      {allowedUserFields.map((f, index) => (
        <Controller
          key={f.id}
          control={control}
          name={`allowedUsers.${index}.userId`}
          defaultValue={f.userId}
          render={() => <></>}
        />
      ))}
    </Grid>
  );
};

export default ApplicationUsers;
