import React, { useEffect } from 'react';
import useTheme from '@mui/styles/useTheme';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ApplicationDatasetFields from './ApplicationDatasetFields';
import Add from '@mui/icons-material/Add';

interface Field {
  id: string;
}

interface AccessField extends Field {
  datasetId: string;
  action: string;
}

const ApplicationDatasets = (): React.ReactElement | null => {
  const theme = useTheme();
  const { control } = useFormContext();

  const {
    fields: accessFields,
    append: appendAccessField,
    remove: removeAccessField,
  } = useFieldArray({
    control,
    name: 'accessFields',
  });

  const handleOnAppendDataset = () => appendAccessField({});
  const handleOnRemoveDataset = (index: number) => removeAccessField(index);

  useEffect(() => {
    if (accessFields.length === 0) {
      appendAccessField({});
    }
  }, [accessFields, appendAccessField]);

  return (
    <Grid item xs={12} sm={6}>
      {accessFields.map((field, index) => {
        return (
          <ApplicationDatasetFields
            key={field.id}
            remove={handleOnRemoveDataset}
            canRemove={accessFields.length > 1}
            field={field as AccessField}
            index={index}
          />
        );
      })}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          aria-label="add-redirect-input"
          sx={{
            marginBottom: theme.spacing(2),
          }}
          size="small"
          onClick={() => handleOnAppendDataset()}
        >
          <Add fontSize="small" /> Add Dataset
        </Button>
      </Box>
    </Grid>
  );
};

export default ApplicationDatasets;
