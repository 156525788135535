import React from 'react';

const useOnScreen = <T extends Element>(ref: React.MutableRefObject<T>, rootMargin: string): boolean => {
  const [intersecting, setIntersecting] = React.useState<boolean>(true);

  React.useEffect(() => {
    const node = ref.current;
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    }, { rootMargin });

    if (node) {
      observer.observe(node);
    }

    return () => {
      if (node) {
        observer.unobserve(node);
      }
    };
  }, [ref, rootMargin]);

  return intersecting;
};

export default useOnScreen;
