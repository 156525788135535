import React from 'react';
import {
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import { useKoverse } from '@koverse/react';
import Billing from '../pages/Billing';
import ChangePlan from '../components/ChangePlan';

const BillingRoutes = (): React.ReactElement | null => {
  const { user, workspace } = useKoverse();
  const isOwner = workspace.userId === user.id;

  if (!user || !workspace) {
    return null;
  }

  return (
    <Switch>
      {!isOwner && <Redirect to="/" />}
      <Route path="/billing" exact><Billing /></Route>
      <Route path="/billing/change-plan" exact><ChangePlan /></Route>
    </Switch>
  );
};

export default BillingRoutes;
