import React from 'react';

interface MaskString {
  maskString: (data: string) => string;
}

const useShortenStringWithInteriorMasking = (
  charsBefore = 10,
  charsAfter = 8,
  mask = '...',
): MaskString => {
  const maskString = React.useCallback((fullString: string) => {
    const total = charsBefore + charsAfter + mask.length;
    const indexOfAfterMask = fullString.length - charsAfter;

    return fullString.length <= total
      ? fullString
      : `${fullString.substring(0, charsBefore)}${mask}${fullString.substring(indexOfAfterMask)}`;
  }, [charsAfter, charsBefore, mask]);

  return {
    maskString,
  };
};

export default useShortenStringWithInteriorMasking;
