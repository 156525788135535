import React from 'react';
import get from 'lodash/get';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@mui/x-data-grid';

interface GridCellOverflowProps extends GridRenderCellParams {
  value: string | number;
  width: number;
}

const willOverflow = (element: Element | null): boolean => {
  return element
    ? (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    )
    : false;
};

const GridCellOverflow = React.memo(function GridCellOverflow({
  width,
  value,
  ...props
}: GridCellOverflowProps) {
  const theme = useTheme();
  const { colDef } = props;
  const anchorDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isOverflowing = willOverflow(get(cellValue, 'current', null));
    setShowPopper(isOverflowing);
    setAnchorEl(anchorDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    const handleKeyDown = (nativeEvent: KeyboardEvent) => {
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        justifyContent: colDef.align === 'right' ? 'flex-end' : 'flex-start',
        lineHeight: theme => theme.spacing(3),
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .cellValue': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
    >
      <span
        ref={anchorDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box ref={cellValue} className="cellValue">
        {value}
      </Box>
      {showPopper && (
        <Popper
          style={{ zIndex: theme.zIndex.drawer + 1 }}
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          modifiers={[
            {
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: false,
                tether: false,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ]}
        >
          <Paper
            elevation={1}
            sx={{ maxWidth: 300 }}
          >
            <Typography variant="body2" sx={{ py: 1, px: 2 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

export default GridCellOverflow;
