import React from 'react';
import { useServiceItem } from '@koverse/react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import DatasetSearch from './DatasetSearch';

interface AccessField {
  id: string;
  datasetId: string;
  action: string;
}

interface ApplicationDatasetFieldsProps {
  field: AccessField;
  canRemove: boolean;
  remove: (index: number) => void;
  index: number;
}

const ApplicationDatasetFields = ({
  field,
  canRemove,
  remove,
  index,
}: ApplicationDatasetFieldsProps): React.ReactElement => {
  const {
    control,
    formState,
    setValue,
  } = useFormContext();
  const { isSubmitting } = formState;
  const {
    item: dataset,
  } = useServiceItem('datasets', field.datasetId);
  const handleOnRemove = (index: number) => remove(index);

  React.useEffect(() => {
    if (dataset) {
      setValue(`requiredDatasetAccess.${index}.dataset`, dataset, { shouldValidate: true });
      setValue(`requiredDatasetAccess.${index}.action`, field.action, { shouldValidate: true });
    }
  }, [dataset, index, field.action, setValue]);

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      sx={{ mb: 2 }}
    >
      <DatasetSearch
        index={index}
        name={`requiredDatasetAccess.${index}.dataset`}
        control={control}
      />
      <FormControl
        variant="outlined"
      >
        <InputLabel id="select-permission-label">Permission Level</InputLabel>
        <Controller
          as={
            <Select label="Permission Level" labelId="selection-permission-label" size="small" sx={{ minWidth: 150 }}>
              <MenuItem value="read">Read</MenuItem>
              <MenuItem value="write">Write</MenuItem>
              <MenuItem value="manage">Manage</MenuItem>
            </Select>
          }
          control={control}
          name={`requiredDatasetAccess.${index}.action`}
          id={`requiredDatasetAccess.${index}.action`}
          disabled={isSubmitting}
          defaultValue="read"
          fullWidth
          rules={{
            required: true,
          }}
        />
      </FormControl>
      {canRemove && (
        <IconButton
          aria-label="delete-datasetId"
          sx={{ ml: (theme) => theme.spacing(0.5) }}
          onClick={() => handleOnRemove(index)}
          size="small">
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default ApplicationDatasetFields;
