import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { useForm, FieldError } from 'react-hook-form';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

type Inputs = {
  email: string;
  password: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const EditEmail = ({
  open,
  onClose,
}: Props): React.ReactElement => {
  const { client, user } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const {
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      email: user.email,
      password: '',
    },
  });

  const { isDirty, isValid, isSubmitting } = formState;

  const handleOnClose = () => {
    setSubmissionError(null);
    onClose();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = async (data: Inputs) => {
    try {
      await client.service('auth-management').create({
        action: 'sendChangeEmail',
        email: data.email,
        password: data.password,
      });
      enqueueSnackbar(t('pages.account.updateEmail.successToast', { email: data.email }), {
        variant: 'info',
      });
      reset();
      handleOnClose();
    } catch (e) {
      if (isArray(get(e, 'errors'))) {
        const errorList = get(e, 'errors') || [];
        errorList.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          setError(path, {
            type: 'manual',
            message: error.message,
          });
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleOnClose} aria-labelledby="form-dialog-title" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">{t('pages.account.updateEmail.title')}</DialogTitle>
        <DialogContent>
          <TextField
            InputLabelProps={{
              htmlFor: 'email',
            }}
            id="email"
            margin='normal'
            label={t('forms.labels.email')}
            name="email"
            variant="outlined"
            fullWidth
            error={!!errors.email}
            helperText={(errors.email && errors.email.message) || t('pages.account.updateEmail.emailHelper')}
            disabled={isSubmitting}
            inputRef={register({ required: t('forms.validation.required') as string })}
          />
          <TextField
            InputLabelProps={{
              htmlFor: 'password',
            }}
            id="password"
            margin='normal'
            label={t('forms.labels.password')}
            name="password"
            variant="outlined"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            error={!!errors.password}
            helperText={(errors.password && errors.password.message) || t('pages.account.updateEmail.passwordHelper')}
            disabled={isSubmitting}
            inputRef={register({ required: t('forms.validation.required') as string })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                width: '100%',
                mt: 2,
              }}
            >
              {submissionError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <Box>
              <Button
                sx={{ mr: 2 }}
                color="primary"
                onClick={onClose}
              >
                {t('forms.cancel')}
              </Button>
              <Button
                disabled={!isDirty || !isValid}
                type="submit"
                color="primary"
                variant="contained"
              >
                {t('forms.saveChanges')}
              </Button>
            </Box>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditEmail;
