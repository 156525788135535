import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import React from 'react';
import { AuditLogData } from '../../../declarations';

interface Props extends DialogProps {
  ageInDays: string,
  data: AuditLogData[];
  isSubmitting: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  open: boolean;
  setSubmissionError: React.Dispatch<React.SetStateAction<string>>;
}

const LogConfigurationDialog = ({
  ageInDays,
  data,
  isSubmitting,
  onClose,
  onConfirm,
  open,
  setSubmissionError,
}: Props): React.ReactElement => {

  const handleOnConfirm = async () => {
    try {
      await onConfirm();
    } catch (e) {
      setSubmissionError(get(e, 'message', ''));
    }
    onClose();
  };

  const oldAuditLogs = React.useMemo(() => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const ttl = Date.now() - Number(ageInDays) * millisecondsPerDay;
    const logsToBeDeleted = data.filter(log => log.timestampMs < ttl);

    return logsToBeDeleted.length;
  }, [ageInDays, data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Audit log changes</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant='body2'>You have configured to delete audit logs after {ageInDays} days.</Typography>
          {oldAuditLogs > 0 && <Typography variant='body2'>{oldAuditLogs} logs are impacted and will be deleted.</Typography>}
        </Box>
        <Box marginTop={2}>
          <Typography variant='body2'>Changes to your audit log may take up to 24 hours to take effect.</Typography>
          <Typography variant='body2'>Deleted logs can&apos;t be recovered.</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          color="primary"
          onClick={onClose}
        >
          cancel
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={handleOnConfirm}
          disabled={isSubmitting}
        >
          confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogConfigurationDialog;
