import { useServiceItem } from '@koverse/react';
import CloseIcon from '@mui/icons-material/Close';
import FileDownload from '@mui/icons-material/FileDownload';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import floor from 'lodash/floor';
import React, { SyntheticEvent } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentRecord } from '../../declarations';
import { useDownloadFileFromDocumentRecord, useMediaDownload } from '../../hooks';
import AppBarOffset from '../AppBarOffset';
import DocumentView from '../DatasetsDetailsCard/DocumentPreview/DocumentView';
import { useNavigationDrawerTransition } from '../NavigationDrawer';

interface Props {
  datasetId: string,
  open: boolean;
  onClose: (e?: SyntheticEvent) => void;
  document: DocumentRecord | null;
}

interface Params {
  id: string;
}

const DocumentPreviewDrawer = ({
  datasetId,
  document,
  open,
  onClose,
}: Props): React.ReactElement => {
  const [page, setPage] = React.useState<number>(1);
  const [pages, setPages] = React.useState<number>(1);
  const [scale, setScale] = React.useState<number>(1);
  const { id } = useParams<Params>();
  const navigationDrawerTransition = useNavigationDrawerTransition();
  const download = useDownloadFileFromDocumentRecord();
  const { save: downloadFromStorage } = useMediaDownload();

  const {
    item: dataset,
  } = useServiceItem('datasets', id);

  const handleOnCloseDrawer = () => onClose();

  const handleFileDownload = React.useCallback(() => {
    if (document) {
      if (document.mimeType.startsWith('video/')) {
        downloadFromStorage(document, id);
      } else {
        download(document);
      }
    }
  }, [document, download, downloadFromStorage, id]);

  const onDocumentLoadSuccess = React.useCallback(({ numPages }: { numPages: number }) => {
    setPages(numPages);
  }, []);

  const handlePageChange = React.useCallback((event: React.ChangeEvent<unknown>, pageNum: number) => {
    setPage(pageNum);
  }, []);

  const handleZoomIn = React.useCallback(() => {
    setScale(floor(scale + 0.5, 2));
  }, [scale, setScale]);

  const handleZoomOut = React.useCallback(() => {
    setScale(floor(scale - 0.5, 2));
  }, [scale, setScale]);

  const scaleValue = React.useMemo(() => {
    return `${scale * 100} %`;
  }, [scale]);

  const mimeType = document?.mimeType;
  const isDocument = !(mimeType?.startsWith('image/') || mimeType?.startsWith('video/'));
  const showPagination = isDocument && pages > 1;

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={handleOnCloseDrawer}
      elevation={0}
      sx={{
        ...navigationDrawerTransition,
        '& .MuiDrawer-paper': {
          overflow: 'hidden',
          height: '100%',
          ...navigationDrawerTransition,
        },
        '& .MuiBackdrop-root': {
          ...navigationDrawerTransition,
        },
      }}
    >
      <AppBarOffset />
      <Toolbar>
        <Grid container alignContent="space-between" alignItems="center">
          <Grid item xs={.5}>
            <IconButton
              edge="start"
              aria-label="back"
              onClick={handleOnCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            {dataset && (
              <Typography
                variant="h6"
                sx={{
                  flexGrow: 1,
                  ml: 0,
                }}
              >
                {document?.fileName}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Toolbar>
      <Toolbar sx={{ borderBottom: 1, borderColor: (theme) => theme.palette.divider }}>
        <Button
          aria-label="file download"
          onClick={handleFileDownload}
          startIcon={<FileDownload />}
        >
          Download
        </Button>
        <div style={{ flex: 1 }} />
        {isDocument && (
          <>
            <ButtonGroup variant="text">
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="zoom-out"
                onClick={handleZoomOut}
                disabled={scale <= 0.5}
              >
                <ZoomOut />
              </IconButton>
              <Typography variant="body2" sx={{ ml: 0.5, mr: 0.5, alignSelf: 'center' }}>
                {scaleValue}
              </Typography>
              <IconButton
                sx={{ p: '10px' }}
                aria-label="zoom-in"
                onClick={handleZoomIn}
                disabled={scale >= 5}
              >
                <ZoomIn />
              </IconButton>
            </ButtonGroup>
            <div style={{ flex: 1 }} />
          </>
        )}
        {showPagination &&
          <Box display="flex" justifyContent="flex-end">
            <Pagination
              count={pages}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Box>
        }
      </Toolbar>
      <Divider />
      {!!document && (
        <DocumentView
          mimeType={document.mimeType}
          uri={document.uri}
          documentId={document.document_id}
          datasetId={datasetId}
          onSuccess={onDocumentLoadSuccess}
          pageNumber={page}
          scale={scale}
          setPageNumber={setPage}
        />
      )}
    </Drawer>
  );
};

export default DocumentPreviewDrawer;
