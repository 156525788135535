import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Invoice } from '../../declarations';

interface UsersTableRowProps {
  invoice: Invoice,
}

const InvoiceTableRow = ({
  invoice,
}: UsersTableRowProps): React.ReactElement => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const total = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
  }).format(invoice.total / 100);
  const periodStart = DateTime.fromSeconds(invoice.period_start).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });
  const periodEnd = DateTime.fromSeconds(invoice.period_end).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>{invoice.number}</TableCell>
      <TableCell>
        {periodStart === periodEnd ? (
          periodStart
        ) : (
          `${periodStart} - ${periodEnd}`
        )}
      </TableCell>
      <TableCell>
        {total}
      </TableCell>
      <TableCell>{invoice.status}</TableCell>
      <TableCell align="right">
        <Box>
          <IconButton
            aria-label="more"
            id="more-button"
            aria-controls={open ? 'action-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="action-menu"
            MenuListProps={{
              'aria-labelledby': 'more-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              component={Link}
              href={invoice.invoice_pdf}
              onClick={() => {
                handleClose();
              }}
              rel="noopener noreferrer"
              target="_blank"
              download
            >
              {t('pages.billing.invoices.download')}
            </MenuItem>
          </Menu>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default InvoiceTableRow;
