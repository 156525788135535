import React from 'react';
import { usePaginatedService } from '@koverse/react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import AttributesIcon from '@mui/icons-material/EditAttributes';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CreateAttribute from '../components/Dialogs/AddAttribute';
import AttributesTable from '../components/AttributesTable';
import BreadCrumbs from '../components/BreadCrumbs';

const Attributes = (): React.ReactElement | null => {
  const { t } = useTranslation();
  const [addAttribute, setAddAttribute] = React.useState<boolean>(false);
  const tooltipText = t('pages.attribute.tooltip');

  const {
    items: attributes,
    loading,
  } = usePaginatedService('attributes');

  const renderAddButton = (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      startIcon={<AddIcon />}
      onClick={() => setAddAttribute(true)}
    >
      {t('common.addAttribute')}
    </Button>
  );

  const renderZeroState = (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <AttributesIcon
        sx={{
          fontSize: 42,
          color: theme => theme.palette.text.secondary,
        }}
      />
      <Typography sx={{ mt: 1 }}>{t('pages.attribute.zeroState.header')}</Typography>
      <Typography sx={{ mb: 2 }}>{t('pages.attribute.zeroState.subheader')}</Typography>
      {renderAddButton}
    </Stack>
  );

  if (loading) {
    return null;
  }

  return (
    <>
      <Card>
        <Box sx={{ ml: 2, mt: 2 }}>
          <BreadCrumbs />
        </Box>
        <CardHeader
          sx={{
            pb: 0,
            '& .MuiCardHeader-action': {
              alignSelf: 'center',
              margin: 0,
            },
          }}
          title={
            <Stack
              direction="row"
              alignItems="center"
            >
              {t('pages.attribute.title')}
              <Tooltip
                title={tooltipText}
                placement="right"
                arrow
              >
                <HelpIcon
                  sx={{
                    ml: 1,
                    color: theme => theme.palette.text.secondary,
                  }}
                />
              </Tooltip>
            </Stack>
          }
          titleTypographyProps={{ variant: 'h6' }}
          subheader={t('pages.attribute.subheader')}
          action={!!attributes.length && renderAddButton}
        />
        {attributes.length === 0 && (
          <CardContent>
            {renderZeroState}
          </CardContent>
        )}
        {!!attributes.length && <AttributesTable />}
      </Card>
      {addAttribute && (
        <CreateAttribute
          open={addAttribute}
          onClose={() => setAddAttribute(false)}
        />
      )}
    </>
  );
};

export default Attributes;
