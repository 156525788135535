import RemoveIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import get from 'lodash/get';
import React from 'react';
import { AttributeAssignment } from '../../../declarations';
import UserAvatar from '../../UserAvatar';

interface UsersListItemProps {
  assignment: AttributeAssignment;
  onRemove: (assignment: AttributeAssignment) => void;
}

const UsersListItem = ({
  assignment,
  onRemove,
}: UsersListItemProps): React.ReactElement | null => {
  const user = get(assignment, 'user');

  if (!user) {
    return null;
  }

  return (
    <ListItem
      dense
      sx={{ backgroundColor: 'action.disabledBackground' }}
      secondaryAction={
        <IconButton
          aria-label="unassign-user"
          onClick={() => {
            onRemove(assignment as AttributeAssignment);
          }}
        >
          <RemoveIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <UserAvatar user={user} />
      </ListItemAvatar>
      <ListItemText
        primary={user.displayName}
        secondary={user.email}
      />
    </ListItem>
  );
};

export default UsersListItem;
