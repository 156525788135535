import React from 'react';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import { useKoverse, usePaginatedService } from '@koverse/react';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ApplicationsIcon from '@mui/icons-material/AccountTreeOutlined';
import ActiveApplicationsIcon from '@mui/icons-material/AccountTree';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import AddApplication from '../../Dialogs/AddApplication';

function ApplicationsMenu(): React.ReactElement {
  const { workspace, theme } = useKoverse();
  const isRouteMatch = useRouteMatch('/applications');
  const DEFAULT_LIMIT = 6;
  const [addApplication, setAddApplication] = React.useState(false);
  const [limit, setLimit] = React.useState(DEFAULT_LIMIT);
  const {
    loading,
    items: applications,
    total,
  } = usePaginatedService('applications', {
    $sort: { name: 1 },
    $limit: -1,
    workspaceId: workspace.id,
  });

  const handleShowToggle = () => {
    setLimit(limit === DEFAULT_LIMIT ? total : DEFAULT_LIMIT);
  };

  return (
    <>
      <ListItemButton
        sx={{
          pl: 4.3,
        }}
        onClick={handleShowToggle}
      >
        <ListItemIcon>
          {isRouteMatch ? <ActiveApplicationsIcon /> : <ApplicationsIcon />}
        </ListItemIcon>
        <ListItemText
          primary="Applications"
        />
        <ListItemSecondaryAction>
          <Tooltip title="Create new Application">
            <IconButton
              edge="end"
              aria-label="add application"
              onClick={(e) => {
                e.stopPropagation();
                setAddApplication(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItemButton>
      {loading && (
        <LinearProgress />
      )}
      {!!applications.length && !loading && (
        <Box>
          <List disablePadding sx={{ maxHeight: 505, overflowY: 'auto' }}>
            {applications.slice(0, limit).map((application) => (
              <Tooltip
                key={application.id}
                title={application.name.length > 25 ? application.name : ''}>
                <ListItemButton
                  sx={{
                    pl: 4.3,
                  }}
                  component={NavLink}
                  to={`/applications/${application.id}`}
                  activeStyle={{
                    background: theme.palette.action.selected,
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{ noWrap: true }}
                    inset
                    primary={application.name}
                  />
                </ListItemButton>
              </Tooltip>
            ))}
          </List>
          {(total > DEFAULT_LIMIT) && (
            <ListItemButton
              sx={{
                pl: 4.3,
              }}
              onClick={() => {
                handleShowToggle();
              }}
            >
              <ListItemText
                inset
                secondary={limit < total ? `Show ${total - limit} More` : 'Show Less'}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Show more">
                  <IconButton
                    edge="end"
                    aria-label={limit < total ? 'show more' : 'show less'}
                    sx={{ color: 'text.secondary' }}
                  >
                    {limit < total ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItemButton>
          )}
        </Box>
      )}
      {addApplication && (
        <AddApplication
          open={addApplication}
          onClose={() => setAddApplication(false)}
        />
      )}
    </>
  );
}

export default ApplicationsMenu;
