import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import times from 'lodash/times';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useSubscription from '../../hooks/useSubscription';
import useSubscriptionLevels from '../../hooks/useSubscriptionLevels';
import PurchasePlan from '../Dialogs/PurchasePlan';
import SubscriptionLevelCard from '../SubscriptionLevelCard';

const ChangePlan = (): React.ReactElement => {
  const { t } = useTranslation();
  const {
    selectedSubscriptionLevelKey,
    setSelectedSubscriptionLevelKey,
    plan,
    subscription,
  } = useSubscription();
  const { loading, subscriptionLevels } = useSubscriptionLevels();
  const subscriptionIsActive = React.useMemo(() => ['active', 'trialing'].includes(get(subscription, 'status', '')), [subscription]);

  const renderLoading = (
    <>
      {times(3, (index) => (
        <Grid item key={index} xs={3}>
          <Skeleton
            variant="rectangular"
            aria-label="placeholder-while-loading-plan-cards"
            height={350}
          />
        </Grid>
      ))}
      <Grid item xs={9}>
        <Skeleton
          variant="rectangular"
          aria-label="placeholder-while-loading-plan-cards"
          height={118}
        />
      </Grid>
    </>
  );

  const renderPlans = (
    <>
      {Object.keys(subscriptionLevels).map((p) => {
        const isCurrent = subscriptionLevels[p].name === plan?.subscriptionLevel;

        return p === 'onPrem'
          ? (
            <Grid item xs={12} key={p}>
              <Paper
                elevation={4}
                sx={{
                  px: 2,
                  py: 4,
                  mb: 2,
                  border: theme => isCurrent
                    ? `1px solid ${theme.palette.primary.main}`
                    : 'none',
                }}>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <Box>
                    <Typography gutterBottom>{t('subscriptionLevels.onPrem.title')}</Typography>
                    <Typography color="textSecondary">
                      {t('subscriptionLevels.onPrem.description')}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }} />
                  <Trans
                    i18nKey="subscriptionLevels.onPrem.cta"
                    components={{
                      mailto: (
                        <Button
                          component="a"
                          href="mailto:support@koverse.com?subject=On-Premises Licensing"
                          color="primary"
                          variant="outlined"
                        />
                      ),
                    }}
                  />
                </Stack>
              </Paper>
              <Link underline="none" href="https://koverse.com/pricing" target="_blank noreferrer">
                <Stack direction="row" alignContent="center">
                  <Typography variant="caption" sx={{ lineHeight: 1 }}>
                    {t('pages.initialization.createSubscription.link')}
                  </Typography>
                  <OpenInNewIcon sx={{ fontSize: 12, ml: 0.5 }} />
                </Stack>
              </Link>
            </Grid>
          )
          : (
            <Grid item xs={4} key={p}>
              <SubscriptionLevelCard
                subscriptionLevel={subscriptionLevels[p]}
                subscriptionLevelKey={p}
                isSelected={isCurrent && subscriptionIsActive}
                selectSubscriptionLevel={setSelectedSubscriptionLevelKey}
              />
            </Grid>
          );
      })}
    </>
  );

  return (
    <Box sx={{ mx: 2 }}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="stretch"
        direction="row"
      >
        <Grid item xs={12}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4" align="center">{t('pages.billing.changePlan.title')}</Typography>
            <Typography align="center">{t('pages.billing.changePlan.subheader')}</Typography>
          </Box>
        </Grid>
        {loading && renderLoading}
        {!loading && renderPlans}
        {!!selectedSubscriptionLevelKey && (
          <PurchasePlan
            open={!!selectedSubscriptionLevelKey}
            onClose={() => setSelectedSubscriptionLevelKey(null)}
            subscriptionLevelKey={selectedSubscriptionLevelKey}
          />
        )}
      </Grid>
    </Box>
  );
};

export default ChangePlan;
