import { useKoverse } from '@koverse/react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import config from '../../config';
import { Dataset, SourceType } from '../../declarations';
import StructuredData from '../AccessControlForm/StructuredData';
import SourceConfiguration from './SourceConfiguration';

type Inputs = {
  sourceType: SourceType | undefined;
  params: Record<string, unknown>;
  accessControlLabel: Record<string, unknown>;
};

interface Props {
  dataset: Dataset;
  onCancel: () => void;
  afterSubmit: () => void;
}

const ConnectToSource = ({ dataset, afterSubmit, onCancel }: Props): React.ReactElement => {
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [submissionError, setSubmissionError] = React.useState<string>('');
  const ingestUrl = `//${config.api}/ingest`;
  const form = useForm<Inputs>({
    mode: 'onChange',
  });
  const { client } = useKoverse();
  const onSubmit = async (data: Inputs) => {
    const jwt = await client.authentication.getAccessToken();
    const type = data.sourceType?.type;
    // TODO: https://koverse.atlassian.net/browse/KDP-2501
    if (type === 'KAFKA') {
      if (data.params.timeoutRules !== 'custom') {
        set(data, 'params.timeout', 0);
      }
      delete data.params.timeoutRules;
    }

    try {
      await axios.post(ingestUrl, {
        workspaceId: dataset.workspaceId,
        datasetId: dataset.id,
        dataSourceParams: {
          type,
          connectionInfo: data.params,
          ...data.accessControlLabel,
        },
      }, {
        headers: { Authorization: `Bearer ${jwt}` },
      });
      afterSubmit();
    } catch (e) {
      setSubmissionError(get(e, 'message', ''));
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack>
          <Box sx={{ display: currentStep === 0 ? undefined : 'none', maxWidth: 620 }}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6">
                Connect to a data source
              </Typography>
              <Typography variant="body2">
                Select and configure a data source.
              </Typography>
              <Typography variant="body2">
                Sources without nested data will display as columnar data. Sources containing nested data will display as a .json tree.
              </Typography>
            </Box>
            <SourceConfiguration
              onNext={() => setCurrentStep(1)}
              onCancel={onCancel}
            />
          </Box>
          <Box sx={{ display: currentStep === 1 ? undefined : 'none' }}>
            <StructuredData
              submissionError={submissionError}
              onBack={() => setCurrentStep(0)}
            />
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ConnectToSource;
