import React from 'react';
import get from 'lodash/get';
import times from 'lodash/times';
import { useKoverse, usePaginatedService } from '@koverse/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import GroupListItem from './GroupListItem';
import Pagination from '@mui/material/Pagination';
import ListItemText from '@mui/material/ListItemText';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';

const GroupsCard = (): React.ReactElement | null => {
  const { user, workspace } = useKoverse();
  const { t } = useTranslation();

  const {
    items: memberships,
    loading,
    currentPage,
    total,
    limit,
    setCurrentPage,
  } = usePaginatedService('group-memberships', {
    userId: get(user, 'id'),
    workspaceId: workspace.id,
    $limit: 5,
    group: null,
  });

  const handleChangePage = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  if (!loading && memberships.length === 0) {
    return null;
  }

  return (
    <Grid item xs={12} sm={6}>
      <Card>
        <CardHeader
          sx={{
            pb: 0,
            '& .MuiCardHeader-action': {
              alignSelf: 'center',
              margin: 0,
            },
          }}
          title={t('pages.workspace.groups.title')}
          titleTypographyProps={{ variant: 'h6' }}
          action={(
            <Button
              color="primary"
              size="small"
              component={Link}
              to={`/groups`}
              focusRipple={false}
            >
              {t('forms.edit')}
            </Button>
          )}
        />
        <CardContent sx={{ px: 0 }}>
          <List dense sx={{ minHeight: total > 5 ? 360 : 'auto' }}>
            {loading && times(5, (index) => (
              <ListItem
                dense
                key={index}
                aria-label="placeholder-list-item-while-loading"
                sx={{
                  backgroundColor: 'action.disabledBackground',
                  mb: index === 4 ? 0 : 1,
                }}
              >
                <ListItemText
                  primary={<Skeleton width={100} />}
                  secondary={<Skeleton width={150} />}
                />
                <AvatarGroup>
                  <Skeleton variant="circular">
                    <Avatar />
                  </Skeleton>
                </AvatarGroup>
              </ListItem>
            ))}
            {memberships.map((membership, index) => {
              return (
                <GroupListItem
                  groupId={membership.groupId}
                  key={index}
                  roomBottom={index !== memberships.length - 1}
                />
              );
            })}
          </List>
          {total > 5 && (
            <Pagination
              count={Math.ceil(total / limit)}
              variant="outlined"
              shape="rounded"
              siblingCount={0}
              page={currentPage}
              onChange={handleChangePage}
              sx={{
                '& .MuiPagination-ul':
                {
                  justifyContent: 'center',
                  margin: (theme) => theme.spacing(2, 0),
                },
              }}
              aria-label="pagination"
            />
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default GroupsCard;
