import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { useKoverse } from '@koverse/react';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import WorkspaceInitializationRoutes from './routes/WorkspaceInitializationRoutes';
import WorkspaceRoutes from './routes/WorkspaceRoutes';
import PublicRoutes from './routes/PublicRoutes';
import Page from './components/Page';
import SubscriptionProvider from './components/SubscriptionProvider';
import config from './config';
import AccountHoldRoutes from './routes/AccountHoldRoutes';

type Hash = {
  error?: string;
};

function App(): React.ReactElement {
  const { client, initialized, user, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const [redirectingToWorkspace, setRedirectingToWorkspace] = React.useState<boolean>(false);
  const hash: Hash = queryString.parse(window.location.hash);

  useEffect(() => {
    if (hash.error) {
      enqueueSnackbar(hash.error, {
        variant: 'error',
        autoHideDuration: 15000,
      });
    }
  }, [enqueueSnackbar, hash.error]);

  const redirectToWorkspace = React.useCallback(async () => {
    const kdpToken = await client.authentication.getAccessToken();
    await client.authentication.removeAccessToken();
    window.location.replace(`${window.location.protocol}//${user.workspaceId}.${config.domain}?${queryString.stringify({ kdpToken })}`);
  }, [client, user]);

  React.useEffect(() => {
    if (user && user.workspaceId && !config.workspaceId && !redirectingToWorkspace) {
      setRedirectingToWorkspace(true);
      redirectToWorkspace();
    }
  }, [user, redirectToWorkspace, redirectingToWorkspace]);

  const canceled = React.useMemo(() => {
    const canceledStates = ['incomplete_expired', 'canceled'];
    return canceledStates.includes(workspace?.subscription?.status);
  }, [workspace]);
  const billingProblem = React.useMemo(() => {
    const billingStates = ['past_due', 'unpaid'];
    return billingStates.includes(workspace?.subscription?.status);
  }, [workspace?.subscription?.status]);

  const onHold = canceled || billingProblem;

  return (
    <SubscriptionProvider>
      {(initialized && !redirectingToWorkspace) ? (
        <Router>
          {!user && <PublicRoutes />}
          {user && !user.workspaceId && <WorkspaceInitializationRoutes />}
          {onHold && <AccountHoldRoutes {...{ canceled, billingProblem }} />}
          {user && user.workspaceId && !onHold && <Page><WorkspaceRoutes /></Page>}
        </Router>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <CircularProgress size={60} />
        </div>
      )}
    </SubscriptionProvider>
  );
}

export default App;
