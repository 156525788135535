import React from 'react';
import { usePaginatedService } from '@koverse/react';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PermissionsListItem from './PermissionsListItem';
import { Dataset, DatasetPermission } from '../../declarations';

interface PermissionsListProps {
  dataset: Dataset;
}

const PermissionsList = ({ dataset }: PermissionsListProps): React.ReactElement => {
  const {
    loading,
    items: permissions,
    currentPage,
    totalPages,
    setCurrentPage,
  } = usePaginatedService('dataset-permissions', {
    datasetId: dataset.id,
  });

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <Paper variant="outlined">
      {loading ? (
        <LinearProgress />
      ) : (
        <List disablePadding>
          <>
            {permissions.length === 0 && (
              <ListItem>
                <ListItemText primary="No permissions have been added." />
              </ListItem>
            )}
            {permissions.map((permission: DatasetPermission, i) => {
              return (
                <PermissionsListItem
                  key={permission.id}
                  dataset={dataset}
                  permission={permission}
                  divider={i !== permissions.length - 1}
                />
              );
            })}
          </>
        </List>
      )}
      {totalPages > 1 && (
        <Stack
          alignItems="center"
          sx={{ my: 2 }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Stack>
      )}
    </Paper>
  );
};

export default PermissionsList;
