import { useKoverse } from '@koverse/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../declarations';
import UserAvatar from '../UserAvatar';

interface UsersTableRowProps {
  user: User;
  isSelected: boolean;
  selectRow: (e: React.ChangeEvent<HTMLInputElement>, user: User) => void;
  setUserToDelete: React.Dispatch<React.SetStateAction<User | null>>;
  setUserToEdit: React.Dispatch<React.SetStateAction<User | null>>;
  total: number;
}

const UsersTableRow = ({
  user,
  isSelected,
  selectRow,
  setUserToDelete,
  setUserToEdit,
  total,
}: UsersTableRowProps): React.ReactElement => {
  const { user: currentUser, workspace } = useKoverse();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow>
      {total > 1 && (
        <TableCell padding="checkbox">
          {currentUser.id !== user.id && (
            <Checkbox
              color="primary"
              checked={isSelected}
              onChange={(event) => selectRow(event, user)}
              inputProps={{
                'aria-labelledby': `table-checkbox-${user.id}`,
              }}
            />
          )}
        </TableCell>
      )}
      <TableCell><UserAvatar user={user} /></TableCell>
      <TableCell>{user.firstName}</TableCell>
      <TableCell>{user.lastName}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{capitalize(user.role)}</TableCell>
      <TableCell align="right">
        <Box>
          <IconButton
            aria-label="more"
            id="more-button"
            aria-controls={open ? 'action-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="action-menu"
            MenuListProps={{
              'aria-labelledby': 'more-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setUserToEdit(user);
                handleClose();
              }}
              divider
            >
              {t('pages.users.moreMenu.edit')}
            </MenuItem>
            <MenuItem
              disabled={currentUser.id === user.id || workspace.userId === user.id}
              onClick={() => {
                setUserToDelete(user);
                handleClose();
              }}
            >
              {t('pages.users.moreMenu.delete')}
            </MenuItem>
          </Menu>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default UsersTableRow;
