import { useServiceItem } from '@koverse/react';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../../../declarations';

interface Props {
  job: Job;
}

const KafkaConfig = ({
  job,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    item: dataset,
  } = useServiceItem('datasets', job.datasetId);

  return (
    <TableContainer>
      <Table aria-label="kafka configuration table">
        <TableHead>
          <TableRow>
            <TableCell width={200}>{t('pages.dataset.jobHistory.sources.sourceType')}</TableCell>
            <TableCell width={200}>{t('pages.dataset.jobHistory.sources.autoIndex')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.brokers')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.consumers')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.topic')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.timeout')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.messageType')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.offset')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.ssl')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('pages.dataset.jobHistory.sources.types.kafka')}
            </TableCell>
            <TableCell>
              {!dataset
                ? <Skeleton />
                : dataset?.autoCreateIndexes ? 'on' : 'off'
              }
            </TableCell>
            <TableCell>
              {get(job, 'config.connectionInfo.bootstrapBrokers')}
            </TableCell>
            <TableCell>
              {get(job, 'config.connectionInfo.consumerGroup')}
            </TableCell>
            <TableCell>
              {get(job, 'config.connectionInfo.topic')}
            </TableCell>
            <TableCell>
              {get(job, 'config.connectionInfo.timeout')}
            </TableCell>
            <TableCell>
              {get(job, 'config.connectionInfo.messageType')}
            </TableCell>
            <TableCell>
              {get(job, 'config.connectionInfo.autoOffsetReset')}
            </TableCell>
            <TableCell>
              {get(job, 'config.connectionInfo.useSsl') ? 'true' : 'false'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default KafkaConfig;
