import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useKoverse, usePaginatedService } from '@koverse/react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EditAvatar from '../components/EditAvatar';
import EditEmail from '../components/Dialogs/EditEmail';
import EditName from '../components/Dialogs/EditName';
import EditPassword from '../components/Dialogs/EditPassword';
import TransferWorkspace from '../components/Dialogs/TransferWorkspace';
import TransferResourceRequests from '../components/TransferResourceRequests';

function Account(): React.ReactElement {
  const { client, user, workspace } = useKoverse();
  const { t } = useTranslation();
  const [isEditNameOpen, setIsEditNameOpen] = useState<boolean>(false);
  const [isEditEmailOpen, setIsEditEmailOpen] = useState<boolean>(false);
  const [isEditPasswordOpen, setIsEditPasswordOpen] = useState<boolean>(false);
  const [isTransferWorkspaceOpen, setIsTransferWorkspaceOpen] = useState<boolean>(false);
  const history = useHistory();
  const isOwner = workspace?.userId === user?.id;

  const {
    items,
    fetchList,
  } = usePaginatedService('transfer-resource-requests', {
    status: 'pending',
    resourceType: 'workspaces',
    $limit: -1,
  });

  const handleSignOut = async () => {
    await client.logout();
    history.replace('/');
  };

  const onRequestPatched = React.useCallback(() => {
    fetchList();
    if (isTransferWorkspaceOpen) {
      setIsTransferWorkspaceOpen(false);
    }
  }, [fetchList, isTransferWorkspaceOpen]);

  React.useEffect(() => {
    client.service('transfer-resource-requests').on('patched', onRequestPatched);
    return function cleanUp() {
      client.service('transfer-resource-requests').off('patched', onRequestPatched);
    };
  }, [client, onRequestPatched]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Stack sx={{ p: 2 }} spacing={3}>
            <Typography variant="h5">
              {t('pages.account.title')}
            </Typography>
            <EditAvatar />
            <Stack spacing={3}>
              <Paper sx={{ p: 2 }}>
                <Stack direction="row" alignItems="start" justifyContent="space-between">
                  <Stack overflow="hidden">
                    <Typography variant="h6" noWrap>{t('common.name')}</Typography>
                    <Typography noWrap>{user.displayName}</Typography>
                  </Stack>
                  <Button
                    aria-label={`${t('common.edit')}-${t('common.name')}`}
                    onClick={() => setIsEditNameOpen(true)}
                  >
                    {t('common.edit')}
                  </Button>
                </Stack>
              </Paper>
              <Paper sx={{ p: 2 }}>
                <Stack direction="row" alignItems="start" justifyContent="space-between">
                  <Stack overflow="hidden">
                    <Typography variant="h6" noWrap>{t('common.email')}</Typography>
                    <Typography noWrap>{user.email}</Typography>
                  </Stack>
                  {!user.linkedAccounts.length && (
                    <Button
                      aria-label={`${t('common.edit')}-${t('common.email')}`}
                      onClick={() => setIsEditEmailOpen(true)}
                    >
                      {t('common.edit')}
                    </Button>
                  )}
                </Stack>
              </Paper>
              {!user.linkedAccounts.length && (
                <Paper sx={{ p: 2 }}>
                  <Stack direction="row" alignItems="start" justifyContent="space-between">
                    <Stack overflow="hidden">
                      <Typography variant="h6" noWrap>{t('common.password')}</Typography>
                      <Typography noWrap>*********</Typography>
                    </Stack>
                    <Button
                      aria-label={`${t('common.edit')}-${t('common.password')}`}
                      onClick={() => setIsEditPasswordOpen(true)}
                    >
                      {t('common.edit')}
                    </Button>
                  </Stack>
                </Paper>
              )}
              {isOwner && (
                <Paper sx={{ p: 2 }}>
                  <Stack direction="row" alignItems="start" justifyContent="space-between">
                    <Stack overflow="hidden">
                      <Typography variant="h6" noWrap>Transfer workspace</Typography>
                      {!items.length && <Typography noWrap>select a new owner</Typography>}
                    </Stack>
                    <Button
                      aria-label="transfer-workspace"
                      onClick={() => setIsTransferWorkspaceOpen(true)}
                      disabled={!!items.length}
                    >
                      Select
                    </Button>
                  </Stack>
                  {!!items.length && (
                    <Box sx={{ mx: -2, mt: 1 }}>
                      <TransferResourceRequests requests={items} outgoing />
                    </Box>
                  )}
                </Paper>
              )}
            </Stack>
          </Stack>
          <Box sx={{ ml: 2, mt: 1 }}>
            <Button
              color="primary"
              onClick={handleSignOut}
            >
              {t('common.signOut')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      {isEditEmailOpen && (
        <EditEmail
          open={isEditEmailOpen}
          onClose={() => setIsEditEmailOpen(false)}
        />
      )}
      {isEditNameOpen && (
        <EditName
          open={isEditNameOpen}
          onClose={() => setIsEditNameOpen(false)}
        />
      )}
      {isEditPasswordOpen && (
        <EditPassword
          open={isEditPasswordOpen}
          onClose={() => setIsEditPasswordOpen(false)}
        />
      )}
      {isTransferWorkspaceOpen && (
        <TransferWorkspace
          open={isTransferWorkspaceOpen}
          onClose={() => setIsTransferWorkspaceOpen(false)}
        />
      )}
    </Box>
  );
}

export default Account;
