import React from 'react';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useFormContext, useFieldArray, FieldValues } from 'react-hook-form';
import ParameterInput from './ParameterInput';
import { Parameter } from '../../declarations';

interface Props {
  parameter: Parameter;
}

const ParameterFieldArray = ({
  parameter,
}: Props): React.ReactElement | null => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: parameter.name,
  });
  const [parameters] = React.useState<Parameter[]>(get(parameter, 'parameters', []));
  const group = reduce(parameters, (result, value) => {
    return {
      ...result,
      [value.name]: '',
    };
  }, {});
  const handleAddField = () => append(group);
  const handleRemoveField = (index: number) => remove(index);

  React.useEffect(() => {
    if (fields.length === 0) {
      append(group);
    }
  }, [append, fields.length, group]);

  return (
    <Box sx={{ mb: 2 }}>
      {fields.map((field: FieldValues, index: number) => {
        const fieldName = `${parameter.name}[${index}]`;
        return (
          <Box key={field.id}>
            {!!parameter.label && (
              <Typography
                variant="subtitle1"
                sx={{ pt: 1 }}
              >
                {parameter.label}
              </Typography>
            )}
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                mr: index !== 0 ? -4.5 : 0,
              }}
            >
              <Box sx={{ flex: 1 }}>
                {parameters.map((p: Parameter) => {
                  const name = `${fieldName}.${p.name}`;
                  return (
                    <ParameterInput
                      key={p.name}
                      parameter={p}
                      name={name}
                    />
                  );
                })}
              </Box>
              {index !== 0 && (
                <Tooltip placement="right" title="remove">
                  <IconButton
                    edge="end"
                    sx={{ ml: 1 }}
                    onClick={() => handleRemoveField(index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Box>
        );
      })}
      <Stack direction="row" justifyContent="flex-end">
        <Button
          sx={{ mt: 1 }}
          onClick={handleAddField}
          startIcon={<AddIcon />}
        >
          add
        </Button>
      </Stack>
    </Box>
  );
};

export default ParameterFieldArray;
