import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { compact } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Attribute } from '../../declarations';
import UsersCell from './UsersCell';

interface Props {
  attribute: Attribute;
  handleAssign: React.Dispatch<React.SetStateAction<Attribute | null>>;
  handleEdit: React.Dispatch<React.SetStateAction<Attribute | null>>;
  handleDelete: React.Dispatch<React.SetStateAction<Attribute | null>>;
}

const AttributesTableRow = ({
  attribute,
  handleAssign,
  handleDelete,
  handleEdit,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const assignments = React.useMemo(() => {
    return attribute?.attributeAssignments || [];
  }, [attribute]);

  const users = React.useMemo(() => {
    const assigned = assignments.map(d => d.user);
    return compact(assigned);
  }, [assignments]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow>
      <TableCell>{attribute.name}</TableCell>
      <TableCell>{attribute.token}</TableCell>
      <UsersCell users={users} />
      <TableCell align="right">
        <Box>
          <IconButton
            aria-label="more"
            id="more-button"
            aria-controls={open ? 'action-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="action-menu"
            MenuListProps={{
              'aria-labelledby': 'more-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleEdit(attribute);
                handleClose();
              }}
              divider
            >
              {t('pages.attribute.moreMenu.edit')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAssign(attribute);
                handleClose();
              }}
              divider
            >
              {t('pages.attribute.moreMenu.assign')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDelete(attribute);
                handleClose();
              }}
            >
              {t('pages.attribute.moreMenu.delete')}
            </MenuItem>
          </Menu>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default AttributesTableRow;
