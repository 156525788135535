import { Logo, useKoverse } from '@koverse/react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import validator from 'validator';
import CreatePasswordField from '../components/CreatePasswordField';
import { useAppState } from '../components/AppStateProvider';
import config from '../config';

type Inputs = {
  email: string;
  password: string;
  confirmPassword: string;
};

interface Params {
  token: string;
}

function ResetPassword(): React.ReactElement {
  const { token } = useParams<Params>();
  const { search } = useLocation();
  const { email } = queryString.parse(search);
  const { mode } = useAppState();
  const [submissionError, setSubmissionError] = useState(null);
  const [sucessfullySubmitted, setSucessfullySubmitted] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [resetTokenValidity, setResetTokenValidity] = useState<boolean>(true);

  const { register, errors, handleSubmit, setError, formState, watch } = useForm<Inputs>({
    mode: 'onChange',
  });
  const { client } = useKoverse();
  const authManagement = client.service('auth-management');

  React.useEffect(() => {
    let active = true;
    const isResetTokenValid = async () => {
      const validity = await authManagement.create({
        action: 'validateResetToken',
        email: email,
        resetToken: token,
      });
      if (active) {
        setResetTokenValidity(validity);
      }
    };
    isResetTokenValid();
    return () => {
      active = false;
    };
  }, [email, token, authManagement]);

  const onSubmit = async (data: Inputs) => {
    try {
      await authManagement.create({
        action: 'resetPassword',
        email: email,
        workspaceId: config.workspaceId,
        resetToken: token,
        password: data.password,
      });
      setSucessfullySubmitted(true);
    } catch (e) {
      setSubmissionError(get(e, 'message', null));
    }
  };

  const handleClickShowPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100%',
        pt: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Logo mode={mode} />
      {sucessfullySubmitted ? (
        <>
          <Typography>Your password has been reset.</Typography>
          <Box
            sx={{
              mt: 4,
              textAlign: 'center',
            }}
          >
            <Button
              sx={{
                mb: 2,
              }}
              component={RouterLink}
              to="/login"
              color="primary"
              variant="contained">
              Return to Login
            </Button>
          </Box>
        </>
      ) : !resetTokenValidity
        ? (<>
          <Typography>Your token is no longer valid</Typography>
          <Box
            sx={{
              mt: 4,
              textAlign: 'center',
            }}
          >
            <Button
              aria-label="return to forgot password"
              sx={{
                mb: 2,
              }}
              component={RouterLink}
              to="/forgot-password"
              color="primary"
              variant="contained">
                Return to forgot password
            </Button>
          </Box>
        </>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <TextField
              InputLabelProps={{
                htmlFor: 'email',
              }}
              id="email"
              margin="normal"
              label="Email"
              name="email"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              helperText={(errors.email && errors.email.message) || ''}
              inputRef={register({
                required: 'Required',
                validate: {
                  email: (value: string) => validator.isEmail(value) || 'Invalid Email',
                },
              })}
              disabled={true}
              defaultValue={email}
            />
            <CreatePasswordField {...{ errors, register, watch, setError }} />
            <TextField
              InputLabelProps={{
                htmlFor: 'confirmPassword',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={() => handleClickShowPassword()}
                      onMouseDown={handleMouseDownPassword}
                      size="large">
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="confirmPassword"
              margin="normal"
              label="Confirm New Password"
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              error={!!errors.confirmPassword}
              helperText={(errors.confirmPassword && errors.confirmPassword.message) || ''}
              inputRef={register({
                required: 'Required',
                validate: {
                  value: (value: string) => value === watch('password') || 'Passwords must match',
                },
              })}
            />
            {submissionError && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{
                  width: '100%',
                  my: 2,
                }}
              >
                {submissionError}
              </Alert>
            )}
            {formState.isSubmitting ? (
              <CircularProgress
                sx={{
                  mb: 1,
                }}
              />
            ) : (
              <Button
                type="submit"
                sx={{
                  my: 2,
                }}
                color="primary"
                variant="contained"
              >
              Reset Password
              </Button>
            )}
          </form>
        )}
    </Container>
  );
}

export default ResetPassword;
