import React from 'react';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import { useKoverse, usePaginatedService } from '@koverse/react';
import AddIcon from '@mui/icons-material/Add';
import DatasetIcon from '@mui/icons-material/LeaderboardOutlined';
import ActiveDatasetIcon from '@mui/icons-material/Leaderboard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import AddDataset from '../../Dialogs/AddDataset';

function DatasetsMenu(): React.ReactElement {
  const { client, workspace, theme } = useKoverse();
  const [addDataset, setAddDataset] = React.useState<boolean>(false);
  const datasetRoute = useRouteMatch('/datasets/:id');
  const {
    fetchList,
    loading,
    items: datasets,
    total,
  } = usePaginatedService('datasets', {
    $sort: { name: 1 },
    $limit: -1,
    workspaceId: workspace.id,
  });

  const DEFAULT_LIMIT = 6;

  const [limit, setLimit] = React.useState(DEFAULT_LIMIT);

  const handleShowToggle = () => {
    setLimit(limit === DEFAULT_LIMIT ? total : DEFAULT_LIMIT);
  };

  React.useEffect(() => {
    client.service('dataset-permissions').on('created', fetchList);
    client.service('dataset-permissions').on('removed', fetchList);
    client.service('dataset-permissions').on('patched', fetchList);
    client.service('dataset-permissions').on('updated', fetchList);

    return function cleanUp() {
      client.service('dataset-permissions').off('created', fetchList);
      client.service('dataset-permissions').off('removed', fetchList);
      client.service('dataset-permissions').off('patched', fetchList);
      client.service('dataset-permissions').off('updated', fetchList);
    };
  }, [client, fetchList]);

  return (
    <>
      <ListItemButton
        sx={{
          pl: 4.3,
        }}
        onClick={handleShowToggle}
      >
        <ListItemIcon>
          {datasetRoute ? <ActiveDatasetIcon /> : <DatasetIcon />}
        </ListItemIcon>
        <ListItemText
          primary="Datasets"
        />
        <ListItemSecondaryAction>
          <Tooltip title="Create new Dataset">
            <IconButton
              edge="end"
              aria-label="add dataset"
              onClick={(e) => {
                e.stopPropagation();
                setAddDataset(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItemButton>
      {loading && (
        <LinearProgress />
      )}
      {!!datasets.length && !loading && (
        <Box>
          <List disablePadding sx={{ maxHeight: 505, overflowY: 'auto' }}>
            {datasets.slice(0, limit).map((dataset) => (
              <Tooltip
                key={dataset.id}
                title={ dataset.name.length > 25 ? dataset.name : '' }>
                <ListItemButton
                  sx={{
                    pl: 4.3,
                  }}
                  component={NavLink}
                  to={`/datasets/${dataset.id}`}
                  activeStyle={{
                    background: theme.palette.action.selected,
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{ noWrap: true }}
                    inset
                    primary={dataset.name}
                  />
                </ListItemButton>
              </Tooltip>
            ))}
          </List>
          {(total > DEFAULT_LIMIT) && (
            <ListItemButton
              sx={{
                pl: 4.3,
              }}
              onClick={handleShowToggle}
            >
              <ListItemText
                inset
                secondary={limit < total ? `Show ${total - limit} More` : 'Show Less'}
              />
              <ListItemSecondaryAction>
                <Tooltip title={limit < total ? 'Show more' : 'Show less'}>
                  <IconButton
                    edge="end"
                    sx={{ color: 'text.secondary' }}
                  >
                    {limit < total ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}

                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItemButton>
          )}
        </Box>
      )}
      {addDataset && (
        <AddDataset
          open={addDataset}
          onClose={() => setAddDataset(false)}
        />
      )}
    </>
  );
}

export default DatasetsMenu;
