import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import React, { useMemo } from 'react';
import { PaginationModel, QueryResult } from '../../declarations';
import useSearch from '../../hooks/useSearchSelect';
import keyBy from 'lodash/keyBy';
import JsonView from '../JsonView';
import Divider from '@mui/material/Divider';

interface DatasetTreeViewProps {
  collapsed: boolean | number;
  disableDataActions: boolean;
  queryResult: QueryResult;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
}

const DatasetTreeView = ({
  collapsed,
  disableDataActions,
  queryResult,
  paginationModel,
  setPaginationModel,
}: DatasetTreeViewProps): React.ReactElement | null => {
  const theme = useTheme();
  const {
    selectedIds,
    setSelectedIds,
  } = useSearch();

  const selectedRecordMap = React.useMemo(() => keyBy(selectedIds), [selectedIds]);

  const getHandleRecordSelectedChangeFn = (id: string) => {
    const handleRecordSelectedChangeFn = (selected: boolean) => {
      if (selected) {
        setSelectedIds([...selectedIds, id]);
      } else {
        const updatedIds = selectedIds.filter((recordId: string) => recordId !== id);
        setSelectedIds(updatedIds);
      }
    };
    return handleRecordSelectedChangeFn;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPaginationModel((prev: PaginationModel) => {
      return {
        ...prev,
        page: newPage,
      };
    });
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationModel((prev: PaginationModel) => {
      return {
        ...prev,
        page: 0,
        pageSize: (+event.target.value),
      };
    });
  };

  const rows = useMemo(() => {
    return queryResult.records.map(rec => {
      const row: Record<string, any> = {};
      row.record = {};
      Object.keys(rec).forEach((key) => {

        if (key.startsWith('_koverse_record_id')) {
          row.id = rec[key];
        }

        if (!key.startsWith('_koverse')) {
          row.record[key] = rec[key];
        }

      });
      return row;
    });
  }, [queryResult.records]);

  return (
    <Box>
      <Divider />
      <Box>
        {rows.map((row, i) => (
          <Box
            key={i}
            sx={{
              p: 0.5,
              pb: 1,
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <JsonView
              data={ row.record } collapsed={ collapsed }
              disableDataActions={disableDataActions}
              checked={!!selectedRecordMap[row.id as string] || false}
              setChecked={ getHandleRecordSelectedChangeFn(row.id as string) }
              showSelectionCheckbox={ true }
            />
          </Box>
        ))}
      </Box>
      <TablePagination
        component="div"
        count={queryResult.total > 9999 && queryResult.more
          ? queryResult.total + paginationModel.pageSize
          : queryResult.total
        }
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={paginationModel.page}
        rowsPerPageOptions={[5, 10, 25, 100]}
        rowsPerPage={paginationModel.pageSize}
        labelRowsPerPage="Rows per page:"
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} of ${count < 9999 ?
            count : count > 9999 && count < 10101 ? `${9999}+` : `${to}+`
          }`; }}
      />
    </Box>
  );
};

export default DatasetTreeView;
