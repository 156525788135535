import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';
import ButtonBase from '@mui/material/ButtonBase';
import ApplicationDatasets from './ApplicationDatasets';
import ApplicationUsers from './ApplicationUsers';

const ApplicationAccess = (): React.ReactElement | null => {
  const { control, getValues, trigger, errors, formState } = useFormContext();
  const restrictAccess = getValues('restrictAccess');
  const enableDatasetAccess = getValues('enableDatasetAccess');
  const enableAllowedUsers = getValues('enableAllowedUsers');
  const [collapseIn, setCollapseIn] = useState(enableAllowedUsers || enableDatasetAccess);
  useEffect(() => {
    if (collapseIn === undefined && enableDatasetAccess !== undefined && enableAllowedUsers !== undefined) {
      setCollapseIn(enableAllowedUsers || enableDatasetAccess);
    }
  }, [collapseIn, setCollapseIn, enableDatasetAccess, enableAllowedUsers]);
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>User Access</Typography>
      <Typography>
        The same level of access a user has in this workspace will apply to your application.
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
        This is based on the user&apos;s  roles, attributes, and permissions in this workspace.
      </Typography>
      <ButtonBase onClick={() => setCollapseIn(!collapseIn)} sx={{ my: 2 }}>
        <Typography color="text.secondary">
          Advanced Options
        </Typography>
        {collapseIn ? (
          <ArrowUp fontSize="small" sx={{ ml: 0.5, color: t => t.palette.text.secondary }} />
        ) : (
          <ArrowDown fontSize="small" sx={{ ml: 0.5, color: t => t.palette.text.secondary }} />
        )}
      </ButtonBase>
      <Collapse in={collapseIn}>
        <Typography>
          Give access to:
        </Typography>
        <Controller
          control={control}
          name="restrictAccess"
          rules={{
            validate: (value) => {
              if (value === true && !getValues('enableDatasetAccess') && !getValues('enableAllowedUsers')) {
                return 'Select one or a combination of both options to grant access to your application for specific use cases.';
              }
              return true;
            },
          }}
          render={({ onChange, ...props }) => (
            <RadioGroup
              {...props}
              sx={{ ml: 4 }}
              onChange = {(e) => {
                onChange(e.target.value === 'true');
                trigger();
              }}
            >
              <FormControlLabel
                control={<Radio value={false} />}
                sx={{ alignItems: 'flex-start', mb: 2 }}
                label={(
                  <Box sx={{ mt: 1, maxWidth: 600 }}>
                    <Typography>All users in this workspace can access my application.</Typography>
                    <Typography color="text.secondary">Level of access in the application will be determined by the individual user’s roles, attributes, and permissions in this workspace.</Typography>
                  </Box>
                )}
              />
              <FormControlLabel
                control={<Radio value={true} />}
                sx={{ alignItems: 'flex-start' }}
                label={(
                  <Box sx={{ mt: 1, maxWidth: 600 }}>
                    <Typography>Specific:</Typography>
                    <Typography
                      color={formState.isSubmitted && errors?.restrictAccess?.message ? 'error' : 'text.secondary'}
                    >
                      Select one or a combination of both options to grant access to your application for specific use cases.
                    </Typography>
                  </Box>
                )}
              />
            </RadioGroup>
          )}
        />
        {restrictAccess && (
          <Stack sx={{ ml: 8 }}>
            <Controller
              control={control}
              name="enableDatasetAccess"
              render={({ value, onChange, ref, ...props }) => (
                <FormControlLabel
                  label={(
                    <Box sx={{ mt: 1, maxWidth: 600 }}>
                      <Typography>Datasets</Typography>
                      <Typography color="text.secondary">Users that have access to these datasets will be allowed to use this application.</Typography>
                    </Box>
                  )}
                  sx={{ alignItems: 'flex-start' }}
                  control={(
                    <Checkbox
                      {...props}
                      checked={value}
                      color="primary"
                      ref={ref}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        trigger();
                      }}
                    />
                  )}
                />

              )}
            />
            {enableDatasetAccess && (
              <Box sx={{ mt: 2, ml: 4 }}>
                <ApplicationDatasets />
              </Box>
            )}
            <Controller
              control={control}
              name="enableAllowedUsers"
              render={({ value, onChange, ref, ...props }) => (
                <FormControlLabel
                  label={(
                    <Box sx={{ mt: 1, maxWidth: 600 }}>
                      <Typography>Users</Typography>
                      <Typography color="text.secondary">Specific users can be allowed to use this application.</Typography>
                    </Box>
                  )}
                  sx={{ alignItems: 'flex-start' }}
                  control={(
                    <Checkbox
                      {...props}
                      checked={value}
                      color="primary"
                      ref={ref}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        trigger();
                      }}
                    />
                  )}
                />
              )}
            />
            {enableAllowedUsers && (
              <Box sx={{ mt: 2, ml: 4 }}>
                <ApplicationUsers />
              </Box>
            )}
          </Stack>
        )}
      </Collapse>
    </Box>
  );
};

export default ApplicationAccess;
