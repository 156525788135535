import { useKoverse } from '@koverse/react';
import ActiveHomeIcon from '@mui/icons-material/Home';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import ApplicationsMenu from '../ApplicationsMenu';
import DatasetsMenu from '../DatasetsMenu';
import WorkspaceDetailSettingsMenu from '../WorkspaceDetailSettingsMenu';

export interface Params {
  workspaceId: string;
}

function WorkspaceDetailMenu(): React.ReactElement | null {
  const { workspace, theme } = useKoverse();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  if (!workspace) {
    return null;
  }

  return (
    <List disablePadding>
      <ListItemButton
        component={NavLink}
        exact
        to='/'
        sx={{
          pl: 4.3,
        }}
        activeStyle={{
          background: theme.palette.action.selected,
        }}
      >
        <ListItemIcon>
          {pathname === '/' ? <ActiveHomeIcon /> : <HomeIcon />}
        </ListItemIcon>
        <ListItemText
          primary={t('common.home')}
        />
      </ListItemButton>
      <DatasetsMenu />
      <Divider />
      <ApplicationsMenu />
      <WorkspaceDetailSettingsMenu />
    </List>
  );
}

export default WorkspaceDetailMenu;
