import { useKoverse } from '@koverse/react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import orderBy from 'lodash/orderBy';
import random from 'lodash/random';
import times from 'lodash/times';
import { useSnackbar } from 'notistack';
import React from 'react';
import { AttributeAssignment } from '../../../declarations';

interface Props {
  assignments: AttributeAssignment[] | undefined;
}

const AttributesList = ({ assignments }: Props): React.ReactElement | null => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState<boolean>(false);
  const assignmentsLength = React.useRef(0);

  const handleOnRemove = async (assignment: AttributeAssignment) => {
    try {
      await client.service('attribute-assignments').remove(assignment.id);
    } catch (e) {
      enqueueSnackbar(`There was an error: ${e}`, {
        variant: 'error',
      });
    }
  };

  React.useEffect(() => {
    if (!assignments) {
      setLoading(true);
    } else {
      assignmentsLength.current = assignments.length;
      setLoading(false);
    }
  }, [assignments]);

  if (!!assignments && assignments.length === 0) {
    return null;
  }

  return (
    <Box sx={{ minHeight: 40 }}>
      {loading ? (
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          sx={{ mt: 1 }}
        >
          {times(assignmentsLength.current, (index) => (
            <Skeleton
              aria-label="placeholder-chip-while-loading"
              sx={{ mb: 1, mr: 1, borderRadius: 4 }}
              key={index}
              width={`${random(45, 140)}px`}
              height="32px"
              variant="rectangular"
            />
          ))}
        </Stack>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          sx={{ mt: 1 }}
        >
          {!!assignments && orderBy(assignments, ['attribute.token'], ['asc'])
            .map((assignment, index) => (
              <Chip
                aria-label={`attribute-chip-${index}`}
                sx={{ mb: 1, mr: 1 }}
                key={assignment.id}
                label={assignment.attribute.token}
                onDelete={() => handleOnRemove(assignment)}
              />
            ))}
        </Stack>
      )}
    </Box>
  );
};

export default AttributesList;
