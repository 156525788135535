import { useKoverse } from '@koverse/react';
import Box from '@mui/material/Box';
import React from 'react';
import {
  Redirect,
  Route, Switch,
} from 'react-router-dom';
import ChangePlan from '../components/ChangePlan';
import AccountHold from '../components/Dialogs/AccountHold';

interface Props {
  canceled: boolean;
  billingProblem: boolean;
}

const AccountHoldRoutes = ({
  canceled,
  billingProblem,
}: Props): React.ReactElement | null => {
  const { user, workspace } = useKoverse();
  const isOwner = workspace.userId === user.id;

  if (!user || !workspace) {
    return null;
  }

  return (
    <Switch>
      <Route path="/account-hold" exact>
        <AccountHold {...{ canceled, billingProblem }} />
      </Route>
      {isOwner && canceled && (
        <Route path="/account-hold/change-plan" exact>
          <Box sx={{ mt: 6 }}>
            <ChangePlan />
          </Box>
        </Route>
      )}
      <Redirect to="/account-hold" />
    </Switch>
  );
};

export default AccountHoldRoutes;
