import React from 'react';
import ReactJson from 'react-json-view';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

interface Props {
  textToHighlight?: string;
  data: Record<string, unknown>;
  disableDataActions?: boolean;
  collapsed?: boolean | number;
  showSelectionCheckbox?: boolean;
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
}
// https://github.com/chriskempson/base16/blob/master/styling.md
//
// base00 - Default Background
// base01 - Lighter Background (Used for status bars, line number and folding marks)
// base02 - Selection Background
// base03 - Comments, Invisibles, Line Highlighting
// base04 - Dark Foreground (Used for status bars)
// base05 - Default Foreground, Caret, Delimiters, Operators
// base06 - Light Foreground (Not often used)
// base07 - Light Background (Not often used)
// base08 - Variables, XML Tags, Markup Link Text, Markup Lists, Diff Deleted
// base09 - Integers, Boolean, Constants, XML Attributes, Markup Link Url
// base0A - Classes, Markup Bold, Search Text Background
// base0B - Strings, Inherited Class, Markup Code, Diff Inserted
// base0C - Support, Regular Expressions, Escape Characters, Markup Quotes
// base0D - Functions, Methods, Attribute IDs, Headings
// base0E - Keywords, Storage, Selector, Markup Italic, Diff Changed
// base0F - Deprecated, Opening/Closing Embedded Language Tags, e.g. <?php ?>

const JsonView = ({ data, disableDataActions, collapsed, showSelectionCheckbox, checked, setChecked, ...rest }: Props): React.ReactElement => {
  const theme = useTheme();

  const baseTheme = {
    base00: 'inherit', // theme.palette.background.default,
    base01: theme.palette.background.paper,
    base02: theme.palette.divider,
    base03: theme.palette.text.secondary,
    base04: theme.palette.text.primary,
    base05: theme.palette.text.primary,
    base06: theme.palette.primary.main,
    base07: theme.palette.text.secondary,
    base08: theme.palette.primary.main,
    base09: theme.palette.text.primary,
    base0A: theme.palette.text.secondary,
    base0B: theme.palette.text.primary,
    base0C: theme.palette.text.secondary,
    base0D: theme.palette.primary.main,
    base0E: theme.palette.primary.main,
    base0F: theme.palette.text.primary,
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setChecked) {
      setChecked(event.target.checked);
    }
  };

  // trick react-json-view into resetting its internal store
  const Wrapper = collapsed || collapsed === 1
    ? 'span'
    : React.Fragment;

  return (
    <Box
      display="flex"
      justifyContent="left"
      alignItems="flex-start"
    >
      {showSelectionCheckbox && (
        <Checkbox
          checked={checked}
          disabled={disableDataActions}
          onChange={handleChange}
          sx={{ mr: 1.5 }}
        />
      )
      }
      <Box pt={1}>
        <Wrapper >
          <ReactJson
            collapsed={collapsed}
            src={data}
            name={null}
            collapseStringsAfterLength={67}
            theme={baseTheme}
            displayObjectSize={true}
            enableClipboard={true}
            quotesOnKeys={false}
            sortKeys={true}
            {...rest}
          />
        </Wrapper>
      </Box>
    </Box>
  );
};

export default JsonView;
