import React from 'react';
import get from 'lodash/get';
import random from 'lodash/random';
import times from 'lodash/times';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useKoverse, usePaginatedService } from '@koverse/react';

interface Props {
  isAdmin: boolean;
}

const AttributesCard = ({
  isAdmin,
}: Props): React.ReactElement | null => {
  const { user, workspace } = useKoverse();
  const { t } = useTranslation();

  const {
    items: assignments,
    loading,
  } = usePaginatedService('attribute-assignments', {
    userId: get(user, 'id'),
    workspaceId: workspace.id,
    $limit: -1,
    attribute: null,
  });

  if (!loading && assignments.length === 0) {
    return null;
  }

  return (
    <Grid item xs={12} sm={6}>
      <Card>
        <CardHeader
          sx={{
            pb: 0,
            '& .MuiCardHeader-action': {
              alignSelf: 'center',
              margin: 0,
            },
          }}
          title={t('pages.workspace.attributes.title')}
          titleTypographyProps={{ variant: 'h6' }}
          action={isAdmin && (
            <Button
              color="primary"
              size="small"
              component={Link}
              to={`/attributes`}
              focusRipple={false}
            >
              {t('forms.edit')}
            </Button>
          )}
        />
        <CardContent>
          {loading ? (
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
            >
              {times(10, (index) => (
                <Skeleton
                  aria-label="placeholder-chip-while-loading"
                  sx={{ mb: 1, mr: 1, borderRadius: 4 }}
                  key={index}
                  width={`${random(45, 150)}px`}
                  height="32px"
                  variant="rectangular"
                />
              ))}
            </Stack>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
            >
              {assignments.map((assignment, index) => {
                return (
                  <Chip
                    aria-label={`attribute-chip-${index}`}
                    sx={{ mb: 1, mr: 1 }}
                    key={index}
                    label={assignment.attribute.name}
                  />
                );
              })}
            </Stack>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AttributesCard;
