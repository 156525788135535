import { useServiceItem } from '@koverse/react';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import get from 'lodash/get';
import some from 'lodash/some';
import tail from 'lodash/tail';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../../../declarations';

interface Props {
  job: Job;
}

interface URL {
  fileName?: string;
  mimeType?: string;
  url: string;
}

const URLConfig = ({
  job,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    item: dataset,
  } = useServiceItem('datasets', job.datasetId);
  const urls: URL[] = React.useMemo(() => get(job, 'config.connectionInfo.urls', []), [job]);
  const sourceType = some(urls, 'fileName')
    ? t('pages.dataset.jobHistory.sources.types.fileUpload')
    : t('pages.dataset.jobHistory.sources.types.url');

  return (
    <TableContainer>
      <Table aria-label="url configuration table">
        <TableHead>
          <TableRow>
            <TableCell width={200}>{t('pages.dataset.jobHistory.sources.sourceType')}</TableCell>
            <TableCell width={200}>{t('pages.dataset.jobHistory.sources.autoIndex')}</TableCell>
            <TableCell>
              {sourceType === t('pages.dataset.jobHistory.sources.types.fileUpload')
                ? t('pages.dataset.jobHistory.sources.file')
                : t('pages.dataset.jobHistory.sources.url')
              }
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">{sourceType}</TableCell>
            <TableCell>
              {!dataset
                ? <Skeleton />
                : dataset?.autoCreateIndexes ? 'on' : 'off'
              }
            </TableCell>
            <TableCell>
              {!!urls.length && urls[0].fileName ? urls[0].fileName : urls[0]?.url}
            </TableCell>
          </TableRow>
          {urls.length > 1 && tail(urls).map((d: URL, i: number) => {
            return (
              <TableRow key={i}>
                <TableCell sx={{ border: 0 }} />
                <TableCell sx={{ border: 0 }} />
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {d.fileName ? d.fileName : d.url}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default URLConfig;
