import { useKoverse } from '@koverse/react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Group } from '../../../declarations';
import DatasetAccessList from './DatasetAccessList';

interface Props {
  group: Group;
  open: boolean;
  onClose: () => void;
}

const DeleteGroup = ({
  group,
  open,
  onClose,
}: Props): React.ReactElement => {
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    setDeleting(true);
    try {
      await client.service('groups').remove(group.id);
      enqueueSnackbar('Saved changes', {
        variant: 'success',
      });
      setDeleting(false);
      onClose();
    } catch (e) {
      setDeleting(false);
      enqueueSnackbar(`There was an error: ${get(e, 'message')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <DialogTitle>
        Delete group?
        <Typography>This will remove all users assigned to this group.</Typography>
      </DialogTitle>
      <DialogContent>
        <DatasetAccessList groupId={group.id} />
      </DialogContent>
      <DialogActions>
        { deleting ? <CircularProgress size={30} />
          : (
            <>
              <Button
                type="button"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={handleClick}
                disabled={deleting}
              >
                Delete
              </Button>
            </>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGroup;
