import React from 'react';
import Grid from '@mui/material/Grid';
import InvoiceTable from '../components/InvoiceTable';
import PlanDetails from '../components/PlanDetails';
import SubscriptionDetails from '../components/SubscriptionDetails';
import { useKoverse } from '@koverse/react';
import useUpcomingInvoice from '../hooks/useUpcomingInvoice';

const Billing = (): React.ReactElement => {
  const { user } = useKoverse();
  const {
    fetchUpcomingInvoice,
    fetchCurrentUsageSummary,
  } = useUpcomingInvoice();
  React.useEffect(() => {
    fetchUpcomingInvoice();
    fetchCurrentUsageSummary();
  }, [fetchUpcomingInvoice, fetchCurrentUsageSummary, user?.subscriptionId]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SubscriptionDetails />
        </Grid>
        <Grid item xs={12}>
          <PlanDetails />
        </Grid>
        <Grid item xs={12}>
          <InvoiceTable />
        </Grid>
      </Grid>
    </>
  );
};

export default Billing;
