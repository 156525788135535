import React from 'react';
import { useKoverse } from '@koverse/react';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';

interface Message {
  text: string;
  severity: 'error' | 'info' | 'success' | 'warning';
}

type Timer = ReturnType<typeof setTimeout>;

const defaultMessage: Message = {
  text: '',
  severity: 'error',
};

const ServerAlert = (): React.ReactElement | null => {
  const { client } = useKoverse();
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<Message>(defaultMessage);
  const [connectionAttempt, setConnectionAttempt] = React.useState<number | null>(null);
  const [connected, setConnected] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (client.io) {
      client.io.on('connect_error', () => setConnected(false));
      client.io.on('reconnecting', (attempt: number) => {
        if (attempt > 2) {
          setConnectionAttempt(attempt);
        }
      });
      client.io.on('reconnect', () => setConnected(true));
    }
  }, [client.io]);

  React.useEffect(() => {
    let timer: Timer = setTimeout(() => setOpen(false), 0);

    if (!connected) {
      clearTimeout(timer);
      setMessage({
        text: 'You have been disconnected from the server.',
        severity: 'error',
      });
      setOpen(true);
    }

    if (!connected && connectionAttempt) {
      setMessage({
        text: `Attempting to reconnect to the server (${connectionAttempt})`,
        severity: 'warning',
      });
    }

    if (connected) {
      clearTimeout(timer);
      setMessage({
        text: 'You have been reconnected to the server, you may want to refresh your browser.',
        severity: 'success',
      });

      timer = setTimeout(() => setOpen(false), 3000);
    }

    return () => clearTimeout(timer);
  }, [connectionAttempt, connected]);

  return message && (
    <Collapse
      in={open}
      sx={{
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: (theme) => theme.zIndex.tooltip + 1,
      }}
    >
      <Alert
        sx={{ borderRadius: 0 }}
        variant="filled"
        severity={message.severity}
      >
        {message.text}
      </Alert>
    </Collapse>
  );
};

export default ServerAlert;
