import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Attribute } from '../../../declarations';
import UserSearch from './UserSearch';
import UsersList from './UsersList';

interface Props {
  open: boolean;
  onClose: () => void;
  attribute: Attribute;
}

const AssignUsersToAttributes = ({
  attribute,
  open,
  onClose,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  const assignments = React.useMemo(() => {
    return get(attribute, 'attributeAssignments');
  }, [attribute]);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
    >
      <DialogTitle>{t('dialogs.attribute.assign.title')}</DialogTitle>
      <DialogContent>
        <UserSearch attributeId={attribute.id} attribute={attribute} />
        <UsersList assignments={assignments} />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => onClose()}
        >
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignUsersToAttributes;
