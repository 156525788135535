import { useKoverse } from '@koverse/react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ThirdPartyApplication } from '../../../declarations';
import { AxiosError } from 'axios';

type Props = {
  open: boolean;
  onClose: () => void;
  application: ThirdPartyApplication;
};

type Inputs = {
  applicationName: string;
};

const DeleteApplication = ({
  open,
  onClose,
  application,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation();
  const { client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const {
    errors,
    formState,
    handleSubmit,
    register,
    setError,
  } = useForm<Inputs>({
    mode: 'onChange',
  });
  const history = useHistory();
  const applications = client.service('applications');
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const { isSubmitting, isValid, touched } = formState;

  const handleOnClose = () => {
    setSubmissionError(null);
    onClose();
  };

  const fetchApplications = async () => {
    const results = await applications.find({
      query: {
        workspaceId: workspace.id,
        $limit: -1,
      },
    });
    return results.data;
  };

  const onSubmit = async () => {
    try {
      await applications.remove(application.id);
      const currentApplications = await fetchApplications();
      !!currentApplications.length
        ? history.replace(`/applications/${currentApplications[0].id}`)
        : history.replace('');
      enqueueSnackbar('Application deleted', {
        variant: 'success',
      });
    } catch (e) {
      const errors = e as AxiosError;
      if (isArray(get(errors, 'errors'))) {
        const errorList = get(errors, 'errors') || [];
        errorList.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          setError(path, {
            type: 'manual',
            message: error.message,
          });
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  return application ? (
    <Dialog open={open} onClose={() => handleOnClose()} aria-labelledby="form-dialog-title" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Delete OAuth Application?</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 3 }}>
            This will permanenty delete your OAuth application. This will invalidate all access tokens authorised by users. This operation cannot be undone.
          </Typography>
          <Typography>
            Type &apos;<strong>{application.name}</strong>&apos; below to confirm.
          </Typography>
          <TextField
            id="applicationName"
            name="applicationName"
            placeholder={application.name}
            margin='dense'
            variant="outlined"
            fullWidth
            error={!!errors.applicationName && touched.applicationName}
            helperText={(touched.applicationName && errors.applicationName && errors.applicationName.message) || ''}
            disabled={isSubmitting}
            inputRef={register({
              required: t('forms.validation.required') as string,
              validate: {
                value: (value: string) => value === application.name || 'Application name does not match',
              },
            })}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                mt: 2,
              }}
            >
              {submissionError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <Button
                type="button"
                color="primary"
                onClick={() => handleOnClose()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid}
              >
                Delete
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  ) : null;
};

export default DeleteApplication;
