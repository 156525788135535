import React from 'react';
import useTheme from '@mui/styles/useTheme';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextAreaField from '../TextAreaField';

const ApplicationDetails = (): React.ReactElement | null => {
  const {
    errors,
    formState,
    register,
    setValue,
    watch,
  } = useFormContext();
  const theme = useTheme();
  const { isSubmitting } = formState;

  return (
    <Box sx={{ maxWidth: 500, mb: 2 }}>
      <TextField
        InputLabelProps={{
          htmlFor: 'name',
        }}
        id="name"
        label="Application name"
        name="name"
        variant="outlined"
        fullWidth
        sx={{
          marginBottom: theme.spacing(2),
        }}
        error={!!errors.name}
        helperText={(errors.name && errors.name.message) || 'Give your OAuth client a name'}
        inputRef={register({ required: 'Required' })}
        disabled={isSubmitting}
      />
      <TextAreaField
        name="description"
        label={<>Description <i>(optional)</i></>}
        maxLength={255}
        {...{ errors, register, isSubmitting, watch, setValue }}
      />
    </Box>
  );
};

export default ApplicationDetails;
