import { PasswordCriteriaStandard, PasswordCriteria, PasswordValidation } from '../declarations';

const useValidatePassword = (password = ''): PasswordValidation => {
  let complexity = 0;

  const nonWord = /[\W_]/.test(password);   // Any non-word character & underscore
  const digit = /[\d]/.test(password);      // Any digit
  const upperCase = /[A-Z]/.test(password); // Any single character in the range A-Z
  const lowerCase = /[a-z]/.test(password); // Any single character in the range a-z
  const isMinLength = password.length >= 8;

  const criteria: PasswordCriteria = {
    nonWord,
    digit,
    upperCase,
    lowerCase,
  };

  Object.keys(criteria).forEach((key) => {
    if (criteria[key as PasswordCriteriaStandard]) {
      complexity += 1;
    }
  });

  return {
    criteria,
    isMinLength,
    isValid: complexity >= 3 && isMinLength,
  };
};

export default useValidatePassword;
