import React from 'react';
import { Link } from 'react-router-dom';
import { useKoverse, usePaginatedService } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TaskIcon from '@mui/icons-material/TaskOutlined';
import Typography from '@mui/material/Typography';
import TransferResourceRequests from '../TransferResourceRequests';

const DashboardTasks = (): React.ReactElement => {
  const { user, client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    items: outgoingTransferRequests,
    fetchList: fetchOutgoing,
  } = usePaginatedService('transfer-resource-requests', {
    status: { $in: ['pending', 'declined'] },
    $limit: -1,
  });
  const {
    items: incomingTransferRequests,
    fetchList: fetchIncoming,
  } = usePaginatedService('transfer-resource-requests', {
    recipient: true,
    status: 'pending',
    $limit: -1,
  });

  const onRequestPatched = React.useCallback(() => {
    fetchOutgoing();
    fetchIncoming();
  }, [fetchIncoming, fetchOutgoing]);

  React.useEffect(() => {
    client.service('transfer-resource-requests').on('patched', onRequestPatched);
    return function cleanUp() {
      client.service('transfer-resource-requests').off('patched', onRequestPatched);
    };
  }, [client, onRequestPatched]);

  const showEmailVerification = React.useMemo(() => {
    return !!user?.changeEmailTokenExpiration
      && user?.changeEmailTokenExpiration > Date.now();
  }, [user?.changeEmailTokenExpiration]);

  const showUpdateBilling = React.useMemo(() => {
    return !!user?.stripeLatestCharge
      && user?.stripeLatestCharge.status === 'failed';
  }, [user?.stripeLatestCharge]);

  const handleCancelEmailChange = () => {
    client.service('auth-management').create({
      action: 'cancelChangeEmail',
    });
  };

  const handleResendEmailChange = () => {
    client.service('auth-management').create({
      action: 'resendChangeEmail',
    });
    enqueueSnackbar(t('pages.workspace.tasks.changeEmail.successToast'), {
      variant: 'info',
    });
  };

  const hasAvailableTasks = showEmailVerification
    || showUpdateBilling
    || !!outgoingTransferRequests.length
    || !!incomingTransferRequests.length;

  return (
    <Paper sx={{ py: 2 }}>
      <Typography variant="h6" sx={{ ml: 2, mb: 2 }}>
        {t('pages.workspace.tasks.title')}
      </Typography>
      {!!outgoingTransferRequests.length && (
        <TransferResourceRequests requests={outgoingTransferRequests} outgoing />
      )}
      {!!incomingTransferRequests.length && (
        <TransferResourceRequests requests={incomingTransferRequests} />
      )}
      {showEmailVerification && (
        <Alert
          severity="info"
          variant="filled"
          sx={{ borderRadius: 0, mb: 1 }}
          action={(
            <Stack direction="row">
              <Button
                color="inherit"
                size="small"
                sx={{ mr: 0.5 }}
                onClick={handleCancelEmailChange}
              >
                {t('pages.workspace.tasks.changeEmail.cancel')}
              </Button>
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                sx={{ ml: 0.5 }}
                onClick={handleResendEmailChange}
              >
                {t('pages.workspace.tasks.changeEmail.submit')}
              </Button>
            </Stack>
          )}
        >
          {t('pages.workspace.tasks.changeEmail.message')}
        </Alert>
      )}
      {showUpdateBilling && (
        <Alert
          severity="info"
          variant="filled"
          sx={{
            borderRadius: 0,
            backgroundColor: 'action.disabledBackground',
          }}
          action={(
            <Button
              component={Link}
              color="inherit"
              size="small"
              sx={{ ml: 0.5 }}
              to="/billing"
              variant="outlined"
            >
              {t('pages.workspace.tasks.updateBilling.cta')}
            </Button>
          )}
        >
          <AlertTitle>{t('pages.workspace.tasks.updateBilling.title')}</AlertTitle>
          {t('pages.workspace.tasks.updateBilling.message')}
        </Alert>
      )}
      {!hasAvailableTasks && (
        <Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <TaskIcon
              sx={{
                fontSize: 48,
                color: theme => theme.palette.text.secondary,
              }}
            />
            <Typography sx={{ my: 2 }}>{t('pages.workspace.tasks.zeroState')}</Typography>
          </Stack>
        </Box>
      )}
    </Paper>
  );
};

export default DashboardTasks;
