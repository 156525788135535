import { useKoverse } from '@koverse/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import get from 'lodash/get';
import React from 'react';
import { Group, GroupMembership, User } from '../../declarations';
import UserAvatar from '../UserAvatar';

interface GroupsTableRowProps {
  group: Group;
  handleDelete: React.Dispatch<React.SetStateAction<Group | null>>;
  handleEdit: React.Dispatch<React.SetStateAction<Group | null>>;
  handleManageGroup: React.Dispatch<React.SetStateAction<Group | null>>;
  handleView: React.Dispatch<React.SetStateAction<Group | null>>;
}

const GroupsTableRow = ({
  group,
  handleDelete,
  handleEdit,
  handleManageGroup,
  handleView,
}: GroupsTableRowProps): React.ReactElement => {
  const { user } = useKoverse();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const groupMemberships = React.useMemo(() => group.groupMemberships, [group]);
  const owner = React.useMemo(() => group.owner, [group]);

  const role = React.useMemo(() => {
    const membership = !!groupMemberships && !!groupMemberships.length
      ? groupMemberships.find(membership => get(membership, 'user.id') === user.id)
      : null;
    return membership?.role;
  }, [groupMemberships, user.id]);

  const isAdmin = React.useMemo(() => role === 'admin', [role]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow>
      <TableCell align="left">{group.name}</TableCell>
      <TableCell align="left">{get(owner, 'displayName', '')}</TableCell>
      <TableCell align="right">
        <AvatarGroup
          aria-label="user-avatars"
          max={4}
          sx={{
            '&.MuiAvatarGroup-root .MuiAvatar-root': {
              borderColor: 'action.hover',
            },
            justifyContent: 'flex-end',
          }}
        >
          {!groupMemberships && (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          )}
          {!!groupMemberships && groupMemberships.map((d: GroupMembership) => (
            <UserAvatar key={d.id} user={d.user as User} />
          ))}
        </AvatarGroup>
      </TableCell>
      <TableCell align="right">
        <Box>
          <IconButton
            aria-label="more"
            id="more-button"
            aria-controls={open ? 'action-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="action-menu"
            MenuListProps={{
              'aria-labelledby': 'more-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {isAdmin
              ? (
                <Box>
                  <MenuItem
                    onClick={() => {
                      handleEdit(group);
                      handleClose();
                    }}
                    divider
                  >
                    Edit Group Name
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleManageGroup(group);
                      handleClose();
                    }}
                    divider
                  >
                    Edit Users
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDelete(group);
                      handleClose();
                    }}
                  >
                    Delete Group
                  </MenuItem>
                </Box>
              ) : (
                <MenuItem
                  onClick={() => {
                    handleView(group);
                    handleClose();
                  }}
                >
                  View Users
                </MenuItem>
              )
            }
          </Menu>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default GroupsTableRow;
