import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import GetStarted from './GetStarted';
import CreateWorkspace from './CreateWorkspace';
import ConfirmSubscriptionLevel from './ConfirmSubscriptionLevel';
import useSubscription from '../../hooks/useSubscription';

const Initialization = (): React.ReactElement => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const {
    subscription,
    loading: loadingSubscription,
  } = useSubscription();
  const steps = [{
    label: t('pages.initialization.getStarted.step'),
    component: (
      <GetStarted
        handleNext={() => setActiveStep(activeStep + 1)}
      />
    ),
  }, {
    label: t('pages.initialization.createSubscription.step'),
    component: (
      <ConfirmSubscriptionLevel
        handleBack={() => setActiveStep(activeStep - 1)}
        handleNext={() => setActiveStep(activeStep + 1)}
      />
    ),
  }, {
    label: t('pages.initialization.createWorkspace.step'),
    component: (
      <CreateWorkspace
        handleBack={() => setActiveStep(activeStep - 1)}
      />
    ),
  }];

  useEffect(() => {
    if (subscription?.status === 'active') {
      setActiveStep(2);
    } else if (!!subscription) {
      setActiveStep(1);
    }
  }, [subscription]);

  return (
    <Container
      fixed
      maxWidth="md"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Paper sx={{ width: '100%', p: 2 }}>
        <Stepper
          activeStep={activeStep}
          sx={{ px: 6, py: 2 }}
        >
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {!loadingSubscription ? (
          steps[activeStep].component
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', m: 4 }}>
            <CircularProgress />
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default Initialization;
