import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface Clipboard {
  copyToClipboard: (text: string, message?: string, errorMessage?: string) => void;
}

const useCopyToClipboard = (): Clipboard => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const copyToClipboard = (text: string, message?: string, errorMessage?: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      enqueueSnackbar(message || t('hooks.useCopyToClipboard.successToast'), {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(errorMessage || t('hooks.useCopyToClipboard.failureToast'), {
        variant: 'error',
      });
    }
  };

  return { copyToClipboard };
};

export default useCopyToClipboard;
