import React from 'react';
import { useKoverse } from '@koverse/react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ThirdPartyApplication } from '../../declarations';

const ApplicationLinks = (): React.ReactElement | null => {
  const mounted = React.useRef(false);
  const { user, client, workspace } = useKoverse();
  const [applications, setApplications] = React.useState<ThirdPartyApplication[]>([]);

  const fetchApplications = React.useCallback(async () => {
    try {
      const results = await client.service('applications').find({
        query: {
          visible: true,
          workspaceId: workspace.id,
          $limit: -1,
        },
      });
      if (mounted.current) {
        setApplications(results.data);
      }
    } catch (e) {}
  }, [client, workspace.id, mounted, setApplications]);

  React.useEffect(() => {
    fetchApplications();
  }, [fetchApplications]);

  React.useEffect(() => {
    client.service('applications').on('created', fetchApplications);
    client.service('applications').on('removed', fetchApplications);
    client.service('applications').on('patched', fetchApplications);
    client.service('applications').on('updated', fetchApplications);

    return function cleanUp() {
      client.service('applications').off('created', fetchApplications);
      client.service('applications').off('removed', fetchApplications);
      client.service('applications').off('patched', fetchApplications);
      client.service('applications').off('updated', fetchApplications);
    };
  }, [client, fetchApplications]);

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  if (!user || applications.length === 0) {
    return null;
  }

  return (
    <Grid
      container
      spacing={3}
      sx={{
        mb: 2,
      }}
    >
      {applications.map((app: ThirdPartyApplication) => (
        <Grid key={app.id} item xs={3}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            href={app.url}
            target="_blank"
            rel="noreferrer noopener"
            sx={{
              height: (theme) => theme.spacing(8),
            }}
          >
            {app.name}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default ApplicationLinks;
