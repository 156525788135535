import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const ApplicationPrivacy = (): React.ReactElement => {
  const { control } = useFormContext();
  return (
    <Grid item xs={12} sx={{ pt: 4 }}>
      <Typography variant="h6">Workspace Visibility</Typography>
      <Typography variant="caption" paragraph color="textSecondary">
        Visibility only applies to the KDP workspace.
      </Typography>
      <Controller
        control={control}
        name="visible"
        render={({ onChange, ...props }) => (
          <RadioGroup {...props} onChange={(e) => onChange(e.target.value === 'true')}>
            <FormControlLabel value={false} control={<Radio />} label="Only workspace admins and the application owner can view this application link in this KDP workspace." />
            <FormControlLabel value={true} control={<Radio />} label="All workspace members can view this application link in this KDP workspace." />
          </RadioGroup>
        )}
      />
    </Grid>
  );
};

export default ApplicationPrivacy;
