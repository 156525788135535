import { Logo, useKoverse, useServiceItem } from '@koverse/react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import queryString from 'query-string';
import React from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import validator from 'validator';
import CreatePasswordField from '../components/CreatePasswordField';
import { useAppState } from '../components/AppStateProvider';
import config from '../config';

type Inputs = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

interface Params {
  invitationId: 'string';
}
interface Query {
  email?: 'string';
}

const Join = (): React.ReactElement | null => {
  const { client } = useKoverse();
  const { invitationId }: Params = useParams();
  const { search } = useLocation();
  const { t } = useTranslation();
  const { mode } = useAppState();
  const [redirecting, setRedirecting] = React.useState<boolean>(false);
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const query: Query = queryString.parse(search);
  const {
    item: workspace,
    loading,
  } = useServiceItem('workspaces', config.workspaceId as string);

  const { email } = query;

  const {
    errors,
    formState,
    handleSubmit,
    register,
    setError,
    watch,
  } = useForm<Inputs>({
    mode: 'onChange',
  });

  const { isSubmitting, isValid, touched } = formState;

  const onSubmit = async (data: Inputs) => {
    try {
      await client.service('users').create({
        ...data,
        email,
        workspaceId: workspace.id,
        invitationId,
      });
      client.authenticate({
        strategy: 'local',
        email: email,
        password: data.password,
        workspaceId: workspace.id,
      });
      setRedirecting(true);
    } catch (e) {
      if (isArray(get(e, 'errors'))) {
        const errorList = get(e, 'errors') || [];
        errorList.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          setError(path, {
            type: 'manual',
            message: error.message,
          });
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  if (!workspace || !email) {
    return null;
  }

  if (redirecting) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100%',
        pt: 8,
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Logo mode={mode} />
        <Typography variant="h6" sx={{ mb: 2 }}>
          {loading ? <Skeleton /> : t('pages.join.title', { workspace: workspace.name })}
        </Typography>
        <Typography sx={{ alignSelf: 'flex-start' }}>{t('common.signUp')}</Typography>
        <TextField
          disabled={isSubmitting}
          InputLabelProps={{
            htmlFor: 'firstName',
          }}
          id="firstName"
          margin="normal"
          label={t('forms.labels.firstName')}
          name="firstName"
          variant="outlined"
          fullWidth
          error={!!errors.firstName}
          helperText={(errors.firstName && errors.firstName.message) || ''}
          inputRef={register({ required: t('forms.validation.required') as string })}
        />
        <TextField
          disabled={isSubmitting}
          InputLabelProps={{
            htmlFor: 'lastName',
          }}
          id="lastName"
          margin="normal"
          label={t('forms.labels.lastName')}
          name="lastName"
          variant="outlined"
          fullWidth
          error={!!errors.lastName}
          helperText={(errors.lastName && errors.lastName.message) || ''}
          inputRef={register({ required: t('forms.validation.required') as string })}
        />
        <TextField
          disabled
          defaultValue={email}
          InputLabelProps={{
            htmlFor: 'email',
          }}
          id="email"
          margin="normal"
          fullWidth
          label={t('forms.labels.email')}
          name="email"
          variant="outlined"
          placeholder={t('forms.placeholders.email')}
          error={!!errors.email && touched.email}
          helperText={(touched.email && errors.email && errors.email.message) || ''}
          inputRef={register({
            required: t('forms.validation.required') as string,
            validate: {
              email: (value: string) => validator.isEmail(value) || t('forms.validation.invalidEmail') as string,
            },
          })}
        />
        <CreatePasswordField
          {...{ errors, register, watch, setError }}
          TextFieldProps={{
            disabled: isSubmitting,
          }}
        />
        {submissionError && (
          <Alert
            severity="error"
            variant="outlined"
            sx={{
              width: '100%',
              my: 2,
            }}
          >
            {submissionError}
          </Alert>
        )}
        <Stack
          alignContent="center"
          alignItems="center"
          spacing={2}
          sx={{ m: 2 }}
        >
          {isSubmitting ? (
            <CircularProgress size={36} />
          ) : (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid}
            >
              {t('common.createAccount')}
            </Button>
          )}
          <Typography gutterBottom>
            {t('common.haveAccount')}&nbsp;
            <Link component={RouterLink} to="/login">
              {t('common.login')}
            </Link>
          </Typography>
        </Stack>
      </form>
    </Container>
  );
};

export default Join;
