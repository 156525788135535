import React from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../components/BreadCrumbs';
import InviteUser from '../components/Dialogs/InviteUser';
import UsersTable from '../components/UsersTable';

const Users = (): React.ReactElement | null => {
  const { t } = useTranslation();
  const [openInviteUsers, setOpenInviteUsers] = React.useState<boolean>(false);

  return (
    <>
      <Card>
        <Box sx={{ ml: 2, mt: 2 }}>
          <BreadCrumbs />
        </Box>
        <CardHeader
          sx={{
            pb: 0,
            '& .MuiCardHeader-action': {
              alignSelf: 'center',
              margin: 0,
            },
          }}
          title={
            <Stack
              direction="row"
              alignItems="center"
            >
              {t('pages.users.title')}
            </Stack>
          }
          titleTypographyProps={{ variant: 'h6' }}
          subheader={t('pages.users.subheader')}
          action={(
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setOpenInviteUsers(true)}
            >
              {t('common.inviteUsers')}
            </Button>
          )}
        />
        <UsersTable />
      </Card>
      <InviteUser
        open={openInviteUsers}
        onClose={() => setOpenInviteUsers(false)}
      />
    </>
  );
};

export default Users;
