import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useAutocomplete } from '../../hooks';
import { User } from '../../declarations';
import { useKoverse } from '@koverse/react';
import get from 'lodash/get';

interface UserSearchProps {
  onChange?: (data: User | User[] | null) => void;
  currentIds?: string[];
  clearOnSelect?: boolean;
  size?: 'medium' | 'small';
  AutocompleteProps?: {
    multiple?: boolean;
  };
}

const UserSearch = ({
  onChange = () => undefined,
  currentIds,
  clearOnSelect = false,
  size,
  AutocompleteProps = {},
}: UserSearchProps): React.ReactElement => {
  const theme = useTheme();
  const { client } = useKoverse();
  const { errors } = useFormContext();
  const {
    open,
    setOpen,
    value,
    setValue,
    inputValue,
    setInputValue,
    options,
    loading,
    setCurrentIds,
  } = useAutocomplete({
    serviceUrl: 'users',
    fields: ['email', 'firstName', 'lastName'],
    clearOnSelect,
    multiple: AutocompleteProps?.multiple,
  });

  React.useEffect(() => {
    if (currentIds) {
      setCurrentIds(currentIds);
    }
  }, [currentIds, setCurrentIds]);
  React.useEffect(() => {
    const fetchMissingUsers = async () => {
      const valueIds = (value as User[]).map(v => v.id);
      const missing = currentIds?.filter(id => !valueIds.includes(id));
      if (missing?.length) {
        const users =  await client.service('users').find({
          query: {
            id: missing,
            $limit: -1,
          },
        });
        setValue([
          ...value as [],
          ...users.data,
        ]);
      }
    };

    fetchMissingUsers();
  }, [client, currentIds, value, setValue]);

  return (
    <>
      <Autocomplete
        sx={{ marginBottom: theme.spacing(2) }}
        id="user-search"
        blurOnSelect
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options as User[]}
        getOptionLabel={(options: User) => options.email}
        filterOptions={(data) => data}
        noOptionsText="No Results"
        value={Array.isArray(value) ? (value as User[]) : (value as User)}
        onChange={(_event: React.ChangeEvent<unknown>, newValue: User | User[] | null) => {
          onChange(newValue);
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(_event: React.ChangeEvent<unknown>, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id="userId"
            fullWidth
            InputLabelProps={{
              htmlFor: 'userId',
            }}
            label="Select users"
            variant="outlined"
            error={!!errors.allowedUsers}
            helperText={get(errors, `allowedUsers.message`, '')}
            size={size}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        PaperComponent={(props) => <Paper {...props} elevation={4} />}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <ListItemText
              primary={option.displayName}
              secondary={option.displayName !== option.email
                ? option.email
                : undefined
              }
            />
          </ListItem>
        )}
        {...AutocompleteProps}
      />
    </>
  );
};

export default UserSearch;
