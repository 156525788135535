import { usePaginatedService } from '@koverse/react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import times from 'lodash/times';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Job } from '../../../declarations';
import JobsTableRow from './JobsTableRow';

interface Params {
  id: string;
}

const JobsTable = (): React.ReactElement => {
  const { id } = useParams<Params>();
  const { t } = useTranslation();
  const {
    items,
    loading,
    currentPage,
    total,
    limit,
    setLimit,
    setCurrentPage,
  } = usePaginatedService('jobs', {
    $sort: { createdAt: -1 },
    $limit: 5,
    datasetId: id,
  });

  const jobs = items as Job[];

  const handleChangePage = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setLimit(+event.target.value);
  };

  if (!loading && jobs.length === 0) {
    return (
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5" align="center" gutterBottom>{t('pages.dataset.jobHistory.title')}</Typography>
          <Stack alignItems="center">
            <Typography gutterBottom paragraph>
              {t('pages.dataset.jobHistory.zeroState.subheader')}
            </Typography>
          </Stack>
        </Paper>
      </Grid>
    );
  }

  return (
    <Paper>
      <Box sx={{ pl: 2, mb: 3 }}>
        <Typography variant="h5">{t('pages.dataset.jobHistory.title')}</Typography>
        <Typography variant="body2" color="textSecondary">
          {t('pages.dataset.jobHistory.subheader')}
        </Typography>
      </Box>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={68} />
              <TableCell align="left" width={200}>{t('common.date')}</TableCell>
              <TableCell align="left" width={200}>{t('common.time')}</TableCell>
              <TableCell align="left" width={200}>{t('common.jobType')}</TableCell>
              <TableCell align="left" width={200}>{t('common.status')}</TableCell>
              <TableCell align="left" width={200}>{t('common.user')}</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              {times(limit, (index) => (
                <TableRow key={index} data-testid="loading-row">
                  <TableCell width={68} />
                  <TableCell><Skeleton height={34} width={200} /></TableCell>
                  <TableCell><Skeleton height={34} width={200} /></TableCell>
                  <TableCell><Skeleton height={34} width={200} /></TableCell>
                  <TableCell><Skeleton height={34} width={200} /></TableCell>
                  <TableCell />
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {jobs.map((job: Job) => (
                <JobsTableRow
                  key={job.id}
                  job={job}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          p: 1,
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={limit}
        page={(currentPage - 1)}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default JobsTable;
