import React from 'react';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CopyIcon from '@mui/icons-material/ContentCopy';
import Typography from '@mui/material/Typography';
import { useCopyToClipboard } from '../../../hooks';

interface Props {
  onClose: () => void;
  open: boolean;
  ipList: string[];
}

const StaticIpList = ({ open, onClose, ipList = [] }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { copyToClipboard } = useCopyToClipboard();

  const formattedIps = React.useMemo(() => {
    return ipList.join('\r\n');
  }, [ipList]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle sx={{ pb: 0 }}>{t('dialogs.staticIp.title')}</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ mb: 2 }}
        >
          {t('dialogs.staticIp.subheader', {
            list: pluralize('address', ipList.length),
          })}
        </Typography>
        <Box>
          <Button
            color="primary"
            startIcon={<CopyIcon />}
            onClick={() => copyToClipboard(formattedIps)}
          >
            {t('common.copy')}
          </Button>
          <Box
            sx={{ m: 1 }}
          >
            {ipList.map((d, i) => (
              <Typography
                key={i}
                display="block"
                variant="overline"
                sx={{ lineHeight: 'inherit' }}
              >
                {d}
              </Typography>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StaticIpList;
