import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import reduce from 'lodash/reduce';
import trim from 'lodash/trim';
import validator from 'validator';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useForm, useWatch, FieldError, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { BatchResponse } from '../../../declarations';

type Inputs = {
  invitations: string[];
  role: string;
  soloEmail: string;
};

interface InvalidChip {
  [key: number]: string;
}

const InviteUsersForm = (): React.ReactElement => {
  const { client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [soloIsValid, setSoloIsValid] = React.useState<boolean>(false);
  const [soloValue, setSoloValue] = React.useState<string>('');
  const [invalidChips, setInvalidChips] = React.useState<InvalidChip | null>(null);
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);

  const {
    errors,
    formState,
    handleSubmit,
    reset,
    control,
    setError,
    clearErrors,
    trigger,
    setValue,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      role: 'member',
      invitations: [],
    },
  });

  const invitations: string[] = useWatch({ control, name: 'invitations', defaultValue: [] });
  const { isValid, isSubmitting, isDirty } = formState;

  const setResultMessaging = (results: BatchResponse[], calls: (string | unknown)[]) => {
    const accepted = [];
    results.forEach((result: BatchResponse, index: number) => {
      if (result.status === 'rejected') {
        enqueueSnackbar(`
          There was an issue inviting
          ${get(calls, `[${index}][2].email`)}:
          ${result.reason.message}
        `, {
          variant: 'error',
          autoHideDuration: 8000,
        });
      } else {
        accepted.push(result);
      }
    });

    if (!!accepted.length) {
      enqueueSnackbar(t('dialogs.invitations.inviteSuccessToast', {
        number: accepted.length,
        users: pluralize('user', accepted.length),
      }), {
        variant: 'success',
      });
    }
  };

  const onSubmit = async ({ invitations, role }: Inputs) => {
    const emails = [...invitations];

    if (!!soloValue) {
      emails.push(soloValue);
    }

    const calls = emails.map((email: string) => {
      return ['create', 'workspace-invitations', {
        workspaceId: workspace.id,
        email,
        role,
      }];
    });

    try {
      const results = await client.service('batch').create({ calls });
      reset({
        invitations: [],
        role: 'member',
      });
      setResultMessaging(results, calls);
    } catch (e) {
      setSubmissionError(get(e, 'message', null));
      if (isArray(get(e, 'errors'))) {
        const errorList = get(e, 'errors') || [];
        errorList.forEach((e: FieldError) => {
          const path: any = get(e, 'path');
          setError(path, {
            type: 'manual',
            message: e.message,
          });
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  const handleNewValueChange = (newValue: string[]) => {
    setSoloValue('');
    setSoloIsValid(false);
    setValue('invitations', newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  React.useEffect(() => {
    if (!!soloValue) {
      const isValid = !!invitations.length
        ? validator.isEmail(soloValue) && indexOf(invitations, soloValue) < 0
        : validator.isEmail(soloValue);

      setSoloIsValid(isValid);
    }
  }, [soloValue, invitations]);

  React.useEffect(() => {
    if (!isValid && soloIsValid && invitations.length === 0) {
      clearErrors('invitations');
    }
  }, [isValid, soloIsValid, invitations, clearErrors]);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          alignItems="center"
        >
          <Controller
            name="invitations"
            control={control}
            rules={{
              validate: (emails) => {
                if (emails.length === 0 && !soloValue) {
                  return t('forms.validation.required') as string;
                }
                const invalid = reduce(emails, (result, value, key) => {
                  return !validator.isEmail(value)
                    ? { ...result, [key]: value }
                    : result;
                }, {});
                setInvalidChips(invalid);
                return isEmpty(invalid) || t('forms.validation.invalidEmail') as string;
              },
            }}
            render={(props) => {
              return (
                <Autocomplete
                  {...props}
                  id="invitations"
                  multiple
                  freeSolo
                  disabled={isSubmitting}
                  sx={{ width: 550, mr: 2 }}
                  options={[]}
                  renderTags={(value: readonly string[], getTagProps) => {
                    return value.map((option: string, index: number) => {
                      const error = get(invalidChips, index) === option;
                      const { onDelete } = getTagProps({ index });

                      return (
                        <Chip
                          {...getTagProps({ index })}
                          onDelete={(e) => {
                            onDelete(e);
                            trigger('invitations');
                          }}
                          key={index}
                          size="small"
                          color={error ? 'error' : 'default'}
                          label={option}
                        />
                      );
                    });
                  }}
                  inputValue={soloValue}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={t('forms.labels.email')}
                        placeholder={t('forms.placeholders.email')}
                        variant="outlined"
                        fullWidth
                        error={!!errors.invitations}
                        margin='normal'
                        helperText={get(errors, 'invitations.message') || t('dialogs.invitations.inputHelper')}
                        onChange={(event) => {
                          setSoloValue(event.target.value);
                        }}
                      />
                    );
                  }}
                  onChange={(event: React.ChangeEvent<unknown>, newValue: string[]) => {
                    handleNewValueChange(newValue);
                  }}
                  onKeyDown={(event: React.KeyboardEvent<unknown>) => {
                    const value = get(event, 'target.value');
                    const code = get(event, 'code');

                    if (code === 'Space' || code === 'Comma') {
                      event.preventDefault();
                      event.stopPropagation();
                      if (!!value && soloIsValid) {
                        handleNewValueChange([
                          ...invitations,
                          trim(value, ','),
                        ]);
                      }
                    }
                  }}
                  onBlur={(event: React.FocusEvent<unknown>) => {
                    const value = get(event, 'target.value');

                    if (!!value && soloIsValid) {
                      handleNewValueChange([
                        ...invitations,
                        trim(value, ','),
                      ]);
                    }
                  }}
                />
              );
            }}
          />
          <Controller
            render={(props) => (
              <FormControl sx={{ minWidth: 150, mb: 2 }}>
                <InputLabel id="role-select-label">{t('forms.labels.role')}</InputLabel>
                <Select
                  labelId="role-select-label"
                  label={t('forms.labels.role')}
                  {...props}
                >
                  <MenuItem value="member">{t('common.member')}</MenuItem>
                  <MenuItem value="admin">{t('common.admin')}</MenuItem>
                </Select>
              </FormControl>
            )}
            name="role"
            control={control}
            rules={{
              required: t('forms.validation.required') as string,
            }}
          />
          {isSubmitting
            ? <CircularProgress size={30} sx={{ mb: 2, ml: 3 }} />
            : (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={
                  (soloValue === '' && !isDirty)
                  || (soloValue === '' && !isValid)
                  || (!!soloValue && !soloIsValid)
                  || (!isValid && !soloIsValid)
                }
                sx={{ ml: 2, mb: 2 }}
              >
                {t('forms.sendInvite')}
              </Button>
            )}
        </Stack>
      </form>
      {submissionError && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{
            width: '100%',
            my: 2,
          }}
        >
          {submissionError}
        </Alert>
      )}
    </Box>
  );
};

export default InviteUsersForm;
