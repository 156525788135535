import React from 'react';
import get from 'lodash/get';
import { useKoverse } from '@koverse/react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextAreaField from '../../TextAreaField';
import { Dataset } from '../../../declarations';

type Inputs = {
  name: string;
  description: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  dataset: Dataset;
};

const EditDatasetInformation = ({
  open,
  onClose,
  dataset,
}: Props): React.ReactElement => {
  const [submissionError, setSubmissionError] = React.useState(null);
  const { client } = useKoverse();
  const datasetService = client.service('datasets');
  const { enqueueSnackbar } = useSnackbar();
  const {
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      name: get(dataset, 'name'),
      description: get(dataset, 'description'),
    },
  });

  const { isDirty, isValid, isSubmitting } = formState;

  React.useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  const handleOnClose = () => {
    setSubmissionError(null);
    onClose();
  };

  const onSubmit = async (data: Inputs) => {
    try {
      if (dataset) {
        const response = await datasetService.patch(dataset.id, data);
        reset(response);
        enqueueSnackbar('The dataset has been updated.', {
          variant: 'success',
        });
        handleOnClose();
      }
    } catch (e) {
      setSubmissionError(get(e, 'message', null));
    }
  };

  return (
    <Dialog open={open} onClose={handleOnClose} aria-labelledby="form-dialog-title" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">Edit Dataset</DialogTitle>
        <DialogContent>
          <TextField
            InputLabelProps={{
              htmlFor: 'name',
            }}
            id="name"
            margin='normal'
            label="Dataset Name"
            name="name"
            variant="outlined"
            fullWidth
            error={!!errors.name}
            helperText={(errors.name && errors.name.message) || ''}
            disabled={isSubmitting}
            inputRef={register({ required: 'Required' })}
          />
          <TextAreaField
            name="description"
            label="Dataset Description"
            maxLength={255}
            {...{ errors, register, isSubmitting, watch, setValue }}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                width: '100%',
                mb: 2,
              }}
            >{submissionError}</Alert>
          )}
        </DialogContent>
        <DialogActions>
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              disabled={!isDirty || !isValid}
              type="submit"
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditDatasetInformation;
