import { DocumentRecord } from '../declarations';
import { useDataUriToBlob, useDownloadFile } from './index';

type DownloadFileFromDocumentRecordFunction = (
  document: DocumentRecord,
) => void;

const useDownloadFileFromDocumentRecord = (): DownloadFileFromDocumentRecordFunction => {
  const { dataUriToBlob } = useDataUriToBlob();
  const downloadFileFunction = useDownloadFile();

  const downloadFileFromDocumentRecord = (document: DocumentRecord) => {
    const blob = dataUriToBlob(document.downloadUri);
    const url = URL.createObjectURL(blob);

    downloadFileFunction(url, document.fileName, document.mimeType);
  };

  return downloadFileFromDocumentRecord;
};

export default useDownloadFileFromDocumentRecord;
