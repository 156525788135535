import React, { createContext } from 'react';
import { CookieStorage } from 'cookie-storage';
import config from '../../config';
import { User } from '../../declarations';
import { useKoverse } from '@koverse/react';

export interface Session {
  email: string;
  workspaceId: string;
  exp: number;
}

const initialContext = {
  activeSessions: [],
};

export interface ActiveSessionsContextInterface {
  activeSessions: Session[]
}

export const ActiveSessionsContext = createContext<ActiveSessionsContextInterface>(initialContext);

export interface ActiveSessionsProviderOptions {
  children?: React.ReactNode;
}

const cookieStorage = new CookieStorage({
  domain: config.domain,
  secure: true,
  sameSite: 'None',
});

export const ActiveSessionsProvider = ({ children }: ActiveSessionsProviderOptions): JSX.Element => {
  const { client } = useKoverse();
  const [activeSessions, setActiveSessions] = React.useState<Session[]>(JSON.parse(cookieStorage.getItem('koverse-sessions') || '[]'));
  React.useEffect(() => {
    function syncActiveSessions({ login, logout }: { login?: Session, logout?: Session }) {
      let sessions = JSON.parse(cookieStorage.getItem('koverse-sessions') || '[]');
      sessions = sessions.filter((session: any) => {
        if (session.exp < Math.ceil(Date.now() / 1000)) {
          return false;
        } else if (login && login.email === session.email && login.workspaceId === session.workspaceId) {
          return false;
        } else if (logout && logout.email === session.email && logout.workspaceId === session.workspaceId) {
          return false;
        }
        return true;
      });
      if (login && login.workspaceId) {
        sessions.push(login);
      }
      cookieStorage.setItem('koverse-sessions', JSON.stringify(sessions));
      setActiveSessions(sessions);
      return sessions;
    }
    client.on('authenticated', async ({ user, authentication }: { user: User, authentication: any }) => {
      syncActiveSessions({
        login: {
          email: user.email,
          workspaceId: user.workspaceId,
          exp: authentication?.payload?.exp,
        },
      });
    });
    client.on('logout', async ({ user, authentication }: { user: User, authentication: any }) => {
      syncActiveSessions({
        logout: {
          email: user.email,
          workspaceId: user.workspaceId,
          exp: authentication?.payload?.exp,
        },
      });
    });
  }, [client]);
  const value = {
    activeSessions,
  };

  return (
    <ActiveSessionsContext.Provider value={value}>
      {children}
    </ActiveSessionsContext.Provider>
  );
};

export default ActiveSessionsProvider;
