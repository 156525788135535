import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { useForm, FieldError } from 'react-hook-form';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import TextAreaField from '../../TextAreaField';
import { Attribute } from '../../../declarations';

type Inputs = {
  token: string;
  name: string;
  description: string;
};

interface Props {
  open: boolean;
  onClose: () => void;
  attribute: Attribute;
}

const EditAttribute = ({
  attribute,
  open,
  onClose,
}: Props): React.ReactElement => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const attributesService = client.service('attributes');
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);

  const {
    errors,
    formState,
    handleSubmit,
    reset,
    register,
    setError,
    setValue,
    watch,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      token: get(attribute, 'token'),
      name: get(attribute, 'name'),
      description: get(attribute, 'description'),
    },
  });

  const { isDirty, isValid, isSubmitting } = formState;

  const onSubmit = async (data: Inputs) => {
    try {
      const result = await attributesService.patch(attribute.id, data);
      reset({ ...result });
      onClose();
      enqueueSnackbar(t('dialogs.attribute.edit.successToast'), {
        variant: 'success',
      });
    } catch (e) {
      if (isArray(get(e, 'errors'))) {
        const errorList = get(e, 'errors') || [];
        errorList.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          if (path !== 'workspaceId') {
            setError(path, {
              type: 'manual',
              message: error.message,
            });
          }
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t('dialogs.attribute.edit.title')}</DialogTitle>
        <DialogContent>
          <TextField
            InputLabelProps={{
              htmlFor: 'name',
              shrink: true,
            }}
            id="name"
            margin="normal"
            label={t('forms.labels.attributeName')}
            name="name"
            variant="outlined"
            fullWidth
            error={!!errors.name}
            helperText={(errors.name && errors.name.message) || ''}
            inputRef={register({
              required: t('forms.validation.required') as string,
            })}
            disabled={isSubmitting}
          />
          <TextField
            InputLabelProps={{
              htmlFor: 'token',
              shrink: true,
            }}
            id="token"
            margin="normal"
            label={t('forms.labels.attributeToken')}
            name="token"
            variant="outlined"
            fullWidth
            error={!!errors.token}
            helperText={t('forms.helperText.editAttributeToken')}
            inputRef={register({
              required: t('forms.validation.required') as string,
            })}
            disabled
          />
          <TextAreaField
            name="description"
            label={`${t('forms.labels.description')} (${t('forms.labels.optional')})`}
            maxLength={255}
            {...{ errors, register, isSubmitting, watch, setValue }}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                width: '100%',
                my: 2,
              }}
            >
              {submissionError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => onClose()}
          >
            {t('forms.cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isDirty || !isValid}
          >
            {t('forms.saveChanges')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditAttribute;
