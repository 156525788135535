import React from 'react';
import get from 'lodash/get';
import { FieldErrors, UseFormMethods, RegisterOptions } from 'react-hook-form';
import TextField from '@mui/material/TextField';

interface Props {
  errors: FieldErrors;
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  setValue: UseFormMethods['setValue'];
  isSubmitting: boolean;
  name: string;
  label: React.ReactNode;
  maxLength: number;
  registerOptions?: Omit<RegisterOptions, 'maxLength'>
}

const TextAreaField = ({
  errors,
  register,
  isSubmitting,
  name,
  label,
  maxLength,
  watch,
  registerOptions,
  setValue,
}: Props): React.ReactElement => {
  const [characters, setCharacters] = React.useState<number>(0);

  const value = watch(name);

  React.useEffect(() => {
    const length = get(value, 'length', 0);
    let str = value;
    if (length > maxLength) {
      str = value.slice(0, maxLength);
      setValue(name, str);
      setCharacters(maxLength);
    } else {
      setCharacters(length);
    }
  }, [value, maxLength, setValue, name]);

  const helperText = get(errors, name) && get(errors, `${name}.message`)
    ? get(errors, `${name}.message`)
    : `${characters}/${maxLength}`;

  return (
    <TextField
      InputLabelProps={{
        htmlFor: name,
      }}
      sx={{
        '& .MuiFormHelperText-root': {
          textAlign: !get(errors, `${name}.message`)
            ? 'right'
            : 'left',
        },
      }}
      margin="normal"
      id={name}
      label={label}
      name={name}
      variant="outlined"
      fullWidth
      multiline
      rows={5}
      error={!!get(errors, `${name}.message`)}
      inputRef={register({
        ...registerOptions,
        maxLength,
      })}
      helperText={helperText}
      disabled={isSubmitting}
    />
  );
};

export default TextAreaField;
