import { usePaginatedService } from '@koverse/react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  name?: string;
}

const SelectParserInput = ({
  name,
}: Props): React.ReactElement => {
  const {
    control,
    formState,
  } = useFormContext();
  const { isSubmitting } = formState;

  const inputName = !!name
    ? name
    : 'accessControlLabel.securityLabelInfo.parserClassName';

  const {
    items: parserOptions,
  } = usePaginatedService('abac-label-parsers', {
    $limit: -1,
  });

  return (
    <FormControl
      fullWidth
      variant="outlined"
      sx={{ maxWidth: 620 }}
      disabled={isSubmitting}
    >
      <InputLabel id="security-parser-select">Choose how Koverse will create your Attributes</InputLabel>
      <Controller
        as={
          <Select
            label="Choose how Koverse will create your Attributes"
            id="security-parser-select"
            labelId="security-parser-select"
            renderValue={(value) => {
              const parser = parserOptions.find(d => d.parserClassName === value);
              return parser.name;
            }}
            MenuProps={{
              sx: { maxWidth: 620 },
            }}
          >
            {parserOptions.map((parser) => (
              <MenuItem value={parser.parserClassName} key={parser.id}>
                <ListItemText
                  primary={parser.name}
                  secondary={parser.helperText}
                  sx={{ textWrap: 'wrap' }}
                />
              </MenuItem>
            ))}
          </Select>
        }
        name={inputName}
        control={control}
        rules={{
          required: 'Required',
        }}
        defaultValue=""
      />
    </FormControl>
  );
};

export default SelectParserInput;
