import validator from 'validator';

export const validateExpressionOperators = (data: string) => {
  const hasOperator = new RegExp(/[&|]/g);
  // remove space for validation
  const value = data.replace(/\s/g, '');

  if (hasOperator.test(value)) {
    // track current operator to prevent mixed operations
    let activeOperator;

    for (let i = 0; i < value.length; i++) {
      const curr = value[i];
      const next = value[i + 1];
      const prev = value[i - 1];

      if (['(', ')'].includes(curr)) {
        // reset the active operator for groups
        activeOperator = null;
      }

      if (['&', '|'].includes(curr)) {
        // if operators are in succession eg &&, ||, &|, |&
        if (['&', '|'].includes(next)) {
          return 'Operators must be separated by values';
        }

        // ensure surrounding chars are groups or a value
        if ((!prev || !next) || (prev === '(') || (next === ')')) {
          return 'Incomplete expression';
        }

        // avoid mixed operations eg A|B&C or (A|B&C)
        if (!!activeOperator && activeOperator !== curr) {
          return 'No mixed operators. Use groups to combine operations (e.g (A|B)&C)';
        }
        // set the active operator
        activeOperator = curr;
      }
    }
  }

  return true;
};

const validParens = (str: string) => {
  const map = {
    '(': ')',
  } as { [key: string]: string };

  const open = [];

  for (const s of str) {
    if (!['(', ')'].includes(s)) {
      continue;
    }

    if (map[s]) {
      open.push(s);
      continue;
    }

    const index = open.length - 1;
    const lastOpen = open[index];

    if (s === map[lastOpen]) {
      open.pop();
    } else {
      return false;
    }
  }

  return open.length === 0;
};

export const validateExpressionGroups = (data: string) => {
  const hasParen = new RegExp(/[()]/g);
  // remove space for validation
  const value = data.replace(/\s/g, '');

  if (hasParen.test(value)) {
    // validate matching parentheses
    if (!validParens(value)) {
      return 'Expression groups must be contained within parentheses';
    }

    // ensure multiple expression groups are connected by operators
    for (let i = 0; i < value.length; i++) {
      const curr = value[i];
      const next = value[i + 1];
      const prev = value[i - 1];

      if (curr === '(') {
        // ensure groups are not empty
        if (next === ')') {
          return 'Expression groups cannot be empty';
        }
        // ensure groups are nested or are preceeded by an operator
        if (!!prev && !['(', '&', '|'].includes(prev)) {
          return 'Expressions and groups must be combined with an operator';
        }
      }

      // ensure a group end is nested or followed by an operator
      if (curr === ')' && !!next && ![')', '&', '|'].includes(next)) {
        return 'Expressions and groups must be combined with an operator';
      }
    }
  }

  return true;
};

export const validateWorkspaceId = (name: string): string | boolean => {
  const isAlphaNumeric = new RegExp(/^[A-Za-z0-9-_]+$/);
  const hasHyphen = new RegExp(/[-]/);

  if (hasHyphen.test(name)) {
    return 'Workspace Id cannot contain a hyphen.';
  }

  return isAlphaNumeric.test(name) ||
    'Only alphanumeric characters and underscores are allowed.';
};

export const validateApplicationUrl = (url: string): boolean | string => {
  return validator.isURL(url, {
    require_tld: false,
    protocols: ['https', 'http'],
    require_protocol: true,
    require_valid_protocol: true,
  }) || 'Invalid Url.';
};
