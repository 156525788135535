import React, { useMemo  } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Invoice } from '../../declarations';
import LineItemDetails from './LineItemDetails';

interface Props {
  invoice: Invoice;
  hideFreeItems?: boolean;
}
const InvoiceDetails = ({ invoice, hideFreeItems = false }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const lineItems = useMemo(() => (
    hideFreeItems ? invoice.lines.data.filter((item) => !!item.amount) : invoice.lines.data
  ), [hideFreeItems, invoice.lines.data]);
  return (
    <Box sx={{ mb: 4, mt: 5 }}>
      <Typography gutterBottom>{t('')}</Typography>
      {lineItems.map((lineItem) => (
        <LineItemDetails key={lineItem.id} lineItem={lineItem} />
      ))}
      <Divider />
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          py: 2,
        }}
      >
        <Stack direction="column">
          <Typography gutterBottom>{t('common.subtotal')}</Typography>
          <Typography>{t('common.tax')}</Typography>
        </Stack>
        <Stack direction="column" alignItems="flex-end">
          <Typography gutterBottom>${(invoice.subtotal / 100).toFixed(2)}</Typography>
          <Typography>${(invoice.tax / 100).toFixed(2)}</Typography>
        </Stack>
      </Stack>
      <Divider sx={{ mb: 2 }} />
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <Stack direction="column">
          <Typography variant="h6">{t('common.total')}</Typography>
        </Stack>
        <Stack direction="column">
          <Typography variant="h6">${(invoice.total / 100).toFixed(2)}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default InvoiceDetails;
