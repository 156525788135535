import { Logo, useKoverse } from '@koverse/react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import validator from 'validator';
import { useAppState } from '../components/AppStateProvider';
import config from '../config';

type Inputs = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

function ForgotPassword(): React.ReactElement {
  const [submissionError, setSubmissionError] = useState(null);
  const [sucessfullySubmitted, setSucessfullySubmitted] = useState(false);
  const { t } = useTranslation();
  const { mode } = useAppState();
  const {
    errors,
    formState,
    handleSubmit,
    register,
  } = useForm<Inputs>();
  const { client } = useKoverse();
  const authManagement = client.service('auth-management');
  const onSubmit = async (data: Inputs) => {
    try {
      await authManagement.create({
        action: 'sendResetPassword',
        email: data.email,
        workspaceId: config.workspaceId,
      });
      setSucessfullySubmitted(true);
    } catch (e) {
      setSubmissionError(get(e, 'message', null));
    }
  };
  return (
    <Container
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        pt: 8,
      }}
    >
      <Logo mode={mode} />
      {sucessfullySubmitted ? (
        <>
          <Typography sx={{ mt: 3 }}>
            {t('pages.forgotPassword.success')}
          </Typography>
          <Button
            sx={{ m: 2 }}
            component={RouterLink}
            to="/login"
            color="primary"
            variant="contained">
            {t('pages.forgotPassword.back')}
          </Button>
        </>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>{t('pages.forgotPassword.title')}</Typography>
          <TextField
            InputLabelProps={{
              htmlFor: 'email',
            }}
            id="email"
            margin="normal"
            label={t('forms.labels.email')}
            name="email"
            variant="outlined"
            fullWidth
            error={!!errors.email}
            helperText={(errors.email && errors.email.message) || ''}
            inputRef={register({
              required: t('forms.validation.required') as string,
              validate: {
                email: (value: string) => validator.isEmail(value) || t('forms.validation.invalidEmail') as string,
              },
            })}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                width: '100%',
                my: 2,
              }}
            >
              {submissionError}
            </Alert>
          )}
          {formState.isSubmitting ? (
            <CircularProgress sx={{ mb: 1 }} />
          ) : (
            <Button
              type="submit"
              sx={{ m: 2 }}
              color="primary"
              variant="contained">
              {t('pages.forgotPassword.submit')}
            </Button>
          )}
        </form>
      )}
    </Container>
  );
}

export default ForgotPassword;
