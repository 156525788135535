import { useKoverse } from '@koverse/react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { User } from '../../../declarations';
import { useAutocomplete } from '../../../hooks';
import UserAvatar from '../../UserAvatar';

const UserSearch = (): React.ReactElement => {
  const { user } = useKoverse();
  const { t } = useTranslation();

  const {
    loading,
    options,
    open,
    setInputValue,
    setValue,
    setOpen,
  } = useAutocomplete({
    serviceUrl: 'users',
    fields: ['email', 'firstName', 'lastName'],
    queryParams: {
      role: 'admin',
      id: { $nin: [user.id] },
    },
  });

  const users = options as User[];

  const {
    control,
    formState,
  } = useFormContext();

  const { isSubmitting } = formState;

  return (
    <Controller
      name="user"
      control={control}
      defaultValue={null}
      rules={{ required: 'true' }}
      render={({ onChange, ...props }) => {
        return (
          <Autocomplete
            {...props}
            disabled={isSubmitting}
            filterSelectedOptions
            filterOptions={(data) => data}
            getOptionLabel={option => option.displayName}
            id="new-owner-search"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText={t('common.noResults')}
            open={open}
            onChange={(event: React.ChangeEvent<unknown>, newValue: User[]) => {
              setValue(newValue);
              onChange(newValue);
            }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            options={users}
            PaperComponent={(props) => <Paper {...props} elevation={4} />}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Select a user to be the new owner of this workspace"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                onChange={(event) => {
                  setInputValue(event.target.value);
                }}
              />
            )}
            renderOption={(props, option) => (
              <ListItem {...props}>
                <ListItemAvatar>
                  <UserAvatar user={option} />
                </ListItemAvatar>
                <ListItemText
                  primary={option.displayName}
                  secondary={option.email}
                />
              </ListItem>
            )}
          />
        );
      }}
    />
  );
};

export default UserSearch;
