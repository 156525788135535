import React, { useState  } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SetupPayment from '../../SetupPayment';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { StripeHandlerEvent } from '../../../declarations';
import useSubscription from '../../../hooks/useSubscription';

type Props = {
  open: boolean;
  onClose: (success?: boolean) => void;
};

type State = {
  stripe?: Stripe;
  elements?: StripeElements;
  cardComplete: boolean;
  submitting: boolean;
  error?: string;
};

const UpdatePayment = ({
  open,
  onClose,
}: Props): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { updatePaymentMethod, payLatestInvoice, subscription } = useSubscription();
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    cardComplete: false,
    submitting: false,
  });

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    try {
      const { stripe, elements } = state;
      if (!elements || !stripe) {
        setState({
          ...state,
          error: 'There is a problem with the form, please refresh the page.',
        });
        return;
      }
      setState({
        ...state,
        submitting: true,
      });
      const res = await stripe?.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements: elements as StripeElements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.href}?stripeRedirect=true`,
        },
      });
      if (res?.error) {
        setState({
          ...state,
          submitting: false,
          error: res?.error.message,
        });
        return;
      }
      const paymentMethod = res?.setupIntent?.payment_method;
      await updatePaymentMethod(paymentMethod as string);

      if (subscription?.latest_invoice?.status === 'open') {
        try {
          await payLatestInvoice();
        } catch (e) {
          onClose(true);
          throw new Error('Your payment method was updated, but there was an error collecting payment');
        }
      }

      setState({
        ...state,
        submitting: false,
      });
      onClose(true);
      enqueueSnackbar(t('dialogs.updatePayment.successToast'), {
        variant: 'success',
      });
    } catch (error) {
      setState({
        ...state,
        submitting: false,
      });
      enqueueSnackbar(`There was an error: ${error}`, {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  const handlePaymentChange = (e: StripeHandlerEvent, stripe: Stripe, elements: StripeElements) => {
    setState({
      ...state,
      stripe,
      elements,
      cardComplete: e.complete,
    });
  };

  return (
    <Dialog open={open} onClose={() => handleOnClose()} aria-labelledby="update-payment-details" fullWidth>
      <DialogTitle>{t('dialogs.updatePayment.title')}</DialogTitle>
      <DialogContent>
        <SetupPayment onChange={handlePaymentChange} />
        {!!state.error && (
          <Alert
            severity="error"
            variant="outlined"
            sx={{
              width: '100%',
              mt: 2,
            }}
          >
            {state.error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {state.submitting ? (
          <CircularProgress size={30} />
        ) : (
          <>
            <Button
              type="button"
              onClick={() => handleOnClose()}
            >
              {t('forms.cancel')}
            </Button>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={handleOnConfirm}
              disabled={!state.cardComplete}
            >
              {t('dialogs.updatePayment.cta')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePayment;
