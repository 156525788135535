import React from 'react';
import {
  useKoverse,
} from '@koverse/react';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Applications from '../pages/Applications';
import Attributes from '../pages/Attributes';
import BillingRoutes from '../routes/BillingRoutes';
import Datasets from '../pages/Datasets';
import Groups from '../pages/Groups';
import Users from '../pages/Users';
import WorkspaceDashboard from '../components/WorkspaceDashboard';
import config from '../config';
import AuditLog from './AuditLog';

function WorkspacePage(): React.ReactElement | null {
  const { user, workspace } = useKoverse();
  const adminRoute = useRouteMatch(['/attributes/:id?', '/users/:id?', '/audit-log/:id?']);

  const isAdmin = user.role ===  'admin';

  if (!user) {
    return null;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {!!workspace && (
        <Switch>
          {!!adminRoute && !isAdmin && <Redirect to="/" />}
          <Route
            path="/groups"
            exact
          >
            <Groups />
          </Route>
          <Route
            path="/users/:id?"
            exact
          >
            <Users />
          </Route>
          <Route
            path="/attributes/:id?"
            exact
          >
            <Attributes />
          </Route>
          <Route
            path="/applications/:id?"
            exact
          >
            <Applications />
          </Route>
          <Route
            path="/datasets/:id?"
          >
            <Datasets />
          </Route>
          {!config.selfHosted && (
            <Route
              path="/billing/:id?"
              exact
            >
              <BillingRoutes />
            </Route>
          )}
          <Route
            path="/audit-log/:id?"
            exact
          >
            <AuditLog />
          </Route>
          <Route path="/" exact>
            <WorkspaceDashboard />
          </Route>
        </Switch>
      )}
    </Box>
  );
}

export default WorkspacePage;
