import { sanitizeUrl } from '@braintree/sanitize-url';
import { useKoverse } from '@koverse/react';
import { Container } from '@mui/material';
import { capitalize } from 'lodash';
import omit from 'lodash/omit';
import { useSnackbar } from 'notistack';
import queryString, { ParsedQuery } from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import VerifyEmailDialog from '../components/Dialogs/VerifyEmail';
import UpcomingInvoiceProvider from '../components/UpcomingInvoiceProvider';
import { Job } from '../declarations';
import Account from '../pages/Account';
import Workspace from '../pages/Workspace';

const WorkspaceRoutes = (): React.ReactElement => {
  const { client, user } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const query: ParsedQuery = React.useMemo(() => queryString.parse(search), [search]);
  const [verfiyingAccount, setVerifyingAccount] = React.useState<boolean>(!!query.verificationToken);

  const stripParams = React.useCallback((params: string[]) => {
    history.replace({
      search: queryString.stringify(omit(query, ...params)),
    });
  }, [history, query]);
  const authRedirect = !!query.authRedirect ? sanitizeUrl(query.authRedirect as string) : '';

  const verifyAccount = React.useCallback(async (verificationToken) => {
    if (user?.verified) {
      stripParams(['verificationToken']);
      return;
    }

    try {
      setVerifyingAccount(true);
      await client.service('auth-management').create({
        action: 'verifyAccount',
        verificationToken,
      });
      enqueueSnackbar(t('pages.workspace.verifyAccount.successToast'), {
        variant: 'success',
      });
      stripParams(['verificationToken']);
    } catch (e) {
      setVerifyingAccount(false);
      enqueueSnackbar(t('pages.workspace.verifyAccount.failureToast'), {
        variant: 'error',
      });
    }
  }, [client, enqueueSnackbar, stripParams, t, user?.verified]);

  const verifyEmailChange = React.useCallback(async (email, token) => {
    if (!user?.changeEmailTokenExpiration) {
      stripParams(['token', 'email']);
      return;
    }

    try {
      await client.service('auth-management').create({
        action: 'changeEmail',
        changeEmailToken: token,
        email,
      });
      enqueueSnackbar(t('pages.workspace.verifyEmailChange.successToast'), {
        variant: 'success',
      });
      stripParams(['token', 'email']);
    } catch (e) {
      enqueueSnackbar(t('pages.workspace.verifyEmailChange.failureToast'), {
        variant: 'error',
      });
    }
  }, [client, enqueueSnackbar, stripParams, t, user?.changeEmailTokenExpiration]);

  const notifyUserOfJobStatus = React.useCallback(async (updatedJob: Job) => {
    if (!updatedJob.dismissed && (updatedJob.status === 'completed' || updatedJob.status === 'failed')) {
      const dataset = await client.service('datasets').get(updatedJob.datasetId);
      const message = capitalize(t('pages.workspace.jobStatusChange', {
        defaultValue: `${updatedJob.type} job ${updatedJob.status} for dataset: ${dataset.name}`,
        job: updatedJob,
        dataset,
      }));
      enqueueSnackbar(message, {
        variant: updatedJob.status === 'completed' ? 'success' : 'error',
      });
    }
  }, [enqueueSnackbar, client, t]);

  const jobStatusNotificationCleanUp = React.useCallback(() => {
    client.service('jobs').removeListener('created', notifyUserOfJobStatus);
    client.service('jobs').removeListener('patched', notifyUserOfJobStatus);
    client.service('jobs').removeListener('updated', notifyUserOfJobStatus);
  }, [client, notifyUserOfJobStatus]);

  const jobsStatusNotifications = React.useCallback(async () => {
    const jobService = client.service('jobs');
    jobService.on('created', (created: Job) => {
      notifyUserOfJobStatus(created);
    });
    jobService.on('patched', (patched: Job) => {
      notifyUserOfJobStatus(patched);
    });
    jobService.on('updated', (updated: Job) => {
      notifyUserOfJobStatus(updated);
    });
    return jobStatusNotificationCleanUp;
  }, [client, jobStatusNotificationCleanUp, notifyUserOfJobStatus]);

  React.useEffect(() => {
    const { email, token, verificationToken } = query;

    if (!!verificationToken) {
      verifyAccount(verificationToken);
    }

    if (!!email && !!token) {
      verifyEmailChange(email, token);
    }
  }, [query, verifyAccount, verifyEmailChange]);

  React.useEffect(() => {
    jobsStatusNotifications();
    return jobStatusNotificationCleanUp;
  }, [jobsStatusNotifications, jobStatusNotificationCleanUp]);

  return (
    <Container
      sx={{
        minWidth: theme => theme.breakpoints.values.md,
      }}
    >
      <UpcomingInvoiceProvider>
        {!user?.verified && (
          <VerifyEmailDialog verifying={verfiyingAccount} />
        )}
        <Switch>
          {!!authRedirect && (
            <Redirect to={authRedirect} />
          )}
          <Route path="/account" exact><Account /></Route>
          <Redirect exact from='/login' to='/' />
          <Redirect exact from='/create-user' to='/' />
          <Redirect exact from='/join/:inviteToken?' to='/' />
          <Redirect exact from='/account-hold/:id?' to='/' />
          <Route path="/"><Workspace /></Route>
          <Redirect to="/" />
        </Switch>
      </UpcomingInvoiceProvider>
    </Container>
  );
};

export default WorkspaceRoutes;
