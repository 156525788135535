import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import get from 'lodash/get';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInput } from '../../declarations';

interface Props {
  parameter: TextInput;
  name?: string;
  disabled?: boolean;
}

const Text = ({
  parameter,
  name,
  disabled,
}: Props): React.ReactElement => {
  const form = useFormContext();
  const { errors, formState } = form;
  const { touched } = formState;
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const inputName = name ? `params.${name}` : `params.${parameter.name}`;
  const inputLabel = parameter.required
    ? parameter.label
    : (
      <>
        {`${parameter.label} `}
        <em>(optional)</em>
      </>
    );

  const helperText = !!get(touched, inputName) && get(errors, `${inputName}.message`)
    ? get(errors, `${inputName}.message`)
    : parameter.helperText;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      name={inputName}
      control={form.control}
      defaultValue={parameter?.defaultValue || ''}
      render={(field) => (
        <TextField
          {...field}
          disabled={disabled}
          fullWidth
          margin="normal"
          label={inputLabel}
          helperText={helperText}
          onChange={(e) => {
            const value = parameter.type === 'number' && !!e.target.value
              ? parseInt(e.target.value)
              : e.target.value;
            field.onChange(value);
          }}
          type={showPassword ? 'text' : parameter.type}
          error={get(touched, inputName) && !!get(errors, inputName)}
          placeholder={parameter.placeholder}
          multiline={parameter.multiline}
          rows={parameter.multiline ? 4 : undefined}
          InputProps={{
            endAdornment: parameter.type === 'password' ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  size="large">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
        />
      )}
      rules={{
        required: parameter.required ? 'Required' : undefined,
      }}
    />
  );
};

export default Text;
