import React from 'react';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { User } from '../../../declarations';

interface Props {
  user: User;
}

const WorkspaceRole = ({
  user,
}: Props): React.ReactElement => {
  const { client, user: currentUser, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const [role, setRole] = React.useState<string>(user.role);

  const canEdit = currentUser.role === 'admin' && currentUser.id !== user.id;

  const updateRole = async (role: string) => {
    try {
      await client.service('workspace-management').create({
        action: 'changeRole',
        userId: user.id,
        role,
        workspaceId: workspace.id,
      });
      setRole(role);
    } catch (error) {
      enqueueSnackbar(`There was an error: ${error}`, {
        variant: 'error',
      });
    }
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      margin="normal"
    >
      <InputLabel id="role-label">Workspace Role</InputLabel>
      <Select
        value={role}
        onChange={(e: SelectChangeEvent<string>) => updateRole(e.target.value)}
        labelId="role-label"
        label="Workspace Role"
        disabled={!canEdit}
      >
        <MenuItem value="member">Member</MenuItem>
        <MenuItem value="admin">Admin</MenuItem>
      </Select>
    </FormControl>
  );
};

export default WorkspaceRole;
