import React, { Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubscriptionLevel } from '../../declarations';
import { useKoverse } from '@koverse/react';

interface Props {
  subscriptionLevel: SubscriptionLevel;
  subscriptionLevelKey: string;
  selectedSubscriptionLevelKey: string | null;
  setSelectedSubscriptionLevelKey: Dispatch<SetStateAction<string | null>>;
}

const SubscriptionLevelButton = ({
  subscriptionLevel,
  subscriptionLevelKey,
  selectedSubscriptionLevelKey,
  setSelectedSubscriptionLevelKey,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { user } = useKoverse();
  return (
    <ButtonBase
      sx={{
        borderRadius: t => `${t.shape.borderRadius}px`,
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        p: 2,
        width: '100%',
        justifyContent: 'start',
        ...(selectedSubscriptionLevelKey === subscriptionLevelKey ? {
          background: t => t.palette.action.selected,
          border: (t) => `1px solid ${t.palette.primary.main}`,
        } : {
          background: t => t.palette.action.hover,
          border: (t) => `1px solid ${t.palette.action.hover}`,
        }),
      }}
      onClick={() => setSelectedSubscriptionLevelKey(subscriptionLevelKey)}
    >
      <Stack sx={{ textAlign: 'left' }}>
        <Typography variant="h6" gutterBottom>
          {subscriptionLevel.name}
        </Typography>
        {subscriptionLevel?.recurringPriceSummary?.amount && (
          <Box sx={{ mb: 1 }}>
            <Typography variant="h5" component="span">
              {subscriptionLevel?.recurringPriceSummary?.amount}
            </Typography>
            <Typography component="span">
              {`/${subscriptionLevel?.recurringPriceSummary?.interval}`}
            </Typography>
          </Box>
        )}
        {subscriptionLevelKey === 'starter' && !user.usedFreeTrial && (
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="h6"
              color="primary"
              sx={{ mb: 0, textTransform: 'uppercase' }}
            >
              {t(`subscriptionLevels.${subscriptionLevelKey}.freeTrial`)}
            </Typography>
            <Typography variant="caption" color="textSecondary">{t(`subscriptionLevels.${subscriptionLevelKey}.freeTrialSubheader`)}</Typography>
          </Box>
        )}
      </Stack>
    </ButtonBase>
  );
};
export default SubscriptionLevelButton;
