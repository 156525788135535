import { useKoverse } from '@koverse/react';
import axios, { AxiosError } from 'axios';
import React from 'react';
import config from '../config';
import { DocumentRecord } from '../declarations';

interface UseMediaDownload {
  save: SaveFunction;
  loading: boolean;
}

type SaveFunction = (
  document: DocumentRecord,
  datasetId: string,
) => void;

const useMediaDownload = (): UseMediaDownload => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { client } = useKoverse();

  const save = React.useCallback(async (
    document: DocumentRecord,
    datasetId: string,
  ) => {
    try {
      const windowDocument = window.document;
      const url = `//${config.api}/serve-media/${datasetId}?documentId=${document.document_id}&download=true`;

      setLoading(true);

      const jwt = await client.authentication.getAccessToken();
      const result = await axios.get(url, {
        headers: { 'Authorization': `Bearer ${jwt}` },
      });

      setLoading(false);

      const link = windowDocument.createElement('a');

      link.setAttribute('download', document.fileName);
      link.setAttribute('href', result.data.url);
      windowDocument.body.appendChild(link);

      link.click();

      windowDocument.body.removeChild(link);
    } catch (e) {
      setLoading(false);
      const error = e as AxiosError;
      throw new Error(`There was an error downloading the file: ${error.message}`);
    }

  }, [client.authentication]);

  return {
    save,
    loading,
  };
};

export default useMediaDownload;
