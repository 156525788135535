import { usePaginatedService } from '@koverse/react';
import AddIcon from '@mui/icons-material/Add';
import GroupsIcon from '@mui/icons-material/GroupOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import BreadCrumbs from '../components/BreadCrumbs';
import CreateGroup from '../components/Dialogs/ManageGroup/CreateGroup';
import GroupsTable from '../components/GroupsTable';

const Groups = (): React.ReactElement | null => {
  const [createGroup, setCreateGroup] = React.useState<boolean>(false);

  const {
    items: groups,
    loading,
  } = usePaginatedService('groups');

  const renderAddButton = (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      startIcon={<AddIcon />}
      onClick={() => setCreateGroup(true)}
    >
      Create Group
    </Button>
  );

  const renderZeroState = (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <GroupsIcon
        sx={{
          fontSize: 42,
          color: theme => theme.palette.text.secondary,
        }}
      />
      <Typography sx={{ mb: 2 }}>There are no groups in this workspace</Typography>
      {renderAddButton}
    </Stack>
  );

  if (loading) {
    return null;
  }

  return (
    <>
      <Card>
        <Box sx={{ ml: 2, mt: 2 }}>
          <BreadCrumbs />
        </Box>
        <CardHeader
          sx={{
            pb: 0,
            '& .MuiCardHeader-action': {
              alignSelf: 'center',
              margin: 0,
            },
          }}
          title="Groups"
          titleTypographyProps={{ variant: 'h6' }}
          subheader="Easily manage multiple users by creating groups"
          action={!!groups.length && renderAddButton}
        />
        {groups.length === 0 && (
          <CardContent>
            {renderZeroState}
          </CardContent>
        )}
        {!!groups.length && <GroupsTable />}
      </Card>
      {createGroup && (
        <CreateGroup
          open={createGroup}
          onClose={() => setCreateGroup(false)}
        />
      )}
    </>
  );
};

export default Groups;
