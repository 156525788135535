import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import CheckFilledIcon from '@mui/icons-material/CheckCircle';
import CheckOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Tooltip from '@mui/material/Tooltip';
import { PasswordCriteriaStandard, PasswordCriteria } from '../../declarations';

type CriteriaLabel = {
  [key in PasswordCriteriaStandard]: string;
}

interface CriteriaPopoverProps {
  criteria: PasswordCriteria;
  isMinLength: boolean;
  children: React.ReactElement;
  open: boolean;
}

const CriteriaPopover = ({
  children,
  isMinLength,
  criteria,
  open,
}: CriteriaPopoverProps): React.ReactElement => {
  const matches = useMediaQuery('(min-width:1180px)');
  const symbols = '"?=$;@!%*#.&-+_^\'`)(,/|~}{[:]<>\\';
  const criteriaLabel: CriteriaLabel = {
    nonWord: '1 symbol',
    digit: '1 number',
    upperCase: '1 upper-case',
    lowerCase: '1 lower-case',
  };

  return (
    <Tooltip
      open={open}
      sx={{
        '& .MuiTooltip-tooltip': {
          maxWidth: 'none',
        },
      }}
      arrow
      placement={matches ? 'right' : 'top'}
      title={(
        <>
          <List
            disablePadding
            sx={{ mb: 1, minWidth: 300 }}
            subheader={(
              <ListSubheader disableSticky>Requirements:</ListSubheader>
            )}
          >
            <ListItem dense>
              <ListItemIcon
                sx={{
                  '& .MuiListItemIcon-root': {
                    minWidth: 4,
                  },
                }}
              >
                {!isMinLength
                  ? <CheckOutlineIcon color="inherit" />
                  : <CheckFilledIcon sx={{ color: (theme) => theme.palette.success.main }} />}
              </ListItemIcon>
              <ListItemText primary="min 8 characters" />
            </ListItem>
          </List>
          <Divider />
          <List
            sx={{ mt: 2, minWidth: 300 }}
            subheader={(
              <ListSubheader
                disableSticky
                sx={{
                  lineHeight: 2,
                }}
              >
                Must include 3 or more of the following:
              </ListSubheader>
            )}
          >
            {Object.keys(criteria).map((standard) => {
              return (
                <ListItem dense key={standard}>
                  <ListItemIcon
                    sx={{
                      '& .MuiListItemIcon-root': {
                        minWidth: 4,
                      },
                    }}
                  >
                    {!criteria[standard as PasswordCriteriaStandard] ? (
                      <CheckOutlineIcon color="inherit" />
                    ) : (
                      <CheckFilledIcon sx={{ color: (theme) => theme.palette.success.main }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={criteriaLabel[standard as PasswordCriteriaStandard]}
                    secondary={standard === 'nonWord' ? symbols : null}
                  />
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    >
      {children}
    </Tooltip>
  );
};

export default CriteriaPopover;
