import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import TableContainer from '@mui/material/TableContainer';
import some from 'lodash/some';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import createPersistedState from 'use-persisted-state';
import { Dataset, PaginationModel } from '../../declarations';
import { useOnScreen, useQueryRequest } from '../../hooks';
import useSearchSelect from '../../hooks/useSearchSelect';
import DataViewSearchBar from './DataViewSearchBar';
import DatasetViews from './DatasetViews';
import DocumentPreview from './DocumentPreview';
import SearchAllDatasetsDrawer from './SearchAllDatasetsDrawer';
import DeleteRecords from '../Dialogs/DeleteRecords';

interface ToggleState {
  [datasetId: string]: boolean;
}

interface Params {
  id: string;
  workspaceId: string;
}

interface Props {
  dataset: Dataset;
  disableDataActions: boolean;
  openUploadDrawer: () => void;
}

// TODO: hook up persisted record limit again
// const useRecordLimitState = createPersistedState('record-limit');
const useToggleViewState = createPersistedState<ToggleState>('toggle-view-state');

const DatasetData = ({
  dataset,
  disableDataActions,
  openUploadDrawer,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams<Params>();
  const { error, loading, queryResult, fetchData } = useQueryRequest('query');
  const {
    debouncedQuery,
    scope,
    setScope,
    setResultIds,
  } = useSearchSelect();
  const [documentViewIsOpen, setDocumentViewOpenState] = useToggleViewState({ [id]: false });
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<{ deleteAll: boolean, displayedTotal: string, documentView: boolean } | null>(null);
  const [showTreeView, setShowTreeView] = React.useState<boolean>(false);
  const [containsDocuments, setContainsDocuments] = React.useState<boolean>(false);
  const [collapsed, setCollapsed] = React.useState<boolean | number>(1);
  const [paginationModel, setPaginationModel] = React.useState<PaginationModel>({
    page: 0,
    pageSize: 5,
  });
  const [queryResultTotal, setQueryResultTotal] = React.useState(0);

  const expressionRef = React.useRef<string>('');
  const suppress = React.useRef<boolean>(false);

  const scrollRef = React.useRef() as React.MutableRefObject<HTMLElement>;
  const isOnScreen = useOnScreen(scrollRef, '-40px');

  const newSearch = expressionRef.current !== debouncedQuery;

  const handleResetPage = React.useCallback(() => {
    // reset page to 0 and update suppress.current so the additional fetch
    // caused by the page update is not called
    suppress.current = true;
    setPaginationModel((prev: PaginationModel) => ({
      ...prev,
      page: 0,
    }));
  }, []);

  const handleToggleCollapse = () => {
    if (collapsed === 1) {
      setCollapsed(false);
    } else {
      setCollapsed(!collapsed);
    }
  };

  const toggleDocumentView = () => {
    setDocumentViewOpenState((state: ToggleState) => ({
      ...state,
      [id]: !state[id],
    }));
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  React.useEffect(() => {
    // if there is a new search term reset page to 0
    if (newSearch) {
      handleResetPage();
    }
  }, [handleResetPage, newSearch]);

  React.useEffect(() => {
    if (scope !== 'all' && !documentViewIsOpen[id]) {
      // update the expression ref
      expressionRef.current = debouncedQuery;

      // ignore fetch data if the search is new and page has not reset to 0
      if (suppress.current && paginationModel.page !== 0) {
        // reset supress.current
        suppress.current = false;
        return;
      }

      fetchData({
        datasetId: id,
        expression: debouncedQuery,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
      });
    }

    return () => {
      suppress.current = false;
      expressionRef.current = '';
    };
  }, [debouncedQuery, documentViewIsOpen, fetchData, id, paginationModel.page, paginationModel.pageSize, scope]);

  React.useEffect(() => {
    setContainsDocuments(some(Object.keys(dataset.schema).map((k) => k.indexOf('document_id')), t => t !== -1));
    setShowTreeView(dataset.jsonView);
  }, [dataset.jsonView, dataset.schema]);

  React.useEffect(() => {
    setResultIds(queryResult?.records.map((r: Record<string, any>) => r._koverse_record_id) || []);
  }, [queryResult?.records, setResultIds]);

  React.useEffect(() => {
    if (queryResult?.total) {
      setQueryResultTotal(queryResult.total);
    }
  }, [queryResult]);

  return (
    <Box sx={{ position: 'relative' }}>
      <DataViewSearchBar
        collapsed={collapsed}
        datasetPermissions={dataset.currentUserPermissions}
        disableDataActions={disableDataActions}
        documentToggleEnabled={containsDocuments}
        documentView={documentViewIsOpen[id] as boolean}
        openDeleteDialog={setOpenDeleteDialog}
        openUploadDrawer={openUploadDrawer}
        toggleCollapsed={showTreeView ? handleToggleCollapse : undefined}
        toggleDocumentView={toggleDocumentView}
        totalResults={queryResultTotal}
      />
      <DeleteRecords
        open={!!openDeleteDialog}
        onClose={() => setOpenDeleteDialog(null)}
        dataset={dataset}
        {...openDeleteDialog}
      />
      <SearchAllDatasetsDrawer
        open={scope === 'all'}
        onClose={() => setScope(undefined)}
      />
      <Box ref={scrollRef} />
      <TableContainer>
        {documentViewIsOpen[id] ? (
          <DocumentPreview disableDataActions={disableDataActions} setQueryResultTotal={setQueryResultTotal} />
        ) : (
          <DatasetViews
            {...{
              collapsed,
              dataset,
              disableDataActions,
              loading,
              queryResult,
              showTreeView,
              error,
              paginationModel,
              setPaginationModel,
            }}
          />
        )}
        {!isOnScreen && (
          <Fab
            variant="extended"
            color="primary"
            onClick={handleScrollToTop}
            sx={{
              position: 'fixed',
              zIndex: theme => theme.zIndex.appBar + 1,
              bottom: theme => theme.spacing(1.75),
              right: theme => theme.spacing(17),
              height: theme => theme.spacing(5.75),
            }}
          >
            <ExpandLessIcon sx={{ mr: 1 }} />
            {t('common.top')}
          </Fab>
        )}
      </TableContainer>
    </Box>
  );
};

export default DatasetData;
