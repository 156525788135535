import React from 'react';
import every from 'lodash/every';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useKoverse, usePaginatedService } from '@koverse/react';
import Grid from '@mui/material/Grid';
import DatasetsDetailsCard from '../components/DatasetsDetailsCard';
import { Dataset } from '../declarations';

function Datasets(): React.ReactElement | null {
  const { client, workspace } = useKoverse();
  const paginatedServiceProps = usePaginatedService('datasets', {
    $sort: {
      name: 1,
    },
    workspaceId: workspace.id,
  });
  const {
    items,
    loading,
    fetchList,
  } = paginatedServiceProps;

  const datasetsMatchWorkspace = every(items, (dataset: Dataset) => dataset.workspaceId === workspace.id);

  React.useEffect(() => {
    client.service('dataset-permissions').on('created', fetchList);
    client.service('dataset-permissions').on('removed', fetchList);
    client.service('dataset-permissions').on('patched', fetchList);
    client.service('dataset-permissions').on('updated', fetchList);

    return function cleanUp() {
      client.service('dataset-permissions').off('created', fetchList);
      client.service('dataset-permissions').off('removed', fetchList);
      client.service('dataset-permissions').off('patched', fetchList);
      client.service('dataset-permissions').off('updated', fetchList);
    };
  }, [client, fetchList]);

  return !loading ? (
    <Grid
      container
      spacing={3}
      sx={{ height: '100%' }}
    >
      <Grid item xs={12}>
        <Switch>
          {datasetsMatchWorkspace && (
            <Route
              path="/datasets/:id"
              render={() => (
                <DatasetsDetailsCard />
              )}
            />
          )}
          {datasetsMatchWorkspace && items.length === 0 && (
            <Redirect to={`/datasets`} />
          )}
          {datasetsMatchWorkspace && !!items.length && (
            <Redirect to={`/datasets/${items[0].id}`} />
          )}
        </Switch>
      </Grid>
    </Grid>
  ) : null;
}

export default Datasets;
