import { Github, Google, Microsoft } from '@icons-pack/react-simple-icons';
import { Logo, useKoverse, useServiceItem } from '@koverse/react';
import Person from '@mui/icons-material/Person';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import validator from 'validator';
import { useAppState } from '../components/AppStateProvider';
import config from '../config';
import useQueryParams from '../hooks/useQueryParams';

type Inputs = {
  email: string;
  password: string;
};

interface CustomOauthConfig {
  name: string;
  providerType: 'keycloak' | 'github' | 'okta' | 'google' | 'microsoft';
  workspaceId: string;
}

function Login(): React.ReactElement {
  const { client } = useKoverse();
  const [customOauth, setCustomOauth] = React.useState<CustomOauthConfig[]>([]);
  const [submissionError, setSubmissionError] = useState(null);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();
  const { mode } = useAppState();
  const { queryParams } = useQueryParams();
  const { email } = queryParams;
  const {
    errors,
    formState,
    handleSubmit,
    register,
  } = useForm<Inputs>({
    mode: 'onChange',
  });
  const { isSubmitting, isValid } = formState;
  const showOr = (!config.disableLocalAuth && (
    config.githubOauth ||
    config.googleOauth ||
    config.microsoftOauth ||
    config.oktaOauth ||
    config.keycloakOauth
  ));

  useEffect(() => {
    localStorage.removeItem('toggle-view-state');
  }, []);

  const {
    item: workspace,
    loading,
    initialized,
  } = useServiceItem('workspaces', config.workspaceId as string);

  const onSubmit = async (data: Inputs) => {
    try {
      await client.authenticate({
        strategy: 'local',
        email: data.email,
        password: data.password,
        workspaceId: config.workspaceId,
      });
    } catch (e) {
      setSubmissionError(get(e, 'message', null));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const trySelfHostedOauth = React.useCallback(async () => {
    if (config.selfHosted && workspace) {
      try {
        const url = `//${config.api}/self-hosted-oauth/${workspace.id}`;
        const { data } = await axios.get(url);
        setCustomOauth(data);
      } catch (e) {}
    }
  }, [workspace]);

  React.useEffect(() => {
    trySelfHostedOauth();
  }, [trySelfHostedOauth]);

  if (!initialized) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100%',
        pt: 8,
      }}
    >
      {loading && <CircularProgress size={36} />}
      {!loading && !workspace && (
        <Stack
          direction="column"
          alignItems="center"
        >
          <Logo mode={mode} />
          <Typography variant="h6">{t('pages.workspace.notFound')}</Typography>
        </Stack>
      )}
      {!loading && workspace && (
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Logo mode={mode} />
          <Typography variant="h6" sx={{ mb: 2 }}>{t('pages.logIn.loginToWorkspace', { workspaceName: workspace.name })}</Typography>
          {!config.disableLocalAuth && (
            <React.Fragment>
              <TextField
                InputLabelProps={{
                  htmlFor: 'email',
                }}
                id="email"
                defaultValue={email}
                margin="normal"
                label={t('forms.labels.email')}
                name="email"
                variant="outlined"
                placeholder={t('forms.placeholders.email')}
                fullWidth
                error={!!errors.email && showEmailError}
                helperText={(showEmailError && errors.email && errors.email.message) || ''}
                inputRef={register({
                  required: t('forms.validation.required') as string,
                  validate: {
                    email: (value: string) => validator.isEmail(value) || t('forms.validation.invalidEmail') as string,
                  },
                })}
                onBlur={() => {
                  if (!!errors.email) {
                    setShowEmailError(true);
                  }
                }}
              />
              <TextField
                InputLabelProps={{
                  htmlFor: 'password',
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="large">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
                id="password"
                margin="normal"
                label={t('forms.labels.password')}
                name="password"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                error={!!errors.password}
                helperText={(errors.password && errors.password.message) || ''}
                inputRef={register({ required: t('forms.validation.required') as string })}
              />
              {submissionError && (
                <Alert
                  severity="error"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    my: 2,
                  }}
                >
                  {submissionError}
                </Alert>
              )}
              <Stack
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ m: 2 }}
              >
                {isSubmitting ? (
                  <CircularProgress size={36} />
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                  >
                    {t('common.login')}
                  </Button>
                )}
                <Typography gutterBottom>
                  <Link component={RouterLink} to="/forgot-password">
                    {t('pages.logIn.forgotPassword')}
                  </Link>
                </Typography>
              </Stack>
            </React.Fragment>
          )}
          {showOr && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%' }}
              spacing={2}
            >
              <Divider sx={{ flexGrow: 1 }} />
              <Typography variant="button">{t('common.or')}</Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Stack>
          )}
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ m: 2 }}
          >
            {config.microsoftOauth && (
              <Button
                href={`//${config.api}/oauth/microsoft?workspaceId=${workspace.id}`}
                startIcon={<Microsoft />}
                variant="outlined"
              >
                Log in with Microsoft
              </Button>
            )}
            {config.githubOauth && (
              <Button
                href={`//${config.api}/oauth/github?workspaceId=${workspace.id}`}
                startIcon={<Github />}
                variant="outlined"
              >
                Log in with Github
              </Button>
            )}
            {config.keycloakOauth && (
              <Button
                href={`//${config.api}/oauth/keycloak?workspaceId=${workspace.id}`}
                startIcon={<Person />}
                variant="outlined"
              >
                Log in with Keycloak
              </Button>
            )}
            {config.googleOauth && (
              <Button
                href={`//${config.api}/oauth/google?workspaceId=${workspace.id}`}
                startIcon={<Google />}
                variant="outlined"
              >
                Log in with Google
              </Button>
            )}
            {config.oktaOauth && (
              <Button
                href={`//${config.api}/oauth/okta?workspaceId=${workspace.id}`}
                startIcon={<Person />}
                variant="outlined"
              >
                Log in with Okta
              </Button>
            )}
            {config.selfHosted && !!customOauth.length && customOauth.map((custom, index) => {
              return workspace.id === custom.workspaceId && (
                <Button
                  key={`${custom.name}-${index}`}
                  href={`//${config.api}/oauth/${custom.name}?workspaceId=${workspace.id}`}
                  startIcon={<Person />}
                  variant="outlined"
                >
                  {`Log in with ${custom.name}`}
                </Button>
              );
            })}
          </Stack>
        </form>
      )}
    </Container>
  );
}

export default Login;
