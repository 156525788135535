import React from 'react';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import { useKoverse, useServiceItem } from '@koverse/react';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Skeleton from  '@mui/material/Skeleton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dataset, DatasetPermission, Group } from '../../declarations';

interface PermissionsListItemProps {
  dataset: Dataset;
  permission: DatasetPermission;
  divider: boolean;
}

const PermissionsListItem = ({
  dataset,
  permission,
  ...props
}: PermissionsListItemProps): React.ReactElement => {
  const { client } = useKoverse();
  const {
    item,
  } = useServiceItem('groups', permission.groupId);
  const group = item as Group;
  const canManage = get(dataset, 'currentUserPermissions.manage', false);
  const handleDelete = () => {
    client.service('dataset-permissions').remove(permission.id);
  };

  return (
    <ListItem
      dense
      key={permission.id}
      {...props}
    >
      <ListItemText
        primary={`Access: ${capitalize(permission.action)}`}
        secondary={group ? `Group: ${group.name}` : (
          <Skeleton
            data-testid="loading"
            variant="rectangular"
            height={13}
            width="90%"
            style={{ marginTop: 5 }}
          />
        )}
      />
      {canManage && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={handleDelete} size="large">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default PermissionsListItem;
