import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { useForm, FieldError, FormProvider } from 'react-hook-form';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AttributeFields from './AttributeFields';
import UserSearchField from './UserSearchField';
import { Attribute, User } from '../../../declarations';

type Inputs = {
  token: string;
  name: string;
  description: string;
  users: User[];
};

interface Props {
  open: boolean;
  onClose: (attribute?: Attribute) => void;
  values?: Inputs | null;
}

const CreateAttribute = ({ open, onClose, values }: Props): React.ReactElement => {
  const { client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);

  const form = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      token: '',
      description: '',
      users: [],
    },
  });

  const {
    formState,
    handleSubmit,
    reset,
    setError,
  } = form;

  const { isDirty, isValid, isSubmitting } = formState;

  React.useEffect(() => {
    if (values) {
      reset(values);
    }
  }, [values, reset]);

  const onSubmit = async (data: Inputs) => {
    try {
      const attribute = await client.service('attributes').create({
        ...data,
        workspaceId: workspace.id,
      });

      if (!!data.users) {
        const calls = data.users.map((user: User) => {
          return ['create', 'attribute-assignments', {
            attributeId: attribute.id,
            userId: user.id,
            workspaceId: workspace.id,
          }];
        });
        await client.service('batch').create({ calls });
      }
      onClose();
      enqueueSnackbar(t('dialogs.attribute.create.successToast'), {
        variant: 'success',
      });
    } catch (e) {
      if (isArray(get(e, 'errors'))) {
        const errorList = get(e, 'errors') || [];

        errorList.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          if (path !== 'workspaceId') {
            setError(path, {
              type: 'manual',
              message: error.message,
            });
          }
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{t('dialogs.attribute.create.title')}</DialogTitle>
          <DialogContent>
            <AttributeFields />
            <Divider sx={{ my: 2 }} />
            <Typography>{t('dialogs.attribute.assign.title')}</Typography>
            <UserSearchField />
            {submissionError && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{
                  width: '100%',
                  my: 2,
                }}
              >
                {submissionError}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => onClose()}
            >
              {t('forms.cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isDirty || !isValid || isSubmitting}
            >
              {t('forms.create')}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default CreateAttribute;
