import React from 'react';
import ReactDOM from 'react-dom';
import { KoverseProvider, theme } from '@koverse/react';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import { FeatureToggleProvider } from './components/FeatureToggle';
import ServerAlert from './components/ServerAlert';
import ActiveSessionsProvider from './components/ActiveSessionsProvider';
import * as serviceWorker from './serviceWorker';
import config from './config';
import './i18n';
import 'fontsource-roboto';
import AppStateProvider, { useAppState } from './components/AppStateProvider';

const Main = () => {
  const { mode } = useAppState();
  const selectedTheme = mode === 'dark' ? theme.dark : theme.light;
  return (
    <KoverseProvider
      api={config.api}
      theme={selectedTheme}
    >
      <CssBaseline />
      <FeatureToggleProvider>
        <ActiveSessionsProvider>
          <SnackbarProvider>
            <ServerAlert />
            <App />
          </SnackbarProvider>
        </ActiveSessionsProvider>
      </FeatureToggleProvider>
    </KoverseProvider>
  );
};
ReactDOM.render(
  <AppStateProvider>
    <Main />
  </AppStateProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
