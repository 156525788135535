import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import queryString from 'query-string';
import toLower from 'lodash/toLower';
import validator from 'validator';
import { useKoverse, Logo } from '@koverse/react';
import { useForm, FieldError } from 'react-hook-form';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ChartIcon from '@mui/icons-material/BarChartOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LockIcon from '@mui/icons-material/LockOutlined';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import TrendingUpIcon from '@mui/icons-material/TrendingUpOutlined';
import Typography from '@mui/material/Typography';
import CreatePasswordField from '../components/CreatePasswordField';
import useSubscription from '../hooks/useSubscription';
import { useAppState } from '../components/AppStateProvider';
import config from '../config';

type Inputs = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

function SignUp(): React.ReactElement {
  const { client } = useKoverse();
  const { search } = useLocation();
  const { setSelectedSubscriptionLevelKey } = useSubscription();
  const { t } = useTranslation();
  const { mode } = useAppState();
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const usersService = client.service('users');

  const {
    errors,
    formState,
    handleSubmit,
    register,
    setError,
    reset,
    watch,
  } = useForm<Inputs>({
    mode: 'onChange',
  });
  const { isSubmitting, isValid, touched } = formState;
  const onSubmit = async (data: Inputs) => {
    try {
      await usersService.create(data);
      await client.authenticate({
        strategy: 'local',
        email: data.email,
        password: data.password,
      });
      reset();
    } catch (e) {
      if (isArray(get(e, 'errors'))) {
        const errorList = get(e, 'errors') || [];
        errorList.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          setError(path, {
            type: 'manual',
            message: error.message,
          });
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  useEffect(() => {
    client.service('users').timeout = 100000000;
  }, [client]);

  useEffect(() => {
    const { plan } = queryString.parse(search);
    const subscriptionLevelKey = !!plan && !isArray(plan)
      ? plan
      : undefined;

    if (!!subscriptionLevelKey && ['Starter', 'Pro', 'Enterprise'].includes(subscriptionLevelKey)) {
      setSelectedSubscriptionLevelKey(toLower(subscriptionLevelKey));
    }
  }, [search, setSelectedSubscriptionLevelKey]);

  return (
    <Container
      maxWidth="lg"
      fixed
    >
      <Box sx={{ p: 8 }}>
        <Grid container>
          <Grid item sm={12}>
            <Box sx={{ ml: -1, mb: 3 }}>
              <Logo mode={mode} />
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              borderRight: t => ({
                md: `1px solid ${t.palette.divider}`,
              }),
              paddingRight: {
                md: 8,
              },
            }}
          >
            <Typography
              variant="h5"
              sx={{ mb: 1 }}
            >
              {t('pages.signUp.marketing.header')}
            </Typography>
            <Typography
              sx={{ mb: 6 }}
              color="textSecondary"
            >
              {t('pages.signUp.marketing.subheader')}
            </Typography>
            { !config.selfHosted &&
              <Typography variant="h5" sx={{ mb: 4 }}>
                {t('pages.signUp.marketing.startTrial')}
              </Typography>
            }
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center', mb: 6 }}>
              <LockIcon fontSize="large" sx={{ color: t => t.palette.text.secondary }} />
              <Typography sx={{ mb: 2 }}>{t('pages.signUp.marketing.integration')}</Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center', mb: 6 }}>
              <ChartIcon fontSize="large" sx={{ color: t => t.palette.text.secondary }} />
              <Typography sx={{ mb: 2 }}>{t('pages.signUp.marketing.scaling')}</Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center', mb: 6 }}>
              <TrendingUpIcon fontSize="large" sx={{ color: t => t.palette.text.secondary }} />
              <Typography sx={{ mb: 2 }}>{t('pages.signUp.marketing.access')}</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              paddingLeft: {
                md: 8,
              },
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              { config.selfHosted ?
                <Typography variant="h6" sx={{ mb: 0 }}>{t('pages.signUp.form.headerSelfHosted')}</Typography> :
                <Typography variant="h6" sx={{ mb: 0 }}>{t('pages.signUp.form.header')}</Typography>
              }
              { !config.selfHosted && <Typography color="textSecondary" sx={{ mb: 2 }}>{t('pages.signUp.form.subheader')}</Typography> }
              <TextField
                InputLabelProps={{
                  htmlFor: 'firstName',
                }}
                id="firstName"
                margin="normal"
                label={t('forms.labels.firstName')}
                name="firstName"
                variant="outlined"
                fullWidth
                error={!!errors.firstName}
                helperText={(errors.firstName && errors.firstName.message) || ''}
                inputRef={register({ required: t('forms.validation.required') as string })}
              />
              <TextField
                InputLabelProps={{
                  htmlFor: 'lastName',
                }}
                id="lastName"
                margin="normal"
                label={t('forms.labels.lastName')}
                name="lastName"
                variant="outlined"
                fullWidth
                error={!!errors.lastName}
                helperText={(errors.lastName && errors.lastName.message) || ''}
                inputRef={register({ required: t('forms.validation.required') as string })}
              />
              <TextField
                InputLabelProps={{
                  htmlFor: 'email',
                }}
                id="email"
                margin="normal"
                fullWidth
                label={t('forms.labels.email')}
                name="email"
                variant="outlined"
                placeholder={t('forms.placeholders.email')}
                error={!!errors.email && touched.email}
                helperText={(touched.email && errors.email && errors.email.message) || ''}
                inputRef={register({
                  required: t('forms.validation.required') as string,
                  validate: {
                    email: (value: string) => validator.isEmail(value) || t('forms.validation.invalidEmail') as string,
                  },
                })}
              />
              <CreatePasswordField {...{ errors, register, watch, setError }} />
              {submissionError && (
                <Alert
                  severity="error"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    my: 2,
                  }}
                >
                  {submissionError}
                </Alert>
              )}
              <Box sx={{ mt: 2, mb: 2 }}>
                <Typography display="inline">
                  <Trans
                    i18nKey='pages.signUp.form.termsAndConditions'
                    components={{
                      a: <Link href="https://www.koverse.com/terms-conditions/" underline="none" />,
                    }}
                  />
                </Typography>
                <Typography display="inline">
                  <Trans
                    i18nKey='pages.signUp.form.privacyPolicy'
                    components={{
                      a: <Link href="https://www.koverse.com/privacy-policy/" underline="none" />,
                    }}
                  />
                </Typography>
              </Box>
              {isSubmitting ? (
                <CircularProgress size={36} />
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!isValid}
                >
                  {t('common.createAccount')}
                </Button>
              )}
              <Typography sx={{ mt: 2 }}>
                {'Already have an account? '}
                <Link component={RouterLink} to="/login">
                  {t('common.login')}
                </Link>
              </Typography>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default SignUp;
