import { useKoverse, useServiceItem } from '@koverse/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ApplicationLinks from '../ApplicationLinks';
import DashboardTasks from '../DashboardTasks';
import AttributesCard from './AttributesCard';
import GroupsCard from './GroupsCard';
import InvitationsCard from './InvitationsCard';

const WorkspaceDashboard = (): React.ReactElement | null => {
  const { user, workspace } = useKoverse();
  const { t } = useTranslation();
  const {
    item: owner,
  } = useServiceItem('users', get(workspace, 'userId'));

  const isAdmin = user.role === 'admin';

  return user ? (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">
          {!workspace ? <Skeleton width={200} /> : workspace.name}
        </Typography>
        <Typography
          variant="caption"
          sx={{ fontStyle: 'italic' }}
          color="textSecondary"
        >
          {!owner
            ? <Skeleton width={200} />
            : t('pages.workspace.subheader', { name: owner.displayName })
          }
        </Typography>
      </Box>
      <ApplicationLinks />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DashboardTasks />
        </Grid>
        {isAdmin && (
          <Grid item xs={12}>
            <InvitationsCard />
          </Grid>
        )}
        <GroupsCard />
        <AttributesCard isAdmin={isAdmin} />
      </Grid>
    </>
  ) : null;
};

export default WorkspaceDashboard;
