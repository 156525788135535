import React from 'react';

interface UseDataUriToBlob {
  dataUriToBlob: (uri: string) => Blob;
}

const useDataUriToBlob = (): UseDataUriToBlob => {
  const dataUriToBlob = React.useCallback((dataURI: string) => {
    // convert base64 to raw binary data held in a string
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }, []);

  return {
    dataUriToBlob,
  };
};

export default useDataUriToBlob;
