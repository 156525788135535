import { useKoverse } from '@koverse/react';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import get from 'lodash/get';
import mime from 'mime';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import config from '../../config';
import { Dataset } from '../../declarations';
import FileDropZone from './FileDropZone';
import FileList from './FileList';
import StorageTypeSelect from './StorageTypeSelect';
import AccessControlForm from '../AccessControlForm';

interface Props {
  dataset: Dataset;
  onCancel: () => void;
  afterSubmit: () => void;
}

interface Inputs {
  accessControlLabel: {
    securityLabeled: boolean | null;
    securityLabelInfo: {
      fields?: string;
      ifEmpty?: 'remove' | 'ignore' | 'replace' | null;
      label?: string;
      parserClassName: string;
      replacementString?: string;
    }
  };
  processAsDocument: 'files' | 'data';
}

const supportedExtensions = [
  'avro', 'csv', 'doc', 'docx',
  'gz', 'gzip', 'html', 'json',
  'pdf', 'ppt', 'pptm', 'pptx',
  'text', 'tsv', 'xls', 'mp4',
  'webm', 'ogg',
];

const FileUpload = ({
  dataset,
  onCancel,
  afterSubmit,
}: Props): React.ReactElement => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<Inputs>({ mode: 'onChange' });
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [files, setFiles] = React.useState<File[]>([]);
  const [hasDuplicateFiles, setHasDuplicateFiles] = React.useState<boolean>(false);
  const [hasUnsupportedFileExtensions, setHasUnsupportedFileExtensions] = React.useState<boolean>(false);

  const { control } = form;

  const storageType = useWatch({
    control,
    name: 'processAsDocument',
  });

  const onSubmit = async (data: Inputs) => {
    const uploadUrl = `//${config.api}/uploads`;
    const formData = new FormData();

    formData.set('datasetId', dataset.id);
    formData.set('processAsDocument', String(data.processAsDocument === 'files'));

    if (data.accessControlLabel.securityLabeled) {
      formData.append('accessControlLabel', JSON.stringify(data.accessControlLabel));
    }

    files.forEach((file) => {
      formData.append('files', file);
    });

    try {
      const jwt = await client.authentication.getAccessToken();
      await axios.post(uploadUrl, formData, {
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${jwt}`,
        },
      });
      afterSubmit();
    } catch (e) {
      enqueueSnackbar(`There was an error during upload: ${get(e, 'message')}`, {
        variant: 'error',
      });
    }
  };

  React.useEffect(() => {
    const fileNames = new Set(files.map(file => file.name));
    fileNames.size !== files.length
      ? setHasDuplicateFiles(true)
      : setHasDuplicateFiles(false);
    setHasUnsupportedFileExtensions(files.some((file) => {
      return !supportedExtensions.includes(mime.getExtension(file.type) as string);
    }));
  }, [files]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box sx={{ display: currentStep === 0 ? undefined : 'none' }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6">
              Upload data from a local file
            </Typography>
            <Typography variant="body2">
              Configure how you want Koverse to store your local files.
            </Typography>
            <Typography variant="body2">
              Files without nested data will display as columnar data. Files containing nested data will display as a .json tree.
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FileDropZone {...{ files, setFiles }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StorageTypeSelect />
              {!!files.length && (
                <>
                  <FileList {...{ files, setFiles }} />
                  {hasUnsupportedFileExtensions && (
                    <Alert
                      variant="outlined"
                      severity="warning"
                      sx={{ my: 1 }}
                    >
                      Warning: Some file types may not be supported
                    </Alert>
                  )}
                  {hasDuplicateFiles && (
                    <Alert
                      variant="outlined"
                      severity="warning"
                      sx={{ my: 1 }}
                    >
                      Warning: You have duplicate file names
                    </Alert>
                  )}
                </>
              )}
            </Grid>
            <Grid item>
              <Typography color="textSecondary">Supported file types are .json, .csv, .xls, .gzip, .gz, .mp4</Typography>
              <Typography color="textSecondary">
                View our <Link href={`//${config.documentationDomain}/docs/datasets/supported-filetypes/`} target="_blank" sx={{ textDecoration: 'none' }}>documentation</Link> for a full list of supported file types.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Button
                color="primary"
                sx={{ mr: 2 }}
                aria-label="cancel"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setCurrentStep(1)}
                disabled={files.length === 0 || !storageType}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: currentStep === 1 ? undefined : 'none' }}>
          <AccessControlForm
            onBack={() => setCurrentStep(0)}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default FileUpload;
