import React from 'react';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { User } from '../../../declarations';

type Props = {
  open: boolean;
  onClose: () => void;
  user: User;
};

const RemoveUser = ({
  open,
  onClose,
  user,
}: Props): React.ReactElement | null => {
  const { client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleOnClose = () => {
    onClose();
  };

  const handleRemove = async () => {
    try {
      await client.service('workspace-management').create({
        action: 'removeMember',
        workspaceId: workspace.id,
        userId: user.id,
      });
      enqueueSnackbar(t('dialogs.removeUser.successToast', {
        user: user.displayName,
      }), {
        variant: 'success',
      });
      onClose();
    } catch (e) {
      enqueueSnackbar(`There was an error: ${e}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog open={open} onClose={() => handleOnClose()} fullWidth>
      <DialogTitle>
        {t('dialogs.removeUser.title')}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t('dialogs.removeUser.message', {
            user: user.displayName,
            email: user.email,
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => handleOnClose()}
        >
          {t('forms.cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleRemove}
        >
          {t('forms.remove')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveUser;
