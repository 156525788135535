import { useKoverse } from '@koverse/react';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StripeHandlerEvent } from '../../../declarations';
import useSubscription from '../../../hooks/useSubscription';
import useSubscriptionLevels from '../../../hooks/useSubscriptionLevels';
import CardPreview from '../../CardPreview';
import SetupPayment from '../../SetupPayment';

type Props = {
  open: boolean;
  onClose: () => void;
  subscriptionLevelKey: string;
};

type PaymentState = {
  stripe?: Stripe;
  elements?: StripeElements;
  cardComplete: boolean;
};

const PurchasePlan = ({
  open,
  onClose,
  subscriptionLevelKey,
}: Props): React.ReactElement => {
  const history  = useHistory();
  const {
    fetchSubscription,
    subscription,
    updatePaymentMethod,
    updateSubscription,
  } = useSubscription();
  const { user, client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { subscriptionLevels } = useSubscriptionLevels();
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [payment, setPayment] = React.useState<PaymentState>({ cardComplete: false });
  const [paymentMethodId, setPaymentMethodId] = React.useState<string | undefined>(undefined);
  const [step, setStep] = React.useState<number>(0);
  const defaultPaymentMethod = React.useMemo(() => subscription?.default_payment_method, [subscription]);
  const disabled = React.useMemo(() => (!!payment.elements && !payment.cardComplete), [payment.cardComplete, payment.elements]);
  const subscriptionIsInvalid = React.useMemo(() => ['incomplete_expired', 'canceled'].includes(get(subscription, 'status', '')), [subscription]);
  const subscriptionLevel = React.useMemo(() => subscriptionLevels[subscriptionLevelKey], [subscriptionLevelKey, subscriptionLevels]);

  const reactivateSubscription = React.useCallback(async () => {
    const newSub = await client.service('stripe').create({
      action: 'createSubscription',
      subscriptionLevel: subscriptionLevelKey,
      paymentMethod: paymentMethodId || defaultPaymentMethod?.id,
    });

    if (newSub?.status === 'incomplete' && newSub?.latest_invoice) {
      await client.service('stripe').create({
        action: 'payInvoice',
        invoiceId: newSub.latest_invoice,
      });
    }

    fetchSubscription();
  }, [client, defaultPaymentMethod?.id, fetchSubscription, paymentMethodId, subscriptionLevelKey]);

  const handleOnConfirm = async () => {
    setLoading(true);
    try {
      if (!subscriptionIsInvalid) {
        await updateSubscription({ subscriptionLevel: subscriptionLevelKey });
        enqueueSnackbar(t('dialogs.purchasePlan.updateSuccessToast'), {
          variant: 'success',
        });
        onClose();
        history.replace('/billing');
      }

      if (subscriptionIsInvalid) {
        await reactivateSubscription();
        enqueueSnackbar(t('dialogs.purchasePlan.createSuccessToast'), {
          variant: 'success',
        });
        onClose();
      }
    } catch (e) {
      setLoading(false);
      setError(get(e, 'message', `There was an error: ${e}`));
      return;
    }
  };

  const handleOnNext = async () => {
    if (!defaultPaymentMethod || edit) {
      setLoading(true);
      try {
        const { stripe, elements } = payment;
        if (!elements || !stripe) {
          setError(t('dialogs.purchasePlan.elementError'));
          setLoading(false);
          return;
        }
        const res = await stripe?.confirmSetup({
          elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: `${window.location.href}?stripeRedirect=true`,
          },
        });

        const id = res?.setupIntent?.payment_method as string;
        setPaymentMethodId(id);

        if (!subscriptionIsInvalid) {
          await updatePaymentMethod(id);
          enqueueSnackbar(t('dialogs.purchasePlan.paymentSuccessToast'), {
            variant: 'success',
          });
        }
        setLoading(false);
      } catch (e) {
        setError(get(e, 'message', `There was an error: ${e}`));
        setLoading(false);
        return;
      }
    }
    setStep(step + 1);
  };

  const handlePaymentChange = (e: StripeHandlerEvent, stripe: Stripe, elements: StripeElements) => {
    setPayment({
      ...payment,
      stripe,
      elements,
      cardComplete: e.complete,
    });
  };

  const Details = (
    <Stack direction="row" spacing={4}>
      <Box sx={{ flex: 1 }}>
        <Typography gutterBottom variant="h6">{t('dialogs.purchasePlan.details')}</Typography>
        {defaultPaymentMethod && defaultPaymentMethod?.card && (
          <Box sx={{ mb: 2 }}>
            <CardPreview card={defaultPaymentMethod.card} onEdit={() => { setEdit(!edit); }} />
          </Box>
        )}
        <Collapse in={edit || !defaultPaymentMethod} unmountOnExit onExited={() => setPayment({ ...payment, elements: undefined })}>
          <Typography>{t('dialogs.purchasePlan.updateDetails')}</Typography>
          <Box sx={{ mt: 2 }}>
            <SetupPayment onChange={handlePaymentChange} />
          </Box>
        </Collapse>
      </Box>
    </Stack>
  );

  const Confirm = (
    <Box sx={{ flex: 1, mb: 3 }}>
      <Typography sx={{ mb: 3 }} variant="h6">{t('dialogs.purchasePlan.summary')}</Typography>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography>{t('common.contactInformation')}</Typography>
        <Typography>{user.email}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography>{t('common.paymentDetails')}</Typography>
        <CardPreview card={defaultPaymentMethod?.card} />
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography>{subscriptionLevel?.name}</Typography>
          <Typography color="textSecondary">{t('common.monthlySubscription')}</Typography>
        </Box>
        <Typography>{subscriptionLevel?.recurringPriceSummary?.amount}</Typography>
      </Stack>
    </Box>
  );

  const steps = [{
    label: 'Payment details',
    component: Details,
    next: handleOnNext,
    back: onClose,
  }, {
    label: 'Confirm',
    component: Confirm,
    next: handleOnConfirm,
    back: () => setStep(step - 1),
  }];

  return (
    <Dialog open={open} onClose={() => onClose()} aria-labelledby="purchase-plan" fullWidth maxWidth="sm">
      <DialogContent>
        <Stepper
          activeStep={step}
          sx={{ px: 6, py: 2, mb: 3 }}
        >
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {steps[step].component}
        {!!error && (
          <Alert
            severity="error"
            variant="outlined"
            sx={{
              width: '100%',
              mt: 2,
            }}
          >
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {loading
          ? <CircularProgress size={30} />
          : (
            <>
              <Button
                type="button"
                onClick={steps[step].back}
              >
                {step === 0 ? t('forms.cancel') : t('common.back')}
              </Button>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={steps[step].next}
                disabled={disabled}
              >
                {step === 0 ? t('common.next') : t('common.confirm')}
              </Button>
            </>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

export default PurchasePlan;
