type DownloadFileFunction = (
  url: string,
  filename: string,
  contentType?: string
) => void;

const useDownloadFile = (): DownloadFileFunction => {

  const downloadFile = (url: string, filename: string, contentType?: string) => {
    const req = new XMLHttpRequest();

    req.open('GET', url, true);
    req.responseType = 'blob';
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: contentType || 'application/octet-stream',
      });

      const windowDocument = window.document;

      const windowUrl = window.URL || window.webkitURL;
      const href = windowUrl.createObjectURL(blob);
      const a = windowDocument.createElement('a');
      a.setAttribute('download', filename);
      a.setAttribute('href', href);
      windowDocument.body.appendChild(a);
      a.click();
      windowDocument.body.removeChild(a);
    };
    req.send();
  };

  return downloadFile;
};

export default useDownloadFile;
