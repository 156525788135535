import React from 'react';
import { useKoverse } from '@koverse/react';
import { useTranslation } from 'react-i18next';
import { RadialBar, RadialBarCustomLayerProps } from '@nivo/radial-bar';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useUpcomingInvoice from '../../hooks/useUpcomingInvoice';

const UsageLayer = (props: RadialBarCustomLayerProps) => {
  const { center, bars } = props;
  const { theme } = useKoverse();
  const { t } = useTranslation();

  return (
    <g
      transform={`translate(${center[0]}, ${center[1]})`}
    >
      <text
        fill={theme.palette.text.primary}
        style={{
          fontSize: '24px',
          fontWeight: 7000,
        }}
        textAnchor="middle"
      >
        {`${bars[0].value}%`}
      </text>
      <text
        fill={theme.palette.text.primary}
        style={{
          fontSize: '12px',
          transform: 'translate(0, 20px)',
        }}
        textAnchor="middle"
      >
        {t('common.used')}
      </text>
    </g>
  );
};

const CpuUsage = (): React.ReactElement | null => {
  const { theme } = useKoverse();
  const { currentUsage } = useUpcomingInvoice();
  const { t } = useTranslation();
  const cpuPercentage = React.useMemo(() => {
    if (currentUsage?.compute) {
      const { total, freeQuantity } = currentUsage.compute;
      return Math.round((total / freeQuantity) * 100);
    }
  }, [currentUsage?.compute]);

  const data = [
    {
      'id': 'CPU',
      'data': [
        {
          'x': 'Used',
          'y': cpuPercentage as number,
        },
      ],
    },
  ];

  return !!currentUsage?.compute ? (
    <Box
      sx={{
        mt: 2,
      }}
      aria-label="cpu-usage-chart"
    >
      <RadialBar
        animate={false}
        colors={() => '#78909C'}
        cornerRadius={10}
        data={data}
        enableLabels={false}
        enableCircularGrid={false}
        enableRadialGrid={false}
        endAngle={360}
        height={175}
        innerRadius={0.7}
        isInteractive={false}
        layers={['tracks', 'bars', UsageLayer]}
        maxValue={100}
        padAngle={0}
        startAngle={-360}
        tracksColor={theme.palette.action.disabledBackground}
        width={175}
      />
      {currentUsage.compute?.hasOverages && (
        <Box sx={{ my: 2 }}>
          <Stack direction="row">
            <ErrorIcon color="error" sx={{ mr: 1 }} />
            <Stack direction="column">
              <Typography variant="caption">{
                t('pages.billing.subscription.overage', {
                  freeQuantity: currentUsage.compute?.freeQuantity,
                  unit: 'CPU/hr',
                })}
              </Typography>
              <Typography variant="caption">{t('pages.billing.subscription.applyOverages')}</Typography>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  ) : null;
};

export default CpuUsage;
