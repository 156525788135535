import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { useKoverse } from '@koverse/react';
import { useForm, FieldError } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CreatePasswordField from '../../CreatePasswordField';

type Inputs = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const EditPassword = ({
  open,
  onClose,
}: Props): React.ReactElement => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState<boolean>(false);
  const {
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    watch,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  const { isDirty, isValid, isSubmitting } = formState;

  const handleOnClose = () => {
    setSubmissionError(null);
    onClose();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = async (data: Inputs) => {
    try {
      await client.service('auth-management').create({
        action: 'changePassword',
        oldPassword: data.oldPassword,
        password: data.password,
        confirmPassword: data.confirmPassword,
      });
      reset();
      enqueueSnackbar(t('pages.account.changePassword.successToast'), {
        variant: 'success',
      });
      handleOnClose();
    } catch (e) {
      if (isArray(get(e, 'errors'))) {
        const errorList = get(e, 'errors') || [];
        errorList.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          setError(path, {
            type: 'manual',
            message: error.message,
          });
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">{t('pages.account.changePassword.title')}</DialogTitle>
        <DialogContent>
          <TextField
            InputLabelProps={{
              htmlFor: 'oldPassword',
            }}
            id="oldPassword"
            margin='normal'
            label={t('forms.labels.password')}
            name="oldPassword"
            variant="outlined"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            error={!!errors.oldPassword}
            helperText={(errors.oldPassword && errors.oldPassword.message) || ''}
            disabled={isSubmitting}
            inputRef={register({ required: t('forms.validation.required') as string })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CreatePasswordField
            errors={errors}
            register={register}
            watch={watch}
            setError={setError}
            TextFieldProps={{
              label: t('forms.labels.newPassword'),
            }}
          />
          <TextField
            InputLabelProps={{
              htmlFor: 'confirmPassword',
            }}
            id="confirmPassword"
            margin='normal'
            label={t('forms.labels.confirmPassword')}
            name="confirmPassword"
            variant="outlined"
            fullWidth
            type={showConfirmPassword ? 'text' : 'password'}
            error={!!errors.confirmPassword}
            helperText={(errors.confirmPassword && errors.confirmPassword.message) || ''}
            disabled={isSubmitting}
            inputRef={register({
              required: t('forms.validation.required') as string,
              validate: {
                value: (value: string) => value === watch('password') || t('forms.validation.confirmPassword') as string,
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                width: '100%',
                mt: 2,
              }}
            >
              {submissionError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <Box>
              <Button
                sx={{ mr: 2 }}
                color="primary"
                onClick={onClose}
              >
                {t('forms.cancel')}
              </Button>
              <Button
                disabled={!isDirty || !isValid}
                type="submit"
                color="primary"
                variant="contained"
              >
                {t('forms.saveChanges')}
              </Button>
            </Box>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditPassword;
