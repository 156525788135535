import { useKoverse, usePaginatedService } from '@koverse/react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import React from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { Dataset, Job } from '../../../declarations';
import config from '../../../config';
import axios, { AxiosError } from 'axios';

type Props = {
  open: boolean;
  onClose: () => void;
  dataset: Dataset;
};

type Inputs = {
  datasetName: string;
};

const ClearDataset = ({
  open,
  onClose,
  dataset,
}: Props): React.ReactElement | null => {
  const { client } = useKoverse();
  const {
    errors,
    formState,
    handleSubmit,
    register,
    setError,
  } = useForm<Inputs>({
    mode: 'onChange',
  });
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const { isSubmitting, isValid, touched } = formState;

  const {
    items,
  } = usePaginatedService('jobs', {
    datasetId: dataset.id,
    status: 'failed',
    dismissed: false,
  });

  const failedJob: Job | null = React.useMemo(() => {
    return !!items.length ? items[0] : null;
  }, [items]);

  const handleOnClose = () => {
    setSubmissionError(null);
    onClose();
  };

  const onSubmit = async () => {
    const url = `//${config.api}/storage/datasets/${dataset.id}/clear`;
    const jwt = await client.authentication.getAccessToken();

    try {
      await axios.post(url, null, {
        headers: {
          'Authorization': `Bearer ${jwt}`,
        },
      });
      if (!!failedJob) {
        await client.service('jobs').patch(failedJob.id, { dismissed: true });
      }
      onClose();
    } catch (e) {
      const errors = e as AxiosError;
      if (isArray(get(errors, 'errors'))) {
        const errorList = get(errors, 'errors') || [];
        errorList.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          setError(path, {
            type: 'manual',
            message: error.message,
          });
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  if (!dataset) {
    return null;
  }

  return (
    <Dialog open={open} onClose={() => handleOnClose()} aria-labelledby="form-dialog-title" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Clear dataset</DialogTitle>
        <DialogContent>
          <Alert
            sx={{ mb: 2 }}
            severity="error"
            variant="outlined"
          >
            This action cannot be undone!
          </Alert>
          <Typography sx={{ mb: 3 }}>
            You are about to remove all of the data in this dataset. This will also apply to the data other users have uploaded.
          </Typography>
          <Typography sx={{ wordWrap: 'break-word' }}>
            Type &apos;<strong>{dataset.name}</strong>&apos; below to confirm.
          </Typography>
          <TextField
            id="datasetName"
            name="datasetName"
            placeholder={dataset.name}
            margin='dense'
            variant="outlined"
            fullWidth
            error={!!errors.datasetName && touched.datasetName}
            helperText={(touched.datasetName && errors.datasetName && errors.datasetName.message) || ''}
            disabled={isSubmitting}
            inputRef={register({
              required: 'Required',
              validate: {
                value: (value: string) => value === dataset.name || 'Dataset name does not match.',
              },
            })}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                mt: 2,
              }}
            >
              {submissionError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <Button
                type="button"
                color="primary"
                onClick={() => handleOnClose()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid}
              >
                Clear
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ClearDataset;
