import React from 'react';
import { useServiceItem, useKoverse } from '@koverse/react';
import useTheme from '@mui/styles/useTheme';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ThirdPartyApplication } from '../../declarations';
import ApplicationForm from '../ApplicationForm';
import ApplicationSecrets from '../ApplicationSecrets';
import BreadCrumbs from '../BreadCrumbs';
import OptionsMenu from './OptionsMenu';

interface ApplicationDetailsProp {
  applicationId: string;
}

const ApplicationDetails = ({
  applicationId,
}: ApplicationDetailsProp): React.ReactElement | null => {
  const theme = useTheme();
  const { user, workspace } = useKoverse();
  const isAdmin = user.role === 'admin';

  const {
    item,
    loading,
  } = useServiceItem('applications', applicationId);

  const application = item as ThirdPartyApplication;
  const {
    item: owner,
  } = useServiceItem('users', application?.userId);
  if (!applicationId) {
    return null;
  }
  return isAdmin ? (
    <Box>
      {!loading && application && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <BreadCrumbs
              breadcrumbMap={{
                [`/`]: workspace.name,
                [`${applicationId}`]: application.name,
              }}
            />
            <Typography
              variant="h6"
              sx={{ mt: 2 }}
            >
              {application.name}
            </Typography>
            {!!application.description && (
              <Typography
                color="text.secondary"
                sx={{ my: 0.5 }}
              >
                {application.description}
              </Typography>
            )}
            {!!owner && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 4 }}
              >
                Owner: {owner.displayName}
              </Typography>
            )}
          </Box>
          {!!application && (
            <OptionsMenu application={application} />
          )}
        </Box>
      )}
      <Card>
        {loading && (
          <LinearProgress
            sx={{
              margin: theme.spacing(3),
            }}
          />
        )}
        {!loading && !application && (
          <CardHeader
            title="No Record Available"
            titleTypographyProps={{ variant: 'body2' }}
          />
        )}
        {!loading && application && (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{ mb: 2 }}
                >
                Application details
                </Typography>
                <ApplicationForm application={application} />
              </CardContent>
            </Grid>
          </Grid>
        )}
      </Card>
      {application?.clientId && application?.clientSecret && (
        <Card sx={{ mt: 3 }}>
          <CardHeader
            title="Application Secrets"
            titleTypographyProps={{ variant: 'h6' }}
            sx={{
              paddingBottom: theme.spacing(0),
            }}
          />
          <CardContent>
            <ApplicationSecrets application={application} hidden allowReset />
          </CardContent>
        </Card>
      )}
    </Box>
  ) : null;
};

export default ApplicationDetails;
