import { useKoverse, usePaginatedService } from '@koverse/react';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import times from 'lodash/times';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group } from '../../declarations';
import DeleteGroup from '../Dialogs/ManageGroup/DeleteGroup';
import EditGroupName from '../Dialogs/ManageGroup/EditGroupName';
import EditGroupMembers from '../Dialogs/ManageGroup/EditGroupMembers';
import ViewMembers from '../Dialogs/ManageGroup/ViewMembers';
import GroupsTableRow from './GroupsTableRow';

interface FieldCell {
  colSpan: number;
  enableSorting: boolean;
  field: string;
  label: string;
  width: number;
}

const GroupsTable = (): React.ReactElement => {
  const { client, workspace } = useKoverse();
  const { t } = useTranslation();
  const [groupToDelete, setGroupToDelete] = React.useState<Group | null>(null);
  const [groupToEdit, setGroupToEdit] = React.useState<Group | null>(null);
  const [groupToManage, setGroupToManage] = React.useState<Group | null>(null);
  const [groupToView, setGroupToView] = React.useState<Group | null>(null);
  const [sortField, setSortField] = useState('name');
  const [sortDir, setSortDir] = useState(1);
  const [query, setQuery] = useState('');

  const cells: FieldCell[] = [{
    label: 'Group Name',
    field: 'name',
    enableSorting: true,
    colSpan: 1,
    width: 300,
  }, {
    label: 'Group Creator',
    field: 'creator',
    enableSorting: false,
    colSpan: 1,
    width: 200,
  }, {
    label: 'Users',
    field: 'users',
    enableSorting: false,
    colSpan: 2,
    width: 200,
  }];

  const $or = React.useMemo(() => {
    return query ? {
      $or: ['name'].map((field: string) => ({
        [field]: { $iLike: `%${query}%` },
      })),
    } : undefined;
  }, [query]);

  const {
    items: groups,
    loading,
    currentPage,
    total,
    limit,
    setLimit,
    setCurrentPage,
    fetchList,
  } = usePaginatedService('groups', {
    $sort: { [sortField]: sortDir },
    $limit: 5,
    $or,
    workspaceId: workspace.id,
    groupMemberships: true,
    owner: true,
  });

  const handleRequestSort = (field: string) => () => {
    const isAsc = sortField === field && sortDir === 1;
    setSortDir(isAsc ? -1 : 1);
    setSortField(field);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setLimit(+event.target.value);
  };

  React.useEffect(() => {
    client.service('groups').on('created', fetchList);
    client.service('groups').on('removed', fetchList);
    client.service('groups').on('patched', fetchList);
    client.service('groups').on('updated', fetchList);

    return function cleanUp() {
      client.service('groups').off('created', fetchList);
      client.service('groups').off('removed', fetchList);
      client.service('groups').off('patched', fetchList);
      client.service('groups').off('updated', fetchList);
    };
  }, [client, fetchList]);

  return (
    <>
      <Paper>
        <Box sx={{ ml: 2, mt: 4, mb: 1 }}>
          <TextField
            hiddenLabel
            id="search-groups"
            variant="filled"
            size="small"
            margin="none"
            sx={{
              width: 400,
              '& .MuiFilledInput-root.MuiFilledInput-underline': {
                borderRadius: '4px',
              },
              [`& .MuiFilledInput-root.MuiFilledInput-underline:before,
              .MuiFilledInput-root.MuiFilledInput-underline:hover:before,
              .MuiFilledInput-root.MuiFilledInput-underline:after`]: {
                border: 'none',
              },
            }}
            placeholder={t('forms.placeholders.search')}
            value={query}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider />
        <TableContainer>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                {cells.map((cell) => (
                  <TableCell
                    key={cell.field}
                    variant="head"
                    align="left"
                    colSpan={cell.colSpan}
                    width={cell.width}
                  >
                    {cell.enableSorting ? (
                      <TableSortLabel
                        active={sortField === cell.field}
                        direction={sortDir === 1 ? 'asc' : 'desc'}
                        onClick={handleRequestSort(cell.field)}
                        aria-label={cell.label}
                      >
                        {cell.label}
                        {sortField === cell.field ? (
                          <span
                            style={{
                              border: 0,
                              clip: 'rect(0 0 0 0)',
                              height: 1,
                              margin: -1,
                              overflow: 'hidden',
                              padding: 0,
                              position: 'absolute',
                              top: 20,
                              width: 1,
                            }}
                          >
                            {sortDir === 1 ? 'sorted ascending' : 'sorted descending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    ) : cell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                {times(limit, (index) => (
                  <TableRow
                    key={index}
                    aria-label="placeholder-while-loading-row"
                    sx={{ height: 77 }}
                  >
                    <TableCell><Skeleton height={24} width={200} /></TableCell>
                    <TableCell><Skeleton height={24} width={75} /></TableCell>
                    <TableCell>
                      <Skeleton variant="circular"><Avatar /></Skeleton>
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton
                        height={24}
                        width={24}
                        variant="circular"
                        sx={{ display: 'inline-table', mr: 1 }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {groups.map((group) => (
                  <GroupsTableRow
                    key={group.id}
                    group={group}
                    handleDelete={setGroupToDelete}
                    handleEdit={setGroupToEdit}
                    handleManageGroup={setGroupToManage}
                    handleView={setGroupToView}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            p: 1,
          }}
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={(currentPage - 1)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {!!groupToDelete && (
        <DeleteGroup
          open={!!groupToDelete}
          group={groupToDelete}
          onClose={() => setGroupToDelete(null)}
        />
      )}
      {!!groupToEdit && (
        <EditGroupName
          open={!!groupToEdit}
          group={groupToEdit}
          onClose={() => setGroupToEdit(null)}
        />
      )}
      {!!groupToManage && (
        <EditGroupMembers
          open={!!groupToManage}
          group={groupToManage}
          onClose={() => setGroupToManage(null)}
        />
      )}
      {!!groupToView && (
        <ViewMembers
          open={!!groupToView}
          group={groupToView}
          onClose={() => setGroupToView(null)}
        />
      )}
    </>
  );
};

export default GroupsTable;
