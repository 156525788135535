import { useKoverse } from '@koverse/react';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MUILink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UpdatePayment from '../UpdatePayment';

interface Props {
  canceled: boolean;
  billingProblem: boolean;
}

const AccountHold = ({
  canceled,
  billingProblem,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { user, workspace } = useKoverse();
  const [open, setOpen] = useState<boolean>(false);
  const isOwner = workspace.userId === user.id;

  const messageKey = useMemo(() => {
    if (isOwner && billingProblem) {
      return 'dialogs.accountHold.adminBillingMessage';
    } else if (isOwner && canceled) {
      return 'dialogs.accountHold.adminCanceledMessage';
    } else {
      return 'dialogs.accountHold.memberMessage';
    }
  }, [isOwner, billingProblem, canceled]);

  return (
    <>
      <Container
        fixed
        maxWidth="sm"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Paper sx={{ width: '100%', p: 4 }}>
          <Stack spacing={2} alignItems="center">
            <ErrorOutline fontSize="large" />
            <Typography variant="h6">{t('dialogs.accountHold.title')}</Typography>
            <Typography align="center">
              <Trans
                i18nKey={messageKey}
                components={{
                  mailto: (
                    <MUILink
                      component={Link}
                      underline="none"
                      to="/account-hold/change-plan"
                    />
                  ),
                }}
              />
            </Typography>
            {isOwner && billingProblem && (
              <Box>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={() => setOpen(true)}
                >
                  {t('dialogs.accountHold.updateBilling')}
                </Button>
              </Box>
            )}
          </Stack>
        </Paper>
      </Container>
      {isOwner && billingProblem && (
        <UpdatePayment open={open} onClose={() => setOpen(false)} />
      )}
    </>
  );
};

export default AccountHold;
