import React from 'react';
import _orderBy from 'lodash/orderBy';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ListIcon from '@mui/icons-material/List';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IndexManagementTableHead from './IndexManagementTableHead';
import IndexManagementTableToolbar from './IndexManagementTableToolbar';
import { Dataset, Index } from '../../../declarations';

interface RowData extends Omit<Index, 'fields' | 'datasetId'> {
  name: string;
}

type Order = 'asc' | 'desc';

interface IndexManagementTableProps {
  dataset: Dataset;
  disabled: boolean;
  indexes: Index[];
  selected: readonly Index[];
  setIndexes: React.Dispatch<React.SetStateAction<Index[]>>;
  setSelected: React.Dispatch<React.SetStateAction<readonly Index[]>>
}

const IndexManagementTable = ({
  dataset,
  disabled,
  indexes,
  selected,
  setIndexes,
  setSelected,
}: IndexManagementTableProps): React.ReactElement => {
  const [rows, setRows] = React.useState<RowData[]>([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy] = React.useState<keyof RowData>('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    setRows(indexes as RowData[]);
  }, [indexes]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RowData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n);
      setSelected(newSelecteds as Index[]);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: Index) => {
    if (disabled) {
      event.stopPropagation();
      return;
    }
    const selectedIndex = selected.indexOf(row);
    let newSelected: readonly Index[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (row: RowData) => selected.indexOf(row as Index) !== -1;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <IndexManagementTableToolbar
        dataset={dataset}
        disabled={disabled}
        indexes={indexes}
        numSelected={selected.length}
        selected={selected}
        setIndexes={setIndexes}
        setSelected={setSelected}
      />
      {!!rows.length ? (
        <>
          <TableContainer>
            <Table
              size="medium"
              aria-labelledby="indexManagementTable"
            >
              <IndexManagementTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                disabled={disabled}
              />
              <TableBody>
                {_orderBy(rows, [orderBy], [order])
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `indexed-fields-table-checkbox-${index}`;

                    return (
                      <TableRow
                        key={index}
                        onClick={(event) => handleClick(event, row as Index)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            disabled={disabled}
                            color="primary"
                            checked={isItemSelected}
                            value={row.name}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{
                            '&.MuiTableCell-root': {
                              color: theme => disabled ? theme.palette.text.disabled : 'inherit',
                            },
                          }}
                        >
                          {row.name}
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              '& .MuiToolbar-root': {
                color: theme => disabled ? theme.palette.text.disabled : 'inherit',
                '& .MuiTablePagination-selectIcon': {
                  color: 'inherit',
                },
              },
            }}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Box sx={{ pt: 3, pb: 10 }}>
          <Stack
            flexDirection="column"
            alignItems="center"
          >
            <ListIcon
              color={disabled ? 'disabled' : 'inherit'}
              sx={{ fontSize: 60, mb: 1 }}
            />
            <Typography
              variant="h6"
              sx={{
                color: theme => disabled
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary,
              }}
            >
              Your index is empty
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default IndexManagementTable;
