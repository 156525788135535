import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import get from 'lodash/get';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { validateExpressionGroups, validateExpressionOperators } from '../../validators';
import AbacExpressionLink from './AbacExpressionLink';

const SecurityLabels = (): React.ReactElement => {
  const {
    control,
    errors,
    formState,
  } = useFormContext();
  const { isSubmitting, touched } = formState;

  const hasError = !!get(touched, 'accessControlLabel.securityLabelInfo.label')
    && !!get(errors, 'accessControlLabel.securityLabelInfo.label');

  return (
    <Grid item>
      <FormControl
        fullWidth
        sx={{ maxWidth: 620 }}
      >
        <Controller
          as={TextField}
          name="accessControlLabel.securityLabelInfo.label"
          id="parser-custom-label"
          control={control}
          variant="outlined"
          label="Enter your custom label"
          rules={{
            required: 'Required',
            validate: {
              grouping: (value: string) => validateExpressionGroups(value),
              operator: (value: string) => validateExpressionOperators(value),
            },
          }}
          error={hasError}
          helperText={
            (hasError && get(errors, 'accessControlLabel.securityLabelInfo.label.message'))
            || 'This will apply to all records in this upload. Custom labels can be attribute expressions.'
          }
          defaultValue=""
          disabled={isSubmitting}
        />
        <AbacExpressionLink />
      </FormControl>
    </Grid>
  );
};

export default SecurityLabels;
