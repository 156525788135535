import { useKoverse, usePaginatedService } from '@koverse/react';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import times from 'lodash/times';
import React from 'react';
import { User } from '../../declarations';
import CreateGroup from '../Dialogs/ManageGroup/CreateGroup';
import EditUser from '../Dialogs/EditUser';
import RemoveUser from '../Dialogs/RemoveUserFromWorkspace';
import UsersTableHead from './UsersTableHead';
import UsersTableRow from './UsersTableRow';
import UsersTableToolbar from './UsersTableToolbar';

const UsersTable = (): React.ReactElement => {
  const { client, workspace } = useKoverse();
  const [createGroup, setCreateGroup] = React.useState<boolean>(false);
  const [userToDelete, setUserToDelete] = React.useState<null | User>(null);
  const [userToEdit, setUserToEdit] = React.useState<null | User>(null);
  const [sortField, setSortField] = React.useState<string>('firstName');
  const [sortDir, setSortDir] = React.useState<number>(1);
  const [query, setQuery] = React.useState<string>('');
  const [selected, setSelected] = React.useState<User[]>([]);
  const numSelected = React.useMemo(() => selected.length, [selected]);

  const $or = React.useMemo(() => {
    return query
      ? {
        $or: ['firstName', 'lastName', 'email', 'role'].map((field: string) => ({
          [field]: { $iLike: `%${query}%` },
        })),
      }
      : undefined;
  }, [query]);

  const {
    items: users,
    loading,
    currentPage,
    total,
    limit,
    setLimit,
    setCurrentPage,
    fetchList,
  } = usePaginatedService('users', {
    $sort: { [sortField]: sortDir, createdAt: 1 },
    $limit: 5,
    $or,
    workspaceId: workspace.id,
  });

  const handleChangePage = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setLimit(+event.target.value);
  };

  const handleOnSelect = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, user: User) => {
    const selectedIndex = selected.findIndex(d => d.id === user.id);
    let newSelected: User[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }, [selected]);

  const isSelected = React.useCallback((user: User) => {
    return selected.findIndex(d => d.id === user.id) !== -1;
  }, [selected]);

  React.useEffect(() => {
    client.service('users').on('created', fetchList);
    client.service('users').on('removed', fetchList);
    client.service('users').on('patched', fetchList);
    client.service('users').on('updated', fetchList);

    return function cleanUp() {
      client.service('users').off('created', fetchList);
      client.service('users').off('removed', fetchList);
      client.service('users').off('patched', fetchList);
      client.service('users').off('updated', fetchList);
    };
  }, [client, fetchList]);

  return (
    <Paper>
      <UsersTableToolbar
        numSelected={numSelected}
        openCreateGroup={setCreateGroup}
        query={query}
        setQuery={setQuery}
      />
      <Divider />
      <TableContainer>
        <Table sx={{ minWidth: 700 }}>
          <UsersTableHead
            {...{
              numSelected,
              total,
              setSelected,
              setSortDir,
              setSortField,
              sortField,
              sortDir,
            }}
          />
          {loading ? (
            <TableBody>
              {times(limit, (index) => (
                <TableRow
                  key={index}
                  aria-label="placeholder-while-loading-row"
                  sx={{ height: 73 }}
                >
                  {total > 1 && <TableCell padding="checkbox" />}
                  <TableCell>
                    <Skeleton variant="circular"><Avatar /></Skeleton>
                  </TableCell>
                  <TableCell><Skeleton height={24} width={100} /></TableCell>
                  <TableCell><Skeleton height={24} width={100} /></TableCell>
                  <TableCell><Skeleton height={24} width={200} /></TableCell>
                  <TableCell><Skeleton height={24} width={75} /></TableCell>
                  <TableCell align="right">
                    <Skeleton
                      height={24}
                      width={24}
                      variant="circular"
                      sx={{ display: 'inline-table', mr: 1 }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {users.map((user) => (
                <UsersTableRow
                  key={user.id}
                  isSelected={isSelected(user)}
                  user={user}
                  selectRow={handleOnSelect}
                  setUserToDelete={setUserToDelete}
                  setUserToEdit={setUserToEdit}
                  total={total}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ p: 1 }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={limit}
        page={(currentPage - 1)}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {!!userToEdit && (
        <EditUser
          open={!!userToEdit}
          onClose={() => setUserToEdit(null)}
          user={userToEdit}
        />
      )}
      {!!userToDelete && (
        <RemoveUser
          open={!!userToDelete}
          onClose={() => setUserToDelete(null)}
          user={userToDelete}
        />
      )}
      {createGroup && (
        <CreateGroup
          open={createGroup}
          onClose={(saved?: boolean) => {
            setCreateGroup(false);
            if (saved) {
              setSelected([]);
            }
          }}
          users={selected}
        />
      )}
    </Paper>
  );
};

export default UsersTable;
