import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const SecurityLabeledInput = (): React.ReactElement => {
  const {
    control,
    formState,
  } = useFormContext();

  const { isSubmitting } = formState;

  const enabled = useWatch({
    control,
    name: 'accessControlLabel.securityLabeled',
    defaultValue: null,
  });

  return (
    <FormControl disabled={isSubmitting}>
      <Controller
        render={({ onChange, ...props }) => (
          <RadioGroup
            row
            aria-label="enable-access-control"
            {...props}
            onChange={(e) => {
              onChange(e.target.value === 'yes');
            }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" checked={!!enabled} />}
              label="Yes"
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" checked={enabled === false} />}
              label="No"
            />
          </RadioGroup>
        )}
        name="accessControlLabel.securityLabeled"
        control={control}
        defaultValue={null}
      />
    </FormControl>
  );
};

export default SecurityLabeledInput;
