import React from 'react';
import { useKoverse } from '@koverse/react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import { get } from 'lodash';
import { CircularProgress } from '@mui/material';

type Props = {
  verifying: boolean;
}

const VerifyEmail = ({ verifying }: Props): React.ReactElement => {
  const { client, user } = useKoverse();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const resend = async () => {
    try {
      await client.service('auth-management').create({
        action: 'sendVerificationEmail',
      });
      enqueueSnackbar(t('dialogs.verifyEmail.successToast', { email: user.email }), {
        variant: 'info',
      });
    } catch (error) {
      enqueueSnackbar(t('dialogs.verifyEmail.failureToast', { error: get(error, 'message', error) }), {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog
      open
      fullWidth
    >
      <DialogContent sx={{ pt: 8, pb: 8 }}>
        {verifying ? (
          <Stack sx={{ alignItems: 'center' }} spacing={2}>
            <CircularProgress />
            <Typography sx={{ mb: 1, mt: 3 }}>
              {t('dialogs.verifyEmail.verifying')}
            </Typography>
          </Stack>
        ) : (
          <Stack sx={{ alignItems: 'center' }} spacing={2}>
            <MailIcon fontSize="large" />
            <Typography variant="h6">{t('dialogs.verifyEmail.title')}</Typography>
            <Typography>{t('dialogs.verifyEmail.checkInbox')}</Typography>
            <Stack sx={{ alignItems: 'center' }}>
              <Typography sx={{ mb: 1, mt: 3 }} variant="caption">
                {t('dialogs.verifyEmail.didntReceive')}
              </Typography>
              <Button onClick={resend} variant="outlined">
                {t('dialogs.verifyEmail.resend')}
              </Button>
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default VerifyEmail;
