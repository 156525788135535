import { Header, useKoverse, useServiceItem } from '@koverse/react';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PaymentIcon from '@mui/icons-material/Payment';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import config from '../../config';
import AppBarOffset from '../AppBarOffset';
import NavigationDrawer, { useNavigationDrawerTransition } from '../NavigationDrawer/index';
import { useAppState } from '../AppStateProvider';
import WorkspaceSettingsMenu from '../Menus/WorkspaceSettingsMenu';

type PageProps = {
  children?: React.ReactNode,
};

function Page({ children }: PageProps): React.ReactElement {
  const { t } = useTranslation();
  const { user, workspace } = useKoverse();
  const { mode, setMode, navDrawerOpen, setNavDrawerOpen } = useAppState();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const {
    item: owner,
  } = useServiceItem('users', get(workspace, 'userId'));

  const handleToggle = () => {
    setNavDrawerOpen(!navDrawerOpen);
  };
  const navigationDrawerTransition = useNavigationDrawerTransition();

  const isAdmin = user.role === 'admin';
  const isOwner = get(owner, 'id') === user.id;
  const trialDaysRemaining = useMemo(() => {
    if (workspace?.subscription?.status !== 'trialing' || !workspace?.subscription?.trialEnd) {
      return 0;
    }
    const now = DateTime.now();
    const end = DateTime.fromSeconds(workspace.subscription.trialEnd);
    return Math.round(end.diff(now).as('days'));
  }, [workspace]);

  const handleThemeChange = () => {
    mode === 'dark' ? setMode('light') : setMode('dark');
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    <MenuItem key="theme" onClick={handleThemeChange}>
      <ListItemIcon>
        {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
      </ListItemIcon>
      <ListItemText primary={mode === 'dark' ? 'Light Mode' : 'Dark Mode'} />
    </MenuItem>,
  ];
  menuItems.push(
    <div>
      <MenuItem onClick={handleOpen} sx={{ minWidth: 268 }}>
        <ListItemIcon>
          <SettingsOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Workspace Settings" />
        <ListItemSecondaryAction>
          <IconButton disableRipple edge="end">
            <KeyboardArrowRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </MenuItem>
      <Menu
        id="workspace-settings-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { minWidth: 200 } }}
      >
        <WorkspaceSettingsMenu handleClose={handleClose} isAdmin={isAdmin} />
      </Menu>
      <Divider />
    </div>,
  );
  if (isOwner && !config.selfHosted) {
    menuItems.unshift(
      <MenuItem
        key="billing"
        component={Link}
        to="/billing"
      >
        <ListItemIcon>
          <PaymentIcon
            sx={{
              fontSize: 25,
            }}
          />
        </ListItemIcon>
        <ListItemText primary={t('glossary.billing')} />
      </MenuItem>,
    );
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Header
        toggleDrawer={handleToggle}
        myAccountUrl='/account'
        menuItems={menuItems}
        toolbarChildren={workspace?.subscription?.status === 'trialing' ? (
          <Button
            disabled
            variant="contained"
            sx={{
              mr: 4,
              '&.Mui-disabled': {
                color: t => t.palette.mode === 'dark' ? t.palette.text.primary : t.palette.common.white,
              },
            }}
          >
            Free Trial: {trialDaysRemaining} {pluralize('day', trialDaysRemaining)}
          </Button>
        ) : undefined}
      />
      <NavigationDrawer />
      <Box
        component="main"
        sx={theme => ({
          padding: theme.spacing(3, 3, 9, 3),
          ...navigationDrawerTransition,
        })}
      >
        <AppBarOffset />
        {children}
      </Box>
    </Box>
  );
}

export default Page;
