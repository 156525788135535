import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { useHistory } from 'react-router-dom';
import { useKoverse } from '@koverse/react';
import { useTranslation, Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useForm, FieldError } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Dataset } from '../../../declarations';

type Props = {
  open: boolean;
  onClose: () => void;
  dataset: Dataset;
};

type Inputs = {
  datasetName: string;
};

const DeleteDataset = ({
  open,
  onClose,
  dataset,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation();
  const { client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const {
    errors,
    formState,
    handleSubmit,
    register,
    setError,
  } = useForm<Inputs>({
    mode: 'onChange',
  });
  const history = useHistory();
  const datasets = client.service('datasets');
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const { isSubmitting, isValid, touched } = formState;

  const handleOnClose = () => {
    setSubmissionError(null);
    onClose();
  };

  const fetchDatasets = async () => {
    const results = await datasets.find({
      query: {
        workspaceId: workspace.id,
        $limit: -1,
      },
    });
    return results.data;
  };

  const onSubmit = async () => {
    try {
      await datasets.remove(dataset.id);
      const currentDatasets = await fetchDatasets();
      !!currentDatasets.length
        ? history.replace(`/datasets/${currentDatasets[0].id}`)
        : history.replace(``);
      enqueueSnackbar(t('dialogs.deleteDataset.deleteSuccess'), {
        variant: 'success',
      });
    } catch (e) {
      if (isArray(get(e, 'errors'))) {
        const errors = get(e, 'errors') || [];
        errors.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          setError(path, {
            type: 'manual',
            message: error.message,
          });
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  return dataset ? (
    <Dialog open={open} onClose={() => handleOnClose()} aria-labelledby="form-dialog-title" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t('dialogs.deleteDataset.title')}</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 3, wordWrap: 'break-word' }}>
            <Trans
              i18nKey='dialogs.deleteDataset.subheader'
              values={{ datasetName: dataset.name }}
              components={{ bold: <strong /> }}
            >
              {t('dialogs.deleteDataset.subheader')}
            </Trans>
          </Typography>
          <Typography sx={{ wordWrap: 'break-word' }}>
            <Trans
              i18nKey='dialogs.deleteDataset.formLabel'
              values={{ datasetName: dataset.name }}
              components={{ bold: <strong /> }}
            >
              {t('dialogs.deleteDataset.formLabel')}
            </Trans>
          </Typography>
          <TextField
            id="datasetName"
            name="datasetName"
            placeholder={dataset.name}
            margin='dense'
            variant="outlined"
            fullWidth
            error={!!errors.datasetName && touched.datasetName}
            helperText={(touched.datasetName && errors.datasetName && errors.datasetName.message) || ''}
            disabled={isSubmitting}
            inputRef={register({
              required: t('forms.validation.required') as string,
              validate: {
                value: (value: string) => value === dataset.name || t('forms.validation.confirmDatasetName') as string,
              },
            })}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                mt: 2,
              }}
            >
              {submissionError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <Button
                type="button"
                color="primary"
                onClick={() => handleOnClose()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid}
              >
                Delete
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  ) : null;
};

export default DeleteDataset;
