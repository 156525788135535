import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import config from '../../config';

const AccessControlHeader = (): React.ReactElement => {
  return (
    <Box sx={{ maxWidth: 700 }}>
      <Typography variant="h5" gutterBottom>Enable Attribute Based Access Control (ABAC) for these records?</Typography>
      <Typography gutterBottom>Provide granular control over who can view your data. Access is determined by the ABAC configuration of your ingested data and the attributes assigned to your users.</Typography>
      <Typography variant="body2" color="textSecondary">
        View our&nbsp;
        <Link
          href={`//${config.documentationDomain}/docs/datasets/abac-attributes/abac-attribute-fields/`}
          target="_blank"
          rel="noreferrer noopener"
          sx={{ textDecoration: 'none' }}
        >
          ABAC documentation&nbsp;
        </Link>
        to learn more about enabling ABAC for your records.
      </Typography>
      <Alert
        severity="warning"
        variant="outlined"
        sx={{
          mt: 2,
        }}
      >
        Edits to ABAC labels cannot be made after import.
      </Alert>
    </Box>
  );
};

export default AccessControlHeader;
