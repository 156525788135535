import Select from '@mui/material/Select';
import { alpha, styled } from '@mui/material/styles';

const StyledSelect = styled(Select)(({ theme }) => ({
  color: 'inherit',
  borderRadius: 0,
  backgroundColor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.15) : alpha(theme.palette.common.black, 0.05),
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? (
      alpha(theme.palette.common.white, 0.18)
    ) : alpha(theme.palette.common.black, 0.07),
  },
  '& ::placeholder': {
    color: theme.palette.text.secondary,
    opacity: 0.9,
  },
  '& fieldset': {
    border: 'none',
  },
  '&.Mui-focused fieldset': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export default StyledSelect;
