import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import JsonView from '../JsonView';
import { DateTime } from 'luxon';
import { Collapse } from '@mui/material';
import { AuditLogData } from '../../declarations';

enum AuditLogEvents {
  AttributeManagement = 'Attribute Management',
  AuditLogConfigManagement = 'Audit Log Config Management',
  BillingPlanManagement = 'Billing Plan Management',
  DataDownload = 'Data Download',
  DatasetManagement = 'Dataset Management',
  GroupManagement = 'Group Management',
  IdentityProviderManagement = 'Identity Provider Management',
  Login = 'Login/Logout',
  UserManagement = 'User Management',
  UserProfileManagement = 'User Profile Management',
  WorkspaceManagement = 'Workspace Management',
}

interface AuditLogRowProps {
  index: number;
  result: AuditLogData;
}

const AuditLogTableRow = ({ index, result }: AuditLogRowProps): React.ReactElement => {
  const [open, setOpen] = React.useState(false);
  const [prevResultId, setPrevResultId] = React.useState('');

  React.useEffect(() => {
    if (result.id !== prevResultId) {
      setOpen(false);
      setPrevResultId(result.id);
    }
  }, [prevResultId, result]);

  const auditLogDate = (timestamp: number) => {
    return DateTime.fromMillis(timestamp).setZone('local').toFormat('MMM d, yyyy');
  };

  const auditLogTime = (timestamp: number) => {
    return DateTime.fromMillis(timestamp).setZone('local').toFormat('hh:mm:ss a ZZZZ');
  };

  return (
    <>
      <TableRow key={index}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{AuditLogEvents[result.type as keyof typeof AuditLogEvents]}</TableCell>
        <TableCell>{result.actor}</TableCell>
        <TableCell>{auditLogDate(result.timestampMs)}</TableCell>
        <TableCell>{auditLogTime(result.timestampMs)}</TableCell>
        <TableCell>{result.sourceIP}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Typography sx={{ marginBottom: 2, marginTop: 2 }}>
              {result.description}
            </Typography>
            <Box sx={{ marginBottom: 2, marginTop: 2 }}>
              {result?.details?.newValue && <JsonView data={result.details.newValue} />}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AuditLogTableRow;
