import React from 'react';
import Box from '@mui/material/Box';
import BreadCrumbs from '../components/BreadCrumbs';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { usePaginatedService } from '@koverse/react';
import pluralize from 'pluralize';
import AuditLogConfiguration from '../components/AuditLogConfiguration';
import AuditLogMenu from '../components/AuditLogMenu';
import { Route, Switch } from 'react-router-dom';
import AuditLogTable from '../components/AuditLogTable';
import { AuditLogConfig, AuditLogData } from '../declarations';
import { useQueryRequest } from '../hooks';

const AuditLog = (): React.ReactElement => {
  const { items } = usePaginatedService('audit-log-configs');
  const { queryResult, fetchData } = useQueryRequest('auditLogQuery');
  const auditLogConfig: AuditLogConfig = items?.[0] || undefined;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (auditLogConfig?.datasetId) {
      fetchData({
        datasetId: auditLogConfig?.datasetId,
        expression: '',
        limit: -1,
        offset: 0,
      });
    }
  }, [auditLogConfig, fetchData]);

  const auditLogTTL = (config: AuditLogConfig) => {
    return config.keepForever ? 'never delete' : `${config.ageInDays} ${pluralize('day', config.ageInDays)}`;
  };

  const data = React.useMemo(() => {
    return queryResult?.data.sort((a: AuditLogData, b: AuditLogData) => b.timestampMs - a.timestampMs) || [];
  }, [queryResult?.data]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const auditLogAction = <AuditLogMenu handleOpen={handleOpen} anchorEl={anchorEl} open={open} onClose={handleClose}  />;

  return (
    <>
      <Card>
        <Box sx={{ ml: 2, mt: 2 }}>
          <BreadCrumbs />
        </Box>
        <Switch>
          <Route
            path={'/audit-log'}
            exact
            render={() => (
              <>
                <CardHeader
                  sx={{
                    pb: 0,
                    '& .MuiCardHeader-action': {
                      alignSelf: 'center',
                      margin: 0,
                    },
                  }}
                  title={
                    <Stack direction="row" alignItems="center">
                      Audit Log
                    </Stack>
                  }
                  titleTypographyProps={{ variant: 'h6' }}
                  subheader="Track and manage all events in the workspace"
                  action={auditLogAction}
                />
                <Box display="flex" justifyContent="space-between" padding={2}>
                  <Box display="flex">
                    {/* TODO: Add download functionality */}
                    {/* <FileDownloadOutlinedIcon color="primary" />
                    <Typography color="primary" sx={{ marginLeft: 1 }}>
                      DOWNLOAD
                    </Typography> */}
                  </Box>
                  <Typography color="text.secondary">
                    Logs set to {auditLogConfig ?
                      <Typography color="text.primary" component="span">{auditLogTTL(auditLogConfig)}</Typography> :
                      <Skeleton variant='text' width={75} sx={{ display: 'inline-block' }} />
                    }
                  </Typography>
                </Box>
                <Box>
                  <AuditLogTable data={data} />
                </Box>
              </>
            )}
          />
          {auditLogConfig && (
            <Route
              path={'/audit-log/log-configuration'}
              exact
              render={() => (
                <AuditLogConfiguration auditLogConfig={auditLogConfig} auditLogAction={auditLogAction} data={data} />
              )}
            />
          )}
        </Switch>
      </Card>
    </>
  );
};

export default AuditLog;
