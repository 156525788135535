import { useKoverse, useServiceItem } from '@koverse/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../declarations';
import UserAvatar from '../../UserAvatar';
import AttributeSearch from './AttributeSearch';
import AttributesList from './AttributesList';
import WorkspaceRole from './WorkspaceRole';

interface Props {
  onClose: () => void;
  open: boolean;
  user: User;
}

const EditUser = ({
  open,
  onClose,
  user,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { client } = useKoverse();

  const {
    item,
    fetchItem,
  } = useServiceItem('users', user.id, {
    attributeAssignments: true,
  });

  const assignments = React.useMemo(() => {
    return get(item, 'attributeAssignments');
  }, [item]);

  React.useEffect(() => {
    client.service('users').on('created', fetchItem);
    client.service('users').on('removed', fetchItem);
    client.service('users').on('patched', fetchItem);
    client.service('users').on('updated', fetchItem);

    return function cleanUp() {
      client.service('users').off('created', fetchItem);
      client.service('users').off('removed', fetchItem);
      client.service('users').off('patched', fetchItem);
      client.service('users').off('updated', fetchItem);
    };
  }, [client, fetchItem]);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
        >
          <UserAvatar user={user} />
          <Box sx={{ ml: 2, lineHeight: 0 }}>
            <Typography variant="body1">{user.displayName}</Typography>
            <Typography variant="body2">{user.email}</Typography>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <WorkspaceRole user={user} />
        <Divider sx={{ my: 2 }} />
        <AttributeSearch userId={user.id} assignments={assignments} />
        <AttributesList assignments={assignments} />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => onClose()}
        >
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUser;
