import { useServiceItem } from '@koverse/react';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { SyntheticEvent } from 'react';
import { useParams } from 'react-router-dom';
import AppBarOffset from '../AppBarOffset';
import ConnectToSource from '../ConnectToSource';
import FileUpload from '../FileUpload';
import { useNavigationDrawerTransition } from '../NavigationDrawer';

interface Props {
  open: boolean;
  onClose: (e?: SyntheticEvent) => void;
}

interface Params {
  id: string;
}

const UPLOAD_TAB = 0;
const SOURCE_TAB = 1;

const DatasetIngestDrawer = ({ open, onClose }: Props): React.ReactElement => {
  const { id } = useParams<Params>();
  const [currentTab, setCurrentTab] = React.useState<number>(UPLOAD_TAB);

  const {
    item: dataset,
  } = useServiceItem('datasets', id);

  const handleOnCloseDrawer = () => {
    onClose();
  };

  const handleChangeTab = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setCurrentTab(newValue);
  };
  const navigationDrawerTransition = useNavigationDrawerTransition();
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={handleOnCloseDrawer}
      elevation={0}
      sx={{
        ...navigationDrawerTransition,
        '& .MuiDrawer-paper': {
          height: '100%',
          ...navigationDrawerTransition,
        },
        '& .MuiBackdrop-root': {
          ...navigationDrawerTransition,
        },
      }}
    >
      <AppBarOffset />
      <Toolbar>
        <IconButton
          edge="start"
          aria-label="back"
          onClick={handleOnCloseDrawer}
        >
          <CloseIcon />
        </IconButton>
        {dataset && (
          <Typography
            variant="h6"
            sx={{
              overflowWrap: 'anywhere',
              ml: 2,
            }}
          >
            {dataset.name}
          </Typography>
        )}
      </Toolbar>

      <Stack
        direction="column"
        sx={{
          py: 4,
          px: 8,
          flex: 1,
        }}
      >
        <Container
          sx={{
            minWidth: theme => theme.breakpoints.values.md,
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Upload File" />
            <Tab label="Connect to a Source" />
          </Tabs>
          <Stack
            direction="column"
            sx={{
              pt: 4,
              pb: 8,
              flex: 1,
            }}
          >
            {currentTab === UPLOAD_TAB && (
              <FileUpload
                dataset={dataset}
                onCancel={onClose}
                afterSubmit={onClose}
              />
            )}
            {currentTab === SOURCE_TAB && (
              <ConnectToSource
                dataset={dataset}
                onCancel={onClose}
                afterSubmit={onClose}
              />
            )}
          </Stack>
        </Container>
      </Stack>
    </Drawer>
  );
};

export default DatasetIngestDrawer;
