import React, { useState } from 'react';
import get from 'lodash/get';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddPermissionDialog from '../Dialogs/AddPermission';
import PermissionsList from './PermissionsList';
import { Dataset } from '../../declarations';

interface PermissionsParams {
  dataset: Dataset;
}

const Permissions = ({
  dataset,
}: PermissionsParams): React.ReactElement | null => {
  const [open, setOpen] = useState(false);
  const canManage = get(dataset, 'currentUserPermissions.manage', false);

  if (!dataset) {
    return null;
  }

  return (
    <>
      <Box sx={{ pl: 2 }}>
        <Typography variant="h5">Permissions</Typography>
        <Typography variant="body2" color="textSecondary">Manage dataset permissions.</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          {canManage && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add Permission
            </Button>
          )}
        </Stack>
        {open && (
          <AddPermissionDialog
            dataset={dataset}
            open={open}
            onClose={() => setOpen(false)}
          />
        )}
        <PermissionsList dataset={dataset} />
      </Box>
    </>
  );
};

export default Permissions;
