import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Group, User } from '../../../declarations';
import UserAvatar from '../../UserAvatar';

interface Props {
  group: Group;
  open: boolean;
  onClose: () => void;
}

const ViewMembers = ({
  group,
  open,
  onClose,
}: Props): React.ReactElement => {
  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <DialogTitle>
        Group Users
      </DialogTitle>
      <DialogContent sx={{ maxHeight: 500, overflow: 'auto' }}>
        {group.groupMemberships && group.groupMemberships?.map((d) => {
          const { user } = d;
          const isAdmin = d.role === 'admin';

          return (
            <ListItem
              key={d.id}
              sx={{
                backgroundColor: 'action.disabledBackground',
                height: theme => theme.spacing(7.5),
                mt: 1,
              }}
            >
              <ListItemAvatar><UserAvatar user={user as User} /></ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary={user?.displayName}
                secondary={user?.email}
              />
              <Typography
                component="label"
                color="textSecondary"
                sx={{ mr: isAdmin ? 1.25 : 0 }}
              >
                {isAdmin ? 'Group Leader' : 'Group Member'}
              </Typography>
            </ListItem>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={() => onClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewMembers;
