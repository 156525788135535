import React from 'react';
import { Link } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

type WorkspaceSettingsProps = {
  handleClose: () => void,
  isAdmin: boolean,
}

function WorkspaceSettingsMenu({ handleClose, isAdmin }: WorkspaceSettingsProps): React.ReactElement {
  return (
    <div>
      {isAdmin && (
        <MenuItem component={Link} to="/users" onClick={handleClose}>
          <ListItemText primary="Users" />
        </MenuItem>
      )}
      <MenuItem component={Link} to="/groups" onClick={handleClose}>
        <ListItemText primary="Groups" />
      </MenuItem>
      {isAdmin && (
        <MenuItem component={Link} to="/attributes" onClick={handleClose}>
          <ListItemText primary="Attributes" />
        </MenuItem>
      )}
      {isAdmin && (
        <MenuItem component={Link} to="/audit-log" onClick={handleClose}>
          <ListItemText primary="Audit Log" />
        </MenuItem>
      )}
    </div>
  );
}

export default WorkspaceSettingsMenu;
