import { Add } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import useTheme from '@mui/styles/useTheme';
import get from 'lodash/get';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { validateApplicationUrl } from '../../validators';

const ApplicationDetails = (): React.ReactElement | null => {
  const {
    errors,
    formState,
    register,
    control,
  } = useFormContext();
  const theme = useTheme();
  const { isSubmitting } = formState;
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'redirectUrl',
  });

  const handleOnAppend = () => append({ url: '' });
  const handleOnRemove = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({ url: '' });
    }
  }, [append, fields]);

  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        InputLabelProps={{
          htmlFor: 'url',
        }}
        id="url"
        label="URL"
        placeholder="https://yourapp.com"
        name="url"
        variant="outlined"
        fullWidth
        sx={{
          marginBottom: theme.spacing(2),
          maxWidth: 500,
        }}
        error={!!errors.url}
        helperText={(errors.url && errors.url.message) || 'Enter the full URL of your application homepage.'}
        inputRef={register({
          required: 'Required',
          validate: (url) => validateApplicationUrl(url),
        })}
        disabled={isSubmitting}
      />
      {fields.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 2,
          }}
        >
          <TextField
            key={field.id}
            InputLabelProps={{
              htmlFor: `redirectUrl.${index}.url`,
            }}
            sx={{ maxWidth: 500 }}
            id={`redirectUrl.${index}.url`}
            label="Redirect URL"
            placeholder="https://www.yourapp.com/redirect_url"
            name={`redirectUrl.${index}.url`}
            variant="outlined"
            fullWidth
            error={!!get(errors, `redirectUrl.${index}.url`)}
            helperText={get(errors, `redirectUrl.${index}.url.message`, 'Users will be redirected here after authorization.')}
            inputRef={register({
              required: 'Required',
              validate: (url) => validateApplicationUrl(url),
            })}
            disabled={isSubmitting}
            defaultValue={fields[index].url || ''}
          />
          {index !== 0 && (
            <IconButton
              aria-label="delete-redirect-url"
              sx={{
                mt: 1,
                ml: 1,
                mr: -1,
              }}
              onClick={() => handleOnRemove(index)}
              size="large">
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          aria-label="add-redirect-input"
          sx={{
            marginBottom: theme.spacing(2),
          }}
          size="small"
          onClick={() => handleOnAppend()}
        >
          <Add fontSize="small" sx={{ mr: 0.5 }} /> Add Redirect Url
        </Button>
      </Box>
    </Box>
  );
};

export default ApplicationDetails;
