import { usePaginatedService } from '@koverse/react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import queryString from 'query-string';
import React, { SyntheticEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQueryRequest } from '../../hooks';
import useSearchSelect from '../../hooks/useSearchSelect';
import AppBarOffset from '../AppBarOffset';
import { useNavigationDrawerTransition } from '../NavigationDrawer';
import NoSearchResults from './NoSearchResults';
import StyledInput from './StyledInput';

interface Props {
  open: boolean;
  onClose: (e?: SyntheticEvent) => void;
}

type Results = {
  datasets: any[];
  totalDatasets: number;
  totalRecords: number;
  searchId: string;
}

const SearchAllDatasetsDrawer = ({ open, onClose }: Props): React.ReactElement => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const { query, debouncedQuery, scope, setQuery } = useSearchSelect();
  const { loading, error, queryResult, fetchData } = useQueryRequest('summary');

  const datasets = usePaginatedService('datasets', {
    searchAnyField: false,
  });

  const navigationDrawerTransition = useNavigationDrawerTransition();
  const showIndexMessage = !!datasets?.items?.length;

  const handleOnCloseDrawer = () => {
    onClose();
  };

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(parseInt(e.target.value, 10));
    setCurrentPage(0);
  };

  const results = queryResult as Results;

  React.useEffect(() => {
    if (scope === 'all') {
      fetchData({
        expression: debouncedQuery,
        limit,
        offset: currentPage * limit,
      });
    }
  }, [currentPage, debouncedQuery, fetchData, limit, scope]);

  return (
    <>
      <Drawer
        open={open}
        anchor="bottom"
        onClose={handleOnCloseDrawer}
        elevation={0}
        sx={{
          ...navigationDrawerTransition,
          '& .MuiDrawer-paper': {
            height: '100%',
            ...navigationDrawerTransition,
          },
          '& .MuiBackdrop-root': {
            ...navigationDrawerTransition,
          },
        }}
      >
        <AppBarOffset />
        <Stack direction="row" alignItems="flex-start" spacing={3} sx={{ m: 4 }}>
          <IconButton onClick={onClose}><CloseIcon /></IconButton>
          <Stack sx={{ flexGrow: 1, pr: 9 }}>
            <Typography variant="h5" sx={{ mt: 0.5, mb: 2 }}>Search across all Datasets</Typography>
            <StyledInput
              placeholder="Search all datasets"
              inputProps={{ 'aria-label': 'search' }}
              fullWidth
              size="small"
              value={query}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setQuery(e.target.value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="clear search box"
                    onClick={() => setQuery('')}
                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault();
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            {loading && (
              <LinearProgress sx={{ mt: 5 }} />
            )}
            {!loading && error && (
              <NoSearchResults search={debouncedQuery as string} error={error} />
            )}
            {results && (
              <Box sx={{ mt: 5 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  <b>{new Intl.NumberFormat().format(results.totalRecords)}</b>{` results for `}<b>{debouncedQuery}</b>
                </Typography>
                {showIndexMessage && (
                  <Typography sx={{ mb: 3 }}>
                    There are fields not being indexed for some datasets which may return 0 results. Changes can be made by going to your index settings of the impacted datasets.
                  </Typography>
                )}
                {results.datasets.map((ds, i) => (
                  <ButtonBase
                    key={ds.id}
                    component={RouterLink}
                    to={`/datasets/${ds.id}?${queryString.stringify({ q: debouncedQuery })}`}
                    sx={{
                      borderTop: t => i === 0 ? `1px solid ${t.palette.divider}` : 'none',
                      borderBottom: t => `1px solid ${t.palette.divider}`,
                      py: 2,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ minWidth: '150px', textAlign: 'left' }}
                    >
                      <b>{new Intl.NumberFormat().format(ds.recordCount)}</b>
                      {` results`}
                    </Typography>
                    <Typography variant="h5" color="primary" sx={{ ml: 1 }}>{ds.name}</Typography>
                  </ButtonBase>
                ))}
                {!!results.datasets.length && (
                  <TablePagination
                    sx={{ p: 1 }}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={results.totalDatasets}
                    rowsPerPage={limit}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
                {!results.datasets.length && (
                  <Stack
                    alignItems="center"
                    sx={{
                      mt: 2,
                      borderTop: t => `1px solid ${t.palette.divider}`,
                    }}
                  >
                    <SearchIcon sx={{ fontSize: 70, mt: 5, mb: 3 }} />
                    <Typography
                      color="textSecondary"
                    >
                      Please check your spelling and try again
                    </Typography>
                  </Stack>
                )}
              </Box>
            )}
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

export default SearchAllDatasetsDrawer;
