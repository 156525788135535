import React from 'react';
import pluralize from 'pluralize';
import times from 'lodash/times';
import { useKoverse, usePaginatedService } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { SelectChangeEvent } from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import InvitationsRoleForm from './InvitationsRoleForm';
import UserRoleForm from './UserRoleForm';
import { WorkspaceInvitation, User } from '../../../declarations';

interface Props {
  setInvitationToRevoke: React.Dispatch<React.SetStateAction<WorkspaceInvitation | null>>;
}

const Collaborators = ({
  setInvitationToRevoke,
}: Props): React.ReactElement => {
  const { client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    items: invitations,
    loading: loadingInvitations,
  } = usePaginatedService('workspace-invitations', {
    $limit: -1,
    workspaceId: workspace.id,
  });

  const {
    items: users,
    loading: loadingUsers,
  } = usePaginatedService('users', {
    $limit: -1,
    workspaceId: workspace.id,
  });

  const loading = loadingInvitations || loadingUsers;

  const handleRoleChange = async (event: SelectChangeEvent, user: User) => {
    try {
      await client.service('workspace-management').create({
        action: 'changeRole',
        userId: user.id,
        role: event.target.value,
        workspaceId: workspace.id,
      });
    } catch (e) {
      enqueueSnackbar(`There was an error: ${e}`, {
        variant: 'error',
      });
    }
  };

  const handleInviteRoleChange = async (event: SelectChangeEvent, invitation: WorkspaceInvitation) => {
    try {
      await client.service('workspace-invitations').patch(invitation.id, {
        role: event.target.value,
        userId: invitation.userId,
      });
    } catch (e) {
      enqueueSnackbar(`There was an error: ${e}`, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography>{t('dialogs.invitations.collaborators.title')}</Typography>
        <Typography variant="body2">
          {t('dialogs.invitations.collaborators.users', {
            number: users.length,
            users: pluralize('user', users.length),
            has: pluralize('have', users.length),
          })}
        </Typography>
      </Box>
      {loading && (
        <List dense>
          {times(5, (index) => (
            <ListItem key={index} aria-label="placeholder-list-item-while-loading">
              <Skeleton
                sx={{ mb: 1 }}
                variant="rectangular"
                height={60}
                width="100%"
              />
            </ListItem>
          ))}
        </List>
      )}
      {!loading && (
        <List dense sx={{ pr: 4 }}>
          {!!invitations.length && (
            <InvitationsRoleForm
              invitations={invitations}
              onChange={handleInviteRoleChange}
              setInvitationToRevoke={setInvitationToRevoke}
            />
          )}
          {!!users.length && (
            <UserRoleForm
              roomTop={!!invitations.length}
              users={users}
              onChange={handleRoleChange}
            />
          )}
        </List>
      )}
    </>
  );
};

export default Collaborators;
