import React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import useQueryParams from '../../hooks/useQueryParams';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import config from '../../config';
import { Workspace } from '../../declarations';
import { ArrowForwardIos } from '@mui/icons-material';

type Props = {
  workspaces: Workspace[];
};

function SelectWorkspace({ workspaces }: Props): React.ReactElement {
  const { queryParams } = useQueryParams();
  const { email } = queryParams;
  return (
    <Stack sx={{ width: '100%' }} alignItems="center">
      <Typography variant="h6" sx={{ mb: 2 }}>Select a Workspace</Typography>
      <Paper sx={{ width: '100%' }}>
        <Typography
          sx={{
            p: 2,
          }}
          color="textSecondary"
        >
          Workspaces for {email}
        </Typography>
        <List>
          {workspaces.map((ws) => (
            <ListItemButton
              key={ws.id}
              component="a"
              href={`https://${ws.id}.${config.domain}/login?email=${email}`}
            >
              <ListItemText primary={ws.name} primaryTypographyProps={{ variant: 'h6' }} />
              <ArrowForwardIos />
            </ListItemButton>
          ))}
        </List>
      </Paper>
    </Stack>
  );
}

export default SelectWorkspace;
