import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import mime from 'mime';
import React from 'react';
import { useParams } from 'react-router-dom';
import { DocumentQueryRecord, DocumentRecord } from '../../../declarations';
import {
  useDownloadFileFromDocumentRecord,
  useMediaDownload,
  useShortenStringWithInteriorMasking,
} from '../../../hooks';
import DocumentView from './DocumentView';
import Checkbox from '@mui/material/Checkbox';

interface Props {
  record: DocumentQueryRecord;
  openDocumentPreview: (document: DocumentRecord) => void;
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
  showSelectionCheckbox?: boolean;
  disableDataActions: boolean;
}

interface Params {
  id: string;
  workspaceId: string;
}

const DocumentThumbnailCard = ({
  record,
  openDocumentPreview,
  checked,
  setChecked,
  showSelectionCheckbox,
  disableDataActions,
}: Props): React.ReactElement | null => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { id } = useParams<Params>();
  const { maskString } = useShortenStringWithInteriorMasking();
  const open = Boolean(anchorEl);
  const download = useDownloadFileFromDocumentRecord();
  const { save: downloadFromStorage } = useMediaDownload();

  const document: DocumentRecord = React.useMemo(() => {
    const {
      _koverse_document_metadata,
      filename,
      metadata,
      originalBytes,
    } = record;
    const { createPdfPreview, createVideoThumbnail, pdfPreviewBytes, videoThumbnailBytes, document_id } = _koverse_document_metadata;
    const mimeType = metadata['Content-Type'];
    let previewMimeType = mimeType;

    if (mimeType === 'video/mp4') {
      previewMimeType = 'image/jpeg';
    }

    const bytes = createPdfPreview ? pdfPreviewBytes : createVideoThumbnail ? videoThumbnailBytes : originalBytes;
    return {
      downloadUri: `data:${mimeType};base64,${originalBytes}`,
      fileName: filename.lastIndexOf('.') < 0
        ? `${filename}.${mime.getExtension(mimeType)}`
        : filename,
      metadata,
      mimeType,
      originalBytes,
      uri: bytes ? `data:${previewMimeType};base64,${bytes}` : '',
      videoThumbnailBytes,
      document_id,
    };
  }, [record]);

  const { masked, displayToolTip } = React.useMemo(() => {
    const masked = maskString(document.fileName);
    const displayToolTip = document.fileName >= masked;

    return {
      masked,
      displayToolTip,
    };
  }, [document.fileName, maskString]);

  const handleOpenDocumentPreview = React.useCallback(() => {
    openDocumentPreview(document);
  }, [document, openDocumentPreview]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = React.useCallback(() => {
    if (document.mimeType.startsWith('video/')) {
      downloadFromStorage(document, id);
    } else {
      download(document);
    }
  }, [document, download, downloadFromStorage, id]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setChecked) {
      setChecked(event.target.checked);
    }
  };

  if (!document) {
    return null;
  }

  return (
    <Card>
      <DocumentView
        open={handleOpenDocumentPreview}
        uri={document.uri}
        pageNumber={1}
        mimeType={document.mimeType}
      />
      <CardActions>
        { showSelectionCheckbox && (
          <Checkbox
            checked={checked}
            disabled={disableDataActions}
            onChange={handleChange}
            sx={{
              p: 0,
            }}
          ></Checkbox>)
        }
        <Tooltip title={displayToolTip ? document.fileName : ''}>
          <Typography
            variant="caption"
          >
            {masked}
          </Typography>
        </Tooltip>
        <div style={{ flex: 1 }} />
        <IconButton
          aria-label="more"
          id="more-button"
          aria-controls={open ? 'action-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          sx={{ mr: -1 }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="action-menu"
          MenuListProps={{
            'aria-labelledby': 'more-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <Box>
            <MenuItem
              onClick={() => {
                handleOpenDocumentPreview();
                handleClose();
              }}
              divider
            >
              Preview
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDownload();
                handleClose();
              }}
            >
              Download
            </MenuItem>
          </Box>
        </Menu>
      </CardActions>
    </Card>
  );
};

export default DocumentThumbnailCard;
