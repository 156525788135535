import DeleteIcon from '@mui/icons-material/Close';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import filesize from 'filesize';
import some from 'lodash/some';
import pluralize from 'pluralize';
import React from 'react';

const UPLOAD_HEIGHT = 320;

interface Props {
  files: any;
  setFiles: any;
}

const columnarDataMimetypes = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/tab-separated-values',
];

const FileList = ({
  files,
  setFiles,
}: Props): React.ReactElement => {
  const stagedFileMessage = React.useMemo(() => {
    return some(files, (file: any) => !columnarDataMimetypes.includes(file.type))
      ? 'Will display as .json tree'
      : 'Will display as columnar data';
  }, [files]);

  const handleRemoveFile = (file: File) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  return (
    <Paper
      elevation={1}
      sx={{
        height: UPLOAD_HEIGHT,
      }}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
        <Typography variant="body2">
          {`${files.length} ${pluralize('file', files.length)}`}
        </Typography>
        <Typography variant="body2">
          {stagedFileMessage}
        </Typography>
      </Stack>
      <List
        dense
        sx={{
          overflow: 'auto',
          maxHeight: (theme) => `${UPLOAD_HEIGHT - parseInt(theme.spacing(8), 10)}px`,
        }}
      >
        {files.map((file: File, index: number) => {
          return (
            <Box key={`${file.name}-${index}`} sx={{ position: 'relative' }}>
              <ListItem divider={files.length - 1 !== index}>
                <ListItemAvatar><FolderIcon /></ListItemAvatar>
                <ListItemText
                  primary={file.name}
                  secondary={filesize(file.size)}
                  primaryTypographyProps={{ noWrap: true }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="remove"
                    onClick={() => handleRemoveFile(file)}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Box>
          );
        })}
      </List>
    </Paper>
  );
};

export default FileList;
