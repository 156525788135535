import React from 'react';
import get from 'lodash/get';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import PhotoIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import UserAvatar from '../UserAvatar';

const EditAvatar = (): React.ReactElement | null => {
  const { client, user } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const avatars = client.service('avatars');
  avatars.timeout = 100000000;
  const users = client.service('users');
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const uploader = React.useRef<null | HTMLInputElement>(null);

  const handleRemoveAvatar = async () => {
    if (user) {
      try {
        await users.patch(user.id, { avatar: null });
      } catch (error) {
        enqueueSnackbar(`There was an error ${error}`, {
          variant: 'error',
        });
      }
    }
  };

  const handleUploadAvatar = () => {
    if (uploader.current) {
      uploader.current.value = '';
      uploader.current.click();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const avatar = get(e, 'target.files[0]');

    if (avatar && user) {
      const reader = new FileReader();

      reader.readAsDataURL(avatar);
      reader.addEventListener('load', async () => {
        try {
          setIsUploading(true);
          await avatars.create({
            data: reader.result,
          });
          setIsUploading(false);
          enqueueSnackbar(t('pages.account.editAvatar.successToast'), {
            variant: 'success',
          });
        } catch (error) {
          enqueueSnackbar(`There was an error: ${error}`, {
            variant: 'error',
          });
          setIsUploading(false);
          return reader.abort();
        }
      });
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Stack justifyContent="start" alignItems="start">
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={(
          <Box
            component='div'
            sx={{
              position: 'relative',
            }}
          >
            <Tooltip title={t('pages.account.avatarTooltip') as string} placement="right">
              <Fab
                size="small"
                color="primary"
                aria-label="edit user avatar"
                aria-controls="menu-avatar"
                aria-haspopup="true"
                onClick={handleUploadAvatar}
                disabled={isUploading}
              >
                {user.avatar ? <PhotoIcon fontSize="small" /> : <AddPhotoIcon fontSize="small" />}
              </Fab>
            </Tooltip>
            {isUploading && (
              <CircularProgress
                size={46}
                sx={{
                  position: 'absolute',
                  top: -3,
                  left: -3,
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        )}
      >
        <UserAvatar
          avatarProps={{
            sx: {
              width: (theme) => theme.spacing(15),
              height: (theme) => theme.spacing(15),
              fontSize: (theme) => theme.typography.h1.fontSize,
            },
          }}
          user={user}
        />
      </Badge>
      <input
        name="avatar"
        type="file"
        accept=".png, .jpg, .jpeg, .gif"
        ref={uploader}
        style={{ display: 'none' }}
        onChange={handleOnChange}
      />
      {!!user.avatar && (
        <Button
          sx={{ mt: 2 }}
          onClick={handleRemoveAvatar}
        >
          {t('pages.account.removePhoto')}
        </Button>
      )}
    </Stack>
  );
};

export default EditAvatar;
