import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Trans } from 'react-i18next';
import { SubscriptionLevel } from '../../declarations';

interface Props {
  isSelected?: boolean;
  subscriptionLevel: SubscriptionLevel;
  subscriptionLevelKey: string;
  selectSubscriptionLevel: (id: string) => void;
}

const SubscriptionLevelCard = ({
  isSelected,
  subscriptionLevel,
  subscriptionLevelKey,
  selectSubscriptionLevel,
}: Props): React.ReactElement => {

  return (
    <Card
      elevation={4}
      sx={{
        height: '100%',
        border: theme => isSelected
          ? `1px solid ${theme.palette.primary.main}`
          : 'none',
      }}
    >
      <CardHeader
        title={subscriptionLevel.name}
        titleTypographyProps={{
          variant: 'h6',
          gutterBottom: true,
        }}
        subheader={<Trans
          i18nKey={`subscriptionLevels.${subscriptionLevelKey}.description`}
          components={{
            b: <b />,
          }}
        />}
        sx={{
          minHeight: theme => theme.spacing(19),
          alignItems: 'flex-start',
        }}
      />
      <CardContent>
        <Box
          sx={{
            minHeight: theme => theme.spacing(8),
          }}
        >
          {!!subscriptionLevel.recurringPriceSummary && (
            <Typography variant="h5" sx={{ mb: 2 }}>
              {`${subscriptionLevel.recurringPriceSummary.amount}/${subscriptionLevel.recurringPriceSummary.interval}`}
            </Typography>
          )}
          {subscriptionLevelKey === 'onPrem' && (
            <Typography>
              <Trans
                i18nKey={`subscriptionLevels.${subscriptionLevelKey}.licensing`}
              />
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            mt: 4,
            mb: 3,
          }}
        >
          <Trans
            i18nKey={isSelected
              ? 'subscriptionLevels.currentPlan'
              : `subscriptionLevels.${subscriptionLevelKey}.cta`}
            components={{
              button: (
                <Button
                  disabled={isSelected}
                  color="primary"
                  variant="contained"
                  onClick={() => selectSubscriptionLevel(subscriptionLevelKey)}
                />
              ),
              mailto: (
                <Button
                  disabled={isSelected}
                  component="a"
                  href="mailto:support@koverse.com?subject=On-Premises Licensing"
                  color="primary"
                  variant="outlined"
                />
              ),
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default SubscriptionLevelCard;
