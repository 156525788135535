import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useSubscription from '../../hooks/useSubscription';
import useUpcomingInvoice from '../../hooks/useUpcomingInvoice';

const MeteredProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#78909C',
  },
}));

const StorageUsage = (): React.ReactElement => {
  const { plan, loading: loadingSubscription } = useSubscription();
  const { currentUsage, loadingCurrentUsage } = useUpcomingInvoice();
  const { t } = useTranslation();
  const loading = loadingSubscription || loadingCurrentUsage;
  const usedGb = React.useMemo(() => {
    if (currentUsage?.storage) {
      return currentUsage.storage.hasOverages
        ? currentUsage.storage?.freeQuantity
        : currentUsage.storage?.total;
    }
  }, [currentUsage?.storage]);

  return (
    <Box sx={{ width: 185, mb: 1 }}>
      {loading && <Skeleton />}
      {!loading && !!currentUsage && (
        <Box>
          {!!currentUsage.storage?.overage && (
            <Box sx={{ mb: 1 }}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {t('pages.billing.subscription.overageTier')}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color="textPrimary">{`${currentUsage.storage?.overage} GB`}</Typography>
                <Typography variant="caption" color="textPrimary">{plan?.storage?.overagePrice}</Typography>
              </Stack>
            </Box>
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="caption" color="textPrimary">{`${usedGb} of ${currentUsage.storage?.freeQuantity}GB`}</Typography>
            <Typography variant="caption" color="textPrimary">{t('common.free')}</Typography>
          </Stack>
          <MeteredProgress variant="determinate" value={currentUsage.storage?.percentageOfFree} sx={{ width: '100%' }} />
          {currentUsage.storage?.hasOverages && (
            <Box sx={{ my: 2 }}>
              <Stack direction="row">
                <ErrorIcon color="error" sx={{ mr: 1 }} />
                <Stack direction="column">
                  <Typography variant="caption">{
                    t('pages.billing.subscription.overage', {
                      freeQuantity: currentUsage.storage?.freeQuantity,
                      unit: 'GB',
                    })}
                  </Typography>
                  <Typography variant="caption">{t('pages.billing.subscription.applyOverages')}</Typography>
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StorageUsage;
