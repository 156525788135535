import { useKoverse } from '@koverse/react';
import React from 'react';
import { SubscriptionLevel } from '../declarations';

type SubscriptionLevels = {
  subscriptionLevels: Record<string, SubscriptionLevel>,
  loading: boolean,
}

const useSubscriptionLevels = (): SubscriptionLevels => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [subscriptionLevels, setSubscriptionLevels] = React.useState<Record<string, SubscriptionLevel>>({});
  const { client } = useKoverse();
  const fetchSubscriptionLevels = React.useCallback(async () => {
    setLoading(true);
    try {
      const results = await client.service('stripe').create({
        action: 'findSubscriptionLevels',
      });
      setSubscriptionLevels(results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [client]);

  React.useEffect(() => {
    fetchSubscriptionLevels();
  }, [fetchSubscriptionLevels]);

  return { subscriptionLevels, loading };
};

export default useSubscriptionLevels;
