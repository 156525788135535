import React from 'react';
import { useKoverse } from '@koverse/react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HandIcon from '@mui/icons-material/PanTool';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface Props {
  handleNext?: () => void;
}

const GetStarted = ({ handleNext }: Props): React.ReactElement => {
  const { user } = useKoverse();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 8,
          pt: 5,
          pb: !!handleNext ? 8 : 0,
          alignItems: 'center',
        }}
      >
        <HandIcon fontSize="large" sx={{ transform: 'rotate(45deg)' }} />
        <Typography variant="h6">
          {t('pages.initialization.getStarted.greeting', { name: user.firstName })}
        </Typography>
        <Stack sx={{ alignItems: 'center' }}>
          <Typography>{t('pages.initialization.getStarted.welcome')}</Typography>
          <Typography>{t('pages.initialization.getStarted.message')}</Typography>
        </Stack>
        {!!handleNext && (
          <Button
            variant="contained"
            onClick={handleNext}
          >
            {t('pages.initialization.getStarted.cta')}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default GetStarted;
