import React, { useEffect } from 'react';
import { useKoverse } from '@koverse/react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import InvoiceTableRow from './invoiceTableRow';

type Field = 'number' | 'description' | 'date' | 'total' | 'status';

interface FieldCell extends TableCellProps {
  field: Field;
  label: string;
}

const InvoiceTable = (): React.ReactElement => {
  const { client } = useKoverse();
  const { t } = useTranslation();
  const [invoices, setInvoices] = React.useState<any[]>([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      const invoices = await client.service('stripe').create({
        action: 'findInvoices',
      });
      setInvoices(invoices);
    };
    fetchInvoices();
  }, [client]);

  const fields: FieldCell[] = React.useMemo(() => {
    return [{
      label: t('pages.billing.invoices.invoice'),
      field: 'number',
      align: 'left',
      width: 150,
    }, {
      label: t('pages.billing.invoices.date'),
      field: 'date',
      align: 'left',
      width: 200,
    }, {
      label: t('pages.billing.invoices.invoiceTotal'),
      field: 'total',
      align: 'left',
      width: 200,
    }, {
      label: t('pages.billing.invoices.status'),
      field: 'status',
      align: 'left',
      width: 200,
    }];
  }, [t]);

  return (
    <Card>
      <CardHeader
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center',
            margin: 0,
          },
        }}
        title={t('pages.billing.invoices.title')}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <Box>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {fields.map((f: FieldCell) => (
                  <TableCell
                    key={f.field}
                    align={f.align}
                    variant="head"
                    width={f.width}
                  >
                    <TableSortLabel
                      aria-label={f.label}
                    >
                      {f.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice) => (
                <InvoiceTableRow
                  key={invoice.id}
                  invoice={invoice}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default InvoiceTable;
