import { useKoverse } from '@koverse/react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Attribute, User } from '../../../declarations';
import { useAutocomplete } from '../../../hooks';
import UserAvatar from '../../UserAvatar';

interface Props {
  attributeId: string;
  attribute?: Attribute;
}

const UserSearch = ({
  attributeId,
  attribute,
}: Props): React.ReactElement => {
  const { client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    open,
    setOpen,
    value,
    setValue,
    inputValue,
    setInputValue,
    options,
    setCurrentIds,
    loading,
  } = useAutocomplete({
    serviceUrl: 'users',
    fields: ['email', 'firstName', 'lastName'],
    clearOnSelect: true,
  });

  React.useEffect(() => {
    if (attribute?.attributeAssignments && !!attribute.attributeAssignments.length) {
      setCurrentIds(attribute.attributeAssignments.map(d => d.userId));
    }
  }, [attribute, setCurrentIds]);

  const handleAddToUser = async (user: User | null) => {
    try {
      if (user) {
        await client.service('attribute-assignments').create({
          attributeId,
          userId: user.id,
          workspaceId: workspace.id,
        });
      }
    } catch (error) {
      enqueueSnackbar(`There was an error: ${get(error, 'message', error)}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Autocomplete
      id="user-search"
      blurOnSelect
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options as User[]}
      getOptionLabel={(options: User) => options.email}
      filterOptions={(data) => data}
      noOptionsText={t('common.noResults')}
      value={value as User}
      onChange={(_event: React.ChangeEvent<unknown>, newValue: User | null) => {
        handleAddToUser(newValue);
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_event: React.ChangeEvent<unknown>, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id="search-users"
          fullWidth
          label={t('forms.labels.assignUsers')}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            htmlFor: 'search-users',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      PaperComponent={(props) => <Paper {...props} elevation={4} />}
      renderOption={(props, option) => (
        <ListItem
          {...props}
          aria-label={option.email}
        >
          <ListItemAvatar>
            <UserAvatar user={option} />
          </ListItemAvatar>
          <ListItemText
            primary={option.displayName}
            secondary={option.email}
          />
        </ListItem>
      )}
    />
  );
};

export default UserSearch;
