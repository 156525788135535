import React, { Dispatch, SetStateAction } from 'react';
import get from 'lodash/get';
import { useKoverse } from '@koverse/react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardPreview from '../CardPreview';
import InvoiceDetails from '../InvoiceDetails';
import useSubscription from '../../hooks/useSubscription';
import { Subscription } from '../../declarations';

interface Props {
  handleNext: () => void;
  paymentMethod: undefined | Subscription['default_payment_method'];
  setPaymentMethod: Dispatch<SetStateAction<undefined | Subscription['default_payment_method']>>;
}

const ApproveInvoice = ({
  handleNext,
  paymentMethod,
  setPaymentMethod,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { user } = useKoverse();
  const {
    deleteSubscription,
    subscription,
    deleting,
  } = useSubscription();
  const back = async () => {
    await deleteSubscription();
  };
  const confirm = () => {
    handleNext();
  };
  const onEdit = () => {
    setPaymentMethod(undefined);
    back();
  };

  return (
    <Box
      sx={{ px: 8, pt: 5, pb: 8 }}
    >
      <Stack spacing={3} sx={{ px: 8, pt: 5, pb: 8 }}>
        <Typography variant="h6" align="left" sx={{ mb: 2 }}>
          {t('pages.initialization.approveInvoice.title')}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Stack direction="column">
            <Typography color="textSecondary">{t('common.contactInformation')}</Typography>
          </Stack>
          <Stack direction="column">
            <Typography>{user.email}</Typography>
          </Stack>
        </Stack>
        {subscription?.default_payment_method?.card && (
          <Stack
            direction="row"
            justifyContent="space-between"
          >
            <Stack>
              <Typography color="textSecondary">{t('common.paymentDetails')}</Typography>
            </Stack>
            {paymentMethod && paymentMethod?.card && (
              <CardPreview
                card={paymentMethod.card}
                direction="column"
                onEdit={onEdit}
              />
            )}
          </Stack>
        )}
        {subscription?.latest_invoice && (
          <InvoiceDetails
            invoice={subscription.latest_invoice}
            hideFreeItems
          />
        )}
        {get(subscription, 'latest_invoice.total', 0) > 0 && (
          <Alert
            color="info"
            variant="outlined"
          >
            {t('pages.initialization.approveInvoice.alert')}
          </Alert>
        )}
        <Box sx={{ textAlign: 'right' }}>
          {deleting ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                onClick={back}
              >
                {t('common.back')}
              </Button>
              <Button
                variant="contained"
                onClick={confirm}
                sx={{ ml: 1 }}
              >
                {t('common.confirm')}
              </Button>
            </>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default ApproveInvoice;
