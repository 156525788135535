import { usePaginatedService } from '@koverse/react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment } from '../../../declarations';

interface Props {
  id: string;
  datasetId: string;
}

const SegmentDetails = ({
  id,
  datasetId,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    items,
    loading,
    total,
  } = usePaginatedService('segments', {
    jobId: id,
    datasetId,
    $limit: -1,
    $sort: { updatedAt: 1 },
  });

  const getTotalTime = (segment: Segment) => {
    const created = DateTime.fromISO(segment.createdAt);
    const updated = DateTime.fromISO(segment.updatedAt);
    const {
      hours: hrs,
      minutes: min,
    } = updated.diff(created, ['hours', 'minutes']).toObject();
    const hours = hrs ? `${hrs} ${pluralize('hour', hrs)}` : '';
    const minutes = min && min >= 1
      ? `${Math.round(min)} ${pluralize('minute', Math.round(min))}`
      : 'Less than a minute';

    return `${hours} ${minutes}`;
  };

  if (loading) {
    return (
      <Box><Skeleton aria-label="placeholder-while-loading" /></Box>
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">
        {t('pages.dataset.jobHistory.segments.title')}
      </Typography>
      <Stack spacing={1} direction="row">
        <Typography>{t('pages.dataset.jobHistory.segments.subheader')}</Typography>
        <Typography>{total}</Typography>
      </Stack>
      <TableContainer>
        <Table aria-label="segments details table">
          <TableHead>
            <TableRow>
              <TableCell width={200}>{t('common.status')}</TableCell>
              <TableCell width={200}>{t('common.date')}</TableCell>
              <TableCell width={200}>{t('common.created')}</TableCell>
              <TableCell width={200}>{t('common.lastUpdated')}</TableCell>
              <TableCell width={200}>{t('common.runTime')}</TableCell>
              <TableCell>{t('common.error')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((d: Segment) => {
              const date = DateTime.fromISO(d.createdAt).toLocaleString();
              const created = DateTime.fromISO(d.createdAt).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
              const updated = DateTime.fromISO(d.updatedAt).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
              return (
                <TableRow
                  key={d.id}
                >
                  <TableCell component="th" scope="row">{d.status}</TableCell>
                  <TableCell>{date}</TableCell>
                  <TableCell>{created}</TableCell>
                  <TableCell>{updated}</TableCell>
                  <TableCell>{getTotalTime(d)}</TableCell>
                  <TableCell>{d?.error?.message}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SegmentDetails;
