import { useKoverse } from '@koverse/react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { AuditLogConfig, AuditLogData } from '../../declarations';
import { useSnackbar } from 'notistack';
import LogConfigurationDialog from '../Dialogs/LogConfigurationDialog';

type Inputs = {
  config: string;
  ageInDays: string;
};

interface Props {
  auditLogAction: React.ReactNode;
  auditLogConfig: AuditLogConfig;
  data: AuditLogData[];
}

const AuditLogConfiguration = ({ auditLogAction, auditLogConfig, data }: Props): React.ReactElement | null => {
  const [submissionError, setSubmissionError] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const { ageInDays, keepForever, id } = auditLogConfig;
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    errors,
    formState,
    getValues,
    handleSubmit,
    reset,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      ageInDays: ageInDays ? `${ageInDays}` : '90',
      config: keepForever ? 'keepForever' : 'ageInDays',
    },
  });
  const { isSubmitting, isDirty } = formState;
  const config = useWatch({
    control,
    name: 'config',
  });

  const onSubmit = async (data: Inputs) => {
    const payload = data.config === 'keepForever'
      ? { keepForever: true }
      : { ageInDays: parseInt(data.ageInDays) };

    try {
      const config: AuditLogConfig = await client.service('audit-log-configs').patch(id, payload);
      reset({
        ageInDays: !!config.ageInDays ? `${config.ageInDays}` : '90',
        config: config.keepForever ? 'keepForever' : 'ageInDays',
      });
      enqueueSnackbar('Saved changes', {
        variant: 'success',
      });
    } catch (e) {
      setSubmissionError(`There was a server error: ${get(e, 'message')}`);
    }
  };

  React.useEffect(() => {
    if (config === 'keepForever' && !!errors.ageInDays) {
      reset({
        ageInDays: !!ageInDays ? `${ageInDays}` : '90',
        config: 'keepForever',
      }, {
        dirtyFields: true,
        errors: false,
        isDirty: true,
        isValid: true,
      });
    }
  }, [ageInDays, config, errors.ageInDays, reset]);

  const SubmitButton = React.useMemo(() => {
    const ageInDays = config === 'ageInDays';
    return (
      <Button
        type={ageInDays ? 'button' : 'submit'}
        color="primary"
        variant="contained"
        onClick={ageInDays ? () => setOpen(true) : undefined}
        disabled={!!errors.ageInDays || !isDirty}
      >
        Save Changes
      </Button>
    );
  }, [config, errors.ageInDays, isDirty]);

  return (
    <Box>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader
            sx={{
              pb: 0,
              '& .MuiCardHeader-action': {
                alignSelf: 'center',
                margin: 0,
              },
            }}
            title="Log Configuration"
            titleTypographyProps={{ variant: 'h6' }}
            subheader="Configure how long audit logs are stored in this workspace."
            action={auditLogAction}
          />
          <CardContent>
            <FormControl
              fullWidth
            >
              <Controller
                as={
                  <RadioGroup aria-label="if-values-are-empty">
                    <FormControlLabel
                      value="keepForever"
                      control={<Radio color="primary" />}
                      label="Never delete logs."
                    />
                    <Typography sx={{ ml: 4, mb: 2 }} variant="body2">
                      By default, KDP4 will not delete audit logs unless specified.
                    </Typography>
                    <FormControlLabel
                      value="ageInDays"
                      control={<Radio color="primary" />}
                      label="Delete audit logs after:"
                    />
                  </RadioGroup>
                }
                name="config"
                control={control}
                rules={{
                  required: 'Required',
                }}
              />
              <FormControl
                fullWidth
                margin='none'
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ ml: 4 }}
                >
                  <Controller
                    as={TextField}
                    name="ageInDays"
                    type="number"
                    control={control}
                    variant="outlined"
                    size="small"
                    placeholder="Enter value"
                    aria-placeholder="Enter age in days value"
                    style={{ width: 150 }}
                    disabled={config === 'keepForever'}
                    error={!!errors.ageInDays}
                    rules={{
                      required: config === 'ageInDays' && 'Required',
                      validate: (value) => parseInt(value) < 90 ? 'Minimum of 90 days' : true,
                    }}
                    helperText={(errors.ageInDays && errors.ageInDays.message) || ''}
                  />
                  <Typography sx={{ ml: 2 }}>Days</Typography>
                </Stack>
              </FormControl>
              {config === 'ageInDays' && (
                <Alert
                  severity="warning"
                  variant="outlined"
                  sx={{ width: 450, ml: 4, mt: 2, alignContent: 'center' }}
                >
                  Changes to your audit log will take up to 24 hours.
                </Alert>
              )}
            </FormControl>
          </CardContent>
          <CardActions
            sx={{ justifyContent: 'flex-end', p: 2 }}
          >
            {!!submissionError && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{
                  mr: 2,
                  ml: 1,
                  flex: 1,
                }}
              >
                {submissionError}
              </Alert>
            )}
            {isSubmitting
              ? <CircularProgress size={30} sx={{ mr: 2 }} />
              : SubmitButton
            }
          </CardActions>
          <LogConfigurationDialog
            ageInDays={getValues().ageInDays}
            data={data}
            isSubmitting={isSubmitting}
            onClose={() => setOpen(false)}
            onConfirm={handleSubmit(onSubmit)}
            open={open}
            setSubmissionError={setSubmissionError}
          />
        </form>
      </Card>
      <Prompt
        when={isDirty}
        message={'You are about to leave this page before saving your changes. Are you sure you want to leave?'}
      />
    </Box>
  );
};

export default AuditLogConfiguration;
