import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import config from '../config';
import ForgotPassword from '../pages/ForgotPassword';
import Join from '../pages/Join';
import Login from '../pages/Login';
import ResetPassword from '../pages/ResetPassword';
import SignUp from '../pages/SignUp';
import UniversalLogin from '../pages/UniversalLogin';

const PublicRoutes = (): React.ReactElement => {
  const { pathname, search } = useLocation();
  const authRedirect = `${pathname}${search}`;

  return config.workspaceId ? (
    <Switch>
      {!config.disableLocalAuth && (
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
      )}
      {!config.disableLocalAuth && (
        <Route path="/reset-password/:token">
          <ResetPassword />
        </Route>
      )}
      {!config.disableLocalAuth && (
        <Route path="/join/:invitationId">
          <Join />
        </Route>
      )}
      <Route path="/login">
        <Login />
      </Route>
      {authRedirect !== '/' ? (
        <Redirect to={`/login?authRedirect=${authRedirect}`} />
      ) : (
        <Redirect to="/login" />
      )}
    </Switch>
  ) : (
    <Switch>
      <Route path="/sign-up">
        <SignUp />
      </Route>
      <Route path="/login">
        <UniversalLogin />
      </Route>
      <Redirect to="/sign-up" />
    </Switch>
  );
};

export default PublicRoutes;
