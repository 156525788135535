import React from 'react';
import { useKoverse } from '@koverse/react';
import { NotImplemented } from '@feathersjs/errors';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import WorkspaceDetailMenu from '../Menus/WorkspaceDetailMenu';
import AppBarOffset from '../AppBarOffset';
import { Link, useTheme } from '@mui/material';
import { useAppState } from '../AppStateProvider';
import config from '../../config';

const NAV_DRAWER_WIDTH = '300px';

export const useNavigationDrawerTransition = () => {
  const theme = useTheme();
  const { navDrawerOpen } = useAppState();
  const navigationDrawerTransition = {
    transition: navDrawerOpen ? theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }) : theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: navDrawerOpen ? `calc(100vw - ${NAV_DRAWER_WIDTH})` : '100vw',
    marginLeft: navDrawerOpen ? NAV_DRAWER_WIDTH : 0,
  };
  return navigationDrawerTransition;
};

function NavigationDrawer(): React.ReactElement {
  const { theme } = useKoverse();
  const { navDrawerOpen, setNavDrawerOpen } = useAppState();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Drawer
      aria-label="navigation drawer"
      open={ navDrawerOpen }
      PaperProps={{ elevation: 1 }}
      onClose={() => setNavDrawerOpen ? setNavDrawerOpen(false) : NotImplemented }
      variant='persistent'
      anchor='left'
      transitionDuration={{
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
      }}
      sx={{
        width: NAV_DRAWER_WIDTH,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          overflowX: 'hidden',
          width: NAV_DRAWER_WIDTH,
          boxSizing: 'border-box',
          borderRight: 'none',
        },
      }}
    >
      <AppBarOffset />
      <Box sx={{ width: NAV_DRAWER_WIDTH, flexShrink: 0 }}><WorkspaceDetailMenu /></Box>
      <Box sx={{ flex: 1 }} />
      <List disablePadding >
        <Divider />
        <ListItemButton
          onClick={handleOpen}
          sx={{
            pl: 4.3,
          }}
        >
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Support" />
        </ListItemButton>
        <Menu
          id="support-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 1,
            sx: {
              borderLeft: `1px solid ${theme.palette.divider}`,
              borderRadius: 0,
              boxShadow: 'none',
              minWidth: 200,
              mt: 2,
            },
          }}
        >
          <MenuItem
            onClick={handleClose}
            component={Link}
            href={`//${config.documentationDomain}/docs/intro/`}
            target="_blank"
            rel="noreferrer noopener"
            sx={{ height: 40 }}
          >
              Documentation
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            component={Link}
            href='mailto:support@koverse.com'
            sx={{ height: 40 }}
          >
              Contact Us
          </MenuItem>
        </Menu>
      </List>
    </Drawer>
  );
}

export default NavigationDrawer;
