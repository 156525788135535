import { useKoverse, usePaginatedService } from '@koverse/react';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import times from 'lodash/times';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Attribute } from '../../declarations';
import AssignUsersToAttributes from '../Dialogs/AssignUsersToAttributes';
import DeleteAttribute from '../Dialogs/DeleteAttribute';
import EditAttribute from '../Dialogs/EditAttribute';
import AttributesTableRow from './AttributesTableRow';

interface FieldCell {
  colSpan: number;
  enableSorting: boolean;
  field: string;
  label: string;
  width: number;
}

const AttributesTable = (): React.ReactElement => {
  const { client, workspace } = useKoverse();
  const { t } = useTranslation();
  const [attributeToAssign, setAttributeToAssign] = React.useState<Attribute | null>(null);
  const [attributeToEdit, setAttributeToEdit] = React.useState<Attribute | null>(null);
  const [attributeToDelete, setAttributeToDelete] = React.useState<Attribute | null>(null);
  const [sortField, setSortField] = useState('name');
  const [sortDir, setSortDir] = useState(1);
  const [query, setQuery] = useState('');

  const fields: FieldCell[] = [{
    label: t('pages.attribute.columnNames.name'),
    field: 'name',
    enableSorting: true,
    colSpan: 1,
    width: 300,
  }, {
    label: t('pages.attribute.columnNames.token'),
    field: 'token',
    enableSorting: true,
    colSpan: 1,
    width: 200,
  }, {
    label: t('pages.attribute.columnNames.users'),
    field: 'users',
    enableSorting: false,
    colSpan: 2,
    width: 200,
  }];

  const $or = query ? {
    $or: ['name', 'token'].map((field: string) => ({
      [field]: { $iLike: `%${query}%` },
    })),
  } : undefined;

  const {
    items: attributes,
    loading,
    currentPage,
    total,
    limit,
    setLimit,
    setCurrentPage,
    fetchList,
  } = usePaginatedService('attributes', {
    attributeAssignments: true,
    $sort: { [sortField]: sortDir },
    $limit: 5,
    $or,
    workspaceId: workspace.id,
  });

  const toAssign: Attribute = React.useMemo(() => {
    return attributeToAssign?.id
      ? attributes.find(d => attributeToAssign?.id === d.id)
      : null;
  }, [attributeToAssign, attributes]);

  const handleRequestSort = (field: string) => () => {
    const isAsc = sortField === field && sortDir === 1;
    setSortDir(isAsc ? -1 : 1);
    setSortField(field);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setLimit(+event.target.value);
  };

  React.useEffect(() => {
    client.service('attributes').on('created', fetchList);
    client.service('attributes').on('removed', fetchList);
    client.service('attributes').on('patched', fetchList);
    client.service('attributes').on('updated', fetchList);

    return function cleanUp() {
      client.service('attributes').off('created', fetchList);
      client.service('attributes').off('removed', fetchList);
      client.service('attributes').off('patched', fetchList);
      client.service('attributes').off('updated', fetchList);
    };
  }, [client, fetchList]);

  return (
    <>
      <Paper>
        <Box sx={{ ml: 2, mt: 4, mb: 1 }}>
          <TextField
            hiddenLabel
            id="search-attributes"
            variant="filled"
            size="small"
            margin="none"
            sx={{
              width: 400,
              '& .MuiFilledInput-root.MuiFilledInput-underline': {
                borderRadius: '4px',
              },
              [`& .MuiFilledInput-root.MuiFilledInput-underline:before,
              .MuiFilledInput-root.MuiFilledInput-underline:hover:before,
              .MuiFilledInput-root.MuiFilledInput-underline:after`]: {
                border: 'none',
              },
            }}
            placeholder={t('forms.placeholders.search')}
            value={query}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider />
        <TableContainer>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                {fields.map((f) => (
                  <TableCell
                    key={f.field}
                    variant="head"
                    align="left"
                    colSpan={f.colSpan}
                    width={f.width}
                  >
                    {f.enableSorting ? (
                      <TableSortLabel
                        active={sortField === f.field}
                        direction={sortDir === 1 ? 'asc' : 'desc'}
                        onClick={handleRequestSort(f.field)}
                        aria-label={f.label}
                      >
                        {f.label}
                        {sortField === f.field ? (
                          <span
                            style={{
                              border: 0,
                              clip: 'rect(0 0 0 0)',
                              height: 1,
                              margin: -1,
                              overflow: 'hidden',
                              padding: 0,
                              position: 'absolute',
                              top: 20,
                              width: 1,
                            }}
                          >
                            {sortDir === 1 ? 'sorted ascending' : 'sorted descending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    ) : f.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                {times(limit, (index) => (
                  <TableRow
                    key={index}
                    aria-label="placeholder-while-loading-row"
                    sx={{ height: 77 }}
                  >
                    <TableCell>
                      <Skeleton height={24} width={200} />
                    </TableCell>
                    <TableCell>
                      <Skeleton height={24} width={75} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="circular">
                        <Avatar />
                      </Skeleton>
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton
                        height={24}
                        width={24}
                        variant="circular"
                        sx={{ display: 'inline-table', mr: 1 }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {attributes.map((attribute) => (
                  <AttributesTableRow
                    attribute={attribute}
                    key={attribute.id}
                    handleAssign={setAttributeToAssign}
                    handleEdit={setAttributeToEdit}
                    handleDelete={setAttributeToDelete}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            p: 1,
          }}
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={(currentPage - 1)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {!!attributeToEdit && (
        <EditAttribute
          attribute={attributeToEdit}
          open={!!attributeToEdit}
          onClose={() => setAttributeToEdit(null)}
        />
      )}
      {!!toAssign && (
        <AssignUsersToAttributes
          attribute={toAssign}
          open={!!toAssign}
          onClose={() => setAttributeToAssign(null)}
        />
      )}
      {!!attributeToDelete && (
        <DeleteAttribute
          attribute={attributeToDelete}
          open={!!attributeToDelete}
          onClose={() => setAttributeToDelete(null)}
        />
      )}
    </>
  );
};

export default AttributesTable;
