import React, { SyntheticEvent } from 'react';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { Dataset, Index } from '../../../declarations';

interface Option {
  name: string;
  type: string;
}

interface AddIndexedFieldProps {
  dataset: Dataset;
  indexes: Index[];
  setIndexes: React.Dispatch<React.SetStateAction<Index[]>>;
  open: boolean;
  onClose: () => void;
}

const AddIndexedField = ({
  dataset,
  indexes,
  onClose,
  open,
  setIndexes,
}: AddIndexedFieldProps): React.ReactElement | null => {
  const [options, setOptions] = React.useState<Option[]>([]);
  const [value, setValue] = React.useState<Option | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (dataset.schema) {
      const options = Object.keys(dataset.schema).map((option): Option => ({
        name: option,
        type: dataset.schema[option],
      }));
      setOptions(options);
    }
    return () => {
      setValue(null);
    };
  }, [dataset]);

  const handleOnClick = () => {
    if (value) {
      const index = {
        name: value.name,
        datasetId: dataset.id,
        fields: [value.name],
      };
      const dupes = indexes.find(d => d.name === index.name);
      if (dupes) {
        setError('This field is already indexed');
      } else {
        setIndexes([index as Index, ...indexes]);
        setValue(null);
        onClose();
      }
    }
  };

  React.useEffect(() => {
    if (value) {
      setError(null);
    }
  }, [value]);

  if (!dataset) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add an index</DialogTitle>
      <DialogContent sx={{ minHeight: 250 }}>
        <Autocomplete
          value={value}
          id="indexes-search"
          blurOnSelect
          options={options}
          onChange={(event: SyntheticEvent<unknown>, newValue: Option | null) => {
            setValue(newValue);
          }}
          getOptionLabel={(option) => option.name}
          getOptionDisabled={(option) => option.type === 'java.lang.Boolean'}
          noOptionsText="No results"
          renderInput={(params) => (
            <TextField
              {...params}
              id="field"
              fullWidth
              margin="normal"
              InputLabelProps={{
                htmlFor: 'field',
              }}
              label="Select a field to index"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          renderOption={(props, option) => (
            <ListItem {...props}>
              <ListItemText
                primary={option.name}
                secondary={option.type === 'java.lang.Boolean'
                  ? 'boolean not currently supported'
                  : null
                }
              />
            </ListItem>
          )}
          ListboxProps={{
            style: { maxHeight: 200 },
          }}
        />
        {!!error && (
          <Alert
            severity="error"
            variant="outlined"
            sx={{
              width: '100%',
              mt: 2,
            }}
          >
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          disabled={!value || !!error}
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleOnClick}
        >
          Add Index
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddIndexedField;
