import Box from '@mui/material/Box';
import DocIcon from '@mui/icons-material/DescriptionOutlined';
import React from 'react';
import Image from 'mui-image';
import { Document, Page, pdfjs } from 'react-pdf';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Card from '@mui/material/Card';
import { useServiceItem } from '@koverse/react';
import VideoPlayer from '../../VideoPlayer';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  mimeType?: string;
  open?: () => void;
  uri: string;
  onSuccess?: ({ numPages }: { numPages: number }) => void;
  pageNumber: number;
  scale?: number;
  setPageNumber?: React.Dispatch<React.SetStateAction<number>>;
  datasetId?: string;
  documentId?: string;
}

const DocumentView = ({
  open,
  onSuccess,
  pageNumber,
  scale,
  uri,
  setPageNumber,
  mimeType,
  datasetId,
  documentId,
}: Props): React.ReactElement => {
  const isThumbnail = React.useMemo(() => !!open, [open]);
  React.useEffect(() => {
    if (!!setPageNumber) {
      setPageNumber(1);
    }
  }, [setPageNumber]);

  const {
    item,
  } = useServiceItem('serve-media', datasetId || '', { documentId: documentId });

  const FullError = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 30,
      }}
    >
      <ErrorIcon sx={{ color: theme => theme.palette.text.secondary, mb: 3 }} />
      <Typography color="textSecondary" gutterBottom>
        There was an issue viewing this document.
      </Typography>
      <Typography color="textSecondary" sx={{ mb: 4 }}>
        Download the document to view it on your device.
      </Typography>
    </Box>
  );

  const ThumbError = (
    <Box
      sx={{
        mt: 10,
      }}
    >
      <DocIcon
        sx={{
          color: theme => {
            return theme.palette.mode === 'dark'
              ? theme.palette.grey[500]
              : theme.palette.text.disabled;
          },
          fontSize: 80,
        }}
      />
    </Box>
  );

  if (mimeType?.startsWith('image/')) {
    return isThumbnail ? (
      <CardMedia
        component="img"
        height="260"
        image={uri}
        alt="document image thumbnail"
        onClick={open}
        sx={{ cursor: 'pointer' }}
      />
    ) : (
      <Box
        sx={{
          m: 2,
          overflow: 'auto',
        }}
      >
        <Image
          src={uri}
          alt="document image"
          duration={500}
          wrapperStyle={{ height: 'auto', width: 'auto' }}
        />
      </Box>
    );
  }

  if (mimeType?.startsWith('video/')) {
    return isThumbnail ? (
      <Card
        sx={{
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <PlayCircleFilledWhiteIcon
          onClick={open}
          sx={{
            position: 'absolute',
            display: 'flex',
            color: (theme) => theme.palette.common.black,
            opacity: 0.5,
            background: theme => theme.palette.grey[300],
            height: 50,
            width: 50,
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          aria-label="Play"
        />
        <CardMedia
          component={!!uri ? 'img' : 'div'}
          height="260"
          image={uri}
          alt="document video thumbnail"
          data-testid="Video"
          onClick={open}
          sx={{
            cursor: 'pointer',
            background: (theme) => theme.palette.common.black,
            backgroundPosition: 'center',
            ...(!uri && {
              height: '260px',
              width: '100%',
            }),
          }}
        />
      </Card>
    ) : (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          overflow: 'auto',
        }}
      >
        {!item
          ? <LinearProgress />
          : (
            <Container maxWidth="lg">
              <VideoPlayer videoUrl={item?.url} />
            </Container>
          )
        }
      </Box>
    );
  }

  return isThumbnail ? (
    <CardContent
      onClick={open}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        cursor: 'pointer',
        p: 0,
        background: (theme) => theme.palette.common.white,
        height: 260,
      }}
    >
      <div className="main">
        <Document
          file={uri}
          noData={ThumbError}
          error={ThumbError}
        >
          <Page
            pageNumber={1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            scale={0.33}
          />
        </Document>
      </div>
    </CardContent>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: `calc(100vh - 192px)`,
      }}
    >
      <div className="main" style={{ overflow: 'auto' }}>
        <Document
          file={uri}
          onLoadSuccess={onSuccess}
          noData={FullError}
          error={FullError}
        >
          <Page
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            scale={scale}
          />
        </Document>
      </div>
    </Box>
  );
};

export default DocumentView;
