import React from 'react';
import {
  Discover,
  Icon,
  Mastercard,
  Visa,
} from '@icons-pack/react-simple-icons';
import CardIcon from '@mui/icons-material/CreditCard';

interface Props {
  card: {
    brand: string,
  }
}

const CardLogo = ({ card }: Props): React.ReactElement => {
  const icons: Record<string, Icon> = {
    discover: Discover,
    mastercard: Mastercard,
    visa: Visa,
  };

  const Icon = icons[card?.brand] || CardIcon;
  return (
    <Icon />
  );
};

export default CardLogo;
