import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import React from 'react';
import { User } from '../../../declarations';
import { useAutocomplete } from '../../../hooks';

interface Props {
  currentIds?: string[];
  onSelect: (data: User | null) => void;
  disabled?: boolean;
}

const UserSearch = ({
  currentIds,
  onSelect,
  disabled,
}: Props): React.ReactElement => {
  const {
    open,
    setOpen,
    value,
    setValue,
    inputValue,
    setInputValue,
    options,
    setCurrentIds,
    loading,
  } = useAutocomplete({
    serviceUrl: 'users',
    fields: ['email', 'firstName', 'lastName'],
    clearOnSelect: true,
  });

  React.useEffect(() => {
    if (!!currentIds) {
      setCurrentIds(currentIds);
    }
  }, [currentIds, setCurrentIds]);

  return (
    <Autocomplete
      disabled={disabled}
      id="search-for-users"
      blurOnSelect
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options as User[]}
      getOptionLabel={(options: User) => options.email}
      filterOptions={(data) => data}
      noOptionsText="No Results"
      value={value as User}
      onChange={(_event: React.ChangeEvent<unknown>, newValue: User | null) => {
        onSelect(newValue);
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_event: React.ChangeEvent<unknown>, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id="search-for-users-text-field"
          fullWidth
          InputLabelProps={{
            htmlFor: 'search-for-users',
          }}
          name="search-for-users"
          margin="normal"
          label="Add users (optional)"
          placeholder="Select users to add to your group"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      PaperComponent={(props) => <Paper {...props} elevation={4} />}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText
            primary={option.displayName}
            secondary={option.displayName !== option.email
              ? option.email
              : undefined
            }
          />
        </ListItem>
      )}
    />
  );
};

export default UserSearch;
