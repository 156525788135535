import React from 'react';
import get from 'lodash/get';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { ThirdPartyApplication } from '../../declarations';
import { useCopyToClipboard } from '../../hooks';
import { Button } from '@mui/material';
import { useKoverse } from '@koverse/react';

interface Props {
  application: ThirdPartyApplication;
  hidden?: boolean;
  allowReset?: boolean;
}

interface RevealText {
  id: boolean;
  secret: boolean;
}

const ApplicationSecrets = ({ application, hidden = false, allowReset = false }: Props): React.ReactElement => {
  const { client } = useKoverse();
  const { copyToClipboard } = useCopyToClipboard();
  const [revealText, setRevealText] = React.useState<RevealText>({
    id: false,
    secret: false,
  });
  const handleRevealText = (name: string, reveal: boolean) => {
    setRevealText({
      ...revealText,
      [name]: reveal,
    });
  };

  const resetSecret = async () => {
    await client.service('applications').patch(application.id, {
      resetSecret: true,
    });
  };

  return (
    <Box>
      <Stack direction="row">
        <TextField
          sx={{
            '& input.Mui-disabled': {
              color: t => t.palette.text.primary,
              WebkitTextFillColor: t => t.palette.text.primary,
            },
            maxWidth: 500,
            mb: 2,
          }}
          name="clientId"
          type={hidden && !revealText.id ? 'password' : 'text'}
          variant="outlined"
          fullWidth
          label="Client Id"
          value={get(application, 'clientId')}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => copyToClipboard(get(application, 'clientId'))}
                  size="large">
                  <CopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={() => handleRevealText('id', !revealText.id)}
          sx={{ mt: 1, mb: 3, ml: 1 }}
        >
          {revealText.id ? 'Hide' : 'Show'}
        </Button>
      </Stack>
      <Stack direction="row">
        <TextField
          sx={{
            '& input.Mui-disabled': {
              color: t => t.palette.text.primary,
              WebkitTextFillColor: t => t.palette.text.primary,
            },
            maxWidth: 500,
            mb: 2,
          }}
          name="clientSecret"
          type={hidden && !revealText.secret ? 'password' : 'text'}
          variant="outlined"
          fullWidth
          disabled
          label="Client Secret"
          value={get(application, 'clientSecret')}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => copyToClipboard(get(application, 'clientSecret'))}
                  size="large">
                  <CopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={() => handleRevealText('secret', !revealText.secret)}
          sx={{ mt: 1, mb: 3, ml: 1 }}
        >
          {revealText.secret ? 'Hide' : 'Show'}
        </Button>
      </Stack>
      {allowReset && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={resetSecret}
          >
            Reset Secret
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ApplicationSecrets;
