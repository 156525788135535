import { useKoverse, usePaginatedService, useServiceItem } from '@koverse/react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import React from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Dataset, DatasetPermission, Job } from '../../declarations';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import useQueryParams from '../../hooks/useQueryParams';
import DatasetIngestDrawer from '../DatasetIngestDrawer';
import DatasetDetails from './DatasetDetails';
import Header from './Header';
import IndexSettings from './IndexManagement/IndexSettings';
import JobHistory from './JobHistory';
import JobStatus from './JobStatus';
import Permissions from './Permissions';

interface Params {
  id: string;
  workspaceId: string;
}

const DatasetDetailsCard = (): React.ReactElement | null => {
  const [clearDataset, setClearDataset] = React.useState<boolean>(false);
  const { client, workspace } = useKoverse();
  const { id } = useParams<Params>();
  const { queryParams, setQueryParam } = useQueryParams();
  const isDatasetRoute = useRouteMatch('/datasets/:id');
  const { copyToClipboard } = useCopyToClipboard();

  const {
    items,
    fetchList,
    initialized: initializedJobs,
  } = usePaginatedService('jobs', {
    datasetId: id,
    status: {
      $in: ['queued', 'running', 'failed'],
    },
    dismissed: false,
    $limit: -1,
  });

  const jobs = items as Job[];

  React.useEffect(() => {
    client.service('jobs').on('patched', fetchList);
    client.service('jobs').on('updated', fetchList);

    return function cleanUp() {
      client.service('jobs').off('patched', fetchList);
      client.service('jobs').off('updated', fetchList);
    };
  }, [client, fetchList]);

  const {
    fetchItem: fetchDataset,
    item,
    initialized: initializedDataset,
  } = useServiceItem('datasets', id);

  const dataset = item as Dataset;

  const {
    item: owner,
    initialized: initializedOwner,
  } = useServiceItem('users', get(dataset, 'userId'));

  const { loading, initialized } = React.useMemo(() => {
    const initialized = initializedDataset && initializedJobs && initializedOwner;
    const loading = !initialized || !dataset || !workspace || !owner;
    return {
      loading,
      initialized,
    };
  }, [dataset, initializedDataset, initializedJobs, initializedOwner, owner, workspace]);

  React.useEffect(() => {
    const maybeFetch = (perm: DatasetPermission) => {
      if (perm.datasetId === id) {
        fetchDataset();
      }
    };
    client.service('dataset-permissions').on('created', maybeFetch);
    client.service('dataset-permissions').on('removed', maybeFetch);
    client.service('dataset-permissions').on('patched', maybeFetch);
    client.service('dataset-permissions').on('updated', maybeFetch);

    return function cleanUp() {
      client.service('dataset-permissions').off('created', maybeFetch);
      client.service('dataset-permissions').off('removed', maybeFetch);
      client.service('dataset-permissions').off('patched', maybeFetch);
      client.service('dataset-permissions').off('updated', maybeFetch);
    };
  }, [client, fetchList, id, fetchDataset]);

  const jobIsRunning = React.useMemo(() => {
    const running = jobs?.filter(job => ['running', 'queued'].includes(job.status));
    return !!running.length;
  }, [jobs]);

  if (!id) {
    return null;
  }

  return (
    <Stack
      direction="column"
    >
      <Card>
        {loading && (
          <>
            <CardHeader
              title={<Skeleton width={100} />}
              action={<Skeleton sx={{ width: 110, height: 32, mr: 1 }} />}
              aria-label="placeholder-while-loading-card"
            />
            <CardContent
              sx={{
                pt: 0,
              }}
            >
              <Typography variant="h5"><Skeleton width={100} /></Typography>
              <Typography variant="caption"><Skeleton width={250} /></Typography>
              <Skeleton variant="rectangular" sx={{ mt: 3.5, mb: 2.5, height: 40, width: 450 }} />
            </CardContent>
          </>
        )}
        {initialized && !dataset && (
          <CardContent
            sx={{
              '&:last-child': {
                paddingBottom: 2,
              },
            }}
          >
            <Typography variant="h5">Dataset Not Found</Typography>
          </CardContent>
        )}
        {initialized && !loading && (
          <>
            <Header
              dataset={dataset}
              workspace={workspace}
              openUploadDrawer={() => setQueryParam('upload', true)}
              setClearDataset={setClearDataset}
              clearDataset={clearDataset}
              disableDataActions={jobIsRunning}
            />
            {!!jobs.length && jobs.map((job: Job) => {
              return (
                <JobStatus
                  key={job.id}
                  job={job}
                  retryClearDataset={setClearDataset}
                />
              );
            })}
            {jobIsRunning && (
              <Box sx={{ px: 2, mb: 4 }}>
                <Alert
                  severity="warning"
                  variant="outlined"
                >
                  A job is currently running. Changes cannot be made at this time.
                </Alert>
              </Box>
            )}
            <CardContent
              sx={{
                flex: 1,
                padding: 0,
                '&:last-child': {
                  paddingBottom: 0,
                },
              }}
            >
              <Switch>
                {dataset?.currentUserPermissions?.manage && (
                  <Route
                    path={'/datasets/:id/permissions'}
                    render={() => (
                      <Permissions dataset={dataset} />
                    )}
                  />
                )}
                {dataset?.currentUserPermissions?.manage && (
                  <Route
                    path={'/datasets/:id/job-history'}
                    render={() => (
                      <JobHistory />
                    )}
                  />
                )}
                {dataset?.currentUserPermissions?.manage && (
                  <Route
                    path={'/datasets/:id/index-settings'}
                    render={() => (
                      <IndexSettings dataset={dataset} jobs={jobs} />
                    )}
                  />
                )}
                {jobIsRunning && !!isDatasetRoute && !!queryParams.upload && (
                  <Redirect to={`/datasets/${id}`} />
                )}
                <Route
                  path={'/datasets/:id'}
                  exact
                  render={() => (
                    <>
                      <Box sx={{ px: 2, mb: 2 }}>
                        <Typography variant="h5" sx={{ wordWrap: 'break-word' }}>{dataset.name}</Typography>
                        <Stack direction="row" style={{
                          alignItems: 'center',
                        }}>
                          <Typography
                            color="textSecondary" variant="body1">ID: {dataset.id ? <span data-testid="data-set-id">{dataset.id}</span> : ''}</Typography>
                          <Tooltip title="Copy Dataset ID">
                            <IconButton
                              color="primary"
                              onClick={() => copyToClipboard(dataset.id, 'Copied Dataset ID')}
                              size="large"
                              aria-label="Copy Dataset ID to clipboard"
                            >
                              <ContentCopyIcon />
                            </IconButton>

                          </Tooltip>
                        </Stack>
                        <Box>
                          <Typography color="textSecondary" variant="caption" sx={{ fontStyle: 'italic' }}>
                            Created by: {owner?.displayName}
                          </Typography>
                          <Typography color="textSecondary" variant="caption">&nbsp;&nbsp;|&nbsp;&nbsp;</Typography>
                          <Typography color="textSecondary" variant="caption" sx={{ fontStyle: 'italic' }}>
                            Last updated: {DateTime.fromISO(dataset?.updatedAt).toRelative()}
                          </Typography>
                          {!!dataset.description && (
                            <Accordion
                              disableGutters
                              sx={{
                                boxShadow: 0, color: t => t.palette.text.secondary,
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: t => t.palette.text.secondary }} />}
                                aria-controls="Dataset Description"
                                id="accordion-summary"
                                sx={{
                                  width: 'max-content',
                                  pl: 0,
                                }}
                              >
                                <Typography>Details</Typography>
                              </AccordionSummary>
                              <AccordionDetails sx={{ width: '65%', pl: 0 }}>
                                <Typography>
                                  {dataset.description}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </Box>
                      </Box>
                      <DatasetDetails
                        jobs={jobs}
                        dataset={dataset}
                        openUploadDrawer={() => setQueryParam('upload', true)}
                        disableDataActions={jobIsRunning}
                      />
                    </>
                  )}
                />
                <Redirect to={`/datasets/${id}`} />
              </Switch>
              {dataset?.currentUserPermissions?.write && (
                <DatasetIngestDrawer
                  open={!!queryParams.upload}
                  onClose={() => setQueryParam('upload', undefined)}
                />
              )}
            </CardContent>
          </>
        )}
      </Card>
    </Stack>
  );
};

export default DatasetDetailsCard;
