import React from 'react';
import isUrl from 'is-url-superb';
import toUpper from 'lodash/toUpper';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { User } from '../../declarations';
import config from '../../config';

interface Props {
  avatarProps?: AvatarProps;
  user: User;
}

const UserAvatar = ({ user, avatarProps }: Props): React.ReactElement => {
  if (!user.avatar) {
    return (
      <Avatar
        {...avatarProps}
        aria-label={`user-avatar-${user.displayName}`}
      >
        {toUpper(user?.firstName[0])}
      </Avatar>
    );
  }

  const source = user.avatar && isUrl(user.avatar)
    ? user.avatar
    : `//${config.api}${user.avatar}`;

  return (
    <Avatar
      {...avatarProps}
      aria-label={`user-avatar-${user.displayName}`}
      alt={`user-avatar-${user.displayName}`}
      src={source}
    />
  );
};

export default UserAvatar;
