import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import has from 'lodash/has';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import AccessControlHeader from '../AccessControlHeader';
import SecurityLabeledInput from '../SecurityLabeledInput';
import SelectParserInput from '../SelectParserInput';
import SecurityFields from '../SecurityFields';
import SecurityLabels from '../SecurityLabels';

interface Props {
  onBack: () => void;
  submissionError?: string;
}

const UnstructuredData = ({
  onBack,
  submissionError,
}: Props): React.ReactElement => {
  const {
    control,
    errors,
    formState,
    trigger,
  } = useFormContext();
  const { isSubmitting, isValid } = formState;

  const enabled = useWatch({
    control,
    name: 'accessControlLabel.securityLabeled',
    defaultValue: null,
  });

  const parser = useWatch({
    control,
    name: 'accessControlLabel.securityLabelInfo.parserClassName',
    defaultValue: '',
  });

  const parserFields = React.useMemo(() => {
    switch (parser) {
      case 'identity-parser':
      case 'simple-parser':
        return <SecurityFields />;
      case 'apply-label-to-all-records-parser':
        return <SecurityLabels />;
      default:
        return null;
    }
  }, [parser]);

  React.useEffect(() => {
    trigger();
  }, [parser, trigger]);

  return (
    <Grid
      container
      direction="column"
      spacing={3}
    >
      <Grid item>
        <AccessControlHeader />
      </Grid>
      {isSubmitting && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      <Grid item>
        <SecurityLabeledInput />
      </Grid>
      {!!enabled && (
        <>
          <Grid item>
            <Typography sx={{ mb: 2 }}>1. How do you want to handle your Attributes?</Typography>
            <SelectParserInput />
          </Grid>
          {parserFields}
        </>
      )}
      {submissionError && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{
            mt: 3,
          }}
        >
          {submissionError}
        </Alert>
      )}
      <Grid item sx={{ mt: 2, textAlign: 'right' }}>
        <Box sx={{ maxWidth: 620 }}>
          <Button
            color="primary"
            sx={{ mr: 2 }}
            aria-label="cancel"
            onClick={onBack}
            disabled={isSubmitting}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={
              has(errors, 'accessControlLabel')
              || enabled === null
              || !isValid
              || isSubmitting
            }
          >
            Import Data
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UnstructuredData;
