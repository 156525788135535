import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React from 'react';

interface SettingsParams {
  disableDataActions: boolean;
  handleClearDataset: (data: boolean) => void;
  handleDeleteDataset: (data: boolean) => void;
  handleNavigation: (data: string) => void;
  handleOpenUploadDrawer: () => void;
  handleRename: (data: boolean) => void;
}

const SettingsButtonMenu = ({
  disableDataActions,
  handleClearDataset,
  handleDeleteDataset,
  handleNavigation,
  handleOpenUploadDrawer,
  handleRename,
}: SettingsParams): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLDivElement | null>(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box ref={anchorRef}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          aria-controls={open ? 'button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="open-settings-menu"
          aria-haspopup="menu"
          onClick={handleToggle}
          endIcon={<ArrowDropDownIcon />}
        >
          Settings
        </Button>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={4}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem
                    divider
                    onClick={() => {
                      handleRename(true);
                      setOpen(false);
                    }}
                  >
                    Rename
                  </MenuItem>
                  <MenuItem
                    divider
                    onClick={() => {
                      handleNavigation('index-settings');
                      setOpen(false);
                    }}
                  >
                    Index Settings
                  </MenuItem>
                  <MenuItem
                    divider
                    onClick={() => {
                      handleNavigation('permissions');
                      setOpen(false);
                    }}
                  >
                    Permissions
                  </MenuItem>
                  <MenuItem
                    divider
                    onClick={() => {
                      handleNavigation('job-history');
                      setOpen(false);
                    }}
                  >
                    Job History
                  </MenuItem>
                  <MenuItem
                    divider
                    onClick={() => {
                      handleOpenUploadDrawer();
                      setOpen(false);
                    }}
                    disabled={disableDataActions}
                  >
                    Add Data
                  </MenuItem>
                  <MenuItem
                    divider
                    onClick={() => {
                      handleClearDataset(true);
                      setOpen(false);
                    }}
                    disabled={disableDataActions}
                  >
                    Clear Dataset
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDeleteDataset(true);
                      setOpen(false);
                    }}
                    disabled={disableDataActions}
                  >
                    Delete Dataset
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SettingsButtonMenu;
