import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper, { PaperProps } from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSubscription from '../../hooks/useSubscription';
import useUpcomingInvoice from '../../hooks/useUpcomingInvoice';
import CpuUsage from './CpuUsage';
import StorageUsage from './StorageUsage';

const StyledPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  padding: theme.spacing(3),
  borderRight: `2px solid ${theme.palette.background.default}`,
  height: '100%',
}));

const PlanDetails = (): React.ReactElement => {
  const { plan, loading: loadingSubscription } = useSubscription();
  const { currentUsage, loading: loadingInvoice, loadingCurrentUsage } = useUpcomingInvoice();
  const loading = loadingSubscription || loadingInvoice;
  const { t } = useTranslation();

  return (
    <Grid container spacing={0}
      direction="row"
      alignItems="stretch"
    >
      <Grid item xs={12} sm={4}>
        <StyledPaper square>
          <Typography
            color="textSecondary"
            sx={{ mb: 2 }}
          >
            {t('pages.billing.plan.details')}
          </Typography>
          <Typography fontWeight={700} gutterBottom>
            {!plan ? <Skeleton /> : plan.subscriptionLevel}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
          >
            <Stack direction="column">
              <Typography>{t('common.cpu')}</Typography>
              {currentUsage?.compute?.hasOverages && (
                <Typography gutterBottom variant="caption">{`${t('common.cpu')} ${t('common.overage')}`}</Typography>
              )}
              <Typography>{t('common.storage')}</Typography>
              {currentUsage?.storage?.hasOverages && (
                <Typography gutterBottom variant="caption">{`${t('common.storage')} ${t('common.overage')}`}</Typography>
              )}
              <Typography>{t('common.egress')}</Typography>
              {currentUsage?.network?.hasOverages && (
                <Typography gutterBottom variant="caption">{`${t('common.egress')} ${t('common.overage')}`}</Typography>
              )}
            </Stack>
            <Stack direction="column">
              <Typography>
                {loading ? <Skeleton width={50} /> : plan?.compute?.description}
              </Typography>
              {currentUsage?.compute?.hasOverages && (
                <Typography gutterBottom variant="caption">
                  {loading ? <Skeleton width={50} /> : plan?.compute?.overagePrice}
                </Typography>
              )}
              <Typography>
                {loading ? <Skeleton width={50} /> : plan?.storage?.description}
              </Typography>
              {currentUsage?.storage?.hasOverages && (
                <Typography gutterBottom variant="caption">
                  {loading ? <Skeleton width={50} /> : plan?.storage?.overagePrice}
                </Typography>
              )}
              <Typography>
                {loading ? <Skeleton width={50} /> : plan?.network?.description}
              </Typography>
              {currentUsage?.network?.hasOverages && (
                <Typography gutterBottom variant="caption">
                  {loading ? <Skeleton width={50} /> : plan?.network?.overagePrice}
                </Typography>
              )}
            </Stack>
          </Stack>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledPaper square sx={{ borderColor: 'transparent' }}>
          <Typography
            color="textSecondary"
            sx={{ mb: 2 }}
          >
            {t('pages.billing.plan.usage')}
          </Typography>
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                color="textSecondary"
                gutterBottom
              >
                {t('common.cpu')}
              </Typography>
              {loadingCurrentUsage && (
                <Box>
                  <Typography variant="h5"><Skeleton /></Typography>
                  <Skeleton
                    variant="circular"
                    sx={{ height: 175, width: 175, mt: 2 }}
                  />
                </Box>
              )}
              {!loadingCurrentUsage && !!currentUsage?.compute && (
                <>
                  <Typography variant="h5" display="inline" sx={{ mr: 1, fontWeight: 'bold' }}>
                    {currentUsage?.compute.total}
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    {t('common.hours')}
                  </Typography>
                  <CpuUsage />
                </>
              )}
            </Box>
          </Stack>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledPaper square sx={{ borderColor: 'transparent' }}>
          <Box sx={{ mt: 5 }}>
            <Typography
              color="textSecondary"
              gutterBottom
            >
              {t('common.storage')}
            </Typography>
            {loadingCurrentUsage && (
              <Typography variant="h5"><Skeleton width={140} /></Typography>
            )}
            {!loadingCurrentUsage && !!currentUsage?.storage && (
              <>
                <Typography variant="h5" display="inline" sx={{ mr: 1, fontWeight: 'bold' }}>{currentUsage?.storage.total}</Typography>
                <Typography variant="subtitle2" display="inline">{t('common.gb')}</Typography>
                <Box sx={{ mt: 2 }}>
                  <StorageUsage />
                </Box>
              </>
            )}
          </Box>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} sm={2}>
        <StyledPaper square>
          <Box sx={{ mt: 5 }}>
            <Typography
              color="textSecondary"
              gutterBottom
            >
              {t('common.egress')}
            </Typography>
            {loadingCurrentUsage && (
              <Typography variant="h5"><Skeleton width={140} /></Typography>
            )}
            {!loadingCurrentUsage && !!currentUsage?.network && (
              <>
                <Typography variant="h5" display="inline" sx={{ mr: 1, fontWeight: 'bold' }}>{currentUsage?.network.total}</Typography>
                <Typography variant="subtitle2" display="inline">{t('common.gb')}</Typography>
                {currentUsage?.network?.hasOverages && (
                  <Box sx={{ my: 2 }}>
                    <Stack direction="row">
                      <ErrorIcon color="error" sx={{ mr: 1 }} />
                      <Stack direction="column">
                        <Typography variant="caption">{
                          t('pages.billing.subscription.overage', {
                            freeQuantity: currentUsage.network?.freeQuantity,
                            unit: 'GB',
                          })}
                        </Typography>
                        <Typography variant="caption">{t('pages.billing.subscription.applyOverages')}</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                )}
              </>
            )}
          </Box>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default PlanDetails;
