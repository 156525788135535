import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import ParameterInput from './ParameterInput';
import { ParameterGroup } from '../../declarations';

interface Props {
  parameterGroup: ParameterGroup;
}

const ParameterGroupSection = ({ parameterGroup }: Props): React.ReactElement => {
  const label = !!parameterGroup.labelAsOptional
    ? (
      <>
        {`${parameterGroup.label} `}
        <em>(optional)</em>
      </>
    )
    : parameterGroup.label;

  return (
    <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
      {parameterGroup.label && (
        <FormLabel
          component="legend"
          sx={{ color: 'text.primary' }}
        >
          {label}
        </FormLabel>
      )}
      <FormGroup>
        <Stack>
          {parameterGroup.parameters.map((param) => (
            <ParameterInput key={param.name} parameter={param} />
          ))}
        </Stack>
      </FormGroup>
    </FormControl>
  );
};

export default ParameterGroupSection;
