import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import truncate from 'lodash/truncate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

interface BreadCrumbProps {
  breadcrumbMap?: {
    [key: string]: string,
  }
}

const BreadCrumbs = ({
  breadcrumbMap = {
    'applications': 'Applications',
    'billing': 'Billing',
    'change-plan': 'Change Plan',
    'attributes': 'Attributes',
    'groups': 'Manage Groups',
    'users': 'Manage Users',
    'audit-log': 'Audit Log',
    'log-configuration': 'Log Configuration',
  },
}: BreadCrumbProps): React.ReactElement => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const pathnames = pathname.split('/').filter(x => x);

  return (
    <Breadcrumbs aria-label="breadcrumb" separator=">">
      {pathname === '/' ? (
        <Typography color="text.primary" key='/'>
          {t('common.home')}
        </Typography>
      ) : (
        <Link component={RouterLink} underline="none" color="inherit" to="/">
          {t('common.home')}
        </Link>
      )}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        if (breadcrumbMap[value]) {
          return last ? (
            <Typography color="text.primary" key={to}>
              {truncate(breadcrumbMap[value], { length: 25 })}
            </Typography>
          ) : (
            <Link component={RouterLink} underline="none" color="inherit" to={to} key={to}>
              {truncate(breadcrumbMap[value], { length: 25 })}
            </Link>
          );
        }
        return null;
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
