import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import differenceBy from 'lodash/differenceBy';
import React from 'react';
import { Dataset, Index } from '../../../declarations';
import AddIndexedField from '../../Dialogs/AddIndexedField';

interface IndexManagementTableToolbarProps {
  dataset: Dataset;
  disabled: boolean;
  indexes: Index[];
  numSelected: number;
  selected: readonly Index[];
  setSelected: React.Dispatch<React.SetStateAction<readonly Index[]>>
  setIndexes: React.Dispatch<React.SetStateAction<Index[]>>;
}

const IndexManagementTableToolbar = ({
  dataset,
  disabled,
  indexes,
  numSelected,
  selected,
  setSelected,
  setIndexes,
}: IndexManagementTableToolbarProps): React.ReactElement => {
  const [openAddIndexedField, setOpenAddIndexedField] = React.useState<boolean>(false);

  const handleRemoveSelected = () => {
    setIndexes(differenceBy(indexes, selected, 'name'));
    setSelected([]);
  };

  return (
    <>
      <Toolbar
        variant="dense"
        sx={{
          bgcolor: 'inherit',
        }}
      >
        {numSelected > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : null}
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton
              disabled={disabled}
              onClick={handleRemoveSelected}
              sx={{ ml: 2 }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            disabled={disabled}
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpenAddIndexedField(true)}
          >
            Add Index
          </Button>
        )}
      </Toolbar>
      {openAddIndexedField && (
        <AddIndexedField
          dataset={dataset}
          indexes={indexes}
          onClose={() => setOpenAddIndexedField(false)}
          open={openAddIndexedField}
          setIndexes={setIndexes}
        />
      )}
    </>
  );
};

export default IndexManagementTableToolbar;
