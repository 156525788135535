import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import differenceBy from 'lodash/differenceBy';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Group } from '../../../declarations';
import { useGroupMemberships } from '../../../hooks';
import DatasetAccessList from './DatasetAccessList';
import UserFields from './UserFields';

type Inputs = {
  users?: {
    id: string,
    groupRole: string,
    membershipId?: string,
  }[];
};

interface Props {
  group: Group;
  open: boolean;
  onClose: () => void;
}

const EditGroupMembers = ({
  group,
  open,
  onClose,
}: Props): React.ReactElement => {
  const {
    createGroupMemberships,
    updateGroupMemberships,
    removeGroupMemberships,
  } = useGroupMemberships();

  const groupMemberships = React.useMemo(() => {
    return get(group, 'groupMemberships', [])
      .filter((d) => d.userId !== group.userId)
      .map((d) => {
        return {
          ...d.user,
          groupRole: d.role,
          membershipId: d.id,
        };
      });
  }, [group]);

  const owner = React.useMemo(() => group.owner, [group]);

  const methods = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      users: groupMemberships,
    },
  });

  const { formState, handleSubmit } = methods;
  const { isDirty, isSubmitting } = formState;

  const updateGroup = React.useCallback((inputs: Inputs, groupId: string) => {
    const users = get(inputs, 'users', []);
    const memberships = keyBy(groupMemberships, 'id');
    const usersToDelete = differenceBy(groupMemberships, users, 'id') as Inputs['users'];
    const usersToAdd: Inputs['users'] = [];
    const usersToUpdate: Inputs['users'] = [];

    for (const user of users) {
      const currentMember = memberships[user.id];
      if (!currentMember) {
        usersToAdd.push(user);
        continue;
      }
      if (currentMember.groupRole !== user.groupRole) {
        usersToUpdate.push({
          ...user,
          // ensure membership id for patch
          membershipId: currentMember.membershipId,
        });
      }
    }

    if (!!usersToDelete?.length) {
      removeGroupMemberships({ users: usersToDelete, groupName: group.name }, groupId);
    }

    if (!!usersToAdd.length) {
      createGroupMemberships({ users: usersToAdd, groupName: group.name }, groupId);
    }

    if (!!usersToUpdate.length) {
      updateGroupMemberships({ users: usersToUpdate, groupName: group.name }, groupId);
    }

  }, [groupMemberships, removeGroupMemberships, group.name, createGroupMemberships, updateGroupMemberships]);

  const onSubmit = async (data: Inputs) => {
    updateGroup(data, group.id);
    onClose();
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <DialogTitle sx={{ pb: 0 }}>
        Edit users
        <Typography>Add or remove users. Assigned roles will only apply to this group.</Typography>
      </DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DatasetAccessList groupId={group.id} />
            <UserFields owner={owner} loading={isSubmitting} />
          </DialogContent>
          <DialogActions>
            {isSubmitting ? (
              <CircularProgress size={30} />
            ) : (
              <>
                <Button
                  type="button"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isDirty}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Save Changes
                </Button>
              </>
            )}
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default EditGroupMembers;
