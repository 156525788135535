import React, { createContext, useState } from 'react';
import useQueryParams from '../../hooks/useQueryParams';
import { GridRowSelectionModel } from '@mui/x-data-grid';

const initialPromise = async () => { throw new Error('SearchSelectContext has not been initialized'); };

const initialContext = {
  debouncedQuery: '',
  query: '',
  resultIds: [],
  scope: undefined,
  searchId: null,
  selectedIds: [],
  setQuery: initialPromise,
  setResultIds: initialPromise,
  setScope: initialPromise,
  setSelectedIds: initialPromise,
};

export interface SearchSelectProviderOptions {
  children?: React.ReactNode;
}

export interface SearchSelectContextInterface {
  debouncedQuery: string;
  query: string;
  resultIds: string[];
  scope?: string;
  searchId: any;
  selectedIds: any;
  setQuery: (v?: string) => void;
  setResultIds: React.Dispatch<React.SetStateAction<string[]>>;
  setScope: (v?: string) => void;
  setSelectedIds: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
}

export const SearchSelectContext = createContext<SearchSelectContextInterface>(initialContext);

let timeout: NodeJS.Timeout;

export const SearchSelectProvider = ({
  children,
}: SearchSelectProviderOptions): React.ReactElement => {
  const { queryParams, setQueryParam } = useQueryParams();
  const scope = queryParams.scope as string || undefined;
  const setScope = (v: string | undefined) => {
    setQueryParam('scope', v);
  };
  const [query, setQueryState] = useState<string>(queryParams.q as string || '');
  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);
  const [resultIds, setResultIds] = React.useState<string[]>([]);

  const searchId = React.useRef<null | string>(null);

  const setQuery = (q?: string) => {
    setQueryState(q || '');
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      searchId.current = null;
      setQueryParam('q', q || undefined);
    }, 800);
  };

  React.useEffect(() => {
    setQueryState(queryParams.q as string || '');
  }, [setQueryState, queryParams.q]);

  const value = {
    debouncedQuery: queryParams.q as string || '',
    query,
    resultIds,
    scope,
    searchId,
    selectedIds,
    setQuery,
    setResultIds,
    setScope,
    setSelectedIds,
  };

  return (
    <SearchSelectContext.Provider value={value}>
      {children}
    </SearchSelectContext.Provider>
  );
};

export default SearchSelectProvider;
