import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { RadioGroup as RadioGroupInput } from '../../declarations';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import ParameterInput from './ParameterInput';

interface Props {
  parameter: RadioGroupInput;
  name?: string;
  disabled?: boolean;
}

const ParameterRadioGroup = ({
  parameter,
  name,
  disabled,
}: Props): React.ReactElement => {
  const form = useFormContext();
  const { control } = form;
  const inputName = name ? `params.${name}` : `params.${parameter.name}`;
  const selectedValue = useWatch({
    control,
    name: inputName,
  });

  return (
    <Controller
      render={(props) => (
        <RadioGroup
          {...props}
          aria-label={`${name || parameter.name}-${parameter.type}`}
          sx={{
            mt: 2,
          }}
        >
          {parameter.options.map((option, index) => (
            <Box
              key={`${option.value}-${index}`}
            >
              <FormControlLabel
                value={option.value}
                control={<Radio disabled={disabled} color="primary" />}
                label={
                  <>
                    {option.label}
                    {!!option.helperText && (
                      <Typography
                        variant="caption"
                        display="block"
                        color="textSecondary"
                      >
                        {option.helperText}
                      </Typography>
                    )}
                  </>
                }
              />
              {!!option?.parameters && (
                <Box sx={{ ml: 4 }}>
                  {option.parameters.map((param) => {
                    return (
                      <Box key={param.name} sx={{ mb: 1 }}>
                        <ParameterInput
                          parameter={param}
                          disabled={selectedValue !== option.value}
                        />
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          ))}
        </RadioGroup>
      )}
      name={inputName}
      control={control}
      defaultValue={parameter.defaultValue}
    />
  );
};

export default ParameterRadioGroup;
