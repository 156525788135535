import { useServiceItem } from '@koverse/react';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../../declarations';

interface Props {
  job: Job;
}

const ReindexConfig = ({
  job,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    item: dataset,
  } = useServiceItem('datasets', job.datasetId);

  return (
    <TableContainer>
      <Table aria-label="reindex configuration table">
        <TableHead>
          <TableRow>
            <TableCell width={200}>{t('pages.dataset.jobHistory.sources.sourceType')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.autoIndex')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('pages.dataset.jobHistory.sources.types.reindex')}
            </TableCell>
            <TableCell>
              {!dataset
                ? <Skeleton />
                : dataset?.autoCreateIndexes ? 'on' : 'off'
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReindexConfig;
