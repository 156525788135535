import { useKoverse } from '@koverse/react';
import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';
import config from '../config';
import { Cancelable, ServerError } from '../declarations';
import useSearchSelect from './useSearchSelect';
import { makeCancelable } from '../utils';

interface FetchRecords {
  datasetId?: string;
  expression: string;
  limit: number;
  offset: number;
}

interface Payload extends FetchRecords {
  searchId?: React.MutableRefObject<string | null>;
}

const useQueryRequest = (type: 'summary' | 'query' | 'document' | 'auditLogQuery') => {
  const [error, setError] = React.useState<ServerError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [queryResult, setQueryResult] = React.useState<AxiosResponse['data'] | null>(null);
  const { client } = useKoverse();
  const { searchId } = useSearchSelect();
  const request = React.useRef<null | Cancelable>(null);
  const mounted = React.useRef(false);
  const source = React.useRef<any>(null);

  const url = React.useMemo(() => {
    const endpoints = {
      summary: `//${config.api}/query/datasets/summary`,
      query: `//${config.api}/query/lucene?includeInternalFields=true`,
      document: `//${config.api}/query/document/lucene`,
      auditLogQuery: `//${config.api}/audit-log/query`,
    };

    return endpoints[type];
  }, [type]);

  const fetchData = React.useCallback(async ({
    datasetId,
    expression,
    limit,
    offset,
  }: FetchRecords): Promise<void> => {
    if (mounted.current) {
      setLoading(true);
      setError(null);
    }

    const payload: Payload = {
      expression,
      limit,
      offset,
    };

    if (['query', 'document', 'auditLogQuery'].includes(type)) {
      set(payload, 'datasetId', datasetId);
    }

    if (type === 'summary') {
      set(payload, 'searchId', searchId.current);
      searchId.current = null;
    }

    if (request.current) {
      request.current.cancel();
    }

    if (source.current) {
      source.current.cancel();
    }

    try {
      const jwt = await client.authentication.getAccessToken();

      const CancelToken = axios.CancelToken;
      source.current = CancelToken.source();

      request.current = makeCancelable(axios.post(url, payload, {
        headers: { Authorization: `Bearer ${jwt}` },
        cancelToken: source.current.token,
      }));

      if (request.current && request.current.promise) {
        const result = (await request.current.promise) as AxiosResponse;
        request.current = null;

        if (mounted.current) {
          if (type === 'summary') {
            searchId.current = get(result, 'data.searchId', null);
          }
          setQueryResult(get(result, 'data', null));
          setLoading(false);
        }
      }
    } catch (e) {
      if (mounted.current && !get(e, 'isCanceled')) {
        setQueryResult(null);
        setLoading(false);
        setError(get(e, 'response.data', '') as unknown as ServerError);
      }
    }
  }, [client.authentication, searchId, type, url]);

  React.useEffect(() => {
    mounted.current = true;
    return () => {

      if (request && request.current) {
        request.current.cancel();
      }

      if (source && source.current) {
        source.current.cancel();
      }

      mounted.current = false;
    };
  }, []);

  return {
    error,
    fetchData,
    loading,
    queryResult,
  };
};

export default useQueryRequest;
