import React from 'react';
import get from 'lodash/get';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { FieldErrors, UseFormMethods } from 'react-hook-form';
import { useValidatePassword } from '../../hooks';
import CriteriaPopover from './CriteriaPopover';

interface CreatePasswordFieldProps {
  errors: FieldErrors;
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  setError: UseFormMethods['setError'];
  TextFieldProps?: TextFieldProps;
}

const CreatePasswordField = ({
  errors,
  register,
  watch,
  setError,
  TextFieldProps = {},
}: CreatePasswordFieldProps): React.ReactElement => {
  const password = watch('password', '');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showPasswordError, setShowPasswordError] = React.useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);

  const handleCloseTooltip = () => setOpenTooltip(false);
  const handleOpenTooltip = () => setOpenTooltip(true);

  const {
    criteria,
    isValid,
    isMinLength,
  } = useValidatePassword(password);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (!isValid && !get(errors, 'password') && !!password.length) {
      setError('password', {
        type: 'manual',
        message: 'Invalid password',
      });
    }
  }, [isValid, password, errors, setError]);

  return (
    <CriteriaPopover
      open={openTooltip}
      {...{ isMinLength, criteria }}
    >
      <TextField
        InputLabelProps={{
          htmlFor: 'password',
        }}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  size="large">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
        id="password"
        margin="normal"
        label="Password"
        name="password"
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        fullWidth
        error={!!errors.password && showPasswordError}
        helperText={(showPasswordError && errors.password && errors.password.message) || ''}
        inputRef={register({
          required: 'Required',
        })}
        onFocus={handleOpenTooltip}
        onBlur={() => {
          handleCloseTooltip();
          if (!!errors.password) {
            setShowPasswordError(true);
          }
        }}
        {...TextFieldProps}
      />
    </CriteriaPopover>
  );
};

export default CreatePasswordField;
