import React, { createContext, useMemo } from 'react';
import { useKoverse } from '@koverse/react';
import { Subscription, Plan } from '../../declarations';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import config from '../../config';

const initialPromise = async () => { throw new Error('SubscriptionContext has not been initialized'); };

const initialContext = {
  canceling: false,
  cancelSubscription: initialPromise,
  creating: false,
  createSubscription: initialPromise,
  fetchSubscription: initialPromise,
  deleting: false,
  deleteSubscription: initialPromise,
  loading: false,
  paying: false,
  payLatestInvoice: initialPromise,
  reenableSubscription: initialPromise,
  selectedSubscriptionLevelKey: null,
  setSelectedSubscriptionLevelKey: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  updatePaymentMethod: initialPromise,
  updateSubscription: initialPromise,
};

export interface SubscriptionProviderOptions {
  children?: React.ReactNode;
}

export interface SubscriptionContextInterface {
  canceling: boolean,
  cancelSubscription: () => Promise<void>,
  creating: boolean,
  createSubscription: (args: { subscriptionLevel: string, paymentMethod?: string }) => Promise<void>,
  deleting: boolean,
  deleteSubscription: () => Promise<void>,
  error?: string,
  fetchSubscription: () => Promise<void>,
  loading: boolean,
  payLatestInvoice: () => Promise<void>,
  paying: boolean,
  plan?: Plan;
  reenableSubscription: () => Promise<void>,
  selectedSubscriptionLevelKey: string | null,
  setSelectedSubscriptionLevelKey: React.Dispatch<React.SetStateAction<string | null>>,
  subscription?: Subscription,
  updatePaymentMethod: (paymentMethod: string) => Promise<void>,
  updateSubscription: (args: { subscriptionLevel: string }) => Promise<void>,
}

export const SubscriptionContext = createContext<SubscriptionContextInterface>(initialContext);

export const SubscriptionProvider = ({
  children,
}: SubscriptionProviderOptions): JSX.Element => {
  const [canceling, setCanceling] = React.useState<boolean>(false);
  const [creating, setCreating] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<undefined | string>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [paying, setPaying] = React.useState<boolean>(false);
  const [selectedSubscriptionLevelKey, setSelectedSubscriptionLevelKey] = React.useState<string | null>(null);
  const [subscription, setSubscription] = React.useState<Subscription>();
  const { client, user } = useKoverse();
  const stripeService = client.service('stripe');

  const plan = useMemo(() => {
    if (!subscription) {
      return;
    }
    const subscriptionItems = subscription?.items?.data.reduce((acc, item) => {
      return {
        ...acc,
        [item.metadata.productKey]: item,
      };
    }, {});
    const amount = get(subscriptionItems, 'base.price.unit_amount');

    if (!amount) {
      throw new Error('Base Price Unit Amount is undefined');
    }

    const price = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format(amount / 100);
    return {
      subscriptionLevel: capitalize(get(subscriptionItems, 'base.metadata.subscriptionLevel')),
      recurringPrice: `${price}/${get(subscriptionItems, 'base.price.recurring.interval')}`,
      compute: {
        description: get(subscriptionItems, 'compute.price.metadata.description'),
        overagePrice: get(subscriptionItems, 'compute.price.metadata.overage_price'),
      },
      storage: {
        description: get(subscriptionItems, 'storage.price.metadata.description'),
        overagePrice: get(subscriptionItems, 'storage.price.metadata.overage_price'),
      },
      network: {
        description: get(subscriptionItems, 'network.price.metadata.description'),
        overagePrice: get(subscriptionItems, 'network.price.metadata.overage_price'),
      },
    };
  }, [subscription]);

  const fetchSubscription = React.useCallback(async (silent = false, force = false) => {
    if (!stripeService || (!user?.stripeSubscriptionId && !force)) {
      setLoading(false);
      return;
    }
    setError(undefined);
    if (!silent) { setLoading(true); }
    try {
      const result = await stripeService.create({
        action: 'getSubscription',
      });
      setSubscription(result);
    } catch (error) {
      setError(get(error, 'message'));
      throw error;
    }
    if (!silent) { setLoading(false); }
  }, [stripeService, user?.stripeSubscriptionId]);

  const createSubscription = React.useCallback(async ({ subscriptionLevel, paymentMethod }: { subscriptionLevel: string, paymentMethod?: string }) => {
    if (!stripeService) { return; }
    setError(undefined);
    setCreating(true);
    try {
      await stripeService.create({
        action: 'createSubscription',
        subscriptionLevel,
        paymentMethod,
      });
      await fetchSubscription(true, true);
    } catch (error) {
      setError(get(error, 'message'));
      throw error;
    }
    setCreating(false);
  }, [fetchSubscription, stripeService]);

  const cancelSubscription = React.useCallback(async () => {
    if (!stripeService) { return; }
    setError(undefined);
    setCanceling(true);
    try {
      await stripeService.create({
        action: 'cancelSubscription',
      });
      await fetchSubscription(true);
    } catch (error) {
      setError(get(error, 'message'));
      throw error;
    }
    setCanceling(false);
  }, [fetchSubscription, stripeService]);

  const deleteSubscription = React.useCallback(async () => {
    if (!stripeService) { return; }
    setError(undefined);
    setDeleting(true);
    try {
      await stripeService.create({
        action: 'deleteSubscription',
      });
      await fetchSubscription(true);
    } catch (error) {
      setError(get(error, 'message'));
      throw error;
    }
    setDeleting(false);
  }, [fetchSubscription, stripeService]);

  const payLatestInvoice = React.useCallback(async () => {
    if (!subscription) { return; }
    setPaying(true);
    if (subscription?.latest_invoice?.status !== 'open') {
      throw new Error('cannot pay the latest invoice');
    }
    try {
      await client.service('stripe').create({
        action: 'payInvoice',
        invoiceId: subscription?.latest_invoice?.id,
      });
      await fetchSubscription(true);
    } catch (error) {
      setError(get(error, 'message'));
      throw error;
    }
    setPaying(false);
  }, [client, fetchSubscription, subscription]);

  const reenableSubscription = React.useCallback(async () => {
    if (!subscription) { return; }
    if (!subscription?.cancel_at_period_end) {
      throw new Error('Subscription has not been canceled');
    }
    try {
      await client.service('stripe').create({
        action: 'reenableSubscription',
      });
      await fetchSubscription(true);
    } catch (error) {
      setError(get(error, 'message'));
      throw error;
    }
  }, [client, fetchSubscription, subscription]);

  const updatePaymentMethod = React.useCallback(async (paymentMethod) => {
    if (!subscription) { return; }
    try {
      await client.service('stripe').create({
        action: 'updateSubscriptionPaymentMethod',
        paymentMethod,
      });
      await fetchSubscription(true);
    } catch (error) {
      setError(get(error, 'message'));
      throw error;
    }
  }, [client, fetchSubscription, subscription]);

  const updateSubscription = React.useCallback(async ({ subscriptionLevel }: { subscriptionLevel: string }) => {
    if (!stripeService) { return; }
    setError(undefined);
    setLoading(true);
    try {
      await stripeService.create({
        action: 'updateSubscription',
        subscriptionLevel,
      });
      await fetchSubscription(true, true);
    } catch (error) {
      setError(get(error, 'message'));
      throw error;
    }
    setLoading(false);
  }, [fetchSubscription, stripeService]);

  React.useEffect(() => {
    if (config.stripePublishableKey) {
      fetchSubscription();
    }
  }, [fetchSubscription, user?.subscriptionId]);

  const value = {
    canceling,
    cancelSubscription,
    creating,
    createSubscription,
    deleting,
    deleteSubscription,
    error,
    fetchSubscription,
    loading,
    payLatestInvoice,
    paying,
    plan,
    reenableSubscription,
    selectedSubscriptionLevelKey,
    setSelectedSubscriptionLevelKey,
    subscription,
    updatePaymentMethod,
    updateSubscription,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
