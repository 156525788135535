import Box from '@mui/material/Box';
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridColDef,
  GridColType,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowModel,
  PaginationPropsOverrides,
  gridPageSizeSelector,
  gridPaginationModelSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import get from 'lodash/get';
import isString from 'lodash/isString';
import map from 'lodash/map';
import pluralize from 'pluralize';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Dataset, KoverseDataTypes, PaginationModel, QueryResult } from '../../declarations';
import useSearchSelect from '../../hooks/useSearchSelect';
import GridCellOverflow from './DataGridCellOverflow';

interface DatasetGridViewProps {
  dataset: Dataset;
  disableDataActions: boolean;
  queryResult: QueryResult;
  loading: boolean;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
}

type GridColTypes = {
  [key in KoverseDataTypes]: GridColType;
};

type PaginationProps = {
  search: string;
  queryResult: QueryResult;
};

const ColTypeDefs: GridColTypes = {
  'java.lang.Integer': 'number',
  'java.lang.Double': 'number',
  'java.lang.Byte': 'number',
  'java.lang.Short': 'number',
  'java.lang.Long': 'number',
  'java.lang.String': 'string',
};

const renderHeader = (params: GridColumnHeaderParams, label?: string) => {
  return (
    <Box>
      {params.field}
      {isString(label) && (
        <Typography
          sx={{ ml: 0.5 }}
          variant="caption"
          color="textSecondary"
        >
          ({label.split('.').pop()})
        </Typography>
      )}
    </Box>
  );
};

const renderGridCellOverflow = (params: GridRenderCellParams) => {
  return (
    <GridCellOverflow
      {...params}
      value={get(params, 'value', '')}
      width={params.colDef.computedWidth}
    />
  );
};

const Pagination = ({
  search,
  queryResult,
}: PaginationProps): React.ReactElement => {
  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const paginationInfo = useGridSelector(apiRef, gridPaginationModelSelector);

  interface Page {
    from: number,
    to: number,
    count: number,
  }

  const unknownCountFn = (page: Page) => {
    return `${page.from}–${page.to} of ${page.count < 9999 ?
      page.count : page.count > 9999 && page.count < 10101 ? `${9999}+` : `${page.to}+`}`;
  };

  const knownCountFn = (page: Page) => {
    return `${page.from}–${page.to} of ${page.count}`;
  };

  return (
    <>
      {!!search && (
        <Typography
          variant="caption"
          sx={{ pl: 2, width: '100%' }}
        >
          {`Displaying ${rowCount} ${pluralize('result', rowCount)} for "${search}"`}
        </Typography>
      )}
      <Box sx={{ flex: 1 }} />
      <TablePagination
        component="div"
        count={rowCount}
        onRowsPerPageChange={(event) => apiRef.current.setPageSize(parseInt(event.target.value))}
        onPageChange={(e, value) => apiRef.current.setPage(value)}
        page={paginationInfo.page}
        rowsPerPageOptions={[5, 10, 25, 100]}
        rowsPerPage={pageSize}
        labelDisplayedRows={ queryResult?.partialCount ? unknownCountFn : knownCountFn}
        sx={{
          width: '100%',
        }}
        aria-label="Pagination Range"
      />
    </>
  );
};

const DatasetGridView = ({
  queryResult,
  dataset,
  disableDataActions,
  paginationModel,
  setPaginationModel,
  loading,
}: DatasetGridViewProps): React.ReactElement | null => {
  const {
    debouncedQuery,
    selectedIds,
    setSelectedIds,
  } = useSearchSelect();
  const [rows, setRows] = React.useState<GridRowModel[] | []>([]);
  const [columns, setColumns] = React.useState<GridColDef[]>([]);

  const getFormattedColumns = React.useMemo(() => {
    return (schema: Record<string, string>, callback: (columns?: GridColDef[]) => void) => {
      const formattedColumns = Object.keys(schema).filter(k => !k.startsWith('_koverse')).map((key) => {
        const headerWidth = key.length * 15;
        return {
          field: key,
          headerName: key,
          type: ColTypeDefs[schema[key] as KoverseDataTypes],
          minWidth: headerWidth < 150 ? 150 : headerWidth,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams) => renderHeader(params, schema[key]),
          renderCell: (params: GridRenderCellParams) => renderGridCellOverflow(params),
        };
      });
      callback(formattedColumns);
    };
  }, []);

  const getFormattedRows = React.useMemo(() => {
    return (queryResult: QueryResult, callback: (rows?: GridRowModel[]) => void) => {
      const formattedRows = map(queryResult.records, (rec) => ({
        ...rec,
        _koverse_record_id: rec._koverse_record_id || uuidv4(),
      }));
      callback(formattedRows);
    };
  }, []);

  const handleGetRowId = (row: any) => {
    return row._koverse_record_id;
  };

  React.useEffect(() => {
    let active = true;

    if (Object.keys(dataset.schema).length !== 0) {
      getFormattedColumns(dataset.schema, (columns?: GridColDef[]) => {
        if (active && columns) {
          setColumns(columns);
        }
      });
      getFormattedRows(queryResult, (rows?: GridRowModel[]) => {
        if (active && rows) {
          setRows(rows);
        }
      });
      return () => {
        active = false;
      };
    }
  }, [
    queryResult,
    dataset.schema,
    setColumns,
    setRows,
    getFormattedColumns,
    getFormattedRows,
  ]);

  const rowCount = React.useMemo(() => {
    return queryResult.total > 9999 && queryResult.more ? queryResult.total + paginationModel.pageSize : queryResult.total;
  }, [queryResult, paginationModel.pageSize]);

  return (
    <Box
      sx={{
        '.MuiDataGrid-root': {
          borderRadius: 0,
          border: 'none',
          '& .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader': {
            outline: 'none',
          },
          '.MuiDataGrid-footerContainer .MuiTablePagination-root .MuiTablePagination-toolbar': {
          },
        },
      }}
    >
      <DataGrid
        autoHeight
        columns={columns}
        columnBuffer={4}
        slots={{
          pagination: Pagination,
        }}
        slotProps={{
          pagination: { search: debouncedQuery, queryResult } as Partial<TablePaginationProps & PaginationPropsOverrides>,
        }}
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        loading={loading}
        pagination
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        rows={rows}
        rowCount={rowCount}
        getRowId={handleGetRowId}
        checkboxSelection
        isRowSelectable={() => !disableDataActions}
        sx={{
          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
            display: 'none',
          },
        }}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectedIds(newRowSelectionModel as string[]);
        }}
        rowSelectionModel={selectedIds}
        keepNonExistentRowsSelected
      />
    </Box>
  );
};

export default DatasetGridView;
