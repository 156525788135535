import { useServiceItem } from '@koverse/react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../../declarations';
import ReindexConfig from './ReindexConfig';
import SegmentDetails from './SegmentDetails';
import JDBCConfig from './SourceTypes/JDBCConfig';
import KafkaConfig from './SourceTypes/KafkaConfig';
import S3Config from './SourceTypes/S3Config';
import URLConfig from './SourceTypes/URLConfig';

interface Props {
  job: Job
}

const JobsTableRow = ({
  job,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [expand, setExpand] = React.useState<boolean>(false);
  const {
    date,
    time,
  } = React.useMemo(() => ({
    date: DateTime.fromISO(job.createdAt).toLocaleString(),
    time: DateTime.fromISO(job.createdAt).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET),
  }), [job.createdAt]);
  const {
    item: user,
  } = useServiceItem('users', job.userId);

  const sourceTypes = {
    JDBC: JDBCConfig,
    KAFKA: KafkaConfig,
    S3: S3Config,
    URL: URLConfig,
  };

  return (
    <>
      <TableRow
        sx={{
          '& > td.MuiTableCell-root': { borderBottom: 'unset' },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setExpand(!expand)}
          >
            {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>{time}</TableCell>
        <TableCell>{job.type}</TableCell>
        <TableCell>{job.status}</TableCell>
        <TableCell>
          {!user
            ? <Skeleton height={34} width={200} />
            : user?.displayName
          }
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          background: theme => theme.palette.background.default,
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <Box sx={{ ml: 6, p: 2 }}>
              <Typography variant="h6">{t('pages.dataset.jobHistory.configuration.title')}</Typography>
              {job.type === 'reindex' && <ReindexConfig job={job} />}
              {job.type === 'ingest' && Object.entries(sourceTypes).map(([key, Component]) => {
                return key === job?.config?.type && <Component key={job.id} job={job} />;
              })}
              <SegmentDetails id={job.id} datasetId={job.datasetId} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default JobsTableRow;
