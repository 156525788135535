import { useKoverse } from '@koverse/react';
import { Alert, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ThirdPartyApplication } from '../../../declarations';
import { ApplicationDetails } from '../../ApplicationForm';

interface Props {
  open: boolean;
  onClose: () => void;
  application: ThirdPartyApplication
}

type RedirectInput = {
  url: string;
}

type Inputs = {
  name: string;
  url: string;
  description: string;
  redirectUrl: string[];
  redirectFields: RedirectInput[];
};

const EditApplication = ({ open, onClose, application }: Props): React.ReactElement => {
  const [submissionError, setSubmissionError] = useState('');
  const methods = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      name: application.name,
      description: application.description,
    },
  });
  const { client } = useKoverse();
  const {
    handleSubmit,
    formState,
  } = methods;
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data: Inputs) => {

    try {
      await client.service('applications').patch(application.id, data);
      onClose();
      enqueueSnackbar('The application has been updated.', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof Error) {
        const errors = get(e, 'errors', []);
        setSubmissionError(errors.length ? errors[0] : e.message);
      } else {
        setSubmissionError('There was a problem updating the application');
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Rename Application
          </Typography>
          <FormProvider {...methods}>
            <ApplicationDetails />
          </FormProvider>
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                mt: 3,
              }}
            >
              {submissionError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formState.isValid}
          >
            Rename Application
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditApplication;
