import { useKoverse } from '@koverse/react';
import get from 'lodash/get';
import times from 'lodash/times';
import { useSnackbar } from 'notistack';
import React from 'react';
import Avatar from '@mui/material/Avatar';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import { AttributeAssignment } from '../../../declarations';
import UsersListItem from './UsersListItem';

interface Props {
  assignments: AttributeAssignment[] | undefined;
}

const UsersList = ({
  assignments,
}: Props): React.ReactElement | null => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const assignmentLength = React.useRef(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage] = React.useState<number>(5);
  const [shouldPaginate, setShouldPaginate] = React.useState<boolean>(false);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page - 1);
  };

  const handleOnRemove = async (assignment: AttributeAssignment) => {
    try {
      await client.service('attribute-assignments').remove(assignment.id, {
        userId: assignment.id,
        attributeId: assignment.attributeId,
      });
    } catch (error) {
      enqueueSnackbar(`There was an error: ${get(error, 'message', error)}`, {
        variant: 'error',
      });
    }
  };

  React.useEffect(() => {
    if (!assignments) {
      setLoading(true);
    } else {
      assignmentLength.current = assignments.length;
      if (assignmentLength.current > rowsPerPage) {
        setShouldPaginate(true);
      }
      if (assignmentLength.current <= rowsPerPage) {
        setPage(0);
        setShouldPaginate(false);
      }
      setLoading(false);
    }
  }, [assignments, page, rowsPerPage]);

  if (!!assignments && assignments.length === 0) {
    return null;
  }

  return (
    <>
      <List dense sx={{ minHeight: shouldPaginate ? rowsPerPage * 64 : 150 }}>
        {loading && <LinearProgress />}
        {loading && times(shouldPaginate ? rowsPerPage : assignmentLength.current, (index) => (
          <ListItem
            key={index}
            dense
            sx={{ backgroundColor: 'action.disabledBackground' }}
          >
            <ListItemAvatar>
              <Skeleton variant="circular"><Avatar /></Skeleton>
            </ListItemAvatar>
            <ListItemText
              primary={<Skeleton width={70} />}
              secondary={<Skeleton width={170} />}
            />
          </ListItem>
        ))}
        {!!assignments && assignments
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((assignment: AttributeAssignment) => (
            <UsersListItem
              key={assignment.id}
              assignment={assignment}
              onRemove={handleOnRemove}
            />
          ))
        }
      </List>
      {shouldPaginate && (
        <Pagination
          count={Math.ceil(assignmentLength.current / rowsPerPage)}
          variant="outlined"
          shape="rounded"
          siblingCount={0}
          page={page + 1}
          onChange={handlePageChange}
          sx={{
            '& .MuiPagination-ul':
            {
              justifyContent: 'center',
              margin: (theme) => theme.spacing(2, 0),
            },
          }}
          aria-label="pagination"
        />
      )}
    </>
  );
};

export default UsersList;
