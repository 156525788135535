import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { useForm, FieldError } from 'react-hook-form';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Attribute } from '../../../declarations';
import { AxiosError } from 'axios';

type Props = {
  open: boolean;
  onClose: () => void;
  attribute: Attribute;
};

const DeleteAttribute = ({
  open,
  onClose,
  attribute,
}: Props): React.ReactElement | null => {
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState,
  } = useForm();

  const attributes = client.service('attributes');

  const handleOnClose = () => {
    setSubmissionError(null);
    onClose();
  };

  const onSubmit = async () => {
    try {
      await attributes.remove(attribute.id);
      enqueueSnackbar(t('dialogs.attribute.delete.successToast'), {
        variant: 'success',
      });
      handleOnClose();
    } catch (e) {
      const errors = e as AxiosError;
      setSubmissionError(get(errors, 'message', null));
      if (isArray(get(errors, 'errors'))) {
        const errorList = get(e, 'errors') || [];
        const errors = errorList.map((error: FieldError) => error.message);
        setSubmissionError(`${get(e, 'message')}: ${errors.join(',')}`);
      }
      enqueueSnackbar('There was an error deleting this attribute.', {
        variant: 'error',
      });
    }
  };

  return attribute ? (
    <Dialog open={open} onClose={() => handleOnClose()} aria-labelledby="form-dialog-title" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t('dialogs.attribute.delete.title')}</DialogTitle>
        <DialogContent>
          <Typography>{t('dialogs.attribute.delete.messageTop', { attribute: attribute.token })}</Typography>
          <Typography>{t('dialogs.attribute.delete.messageBottom')}</Typography>
          {submissionError && (
            <Typography color="error">{submissionError}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {formState.isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <Button
                color="primary"
                onClick={() => handleOnClose()}
              >
                {t('forms.cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
              >
                {t('forms.delete')}
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  ) : null;
};

export default DeleteAttribute;
