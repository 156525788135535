import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import React, { ChangeEvent, FocusEvent, useRef, useState } from 'react';
import { useKoverse } from '@koverse/react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import config from '../../config';
import useQueryParams from '../../hooks/useQueryParams';

type Inputs = {
  code: string[];
};

export default function VerifyCode(): React.ReactElement {
  const { client } = useKoverse();
  const [submissionError, setSubmissionError] = useState(null);
  const { t } = useTranslation();
  const { queryParams, setQueryParam } = useQueryParams();
  const { email } = queryParams;
  const {
    formState,
    handleSubmit,
    register,
    getValues,
    setValue,
    trigger,
  } = useForm<Inputs>({
    mode: 'onChange',
  });
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null);
  const { isValid } = formState;
  const onSubmit = async (data: Inputs) => {
    try {
      const code = data.code.join('');
      setQueryParam('code', code);
    } catch (e) {
      setSubmissionError(get(e, 'message', null));
    }
  };

  const handleChange = (i: number) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setValue(`code[${i}]`, e.target.value.toUpperCase());
      trigger();
      document.getElementById(`code-${i + 1}`)?.focus();
    }
    if (isValid && formRef.current) {
      (formRef.current as any).dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  };
  const handleFocus = (e: FocusEvent<HTMLInputElement>) => e.target.select();
  const handleKeyUp = (i: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (i > 0 && e.code === 'Backspace' && !getValues(`code[${i}]`)) {
      document.getElementById(`code-${i - 1}`)?.focus();
    }
  };

  const resendEmail = async () => {
    try {
      await client.service('auth-management').create({
        action: 'requestWorkspacesByEmail',
        email,
      });
      enqueueSnackbar('Verification email has been resent', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('There was an error sending the email', {
        variant: 'error',
      });
    }
  };

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>Check your email</Typography>
      <Typography>Verify your identity by entering the code sent to {email}</Typography>
      {!config.disableLocalAuth && (
        <React.Fragment>
          <Paper sx={{ mt: 4, mb: 5, py: 5, px: 4 }}>
            <Stack direction="row" alignItems="center">
              <TextField
                name="code[0]"
                aria-label="digit 1 of 6"
                inputRef={register({
                  required: t('forms.validation.required') as string,
                  maxLength: 1,
                })}
                onChange={handleChange(0)}
                onFocus={handleFocus}
                onKeyUp={handleKeyUp(0)}
                sx={{ mx: 0.5 }}
                inputProps={{
                  id: 'code-0',
                  maxLength: 1,
                  autoFocus: true,
                  sx: {
                    fontSize: (t: any) => t.typography.h3.fontSize,
                    textAlign: 'center',
                  },
                }}
              />
              <TextField
                name="code[1]"
                aria-label="digit 2 of 6"
                inputRef={register({
                  required: t('forms.validation.required') as string,
                  maxLength: 1,
                })}
                onChange={handleChange(1)}
                onFocus={handleFocus}
                onKeyUp={handleKeyUp(1)}
                sx={{ mx: 0.5 }}
                inputProps={{
                  id: 'code-1',
                  maxLength: 1,
                  sx: {
                    fontSize: (t: any) => t.typography.h3.fontSize,
                    textAlign: 'center',
                  },
                }}
              />
              <TextField
                name="code[2]"
                aria-label="digit 3 of 6"
                inputRef={register({
                  required: t('forms.validation.required') as string,
                  maxLength: 1,
                })}
                onChange={handleChange(2)}
                onFocus={handleFocus}
                onKeyUp={handleKeyUp(2)}
                sx={{ mx: 0.5 }}
                inputProps={{
                  id: 'code-2',
                  maxLength: 1,
                  sx: {
                    fontSize: (t: any) => t.typography.h3.fontSize,
                    textAlign: 'center',
                  },
                }}
              />
              <Typography variant="h3" sx={{ mx: 2 }}>-</Typography>
              <TextField
                name="code[3]"
                aria-label="digit 4 of 6"
                inputRef={register({
                  required: t('forms.validation.required') as string,
                  maxLength: 1,
                })}
                onChange={handleChange(3)}
                onFocus={handleFocus}
                onKeyUp={handleKeyUp(3)}
                sx={{ mx: 0.5 }}
                inputProps={{
                  id: 'code-3',
                  maxLength: 1,
                  sx: {
                    fontSize: (t: any) => t.typography.h3.fontSize,
                    textAlign: 'center',
                  },
                }}
              />
              <TextField
                name="code[4]"
                aria-label="digit 5 of 6"
                inputRef={register({
                  required: t('forms.validation.required') as string,
                  maxLength: 1,
                })}
                onChange={handleChange(4)}
                onFocus={handleFocus}
                onKeyUp={handleKeyUp(4)}
                sx={{ mx: 0.5 }}
                inputProps={{
                  id: 'code-4',
                  maxLength: 1,
                  sx: {
                    fontSize: (t: any) => t.typography.h3.fontSize,
                    textAlign: 'center',
                  },
                }}
              />
              <TextField
                name="code[5]"
                aria-label="digit 6 of 6"
                inputRef={register({
                  required: t('forms.validation.required') as string,
                  maxLength: 1,
                })}
                onChange={handleChange(5)}
                onFocus={handleFocus}
                onKeyUp={handleKeyUp(5)}
                sx={{ mx: 0.5 }}
                inputProps={{
                  id: 'code-5',
                  maxLength: 1,
                  sx: {
                    fontSize: (t: any) => t.typography.h3.fontSize,
                    textAlign: 'center',
                  },
                }}
              />
            </Stack>
            {submissionError && (
              <Alert
                severity="error"
                sx={{
                  width: '100%',
                  my: 2,
                }}
              >
                {submissionError}
              </Alert>
            )}
          </Paper>
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography gutterBottom>
              {'Did not recieve the verification email? '}
              <Link sx={{ cursor: 'pointer' }} onClick={resendEmail}>
                Resend email
              </Link>
            </Typography>
            <Typography gutterBottom>
              {'Don\'t have an account? '}
              <Link component={RouterLink} to="/sign-up">
                {t('common.signUp')}
              </Link>
            </Typography>
          </Stack>
        </React.Fragment>
      )}
    </form>
  );
}
