import { useServiceItem } from '@koverse/react';
import AvatarGroup from '@mui/material/AvatarGroup';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Group, User } from '../../declarations';
import UserAvatar from '../UserAvatar';

interface Props {
  groupId: string;
  roomBottom: boolean;
}

const GroupListItem = ({
  roomBottom,
  groupId,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation();

  const {
    item,
  } = useServiceItem('groups', groupId, {
    groupMemberships: true,
    owner: true,
  });

  const group = item as Group;

  if (!group || !group.owner || !group.groupMemberships) {
    return null;
  }

  return (
    <ListItem
      dense
      sx={{
        backgroundColor: 'action.disabledBackground',
        mb: roomBottom ? 1 : 0,
      }}
    >
      <ListItemText
        primary={group.name}
        primaryTypographyProps={{ noWrap: true }}
        secondary={t('common.createdBy', { name: group.owner.displayName })}
      />
      <AvatarGroup
        max={4}
        sx={{
          '&.MuiAvatarGroup-root .MuiAvatar-root': {
            borderColor: 'action.hover',
          },
        }}
      >
        {group.groupMemberships.map((membership) => (
          <UserAvatar key={membership.id} user={membership?.user as User} />
        ))}
      </AvatarGroup>
    </ListItem>
  );
};

export default GroupListItem;
