import { useKoverse } from '@koverse/react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { User } from '../../../declarations';
import { useGroupMemberships } from '../../../hooks';
import UserFields from './UserFields';

type Inputs = {
  groupName: string;
  users: {
    id: string,
    groupRole: string,
  }[];
};

interface Props {
  open: boolean;
  onClose: (saved?: boolean) => void;
  users?: User[];
}

const CreateGroup = ({
  open,
  onClose,
  users,
}: Props): React.ReactElement => {
  const { client, user, workspace } = useKoverse();
  const { createGroupMemberships } = useGroupMemberships();
  const { enqueueSnackbar } = useSnackbar();
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const groupsService = client.service('groups');

  const methods = useForm<Inputs>({
    mode: 'onSubmit',
    defaultValues: {
      users,
      groupName: '',
    },
  });

  const {
    errors,
    formState,
    handleSubmit,
    control,
  } = methods;

  const {
    dirtyFields,
    isSubmitting,
  } = formState;

  const createGroup = React.useCallback(async (name: string) => {
    return await groupsService.create({
      name,
      workspaceId: workspace.id,
    });
  }, [groupsService, workspace.id]);

  const onSubmit = async (data: Inputs) => {
    try {
      const group = await createGroup(data.groupName);
      if (!!data.users) {
        createGroupMemberships(data, group.id);
      }
      enqueueSnackbar(`${group.name} created`, {
        variant: 'success',
      });
      onClose(true);
    } catch (e) {
      setSubmissionError(`There was a server error: ${get(e, 'message')}`);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <DialogTitle>Create a group</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              control={control}
              defaultValue=""
              name="groupName"
              rules={{ required: 'Required' }}
              render={(field) => {
                return (
                  <TextField
                    {...field}
                    InputLabelProps={{
                      htmlFor: 'groupName',
                    }}
                    id="groupName"
                    label="Group Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.groupName}
                    helperText={(errors.groupName && errors.groupName.message) || ''}
                    disabled={isSubmitting}
                    margin='none'
                  />
                );
              }}
            />
            <UserFields owner={user} loading={isSubmitting} />
            {!!submissionError && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{
                  mb: 2,
                }}
              >
                {submissionError}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            {isSubmitting ? (
              <CircularProgress size={30} />
            ) : (
              <>
                <Button
                  type="button"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!dirtyFields?.groupName || !!submissionError}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Save Changes
                </Button>
              </>
            )}
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default CreateGroup;
