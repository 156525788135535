import React, { useEffect, useState, useCallback } from 'react';
import { Logo, useKoverse } from '@koverse/react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useAppState } from '../../components/AppStateProvider';
import useQueryParams from '../../hooks/useQueryParams';
import EnterEmail from './EnterEmail';
import SelectWorkspace from './SelectWorkspace';
import VerifyCode from './VerifyCode';
import { Workspace } from '../../declarations';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';
import useActiveSessions from '../../hooks/useActiveSessions';
import ActiveSessions from './ActiveSessions';

function UniversalLogin(): React.ReactElement {

  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { mode } = useAppState();
  const [workspaces, setWorkspaces] = useState<null | Workspace[]>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { setQueryParam, queryParams } = useQueryParams();
  const { email, code, sessions } = queryParams;
  const { activeSessions } = useActiveSessions();

  const fetchWorkspaces = useCallback(async () => {
    if (email && code && !workspaces && !loading) {
      setLoading(true);
      try {
        const workspaces = await client.service('auth-management').create({
          action: 'requestWorkspacesByEmail',
          email,
          code,
        });
        if (Array.isArray(workspaces)) {
          setWorkspaces(workspaces);
        }
      } catch (e) {
        const message = (e instanceof Error) ? e.message : 'Unknown Error';
        enqueueSnackbar(message, {
          variant: 'error',
        });
        setWorkspaces(null);
        setQueryParam('code', undefined, false);
      }
      setLoading(false);
    } else if (!email || !code) {
      setWorkspaces(null);
    }
  }, [client, email, code, loading, workspaces, enqueueSnackbar, setQueryParam]);

  useEffect(() => {
    fetchWorkspaces();
  }, [fetchWorkspaces]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '100%',
        pt: 8,
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        spacing={3}
      >
        <Logo mode={mode} />
        {email === undefined && (
          <>
            {!activeSessions.length || sessions === 'false' ? <EnterEmail /> : <ActiveSessions />}
          </>
        )}
        {!!email && !code && (
          <VerifyCode />
        )}
        {loading && (
          <Box sx={{ pt: 8 }}>
            <CircularProgress />
          </Box>
        )}
        {workspaces && (
          <SelectWorkspace workspaces={workspaces} />
        )}
      </Stack>
    </Container>
  );
}

export default UniversalLogin;
