import { useKoverse } from '@koverse/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import get from 'lodash/get';
import partition from 'lodash/partition';
import { useSnackbar } from 'notistack';
import React from 'react';
import config from '../config';

interface User {
  id: string,
  groupRole: string,
  membershipId?: string | undefined;
}

interface Data {
  groupId: string;
  userId: string;
  role: string;
}

type Inputs = {
  groupName: string;
  users: User[];
};

type Method = 'create' | 'remove' | 'patch';

type Call = [method: 'patch', service: string, id: string, data: Data]
              | [method: 'remove', service: string, id: string]
              | [method: 'create', service: string, data: Data]

const useGroupMemberships = () => {
  const { client } = useKoverse();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dismiss = React.useCallback((id: string): React.ReactElement => {
    return (
      <Button
        color="inherit"
        size="small"
        onClick={() => closeSnackbar(id)}
      >
        Okay
      </Button>
    );
  }, [closeSnackbar]);

  const serverErrorSnack = React.useCallback((msg: string, groupName?: string) => (
    enqueueSnackbar(
      <Box>
        <Typography>
          {`Error: ${msg}`}
        </Typography>
        <Typography>
          {`Changes to group memberships may not have been saved for ${groupName}`}
        </Typography>
      </Box>
      , {
        variant: 'error',
        persist: true,
        action: dismiss,
      })
  ), [dismiss, enqueueSnackbar]);

  const rejectedSnack = React.useCallback((msg: string) => (
    enqueueSnackbar(msg, {
      variant: 'error',
      persist: true,
      action: dismiss,
    })
  ), [dismiss, enqueueSnackbar]);

  const batchCalls = React.useCallback(async (calls: Call[], method: Method, groupName: string, groupId: string) => {
    const url = `//${config.api}/group-memberships/batch`;

    try {
      const jwt = await client.authentication.getAccessToken();
      const { data } = await axios.post(url, {
        calls,
        groupId,
      }, {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
      });

      const [rejected] = partition(data, result => result.status === 'rejected');

      if (!!rejected.length) {
        rejectedSnack(`Some memberships could not be created for group: ${groupName}`);
      }
    } catch (e) {
      serverErrorSnack(get(e, 'message', ''), groupName);
    }
  }, [client, rejectedSnack, serverErrorSnack]);

  const createGroupMemberships = React.useCallback(async (inputs: Inputs, groupId: string) => {
    const { users, groupName } = inputs;

    const calls = users.map(({ id: userId, groupRole: role }) => {
      return ['create', 'group-memberships', {
        groupId,
        userId,
        role,
      }] as Call;
    });

    await batchCalls(calls, 'create', groupName, groupId);
  }, [batchCalls]);

  const updateGroupMemberships = React.useCallback(async (inputs: Inputs, groupId: string) => {
    const { users, groupName } = inputs;

    const calls = users.map(({ id: userId, groupRole: role, membershipId }) => {
      return ['patch', 'group-memberships', membershipId, {
        groupId,
        userId,
        role,
      }] as Call;
    });

    await batchCalls(calls, 'patch', groupName, groupId);
  }, [batchCalls]);

  const removeGroupMemberships = React.useCallback(async (inputs: Inputs, groupId: string) => {
    const { users, groupName } = inputs;

    const calls = users.map(({ membershipId }) => {
      return ['remove', 'group-memberships', membershipId] as Call;
    });

    await batchCalls(calls, 'remove', groupName, groupId);
  }, [batchCalls]);

  return {
    createGroupMemberships,
    updateGroupMemberships,
    removeGroupMemberships,
  };
};

export default useGroupMemberships;
