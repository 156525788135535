import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';

type AuditLogMenuProps = {
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
};

const AuditLogMenu = ({ handleOpen, anchorEl, open, onClose }: AuditLogMenuProps): React.ReactElement => {
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleOpen}
        endIcon={<ArrowDropDownIcon />}
      >
        Settings
      </Button>
      <Menu
        id="audit-log-settings-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={onClose}
      >
        <MenuItem component={Link} to="/audit-log/log-configuration" onClick={onClose}>
          Log Configuration
        </MenuItem>
      </Menu>
    </>
  );
};

export default AuditLogMenu;
