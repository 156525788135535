import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { CheckboxInput } from '../../declarations';

interface Props {
  parameter: CheckboxInput;
  name?: string;
  disabled?: boolean;
}

const CheckboxParameter = ({
  parameter,
  name,
  disabled,
}: Props): React.ReactElement => {
  const form = useFormContext();
  const inputName = name ? `params.${name}` : `params.${parameter.name}`;

  return (
    <>
      <Controller
        name={inputName}
        control={form.control}
        defaultValue={parameter.defaultChecked}
        render={({ value, onChange, ref, ...props }) => (
          <FormControlLabel
            control={
              <Checkbox
                {...props}
                disabled={disabled}
                checked={value}
                color="primary"
                ref={ref}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
              />
            }
            label={parameter.label}
          />
        )}
      />
      {parameter.helperText && <FormHelperText>{parameter.helperText}</FormHelperText>}
    </>
  );
};

export default CheckboxParameter;
