import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper, { PaperProps } from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CancelSubscription from '../Dialogs/CancelSubscription';
import UpdatePayment from '../Dialogs/UpdatePayment';
import CardLogo from '../CardLogo';
import useSubscription from '../../hooks/useSubscription';
import useUpcomingInvoice from '../../hooks/useUpcomingInvoice';

const StyledPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  padding: theme.spacing(3),
  borderRight: `2px solid ${theme.palette.background.default}`,
  height: '100%',
}));

const SubscriptionDetails = (): React.ReactElement => {
  const {
    loading,
    subscription,
    plan,
    reenableSubscription,
  } = useSubscription();
  const {
    upcomingInvoice,
    loading: loadingUpcomingInvoice,
    estimatedOverages,
  } = useUpcomingInvoice();

  const showSkeletons = loading || loadingUpcomingInvoice;
  const [cancelSubscription, setCancelSubscription] = React.useState<boolean>(false);
  const [updatePayment, setUpdatePayment] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const start = useMemo(() => (
    !!upcomingInvoice ? DateTime.fromSeconds(upcomingInvoice?.period_start).toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' }) : null
  ), [upcomingInvoice]);
  const end = useMemo(() => (
    !!upcomingInvoice ? DateTime.fromSeconds(upcomingInvoice?.period_end).toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' }) : null
  ), [upcomingInvoice]);

  return (
    <>
      <Grid container spacing={0}
        direction="row"
        alignItems="stretch"
      >
        <Grid item xs={12} sm={4}>
          <StyledPaper square>
            <Stack
              direction="column"
              sx={{ height: '100%' }}
            >
              <Typography
                color="textSecondary"
                sx={{ mb: 2 }}
              >
                {t('pages.billing.subscription.period')}
              </Typography>
              {showSkeletons ? (
                <Skeleton />
              ) : (
                <Typography variant="h6">{`${start} - ${end}`}</Typography>
              )}

              <Box sx={{ flex: 1 }} />
              {showSkeletons && (
                <Skeleton />
              )}
              {!showSkeletons && subscription?.default_payment_method?.type === 'card' && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  flexWrap="wrap"
                >
                  <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                    <CardLogo card={subscription?.default_payment_method?.card} />
                  </Box>
                  <Typography>
                    {t('pages.billing.subscription.card', { last4: subscription?.default_payment_method?.card.last4 })}
                  </Typography>
                  <Box sx={{ flex: 1 }} />
                  <Button
                    color="primary"
                    onClick={() => setUpdatePayment(true)}
                  >
                    {t('common.update')}
                  </Button>
                </Stack>
              )}
              {!showSkeletons && !subscription?.default_payment_method && (
                <Box>
                  <Button
                    color="primary"
                    onClick={() => setUpdatePayment(true)}
                  >
                    {t('pages.billing.subscription.addPaymentMethod')}
                  </Button>
                </Box>
              )}
            </Stack>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledPaper square>
            <Typography
              color="textSecondary"
              sx={{ mb: 2 }}
            >
              {t('pages.billing.subscription.charges')}
            </Typography>
            {showSkeletons ? (
              <Skeleton />
            ) : (
              <Typography variant="h6">{estimatedOverages}</Typography>
            )}
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={5}>
          <StyledPaper square>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Stack>
                <Typography
                  color="textSecondary"
                  sx={{ mb: 2 }}
                >
                  {t('pages.billing.subscription.plan')}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {showSkeletons ? <Skeleton /> : plan?.subscriptionLevel}
                </Typography>
                <Typography variant="h6">
                  {showSkeletons ? <Skeleton /> : plan?.recurringPrice}
                </Typography>
              </Stack>
              <Stack>
                <Button
                  component={Link}
                  to="/billing/change-plan"
                  color="primary"
                  variant="contained"
                  sx={{ mb: 3 }}
                  size="small"
                >
                  {t('common.changePlan')}
                </Button>
                {!subscription?.cancel_at_period_end && (
                  <Button
                    color="primary"
                    onClick={() => setCancelSubscription(true)}
                    size="small"
                  >
                    {t('common.cancelPlan')}
                  </Button>
                )}
              </Stack>
            </Box>
            {subscription?.cancel_at_period_end && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography color="textSecondary">
                  {`Subscription will end on ${end}`}
                </Typography>
                <Button
                  color="primary"
                  onClick={reenableSubscription}
                  size="small"
                >
                  {t('common.undo')}
                </Button>
              </Box>
            )}
          </StyledPaper>
        </Grid>
      </Grid>
      {cancelSubscription && (
        <CancelSubscription
          open={!!cancelSubscription}
          onClose={() => setCancelSubscription(false)}
        />
      )}
      {updatePayment && (
        <UpdatePayment
          open={!!updatePayment}
          onClose={() => setUpdatePayment(false)}
        />
      )}
    </>
  );
};

export default SubscriptionDetails;
