import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import get from 'lodash/get';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectInput } from '../../declarations';

interface Props {
  parameter: SelectInput;
  name?: string;
  disabled?: boolean;
}

const SelectField = ({
  parameter,
  name,
  disabled,
}: Props): React.ReactElement => {
  const form = useFormContext();
  const { control, errors, formState } = form;
  const { touched } = formState;
  const inputName = name ? `params.${name}` : `params.${parameter.name}`;
  const helperText = !!get(touched, inputName) && get(errors, `${inputName}.message`)
    ? get(errors, `${inputName}.message`)
    : parameter.helperText;

  const inputLabel = parameter.required
    ? parameter.label
    : (
      <>
        {`${parameter.label} `}
        <em>(optional)</em>
      </>
    );

  return (
    <Controller
      name={inputName}
      control={control}
      defaultValue={parameter?.defaultValue || ''}
      render={(field) => (
        <FormControl
          fullWidth
          error={get(touched, inputName) && !!get(errors, inputName)}
          margin="normal"
        >
          <InputLabel id={`${inputName}-label`}>{inputLabel}</InputLabel>
          <Select
            {...field}
            disabled={disabled}
            labelId={`${inputName}-label`}
            label={inputLabel}
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
          >
            {parameter.options.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
            ))}
          </Select>
          {!!helperText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
        </FormControl>
      )}
      rules={{
        required: parameter.required ? 'Required' : undefined,
      }}
    />
  );
};

export default SelectField;
