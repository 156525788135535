import CardHeader from '@mui/material/CardHeader';
import get from 'lodash/get';
import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Dataset, Workspace } from '../../declarations';
import BreadCrumbs from '../BreadCrumbs';
import ClearDataset from '../Dialogs/ClearDataset';
import DeleteDataset from '../Dialogs/DeleteDataset';
import EditDatasetInformation from '../Dialogs/EditDatasetInformation';
import SettingsMenu from './SettingsMenu';

interface Params {
  workspaceId: string;
  id: string;
}

interface HeaderParams {
  clearDataset: boolean;
  dataset: Dataset;
  disableDataActions: boolean;
  openUploadDrawer: () => void;
  setClearDataset: React.Dispatch<React.SetStateAction<boolean>>;
  workspace: Workspace;
}

const Header = ({
  clearDataset,
  dataset,
  disableDataActions,
  openUploadDrawer,
  setClearDataset,
  workspace,
}: HeaderParams): React.ReactElement | null => {
  const { id } = useParams<Params>();
  const { url } = useRouteMatch();
  const history = useHistory();
  const [edit, setEdit] = React.useState<boolean>(false);
  const [deleteDataset, setDeleteDataset] = React.useState<boolean>(false);
  const userCanManage = get(dataset, 'currentUserPermissions.manage');
  const breadcrumbMap = {
    [`/`]: workspace.name,
    [`${id}`]: dataset.name,
    'index-settings': 'Index Settings',
    'permissions': 'Permissions',
    'job-history': 'Job History',
  };

  const handleMenuNavigation = (location: string) => {
    history.push(`${url}/${location}`);
  };

  return (
    <>
      <CardHeader
        sx={{
          '& .MuiCardHeader-action': {
            m: 0,
          },
        }}
        title={<BreadCrumbs breadcrumbMap={breadcrumbMap} />}
        action={!!userCanManage && (
          <SettingsMenu
            handleClearDataset={setClearDataset}
            handleDeleteDataset={setDeleteDataset}
            handleNavigation={handleMenuNavigation}
            handleOpenUploadDrawer={openUploadDrawer}
            handleRename={setEdit}
            disableDataActions={disableDataActions}
          />
        )}
      />
      {edit && (
        <EditDatasetInformation
          open={edit}
          onClose={() => setEdit(false)}
          dataset={dataset}
        />
      )}
      {clearDataset && (
        <ClearDataset
          open={clearDataset}
          onClose={() => setClearDataset(false)}
          dataset={dataset}
        />
      )}
      {deleteDataset && (
        <DeleteDataset
          open={deleteDataset}
          onClose={() => setDeleteDataset(false)}
          dataset={dataset}
        />
      )}
    </>
  );
};

export default Header;
