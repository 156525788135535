import React from 'react';

const features = {
  sls: process.env.REACT_APP_FEATURE_SLS,
};

interface Feature {
  [key: string]: string | undefined;
}

interface FeatureProviderProps {
  children: React.ReactNode;
}

interface FeatureToggleProps {
  feature: string;
  children: React.ReactNode;
  unless?: boolean;
}

export const FeatureToggleContext = React.createContext<Feature>(features);

export const FeatureToggleProvider = ({ children }: FeatureProviderProps): React.ReactElement | null => {
  if (!features) {
    return null;
  }
  return (
    <FeatureToggleContext.Provider value={features}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

export const FeatureToggle = ({
  feature,
  children,
  unless = false,
}: FeatureToggleProps): React.ReactElement | null => {
  return (
    <FeatureToggleContext.Consumer>
      {value => {
        const toggle = unless
          ? !(value[feature] === 'true')
          : (value[feature] === 'true');
        return toggle ? children : null;
      }
      }
    </FeatureToggleContext.Consumer>
  );
};

export const useFeatureToggle = (feature: string): boolean => {
  const features = React.useContext(FeatureToggleContext);

  return features ? (features[feature] === 'true') : false;
};
