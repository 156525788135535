import React, { createContext, useMemo } from 'react';
import { useKoverse } from '@koverse/react';
import { CurrentUsage, Invoice } from '../../declarations';
import get from 'lodash/get';
import sum from 'lodash/sum';
import useSubscription from '../../hooks/useSubscription';

const initialPromise = async () => { throw new Error('UpcomingInvoiceContext has not been initialized'); };

const initialContext = {
  loading: false,
  loadingCurrentUsage: false,
  fetchUpcomingInvoice: initialPromise,
  fetchCurrentUsageSummary: initialPromise,
};

export interface UpcomingInvoiceProviderOptions {
  children?: React.ReactNode;
}

export interface UpcomingInvoiceContextInterface {
  currentUsage?: CurrentUsage
  fetchUpcomingInvoice: () => Promise<void>,
  fetchCurrentUsageSummary: () => Promise<void>,
  loading: boolean,
  loadingCurrentUsage: boolean,
  error?: string,
  upcomingInvoice?: Invoice,
  estimatedOverages?: string;
}

export const UpcomingInvoiceContext = createContext<UpcomingInvoiceContextInterface>(initialContext);

export const UpcomingInvoiceProvider = ({
  children,
}: UpcomingInvoiceProviderOptions): JSX.Element => {
  const { client, user } = useKoverse();
  const { subscription } = useSubscription();
  const stripeService = client.service('stripe');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingCurrentUsage, setLoadingCurrentUsage] = React.useState<boolean>(true);
  const [upcomingInvoice, setUpcomingInvoice] = React.useState<Invoice>();
  const [currentUsage, setCurrentUsage] = React.useState<CurrentUsage>();
  const [error, setError] = React.useState<undefined | string>();
  const estimatedOverages = useMemo(() => {
    if (!subscription || !upcomingInvoice) {
      return;
    }
    const overageProductKeys = ['compute', 'network', 'storage'];
    const overageSubscriptionItemIds = subscription?.items?.data?.filter(item => (
      overageProductKeys.includes(item.metadata.productKey)
    )).map(i => i.id);
    const overage = sum(upcomingInvoice.lines.data.filter((line) => (
      overageSubscriptionItemIds?.includes(line.subscription_item)
    )).map(l => l.amount));
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format(overage / 100);
  }, [subscription, upcomingInvoice]);

  const fetchUpcomingInvoice = React.useCallback(async (silent = false) => {
    if (!stripeService || !user?.stripeSubscriptionId) { return; }
    setError(undefined);
    if (!silent) { setLoading(true); }
    try {
      const result = await stripeService.create({
        action: 'getUpcomingInvoice',
      });
      setUpcomingInvoice(result);
    } catch (error) {
      setError(get(error, 'message'));
    }
    if (!silent) { setLoading(false); }
  }, [stripeService, user?.stripeSubscriptionId]);

  const fetchCurrentUsageSummary = React.useCallback(async (silent = false) => {
    if (!stripeService || !user?.stripeSubscriptionId) { return; }
    setError(undefined);
    if (!silent) { setLoadingCurrentUsage(true); }
    try {
      const result = await stripeService.create({
        action: 'getUpcomingUsageSummary',
      });

      const usage = Object.keys(result).reduce((acc, key) => {
        if (key === 'base') {
          return { [key]: { ...result[key] } };
        }
        const total = result[key].total;
        const freeQuantity = result[key].freeQuantity;
        const hasOverages = total > freeQuantity;
        const percentageOfFree = hasOverages ? 100 : (total / freeQuantity) * 100;

        return {
          ...acc,
          [key]: {
            ...result[key],
            percentageOfFree,
            overage: hasOverages ? total - freeQuantity : 0,
            hasOverages,
          },
        };
      }, {});

      setCurrentUsage(usage as CurrentUsage);
    } catch (error) {
      setError(get(error, 'message'));
    }
    if (!silent) { setLoadingCurrentUsage(false); }
  }, [stripeService, user?.stripeSubscriptionId]);

  const value = {
    error,
    currentUsage,
    fetchUpcomingInvoice,
    fetchCurrentUsageSummary,
    loading,
    loadingCurrentUsage,
    upcomingInvoice,
    estimatedOverages,
  };

  return (
    <UpcomingInvoiceContext.Provider value={value}>
      {children}
    </UpcomingInvoiceContext.Provider>
  );
};

export default UpcomingInvoiceProvider;
