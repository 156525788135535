import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardLogo from '../CardLogo';

interface Props {
  card: {
    brand: string,
    last4: string;
  },
  onEdit?: () => void;
  direction?: 'row' | 'column';
}

const CardPreview = ({ card, direction = 'row', onEdit }: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Stack spacing={direction === 'row' ? 1 : 0} direction={direction}>
      <Stack direction="row" spacing={1} alignItems="center">
        <CardLogo card={card} />
        {card?.last4 && (
          <Typography>
            {t('common.cardEnding', {
              last4: card?.last4 as string,
            })}
          </Typography>
        )}
      </Stack>
      {!!onEdit && (
        <Button
          onClick={onEdit}
          sx={{
            alignSelf: direction === 'column' ? 'flex-end' : 'center',
          }}
        >
          {t('common.edit')}
        </Button>
      )}
    </Stack>
  );
};

export default CardPreview;
