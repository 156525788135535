import { useKoverse } from '@koverse/react';
import Box from '@mui/material/Box';
import axios from 'axios';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import React from 'react';
import config from '../../config';
import { TransferResourceRequest } from '../../declarations';
import TransferRequestCard from './TransferRequestCard';

interface Props {
  requests: TransferResourceRequest[];
  outgoing?: boolean;
}

const TransferResourceRequests = ({
  requests,
  outgoing = false,
}: Props): React.ReactElement | null => {
  const [submitting, setSubmitting] = React.useState<Record<string, boolean> | null>(null);
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteRequest = async (id: string) => {
    setSubmitting({ ...submitting, [id]: true });
    try {
      await client.service('transfer-resource-requests').remove(id);
      setSubmitting({ ...submitting, [id]: false });
    } catch (e) {
      setSubmitting({ ...submitting, [id]: false });
      enqueueSnackbar(`There was an error: ${get(e, 'message')}`, {
        variant: 'error',
      });
    }
  };

  const handleUpdateRequest = async (id: string, status: string) => {
    setSubmitting({ ...submitting, [id]: true });
    try {
      await client.service('transfer-resource-requests').patch(id, {
        status,
      });
      setSubmitting({ ...submitting, [id]: false });
    } catch (e) {
      setSubmitting({ ...submitting, [id]: false });
      enqueueSnackbar(`There was an error: ${get(e, 'message')}`, {
        variant: 'error',
      });
    }
  };

  const handleResendEmail = async (request: TransferResourceRequest) => {
    const { id } = request;
    const url = `//${config.api}/transfer-resource-requests/${id}/resend`;
    const jwt = await client.authentication.getAccessToken();
    try {
      await axios.post(url, {}, {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
      });
      enqueueSnackbar('Email has been sent', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(`There was an error: ${get(e, 'message')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Box sx={{ mb: 1 }}>
      {requests.map((request: TransferResourceRequest) => {
        if (['revoked', 'accepted'].includes(request.status)) {
          return null;
        }
        return (
          <TransferRequestCard
            key={request.id}
            outgoing={outgoing}
            handleAction={handleUpdateRequest}
            handleDelete={handleDeleteRequest}
            handleResend={handleResendEmail}
            request={request}
            submitting={submitting}
          />
        );
      })}
    </Box>
  );
};

export default TransferResourceRequests;
