import React from 'react';
import get from 'lodash/get';
import { useKoverse } from '@koverse/react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import UserAvatar from '../../UserAvatar';
import { User } from '../../../declarations';

interface Props {
  users: User[];
  onChange: (event: SelectChangeEvent, user: User) => void;
  roomTop: boolean;
}

const UserRoleForm = ({
  users,
  onChange,
  roomTop,
}: Props): React.ReactElement => {
  const { user: currentUser } = useKoverse();
  const { t } = useTranslation();
  const {
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      roles: users.map(user => ({ role: user.role })),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'roles',
  });

  return (
    <form>
      {users.map((user, index) => {
        const isCurrentUser = get(user, 'id') === currentUser.id;
        const displayName = get(user, 'displayName', '');

        return (
          <ListItem
            key={user.id}
            sx={{
              backgroundColor: 'action.disabledBackground',
              mt: roomTop ? 1 : 0,
              mb: index !== users.length - 1 ? 1 : 0,
              minHeight: theme => theme.spacing(7.5),
            }}
          >
            <ListItemAvatar>
              <UserAvatar user={user} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              secondaryTypographyProps={{ noWrap: true }}
              primary={isCurrentUser ? `${displayName} (you)` : displayName}
              secondary={user.email}
            />
            {!!fields.length && (
              <Controller
                key={get(fields, `[${index}].id`)}
                render={(props) => (
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: 150 }}
                  >
                    <Select
                      {...props}
                      label={t('forms.labels.role')}
                      margin="none"
                      onChange={(e) => {
                        setValue(`roles[${index}].role`, e.target.value);
                        onChange(e, user);
                      }}
                      disabled={isCurrentUser}
                    >
                      <MenuItem value="member">Member</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                    </Select>
                  </FormControl>
                )}
                name={`roles[${index}].role`}
                control={control}
                defaultValue={user.role}
                rules={{
                  required: t('forms.validation.required') as string,
                }}
              />
            )}
          </ListItem>
        );
      })}
    </form>
  );
};

export default UserRoleForm;
