import React from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import OnPremInitialization from '../components/WorkspaceInitialization/OnPremInitialization';
import WorkspaceInitialization from '../components/WorkspaceInitialization';
import config from '../config';

const WorkspaceInitializationRoutes = (): React.ReactElement => {
  const beforeUnload = React.useCallback((e) => {
    e.preventDefault();
    e.returnValue = '';
  }, []);

  React.useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [beforeUnload]);

  return (
    <Switch>
      <Route path="/" exact>
        {config.selfHosted
          ? <OnPremInitialization />
          : <WorkspaceInitialization />}
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default WorkspaceInitializationRoutes;
