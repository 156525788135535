import { useServiceItem } from '@koverse/react';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../../../declarations';

interface Props {
  job: Job;
}

const S3Config = ({
  job,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    item: dataset,
  } = useServiceItem('datasets', job.datasetId);

  return (
    <TableContainer>
      <Table aria-label="s3 configuration table">
        <TableHead>
          <TableRow>
            <TableCell width={300}>{t('pages.dataset.jobHistory.sources.sourceType')}</TableCell>
            <TableCell width={200}>{t('pages.dataset.jobHistory.sources.autoIndex')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.region')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.bucket')}</TableCell>
            <TableCell>{t('pages.dataset.jobHistory.sources.recursiveDir')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('pages.dataset.jobHistory.sources.types.s3')}
            </TableCell>
            <TableCell>
              {!dataset
                ? <Skeleton />
                : dataset?.autoCreateIndexes ? 'on' : 'off'
              }
            </TableCell>
            <TableCell>
              {get(job, 'config.connectionInfo.region')}
            </TableCell>
            <TableCell>
              {get(job, 'config.connectionInfo.bucket')}
            </TableCell>
            <TableCell>
              {get(job, 'config.connectionInfo.recursiveDirs') ? 'selected' : 'off'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default S3Config;
