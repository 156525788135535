import React from 'react';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useTranslation, Trans } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import useSubscription from '../../../hooks/useSubscription';

type Props = {
  open: boolean;
  onClose: () => void;
};

const CancelSubscription = ({
  open,
  onClose,
}: Props): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    canceling,
    cancelSubscription,
    subscription,
  } = useSubscription();
  const date = DateTime.fromSeconds(subscription?.current_period_end as number).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });

  const handleOnClose = () => {
    onClose();
  };

  const handleOnClick = async () => {
    try {
      await cancelSubscription();
      enqueueSnackbar(t('dialogs.cancelSubscription.successToast'), {
        variant: 'success',
      });
      onClose();
    } catch (error) {
      enqueueSnackbar(`There was an error: ${error}`);
    }
  };

  return (
    <Dialog open={open} onClose={() => handleOnClose()} aria-labelledby="cancel-subscription" fullWidth>
      <DialogTitle>{t('dialogs.cancelSubscription.title')}</DialogTitle>
      <DialogContent>
        <Typography>
          <Trans
            i18nKey='dialogs.cancelSubscription.content'
            values={{ date }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <>
          <Button
            type="button"
            onClick={() => handleOnClose()}
          >
            {t('common.close')}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={handleOnClick}
            disabled={canceling}
          >
            {t('common.confirm')}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSubscription;
