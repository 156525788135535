import { useKoverse } from '@koverse/react';
import MailIcon from '@mui/icons-material/Mail';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import config from '../../../config';
import { TransferResourceRequest, User } from '../../../declarations';
import UserSearch from './UserSearch';

interface Inputs {
  user: User;
}

interface Props {
  onClose: () => void;
  open: boolean;
}

const TransferWorkspace = ({ open, onClose }: Props): React.ReactElement => {
  const [step, setStep] = React.useState<number>(1);
  const [selectedUser, setSelectedUser] = React.useState<User | undefined>(undefined);
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const [transferRequest, setTrasferRequest] = React.useState<TransferResourceRequest | null>(null);
  const { client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<Inputs>({
    mode: 'onChange',
  });
  const { formState, handleSubmit } = methods;
  const { isValid, isSubmitting } = formState;

  const onSubmit = async (data: Inputs) => {
    try {
      const request = await client.service('transfer-resource-requests').create({
        recipientUserId: data.user.id,
        resourceType: 'workspaces',
        resourceId: workspace.id,
      });
      setTrasferRequest(request);
      setSelectedUser(data.user);
      setStep(2);
    } catch (e) {
      setSubmissionError(get(e, 'message', ''));
    }
  };

  const handleResendEmail = async (request: TransferResourceRequest) => {
    const { id } = request;
    const url = `//${config.api}/transfer-resource-requests/${id}/resend`;
    const jwt = await client.authentication.getAccessToken();
    try {
      await axios.post(url, {}, {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
      });
      enqueueSnackbar('Email has been sent', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(`There was an error: ${get(e, 'message')}`, {
        variant: 'error',
      });
    }
  };

  const userSelection = (
    <>
      <Alert
        variant="outlined"
        severity="error"
        aria-label="cannot be undone"
        sx={{ mb: 2 }}
      >
        This action cannot be undone!
      </Alert>
      <Typography sx={{ mb: 2 }}>
        Select a user to be the new owner of this workspace. The new workspace owner will be able to manage users, groups, applications, and attributes.
      </Typography>
      <Typography>{workspace.name}</Typography>
      <UserSearch />
      <Alert
        variant="outlined"
        severity="info"
        aria-label="existing payment method will transfer"
        sx={{ alignItems: 'center', mt: 2 }}
      >
        The existing payment method will continue to be used until it is updated by the new workspace owner.
      </Alert>
      {submissionError && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{
            width: '100%',
            my: 2,
          }}
        >
          {submissionError}
        </Alert>
      )}
    </>
  );

  const confirmation = (
    <Stack sx={{ px: 2, mt: 4 }} spacing={2} alignItems="center">
      <MailIcon fontSize="large" />
      <Typography variant="h6">A verification email has been sent</Typography>
      {!!selectedUser && (
        <>
          <Box>
            <Typography align="center">
              {`A verification email has been sent to ${selectedUser.displayName}, ${selectedUser.email}.`}
            </Typography>
            <Typography align="center">
              They must accept the request to complete the transfer ownership process.
            </Typography>
          </Box>
          <Stack sx={{ alignItems: 'center' }}>
            <Typography sx={{ mb: 1, mt: 3 }} variant="caption">
              {`${selectedUser.displayName} did not receive the email?`}
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                if (!!transferRequest) {
                  handleResendEmail(transferRequest);
                }
              }}
            >
              Resend Email
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {step === 1 && <DialogTitle>New workspace owner</DialogTitle>}
          <DialogContent>
            {step === 1 && userSelection}
            {step === 2 && confirmation}
          </DialogContent>
          <DialogActions>
            {isSubmitting
              ? <CircularProgress size={30} />
              : (
                <>
                  <Button
                    color="primary"
                    onClick={onClose}
                  >
                    {step === 1 ? 'Cancel' : 'Close'}
                  </Button>
                  {step === 1 && (
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={!isValid}
                    >
                      Next
                    </Button>
                  )}
                </>
              )
            }
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default TransferWorkspace;
