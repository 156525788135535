import { useKoverse } from '@koverse/react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import validator from 'validator';
import config from '../../config';
import useQueryParams from '../../hooks/useQueryParams';

type Inputs = {
  email: string;
};

export default function EnterEmail() {
  const { client } = useKoverse();
  const [submissionError, setSubmissionError] = useState(null);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const { setQueryParam } = useQueryParams();
  const { t } = useTranslation();
  const {
    errors,
    formState,
    handleSubmit,
    register,
  } = useForm<Inputs>({
    mode: 'onChange',
  });
  const { isSubmitting, isValid } = formState;
  const onSubmit = async (data: Inputs) => {
    try {
      const email = data.email.toLowerCase();
      await client.service('auth-management').create({
        action: 'requestWorkspacesByEmail',
        email,
      });
      setQueryParam('email', email);
    } catch (e) {
      setSubmissionError(get(e, 'message', null));
    }
  };
  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>Log into your Workspace</Typography>
      {!config.disableLocalAuth && (
        <React.Fragment>
          <TextField
            InputLabelProps={{
              htmlFor: 'email',
            }}
            id="email"
            sx={{ maxWidth: 400 }}
            margin="normal"
            label={t('forms.labels.email')}
            name="email"
            variant="outlined"
            placeholder={t('forms.placeholders.email')}
            fullWidth
            error={!!errors.email && showEmailError}
            helperText={(showEmailError && errors.email && errors.email.message) || ''}
            inputRef={register({
              required: t('forms.validation.required') as string,
              validate: {
                email: (value: string) => validator.isEmail(value) || t('forms.validation.invalidEmail') as string,
              },
            })}
            onBlur={() => {
              if (!!errors.email) {
                setShowEmailError(true);
              }
            }}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                width: '100%',
                my: 2,
              }}
            >
              {submissionError}
            </Alert>
          )}
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={6}
            sx={{ mt: 2 }}
          >
            {isSubmitting ? (
              <CircularProgress size={36} />
            ) : (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid}
              >
                {t('common.next')}
              </Button>
            )}
            <Typography>
              {'Don\'t have an account? '}
              <Link component={RouterLink} to="/sign-up">
                {t('common.signUp')}
              </Link>
            </Typography>
          </Stack>
        </React.Fragment>
      )}
    </form>
  );
}
