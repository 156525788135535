import { useKoverse } from '@koverse/react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Group } from '../../../declarations';

type Inputs = {
  groupName: string;
};

interface Props {
  group: Group;
  open: boolean;
  onClose: (saved?: boolean) => void;
}

const EditGroupName = ({
  group,
  open,
  onClose,
}: Props): React.ReactElement => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);

  const {
    errors,
    formState,
    handleSubmit,
    register,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      groupName: group.name,
    },
  });

  const {
    isDirty,
    isValid,
    isSubmitting,
  } = formState;

  const onSubmit = async (data: Inputs) => {
    try {
      await client.service('groups').patch(group.id, { name: data.groupName });
      enqueueSnackbar('Saved changes', {
        variant: 'success',
      });
      onClose();
    } catch (e) {
      setSubmissionError(`There was a server error: ${get(e, 'message')}`);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <DialogTitle>Edit group name</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            InputLabelProps={{
              htmlFor: 'groupName',
            }}
            id="groupName"
            label="Group Name"
            name="groupName"
            variant="outlined"
            fullWidth
            error={!!errors.groupName}
            helperText={(errors.groupName && errors.groupName.message) || ''}
            inputRef={register({ required: 'Required' })}
            disabled={isSubmitting}
            margin='none'
          />
          {!!submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                mb: 2,
              }}
            >
              {submissionError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <Button
                type="button"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button
                disabled={!isDirty || !isValid}
                type="submit"
                color="primary"
                variant="contained"
              >
                Save Changes
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditGroupName;
