import { useKoverse } from '@koverse/react';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../declarations';

interface FieldCell {
  field: string;
  label: string;
  width: number;
}

interface Props {
  numSelected: number;
  total: number;
  setSelected: React.Dispatch<React.SetStateAction<User[]>>;
  setSortDir: React.Dispatch<React.SetStateAction<number>>;
  setSortField: React.Dispatch<React.SetStateAction<string>>;
  sortField: string;
  sortDir: number;
}

const UsersTableHead = ({
  numSelected,
  total,
  setSelected,
  setSortDir,
  setSortField,
  sortField,
  sortDir,
}: Props): React.ReactElement => {
  const { client, user } = useKoverse();
  const { t } = useTranslation();

  const cells: FieldCell[] = [{
    label: t('pages.users.columnNames.firstName'),
    field: 'firstName',
    width: 150,
  }, {
    label: t('pages.users.columnNames.lastName'),
    field: 'lastName',
    width: 150,
  }, {
    label: t('pages.users.columnNames.email'),
    field: 'email',
    width: 200,
  }, {
    label: t('pages.users.columnNames.role'),
    field: 'role',
    width: 200,
  }];

  const handleRequestSort = (field: string) => () => {
    const isAsc = sortField === field && sortDir === 1;
    setSortDir(isAsc ? -1 : 1);
    setSortField(field);
  };

  const getAllUsers = React.useCallback(async () => {
    return await client.service('users').find({
      query: { $limit: -1, id: { $nin: [user.id] } },
    });
  }, [client, user.id]);

  const selectAllRows = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const { data } = await getAllUsers();
      setSelected(data);
      return;
    }
    setSelected([]);
  };

  return (
    <TableHead>
      <TableRow>
        {total > 1 && <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < (total - 1)}
            checked={(total - 1) > 0 && numSelected === (total - 1)}
            onChange={selectAllRows}
            inputProps={{
              'aria-label': 'select all users',
            }}
          />
        </TableCell>}
        <TableCell width={73} />
        {cells.map((cell) => (
          <TableCell
            key={cell.field}
            align="left"
            variant="head"
            width={cell.width}
          >
            <TableSortLabel
              active={sortField === cell.field}
              direction={sortDir === 1 ? 'asc' : 'desc'}
              onClick={handleRequestSort(cell.field)}
              aria-label={cell.label}
            >
              {cell.label}
              {sortField === cell.field ? (
                <span
                  style={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {sortDir === 1 ? 'sorted ascending' : 'sorted descending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default UsersTableHead;
