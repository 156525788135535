type Config = {
  api: string,
  disableLocalAuth: boolean,
  documentationDomain: string,
  domain: string,
  githubOauth: boolean,
  googleOauth: boolean,
  keycloakOauth: boolean;
  microsoftOauth: boolean,
  oktaOauth: boolean,
  selfHosted: boolean;
  stripePublishableKey: string,
  workspaceId: string | null;
};

const isDev = process.env.NODE_ENV === 'development';
const runtime = isDev ? process.env : window;

//NOTE: Environment variables added to the runtime need to be added to the configure-build.js, or they will not be set here.
const api = runtime.REACT_APP_KDP_API_DOMAIN as string;
const disableLocalAuth = runtime.REACT_APP_DISABLE_LOCAL_AUTH as string === 'true';
const documentationDomain = runtime.REACT_APP_KDP_DOCUMENTATION_DOMAIN as string;
const domain = runtime.REACT_APP_KDP_FRONTEND_DOMAIN as string;
const githubOauth = runtime.REACT_APP_OAUTH_GITHUB as string === 'true';
const googleOauth = runtime.REACT_APP_OAUTH_GOOGLE as string === 'true';
const keycloakOauth = runtime.REACT_APP_OAUTH_KEYCLOAK as string === 'true';
const microsoftOauth = runtime.REACT_APP_OAUTH_MICROSOFT as string === 'true';
const oktaOauth = runtime.REACT_APP_OAUTH_OKTA as string === 'true';
const selfHosted = runtime.REACT_APP_SELF_HOSTED as string === 'true';
const stripePublishableKey = runtime.REACT_APP_STRIPE_PUBLISHABLE_KEY as string;

const config = {
  api,
  disableLocalAuth,
  documentationDomain,
  domain,
  githubOauth,
  googleOauth,
  keycloakOauth,
  microsoftOauth,
  oktaOauth,
  selfHosted,
  stripePublishableKey,
  workspaceId: window.location.host.replace(domain, '').replace('.', '') || null,
};

export default config as Config;
