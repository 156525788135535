import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { times } from 'lodash';
import React from 'react';
import { Dataset, PaginationModel, QueryResult, ServerError } from '../../declarations';
import { useSearchSelect } from '../../hooks';
import DatasetGridView from './DatasetGridView';
import DatasetTreeView from './DatasetTreeView';
import NoSearchResults from './NoSearchResults';

interface Props {
  collapsed: boolean | number;
  dataset: Dataset;
  disableDataActions: boolean;
  error: ServerError | null;
  loading: boolean;
  queryResult: QueryResult | null;
  showTreeView: boolean;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
}

const DatasetViews = ({
  collapsed,
  dataset,
  disableDataActions,
  error,
  loading,
  queryResult,
  showTreeView,
  paginationModel,
  setPaginationModel,
} : Props): React.ReactElement | null => {
  const { debouncedQuery } = useSearchSelect();
  const { showGridLoader, showTreeLoader } = React.useMemo(() => {
    const showTreeLoader = (loading && showTreeView) || (loading && !!error && showTreeView);
    const showGridLoader = (!queryResult?.records && loading && !showTreeView) || (loading && !!error && !showTreeView);

    return {
      showGridLoader,
      showTreeLoader,
    };
  }, [error, loading, queryResult?.records, showTreeView]);

  if (showTreeLoader) {
    return (
      <Box sx={{ minHeight: !collapsed ? 500 : 250 }}>
        <Divider />
        {times(paginationModel.pageSize as number, (index) => {
          return (
            <Box
              key={index}
              sx={{
                p: 0.5,
                pb: 1,
                pl: 6,
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
              aria-label="placeholder-while-loading-nested-data"
            >
              {collapsed === 1 || !collapsed
                ? (
                  <Box sx={{ p: 2 }}>
                    <Skeleton sx={{ mb: 2, height: 16, width: 150 }} />
                    <Skeleton sx={{ mb: 2, height: 16, width: 200 }} />
                    <Skeleton sx={{ mb: 2, height: 16, width: 100 }} />
                    <Skeleton sx={{ mb: 2, height: 16, width: 350 }} />
                    <Skeleton sx={{ height: 16, width: 350 }} />
                  </Box>
                ) : (
                  <Box sx={{ pt: '13px', pb: '13px' }}>
                    <Skeleton sx={{ height: 16, width: 200 }} />
                  </Box>
                )
              }
            </Box>
          );
        })}
        <Box height={51} />
      </Box>
    );
  }

  if (showGridLoader) {
    return (
      <Table aria-label="placeholder-while-loading-data-table">
        <TableHead>
          <TableRow>
            <TableCell><Skeleton width="100%" /></TableCell>
            <TableCell><Skeleton width="100%" /></TableCell>
            <TableCell><Skeleton width="100%" /></TableCell>
            <TableCell><Skeleton width="100%" /></TableCell>
            <TableCell><Skeleton width="100%" /></TableCell>
            <TableCell align="right"><Skeleton sx={{ width: 24, display: 'inline-table', mr: 1 }} /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {times(paginationModel.pageSize as number, (index) => (
            <TableRow
              key={index}
              aria-label="placeholder-while-loading-row"
              sx={{ height: 50 }}
            >
              <TableCell><Skeleton height={16} width="100%" /></TableCell>
              <TableCell><Skeleton height={16} width="100%" /></TableCell>
              <TableCell><Skeleton height={16} width="100%" /></TableCell>
              <TableCell><Skeleton height={16} width="100%" /></TableCell>
              <TableCell><Skeleton height={16} width="100%" /></TableCell>
              <TableCell align="right">
                <Skeleton
                  height={24}
                  width={24}
                  variant="circular"
                  sx={{ display: 'inline-table', mr: 1 }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  if (!!error && !loading) {
    return (
      <NoSearchResults
        search={debouncedQuery as string}
        error={error}
      />
    );
  }

  if (!!debouncedQuery && queryResult?.records.length === 0) {
    return <NoSearchResults search={debouncedQuery as string} />;
  }

  if (!debouncedQuery && queryResult?.records.length === 0) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography color="textSecondary" align="center">
          There are no records to display.
        </Typography>
      </Box>
    );
  }

  if (!!queryResult) {
    if (showTreeView) {
      return (
        <DatasetTreeView
          collapsed={collapsed}
          disableDataActions={disableDataActions}
          queryResult={queryResult}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      );
    }

    return (
      <DatasetGridView
        dataset={dataset}
        disableDataActions={disableDataActions}
        queryResult={queryResult}
        loading={loading}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    );
  }

  return null;
};

export default DatasetViews;
