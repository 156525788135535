import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import CreateWorkspace from './CreateWorkspace';
import GetStarted from './GetStarted';

const OnPremInitialization = (): React.ReactElement => {
  return (
    <Container
      fixed
      maxWidth="sm"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Paper sx={{ width: '100%', p: 2 }}>
        <GetStarted />
        <CreateWorkspace />
      </Paper>
    </Container>
  );
};

export default OnPremInitialization;
