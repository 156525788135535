import React from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useKoverse } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { makeCancelable } from '../utils';
import { Attribute, User, Group, Cancelable, Resource, Dataset } from '../declarations';

type Option = Attribute | User | Group | Dataset | null;

type AutocompleteService = {
  options: Option[],
  inputValue: string,
  open: boolean,
  value: Option | Option[],
  loading: boolean,
  setOptions: React.Dispatch<React.SetStateAction<Option[]>>,
  setInputValue: React.Dispatch<React.SetStateAction<string>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setValue: React.Dispatch<React.SetStateAction<Option | Option[]>>
  setCurrentIds: React.Dispatch<React.SetStateAction<string[]>>,
}

type useAutocompleteProps ={
  serviceUrl: string,
  fields: string[],
  clearOnSelect?: boolean,
  queryParams?: Record<string, unknown>,
  multiple?: boolean,
}

const useAutocomplete = ({
  serviceUrl,
  fields,
  clearOnSelect = false,
  queryParams = {},
  multiple,
}: useAutocompleteProps): AutocompleteService => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const service = client.service(serviceUrl);
  const [options, setOptions] = React.useState<Option[]>([]);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [currentIds, setCurrentIds] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<Option | Option[] | null>(multiple ? [] : null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [params] = React.useState<Record<string, unknown>>(queryParams);
  const [searchFields] = React.useState(fields);
  const req = React.useRef<null | Cancelable>(null);

  const $or = React.useMemo(() => {
    return !!inputValue
      ? searchFields.map((field: string) => ({
        [field]: { $iLike: `%${inputValue}%` },
      }))
      : undefined;
  }, [inputValue, searchFields]);

  const getData = React.useMemo(() => {
    const query = {
      $sort: { id: 1 },
      id: { $nin: currentIds },
      $or,
      ...params,
    };

    return debounce(async (request: { input: string }, callback: (results?: Option[]) => void) => {
      try {
        setLoading(true);
        req.current = makeCancelable(service.find({ query }));
        const results = await req.current.promise as Resource;
        req.current = null;
        setLoading(false);
        callback(results.data as Option[]);
      } catch (error) {
        setLoading(false);
        if (get(error, 'message')) {
          enqueueSnackbar(`Error: ${get(error, 'message')}`, {
            variant: 'error',
          });
        }
      }
    }, 200);
  }, [currentIds, $or, params, service, enqueueSnackbar]);

  React.useEffect(() => {
    let active = true;

    if (!inputValue && !open) {
      setOptions([]);
      return undefined;
    }

    getData({ input: inputValue }, (results?: Option[]) => {
      if (active) {
        let newOptions = [] as Option[];

        if (value) {
          newOptions = [value].flat();
        }

        if (results) {
          newOptions = [
            ...newOptions,
            ...results,
          ];
        }

        setOptions(newOptions);
      }
    });
    return function cleanup() {
      active = false;
      if (req && req.current) {
        req.current.cancel();
      }
    };
  }, [value, inputValue, getData, open, currentIds]);

  React.useEffect(() => {
    if (clearOnSelect && value && !!inputValue) {
      setValue(null);
      setInputValue('');
    }
  }, [clearOnSelect, value, inputValue]);

  return {
    options,
    setOptions,
    inputValue,
    setInputValue,
    setCurrentIds,
    open,
    setOpen,
    value,
    setValue,
    loading,
  };
};

export default useAutocomplete;
