import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  files: any;
  setFiles: any;
}

const FileDropZone = ({
  files,
  setFiles,
}: Props): React.ReactElement => {
  const onDrop = React.useCallback(async (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  }, [files, setFiles]);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  return (
    <Box
      sx={{
        borderColor: (theme) => theme.palette.divider,
        borderStyle: 'dashed',
        borderRadius: '4px',
        borderWidth: '2px',
        marginBottom: (theme) => theme.spacing(2),
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 400,
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} aria-label="file-input" />
        <FileUploadIcon
          sx={{
            '&.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
              fontSize: 56,
            },
            mb: 1,
          }}
        />
        <Typography paragraph color="textSecondary">Drag and drop files to upload.</Typography>
        <Typography paragraph color="textSecondary">or</Typography>
        <Button type="button" onClick={open} color="primary" variant="outlined">
          Browse Files
        </Button>
      </div>
    </Box>
  );
};

export default FileDropZone;
