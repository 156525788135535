import React from 'react';
import get from 'lodash/get';
import { useKoverse, usePaginatedService } from '@koverse/react';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useAutocomplete from '../../../hooks/useAutocomplete';
import { Dataset, Group, DatasetPermission } from '../../../declarations';

type Inputs = {
  group: Group;
  action: string;
};

interface AddPermissionDialogProps {
  dataset: Dataset;
  open: boolean;
  onClose: () => void;
}

const AddPermissionDialog = ({
  dataset,
  open,
  onClose,
}: AddPermissionDialogProps): React.ReactElement | null => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const [submissionError, setSubmissionError] = React.useState(null);
  const {
    control,
    formState,
    handleSubmit,
    reset,
  } = useForm<Inputs>({
    mode: 'onChange',
  });
  const { isSubmitting, isValid } = formState;
  const {
    open: autocompleteOpen,
    setOpen: setAutocompleteOpen,
    options,
    setCurrentIds,
    loading,
    setInputValue,
  } = useAutocomplete({
    serviceUrl: 'groups',
    fields: ['name'],
    queryParams: { workspaceId: dataset.workspaceId },
  });
  const {
    items: permissions,
  } = usePaginatedService('dataset-permissions', {
    $select: ['groupId'],
    $limit: -1,
    datasetId: dataset.id,
  });

  React.useEffect(() => {
    setCurrentIds(permissions.map((d) => (d as DatasetPermission).groupId));
  }, [permissions, setCurrentIds]);

  const onSubmit = React.useCallback(async (data: Inputs) => {
    try {
      await client.service('dataset-permissions').create({
        datasetId: dataset.id,
        groupId: data.group.id,
        action: data.action,
      });
      onClose();
      enqueueSnackbar('The dataset permission has been created.', {
        variant: 'success',
      });
      reset();
    } catch (error) {
      setSubmissionError(get(error, 'message', null));
    }
  }, [enqueueSnackbar, reset, client, dataset.id, onClose]);

  if (!dataset) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Permission</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            control={control}
            name="group"
            defaultValue={null}
            rules={{ required: true }}
            render={({ onChange, value, ref }) => (
              <Autocomplete
                ref={ref}
                value={value}
                onChange={(_e, newValue) => onChange(newValue)}
                id="group-search"
                blurOnSelect
                open={autocompleteOpen}
                onOpen={() => setAutocompleteOpen(true)}
                onClose={() => setAutocompleteOpen(false)}
                options={options as Group[]}
                getOptionLabel={(option: Group) => option.name}
                isOptionEqualToValue={(option: Group, value: Group) => option.id === value.id }
                filterOptions={(data) => data}
                noOptionsText="No Results"
                onInputChange={(_event: React.ChangeEvent<unknown>, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                sx={{
                  mb: 2,
                  width: 300,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="groupId"
                    fullWidth
                    InputLabelProps={{
                      htmlFor: 'groupId',
                    }}
                    label="Search for Groups"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <ListItem {...props}>
                    <ListItemText
                      primary={option.name}
                      secondary={option.memberCount}
                    />
                  </ListItem>
                )}
              />
            )}
          />
          <FormControl
            variant="outlined"
            margin='normal'
            sx={{
              width: 300,
            }}
          >
            <InputLabel>Action</InputLabel>
            <Controller
              as={
                <Select label="Action">
                  <MenuItem value="read">Read</MenuItem>
                  <MenuItem value="write">Write</MenuItem>
                  <MenuItem value="manage">Manage</MenuItem>
                </Select>
              }
              control={control}
              name="action"
              defaultValue=""
              rules={{ required: true }}
            />
          </FormControl>
          {submissionError && (
            <Typography color="error">{submissionError}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {isSubmitting ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <Button
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid}
                type="submit"
                color="primary"
                variant="contained"
              >
                Add Permission
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPermissionDialog;
