import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import StructuredData from '../AccessControlForm/StructuredData';
import UnstructuredData from '../AccessControlForm/UnstructuredData';

interface Props {
  onBack: () => void;
}

const AccessControlForm = ({
  onBack,
}: Props): React.ReactElement => {
  const { control } = useFormContext();

  const storageType = useWatch({
    control,
    name: 'processAsDocument',
  });

  return storageType === 'data' ? (
    <StructuredData
      onBack={onBack}
    />
  ) : (
    <UnstructuredData
      onBack={onBack}
    />
  );
};

export default AccessControlForm;
