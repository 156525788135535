import React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import times from 'lodash/times';
import AuditLogTableRow from './AuditLogTableRow';
import { AuditLogData } from '../../declarations';

const headers = [{
  key: 'event',
  label: 'Event',
}, {
  key: 'user',
  label: 'User',
}, {
  key: 'date',
  label: 'Date',
}, {
  key: 'time',
  label: 'Time',
}, {
  key: 'ip',
  label: 'IP',
}];

interface AuditLogTableProps {
  data: AuditLogData[];
}

const AuditLogTable = ({ data }: AuditLogTableProps): React.ReactElement => {
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [limit, setLimit] = React.useState<number>(10);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    setLimit(+event.target.value);
  };

  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell width={66}></TableCell>
              {headers.map((header, index) => (
                <TableCell key={header.key} sx={{ paddingRight: 0 }}>
                  <Stack direction="row">
                    {header.label}
                    {index !== headers.length - 1 && <Divider orientation="vertical" sx={{ height: 24, flex: 1 }} />}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data.length > 0 ? (
            <TableBody>
              {data.slice(currentPage * limit, currentPage * limit + limit).map((result: AuditLogData, index: number) => (
                <AuditLogTableRow key={index} result={result} index={index} />
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {times(limit, (index) => (
                <TableRow key={index}>
                  <TableCell><Skeleton aria-label="table-cell-skeleton" /></TableCell>
                  <TableCell><Skeleton aria-label="table-cell-skeleton" /></TableCell>
                  <TableCell><Skeleton aria-label="table-cell-skeleton" /></TableCell>
                  <TableCell><Skeleton aria-label="table-cell-skeleton" /></TableCell>
                  <TableCell><Skeleton aria-label="table-cell-skeleton" /></TableCell>
                  <TableCell><Skeleton aria-label="table-cell-skeleton" /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {data && (
        <TablePagination
          sx={{ p: 1 }}
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={limit}
          page={(currentPage)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default AuditLogTable;
