import React from 'react';
import { useKoverse } from '@koverse/react';
import get from 'lodash/get';
import { Controller, Control, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useAutocomplete } from '../../hooks';
import { Dataset } from '../../declarations';

interface Props {
  index: number;
  name: string;
  control: Control;
}

const DatasetSearch = ({
  index,
  name,
  control,
}: Props): React.ReactElement => {
  const { workspace } = useKoverse();
  const {
    open: autocompleteOpen,
    setOpen: setAutocompleteOpen,
    options,
    loading,
    setInputValue,
  } = useAutocomplete({
    serviceUrl: 'datasets',
    fields: ['name'],
    queryParams: { workspaceId: workspace.id },
  });
  const { clearErrors, errors } = useFormContext();
  const error = get(errors, name);
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      rules={{
        required: true,
      }}
      render={({ onChange, value, ref }) => (
        <Autocomplete
          ref={ref}
          value={value}
          onChange={(_e, newValue) => {
            onChange(newValue);
            clearErrors(name);
          }}
          id={name}
          blurOnSelect
          fullWidth
          open={autocompleteOpen}
          onOpen={() => setAutocompleteOpen(true)}
          onClose={() => setAutocompleteOpen(false)}
          options={options as Dataset[]}
          getOptionLabel={(option: Dataset) => option.name}
          isOptionEqualToValue={(option: Dataset, value: Dataset) => option.id === value.id }
          filterOptions={(data) => data}
          noOptionsText="No Results"
          onInputChange={(_event: React.ChangeEvent<unknown>, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={`dataset-search-${index}`}
              fullWidth
              size="small"
              InputLabelProps={{
                htmlFor: `dataset-search-${index}`,
              }}
              label="Select Dataset"
              variant="outlined"
              error={!!error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          PaperComponent={(props) => <Paper {...props} elevation={4} />}
          renderOption={(props, option) => (
            <ListItem {...props}>
              <ListItemText
                primary={option.name}
              />
            </ListItem>
          )}
          sx={{
            flex: 1,
            mr: 1,
          }}
        />
      )}
    />
  );
};

export default DatasetSearch;
