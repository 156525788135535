import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import createPersistedState from 'use-persisted-state';

interface Inputs {
  processAsDocument: string;
}

interface Params {
  id: string;
}

interface StorageType {
  [datasetId: string]: 'files' | 'data' | '';
}

const useStorageType = createPersistedState<StorageType>('storage-type');

const StorageTypeSelect = (): React.ReactElement => {
  const { id } = useParams<Params>();
  const { control, formState } = useFormContext<Inputs>();
  const [storageType, setStorageType] = useStorageType({ [id]: '' });

  const { errors, touched } = formState;

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 1 }}>
      <FormControl
        fullWidth
        error={get(touched, 'processAsDocument') && !!get(errors, 'processAsDocument')}
        variant="outlined"
      >
        <InputLabel id="storage-type">
          How do you want Koverse to store your files?
        </InputLabel>
        <Controller
          name="processAsDocument"
          control={control}
          rules={{ required: 'Required' }}
          defaultValue={storageType[id] || ''}
          render={(field) => (
            <Select
              {...field}
              label="How do you want Koverse to store your files?"
              id="storage-type"
              labelId="storage-type"
              renderValue={(value) => {
                return value === 'files'
                  ? 'Ingest as original files'
                  : 'Ingest files as data';
              }}
              onChange={(e) => {
                field.onChange(e.target.value);
                setStorageType((prev: StorageType) => {
                  return {
                    ...prev,
                    [id]: e.target.value,
                  };
                });
              }}
            >
              <MenuItem value='files'>
                <div>
                  <Typography>Ingest as original files</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Import files in their original format. Files will not be converted into a data table.
                  </Typography>
                </div>
              </MenuItem>
              <MenuItem value='data'>
                <div>
                  <Typography>Ingest files as data</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Files imported will be converted to display as a data table.
                  </Typography>
                </div>
              </MenuItem>
            </Select>
          )}
        />
      </FormControl>
    </Paper>
  );
};

export default StorageTypeSelect;
