import { useKoverse, usePaginatedService } from '@koverse/react';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isString from 'lodash/isString';
import replace from 'lodash/replace';
import { useSnackbar } from 'notistack';
import React from 'react';
import config from '../../config';
import { Job, Segment } from '../../declarations';
import ConfirmationDialog from '../Dialogs/Confirmation';
import { deleteRecordsRequest } from '../../utils';

interface JobStatusParams {
  job: Job;
  retryClearDataset: React.Dispatch<React.SetStateAction<boolean>>;
}

const JobStatus = ({
  job,
  retryClearDataset,
}: JobStatusParams): React.ReactElement | null => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const [jobToCancel, setJobToCancel] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    items: segments,
  } = usePaginatedService('segments', {
    jobId: get(job, 'id'),
    datasetId: get(job, 'datasetId'),
    $limit: -1,
  });

  const { progressBuffer, progressValue }: any = React.useMemo(() => {
    let progressBuffer = 0;
    let progressValue = 0;

    if (!!segments.length) {
      const totalSegments = segments.length;
      let runningSegments = 0;
      let completedSegments = 0;

      segments.forEach((segment: Segment) => {
        if (segment.status === 'running') { runningSegments += 1; }
        if (segment.status === 'completed') { completedSegments += 1; }
      });

      const percentComplete = completedSegments / totalSegments * 100;
      const bufferValue = percentComplete + (runningSegments / totalSegments * 100);

      progressBuffer = bufferValue >= 90 ? 90 : Math.floor(bufferValue);
      progressValue = Math.floor(percentComplete);
    }

    return {
      progressBuffer,
      progressValue,
    };
  }, [segments]);

  const errorMessage = React.useMemo(() => {
    return segments.reduce((message, segment) => {
      if (segment.status === 'failed' && isString(segment.error?.message)) {
        return segment.error.message;
      }
      return message;
    }, 'There was an error running the job');
  }, [segments]);

  const handleCancelJob = async (id: string) => {
    try {
      await client.service('jobs').patch(id, { status: 'canceled' });
      enqueueSnackbar('The job has been canceled.', {
        variant: 'info',
      });
    } catch (e) {
      enqueueSnackbar(`There was an error: ${get(e, 'message', e)}`, {
        variant: 'error',
      });
    }
  };

  const handleDismissJob = async (id: string) => {
    try {
      await client.service('jobs').patch(id, { dismissed: true });
      enqueueSnackbar('The job has been dismissed.', {
        variant: 'info',
      });
    } catch (e) {
      enqueueSnackbar(`There was an error: ${get(e, 'message', e)}`, {
        variant: 'error',
      });
    }
  };

  const retryIngest = React.useCallback(async () => {
    const ingestUrl = `//${config.api}/ingest`;
    try {
      setLoading(true);
      const jwt = await client.authentication.getAccessToken();
      await axios.post(ingestUrl, {
        workspaceId: job.workspaceId,
        datasetId: job.datasetId,
        dataSourceParams: job.config,
        retryId: job.id,
      }, {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
      });
      await client.service('jobs').patch(job.id, { dismissed: true });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(`There was an error: ${get(e, 'message', e)}`, {
        variant: 'error',
      });
    }
  }, [client, enqueueSnackbar, job.config, job.datasetId, job.id, job.workspaceId]);

  const retryDeleteRecords = React.useCallback(async () => {
    const  { config: requested } = job;
    const datasetId = job.datasetId;
    const deleteAll = requested?.deleteAll as boolean;
    const documentDelete = job.type === 'delete-document';
    const query = requested?.query ? requested.query as string : '';
    const recordIds = requested?.recordIds as string[];
    const { url, payload } = deleteRecordsRequest({ datasetId, deleteAll, documentDelete, query, recordIds });

    try {
      setLoading(true);
      const jwt = await client.authentication.getAccessToken();
      await axios.post(url, payload, {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
      });
      await client.service('jobs').patch(job.id, { dismissed: true });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(`There was an error: ${get(e, 'message', e)}`, {
        variant: 'error',
      });
    }
  }, [client, enqueueSnackbar, job]);

  const handleRetryJob = async (type: string) => {
    switch (type) {
      case 'ingest':
        retryIngest();
        break;
      case 'clear-dataset':
        retryClearDataset(true);
        break;
      case 'delete-document':
      case 'delete-record':
        retryDeleteRecords();
        break;
      default:
        break;
    }
  };

  if (!job || job.status === 'completed' || job.status === 'canceled') {
    return null;
  }

  return (
    <>
      <Alert
        icon={
          job.status === 'failed'
            ? <ErrorIcon color="error" />
            : false
        }
        sx={{
          borderRadius: 0,
          position: 'relative',
          borderBottom: (theme) => job.status === 'failed'
            ? `1px solid ${theme.palette.divider}`
            : 'none',
          color: 'text.secondary',
          bgcolor: 'background.default',
          mb: 2,
          alignItems: 'center',
        }}
        action={(
          <>
            {job.status === 'failed' && (
              <Button
                color="primary"
                sx={{ mr: 1 }}
                onClick={() => handleRetryJob(job.type)}
                disabled={loading}
              >
                Retry
              </Button>
            )}
            {job.status !== 'failed' && !['clear-dataset'].includes(job.type) && (
              <Button
                color="primary"
                sx={{ mr: 1 }}
                onClick={() => setJobToCancel(job.id)}
                disabled={loading}
              >
                Stop
              </Button>
            )}
            {job.status === 'failed' && (
              <Button
                color="primary"
                sx={{ mr: 1 }}
                onClick={() => handleDismissJob(job.id)}
                disabled={loading}
              >
                Dismiss
              </Button>
            )}
          </>
        )}
      >
        <Typography display="inline" color="textSecondary">
          {`${capitalize(replace(job.type, '-', ' '))}: `}
        </Typography>
        <Typography
          display="inline"
          color={job.status === 'failed' ? 'error' : 'textPrimary'}
        >
          {job.status}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          display="block"
          sx={{ wordBreak: 'break-word' }}
        >
          {job.status === 'failed'
            ? errorMessage
            : 'This may take several minutes'
          }
        </Typography>
        {job.status !== 'failed' && (
          <LinearProgress
            variant={segments?.length > 1 ? 'buffer' : 'indeterminate'}
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
            }}
            {...(segments?.length > 1 && {
              valueBuffer: progressBuffer,
              value: progressValue,
            })}
          />
        )}
        {loading && (
          <LinearProgress
            variant="indeterminate"
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
            }}
          />
        )}
      </Alert>
      {jobToCancel && (
        <ConfirmationDialog
          maxWidth="xs"
          fullWidth
          open={!!jobToCancel}
          onClose={() => setJobToCancel(null)}
          onConfirm={() => {
            handleCancelJob(jobToCancel);
            setJobToCancel(null);
          }}
          title={'Stop import?'}
          confirmButtonText="Confirm"
          cancelButtonText="Go Back"
        >
          {job.status !== 'failed' ? (
            <Typography>
              This will stop your dataset {job.type}.
            </Typography>
          ) : (
            <Typography>
              Canceling will stop your dataset {job.type}.
            </Typography>
          )}
        </ConfirmationDialog>
      )}
    </>
  );
};

export default JobStatus;
