import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridViewIcon from '@mui/icons-material/GridView';
import SearchIcon from '@mui/icons-material/Search';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import pluralize from 'pluralize';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSearchSelect from '../../hooks/useSearchSelect';
import StyledInput from './StyledInput';
import StyledSelect from './StyledSelect';

interface Props {
  collapsed: boolean | number;
  datasetPermissions?: { [key: string]: boolean };
  documentToggleEnabled: boolean;
  documentView: boolean;
  disableDataActions: boolean;
  openDeleteDialog: React.Dispatch<React.SetStateAction<{ deleteAll: boolean, displayedTotal: string, documentView: boolean } | null>>;
  openUploadDrawer: () => void;
  toggleCollapsed?: () => void | undefined;
  toggleDocumentView?: () => void | undefined;
  totalResults: number;
}

const DataViewSearchBar = ({
  collapsed,
  datasetPermissions,
  documentToggleEnabled,
  documentView,
  disableDataActions,
  openDeleteDialog,
  openUploadDrawer,
  toggleCollapsed,
  toggleDocumentView,
  totalResults,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    query,
    setQuery,
    setScope,
    selectedIds,
    setSelectedIds,
    resultIds,
  } = useSearchSelect();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectAll, setSelectAll] = React.useState<boolean>(false);
  const resultIdsRef = React.useRef(resultIds);
  const selectedIdsRef = React.useRef(selectedIds.length);
  const open = Boolean(anchorEl);
  const userCanWrite = datasetPermissions?.manage || datasetPermissions?.write;

  const { allVisibleSelected, someVisibleSelected } = React.useMemo(() => {
    const visibleSelected = intersection(selectedIds, resultIds).length;
    const allSelected = !!resultIds.length && visibleSelected === resultIds.length;

    return {
      allVisibleSelected: allSelected,
      someVisibleSelected: !allSelected && visibleSelected > 0,
    };
  }, [resultIds, selectedIds]);

  const displayedTotal = React.useMemo(() => {
    return totalResults > 9999 ? '9999+' : String(totalResults);
  }, [totalResults]);

  const displayedType = React.useMemo(() => {
    return documentView ? 'document' : 'record';
  }, [documentView]);

  const handleScopeChange = (e: SelectChangeEvent<unknown>) => setScope(e.target.value === 'all' ? 'all' : undefined);
  const handleOpenSelectionMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleCloseSelectionMenu = () => setAnchorEl(null);

  const deleteRecords = () => {
    openDeleteDialog({
      deleteAll: selectAll,
      displayedTotal,
      documentView,
    });
  };

  const updateSelectedIds = React.useCallback((updatedIds: string[]) => {
    setSelectedIds(updatedIds);
    setAnchorEl(null);
  }, [setSelectedIds]);

  const onSelectAllVisible = React.useCallback(() => {
    const updatedIds = uniq([...selectedIds, ...resultIds]);
    updateSelectedIds(updatedIds);
  }, [resultIds, selectedIds, updateSelectedIds]);

  const onSelectCheckbox = React.useCallback(() => {
    let updatedIds = [];
    if (allVisibleSelected || someVisibleSelected) {
      updatedIds = difference(selectedIds, resultIds);
    } else {
      updatedIds = uniq([...selectedIds, ...resultIds]);
    }

    updateSelectedIds(updatedIds);
  }, [allVisibleSelected, someVisibleSelected, updateSelectedIds, selectedIds, resultIds]);

  const onClearAll = React.useCallback(() => {
    setSelectedIds(difference(selectedIds, resultIds));
    handleCloseSelectionMenu();
  }, [resultIds, selectedIds, setSelectedIds]);

  React.useEffect(() => {
    if (!isEqual(resultIdsRef.current, resultIds) && selectAll) {
      setSelectAll(false);
      setSelectedIds([]);
    }

    if (selectedIdsRef.current !== selectedIds.length && selectAll) {
      setSelectAll(false);
    }

    resultIdsRef.current = resultIds;
    selectedIdsRef.current = selectedIds.length;
  }, [resultIds, selectAll, selectedIds.length, setSelectedIds]);

  return (
    <Toolbar
      sx={{
        '&.MuiToolbar-root': {
          px: 0.5,
        },
        backgroundColor: !!selectedIds.length ? 'rgba(255, 255, 255, 0.12)' : 'transparent',
      }}
    >
      <Checkbox
        indeterminate={someVisibleSelected}
        checked={allVisibleSelected}
        disabled={disableDataActions}
        onClick={onSelectCheckbox}
      />
      <IconButton onClick={handleOpenSelectionMenu}>
        <ExpandMoreIcon sx={{ color: t => t.palette.text.secondary }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseSelectionMenu}
        PaperProps={{ sx: { minWidth: 150 } }}
      >
        <MenuItem disabled={disableDataActions} onClick={onSelectAllVisible}>All</MenuItem>
        <MenuItem disabled={disableDataActions} onClick={onClearAll}>None</MenuItem>
      </Menu>
      <Divider orientation="vertical" sx={{ height: 24, mx: 2 }} />
      {toggleCollapsed && (
        <Button
          onClick={toggleCollapsed}
          sx={{ mr: 2 }}
          disabled={documentView}
        >
          {collapsed === true || collapsed === 1
            ? t('common.expandAll')
            : t('common.collapseAll')}
        </Button>
      )}
      {!!selectedIds.length ? (
        <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1}>
          <Box display="flex" alignItems="center">
            {selectAll || selectedIds.length === totalResults ? (
              <Typography>{`Selected all ${displayedTotal} ${pluralize(displayedType, totalResults)} ${query && `for "${query}"`}`}</Typography>
            ) : (
              <>
                <Typography>{`Selected ${selectedIds.length} ${pluralize(displayedType, selectedIds.length)} ${query && `for "${query}"`}`}</Typography>
                {allVisibleSelected && (
                  <Button
                    color="primary"
                    sx={{ textTransform: 'none', fontSize: '16px', marginLeft: 3 }}
                    onClick={() => setSelectAll(true)}
                  >
                    {`Select all ${displayedTotal} ${pluralize(displayedType, totalResults)}`}
                  </Button>
                )}
              </>
            )}
          </Box>
          <Tooltip title="Delete">
            <span>
              <IconButton
                onClick={deleteRecords}
                disabled={!userCanWrite}
                aria-label="delete selected records"
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ) : (
        <>
          <StyledInput
            placeholder="Search"
            inputProps={{ 'aria-label': 'search' }}
            fullWidth
            size="small"
            value={query}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="clear search box"
                  onClick={() => setQuery('')}
                  onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                  }}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <StyledSelect
            size="small"
            value="this"
            onChange={handleScopeChange}
          >
            <MenuItem value="this">
              This Dataset
            </MenuItem>
            <MenuItem value="all">
              All Datasets
            </MenuItem>
          </StyledSelect>
          {!documentView && documentToggleEnabled && (
            <IconButton
              sx={{ ml: 1 }}
              aria-label="toggle document grid"
              aria-controls="toggle-document-grid"
              onClick={toggleDocumentView}
            >
              <GridViewIcon />
            </IconButton>
          )}
          {documentView && (
            <IconButton
              sx={{ ml: 1 }}
              aria-label="toggle document grid"
              aria-controls="toggle-document-grid"
              onClick={toggleDocumentView}
            >
              <ViewListOutlinedIcon />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {userCanWrite && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              aria-label="open upload drawer"
              aria-controls="open-upload-drawer"
              onClick={openUploadDrawer}
              disabled={disableDataActions}
            >
              {t('common.addData')}
            </Button>
          )}
        </>
      )}
    </Toolbar>
  );
};

export default DataViewSearchBar;
