import { useKoverse } from '@koverse/react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React from 'react';
import { Dataset } from '../../../declarations';
import get from 'lodash/get';
import { useSearchSelect } from '../../../hooks';
import pluralize from 'pluralize';
import { useSnackbar } from 'notistack';
import { deleteRecordsRequest } from '../../../utils';

type Props = {
  dataset: Dataset;
  deleteAll?: boolean;
  displayedTotal?: string;
  documentView?: boolean;
  onClose: () => void;
  open: boolean;
};

const DeleteRecords = ({
  dataset,
  deleteAll,
  displayedTotal,
  documentView,
  onClose,
  open,
}: Props): React.ReactElement | null => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedIds,
    setSelectedIds,
    query,
  } = useSearchSelect();

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [serverError, setServerError] = React.useState<string | null>(null);

  const { displayedType, displayedTitleType } = React.useMemo(() => {
    const displayedType = documentView ? 'document' : 'record';
    return {
      displayedType,
      displayedTitleType: displayedType.charAt(0).toUpperCase() + displayedType.slice(1),
    };
  }, [documentView]);

  const numRecords = React.useMemo(() => {
    return selectedIds.length;
  }, [selectedIds.length]);

  const recordIds = React.useMemo(() => {
    return selectedIds.reduce((acc: string[], val: string) => [...acc, ...val.split('::')], []);
  }, [selectedIds]);

  const { message, title } = React.useMemo(() => {
    const hasRecords = numRecords > 0 && !deleteAll;
    const allText = !!query && deleteAll
      ? `all ${displayedTotal} queried ${displayedType}s`
      : `all ${displayedTotal} ${displayedType}s`;
    const message = hasRecords
      ? `You have selected to delete ${numRecords} ${pluralize(displayedType, numRecords)} from this dataset.`
      : `You have selected to delete ${allText} from this dataset.`;
    const title = hasRecords
      ? `Delete ${numRecords} ${pluralize(displayedTitleType, numRecords)}`
      : `Delete ${displayedTitleType}s`;

    return { message, title };
  }, [deleteAll, displayedTitleType, displayedTotal, displayedType, numRecords, query]);

  const handleOnClose = React.useCallback(() => {
    setServerError(null);
    onClose();
  }, [onClose]);

  const handleOnConfirm = React.useCallback(async () => {
    const datasetId = dataset.id;
    const documentDelete = (!!documentView && !!deleteAll);
    const { url, payload } = deleteRecordsRequest({ datasetId, deleteAll, documentDelete, query, recordIds });
    const jwt = await client.authentication.getAccessToken();

    try {
      setSubmitting(true);

      await axios.post(url, payload, {
        headers: {
          'Authorization': `Bearer ${jwt}`,
        },
      });

      setSubmitting(false);
      setSelectedIds([]);

      enqueueSnackbar('Deleting Records', {
        variant: 'success',
      });

      handleOnClose();
    } catch (e) {
      enqueueSnackbar(`There was an error: ${get(e, 'message', e)}`, {
        variant: 'error',
      });
      setSubmitting(false);
      onClose();
    }
  }, [query, deleteAll, documentView, dataset.id, recordIds, client.authentication, setSelectedIds, enqueueSnackbar, handleOnClose, onClose]);

  if (!dataset) {
    return null;
  }

  return (
    <Dialog open={open} onClose={() => handleOnClose()} aria-labelledby="dialog-title" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Alert
          sx={{ mb: 2 }}
          severity="error"
          variant="outlined"
        >
          This action cannot be undone!
        </Alert>
        <Typography sx={{ mb: 3 }}>
          {message}
        </Typography>
        {serverError && (
          <Alert
            severity="error"
            variant="outlined"
            sx={{
              mt: 2,
            }}
          >
            {serverError}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {submitting ? (
          <CircularProgress size={30} />
        ) : (
          <>
            <Button
              type="button"
              color="primary"
              onClick={() => handleOnClose()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleOnConfirm()}
            >
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRecords;
