import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { ThirdPartyApplication } from '../../declarations';
import EditApplication from '../Dialogs/EditApplication';
import DeleteApplication from '../Dialogs/DeleteApplication';

interface Props {
  application: ThirdPartyApplication;
}

const OptionsMenu = ({
  application,
}: Props): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const [editOpen, setEditOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLDivElement | null>(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box>
        <Box ref={anchorRef}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            aria-controls={open ? 'button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="open-options-menu"
            aria-haspopup="menu"
            onClick={handleToggle}
            endIcon={<ArrowDropDownIcon />}
          >
            Options
          </Button>
        </Box>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={4}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem
                    divider
                    onClick={() => {
                      setEditOpen(true);
                      setOpen(false);
                    }}
                  >
                    Rename application
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setDeleteOpen(true);
                      setOpen(false);
                    }}
                  >
                    Delete application
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {editOpen && (
        <EditApplication
          open={editOpen}
          onClose={() => setEditOpen(false)}
          application={application}
        />
      )}
      {deleteOpen && (
        <DeleteApplication
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          application={application}
        />
      )}
    </>
  );
};

export default OptionsMenu;
