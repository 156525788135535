import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import keyBy from 'lodash/keyBy';
import times from 'lodash/times';
import pluralize from 'pluralize';
import React from 'react';
import { useParams } from 'react-router-dom';
import createPersistedState from 'use-persisted-state';
import config from '../../../config';
import { DocumentQueryRecord, DocumentRecord } from '../../../declarations';
import { useQueryRequest, useSearchSelect } from '../../../hooks';
import DocumentPreviewDrawer from '../../DocumentPreviewDrawer';
import NoSearchResults from '../NoSearchResults';
import DocumentThumbnailCard from './DocumentThumbnailCard';
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';

interface Params {
  id: string;
  workspaceId: string;
}

const useRecordLimitState = createPersistedState<number>('document-limit');

interface Props {
  disableDataActions: boolean;
  setQueryResultTotal: React.Dispatch<React.SetStateAction<number>>;
}

const DocumentPreview = ({ disableDataActions, setQueryResultTotal }: Props): React.ReactElement | null => {
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [document, setDocument] = React.useState<DocumentRecord | null>(null);
  const [limit, setLimit] = useRecordLimitState(5);
  const { id } = useParams<Params>();
  const { error, loading, queryResult, fetchData } = useQueryRequest('document');
  const {
    debouncedQuery,
    scope,
    selectedIds,
    setSelectedIds,
    setResultIds,
  } = useSearchSelect();

  const expressionRef = React.useRef<string>('');
  const suppress = React.useRef<boolean>(false);

  const newSearch = expressionRef.current !== debouncedQuery;

  const selectedRecordMap = React.useMemo(() => keyBy(selectedIds), [selectedIds]);

  const queryResultIds = React.useMemo(() => {
    if (!queryResult?.records || queryResult?.records.length === 0) {
      return [];
    }
    return queryResult?.records.map((r: DocumentQueryRecord) => {
      const metadata = r._koverse_document_metadata;
      return !!metadata.documentMetadataRowId
        ? `${metadata.documentRowId}::${metadata.documentMetadataRowId}`
        : metadata.documentRowId;
    });
  }, [queryResult?.records]);

  const handleResetPage = React.useCallback(() => {
    // reset page to 0 and update suppress.current so the additional fetch
    // caused by the page update is not called
    suppress.current = true;
    setCurrentPage(0);
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => setCurrentPage(newPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    setLimit(+event.target.value);
  };

  const handleSetChecked = (recordId: string) => {
    return (checked: boolean) => {
      if (checked) {
        setSelectedIds((prev: GridRowSelectionModel) => [...prev, recordId]);
      } else {
        setSelectedIds((prev: GridRowSelectionModel) => prev.filter((prevId: GridRowId) => prevId !== recordId));
      }
    };
  };

  React.useEffect(() => {
    // if there is a new search term reset page to 0
    if (newSearch) {
      handleResetPage();
    }
  }, [handleResetPage, newSearch]);

  React.useEffect(() => {
    if (scope !== 'all') {
      // update the expression ref
      expressionRef.current = debouncedQuery;

      // ignore fetch data if the search is new and page has not reset to 0
      if (suppress.current && currentPage !== 0) {
        // reset supress.current
        suppress.current = false;
        return;
      }

      fetchData({
        datasetId: id,
        expression: debouncedQuery,
        limit,
        offset: currentPage * limit,
      });
    }

    return () => {
      suppress.current = false;
      expressionRef.current = '';
    };
  }, [currentPage, debouncedQuery, fetchData, id, limit, scope]);

  React.useEffect(() => {
    setResultIds(queryResultIds);
  }, [queryResultIds, setResultIds]);

  React.useEffect(() => {
    if (queryResult?.total) {
      setQueryResultTotal(queryResult.total);
    }
  }, [queryResult?.total, setQueryResultTotal]);

  if (!debouncedQuery && queryResult?.records.length === 0 && !loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <DescriptionOutlinedIcon
          sx={{
            mt: 4,
            mb: 1,
            fontSize: 44,
            color: t => t.palette.text.secondary,
          }}
        />
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Your document view is empty
        </Typography>
        <Typography color="textSecondary">
          Documents may not be uploaded to this dataset
        </Typography>
        <Typography color="textSecondary">
          or <Link href={`//${config.documentationDomain}/docs/datasets/abac-attributes/abac-attribute-fields/`} target="_blank" rel="noreferrer noopener" sx={{ textDecoration: 'none' }}>ABAC</Link>
          &nbsp;may have been applied to restrict document viewing access.
        </Typography>
      </Box>
    );
  }

  if (!!debouncedQuery && queryResult?.records.length === 0 && !loading) {
    return (
      <NoSearchResults search={debouncedQuery as string} />
    );
  }

  if (!!error) {
    return (
      <NoSearchResults
        search={debouncedQuery as string}
        error={error}
      />
    );
  }

  return (
    <>
      <Box sx={{ mt: 2, pl: 2, pr: 2, pb: 2 }}>
        <Box sx={{ mb: 2 }}>
          {!queryResult && !queryResult?.total ? (
            <Typography component="div"><Skeleton width={150} /></Typography>
          ) : (
            <Typography display="inline" color="textPrimary">
              {queryResult.total}
              <Typography display="inline" color="textSecondary" component="span">
                {` Available ${pluralize('document', queryResult.total)} in this dataset.`}
              </Typography>
            </Typography>
          )}
        </Box>
        <Grid container columns={10} spacing={2}>
          {(loading || !queryResult?.records) ? times(limit, (index: number) => (
            <Grid item xs={5} sm={3} md={2} key={index}>
              <Card aria-label="placeholder-while-loading-thumbnail">
                <CardContent sx={{ height: 268, p: 0 }}>
                  <Skeleton variant="rectangular" sx={{ height: 260 }} />
                </CardContent>
                <CardActions>
                  <Skeleton width={150} height={20} />
                  <div style={{ flex: 1 }} />
                  <Skeleton variant="circular"
                    sx={{
                      width: 32,
                      height: 32,
                      ml: -1,
                    }}
                  />
                </CardActions>
              </Card>
            </Grid>
          )) : queryResult.records.map((record: DocumentQueryRecord, index: number) => {
            const metadata = record._koverse_document_metadata;
            const recordId = !!metadata.documentMetadataRowId
              ? `${metadata.documentRowId}::${metadata.documentMetadataRowId}`
              : metadata.documentRowId;

            if (!metadata || !record.filename || !record.metadata) {
              return (
                <Grid item xs={5} sm={3} md={2} key={index}>
                  <Card>
                    <CardContent
                      sx={{
                        p: 0,
                        background: (theme) => theme.palette.common.white,
                        height: 260,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                        }}
                      >
                        <ErrorOutlineIcon
                          sx={{
                            color: theme => {
                              return theme.palette.mode === 'dark'
                                ? theme.palette.grey[500]
                                : theme.palette.text.disabled;
                            },
                            fontSize: 80,
                          }}
                        />
                      </Box>
                    </CardContent>
                    <CardActions>
                      <Typography variant="caption" sx={{ pt: 1, pb: 1 }}>
                        Document cannot be displayed
                      </Typography>
                    </CardActions>
                  </Card>
                </Grid>
              );
            }

            return (
              <Grid item xs={5} sm={3} md={2} key={record._koverse_document_metadata.document_id}>
                <DocumentThumbnailCard
                  record={record}
                  openDocumentPreview={(doc) => setDocument(doc)}
                  showSelectionCheckbox={ true }
                  checked={!!selectedRecordMap[recordId] || false}
                  setChecked={handleSetChecked(recordId)}
                  disableDataActions={disableDataActions}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {queryResult?.total && (
        <TablePagination
          component="div"
          count={queryResult.total}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={currentPage}
          rowsPerPageOptions={[5, 10, 25, 100]}
          rowsPerPage={limit}
          labelRowsPerPage="Documents per page:"
        />
      )}
      <DocumentPreviewDrawer
        datasetId={id}
        document={document}
        open={!!document}
        onClose={() => setDocument(null)}
      />
    </>
  );
};

export default DocumentPreview;
