import { useKoverse, usePaginatedService } from '@koverse/react';
import InfoIcon from '@mui/icons-material/HelpOutlineOutlined';
import ListIcon from '@mui/icons-material/List';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import differenceBy from 'lodash/differenceBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { Dataset, Index, Job } from '../../../declarations';
import IndexManagement from './IndexManagement';

interface IndexSettingsProps {
  dataset: Dataset;
  jobs: Job[];
}

const TOOLTIP_TOP = `
  Enabling this feature will allow users to
  search datasets using general search parameters.
  This increases storage usage.`;

const TOOLTIP_BOTTOM = `
  Disabling this feature will require users to input specific search
  parameters when querying a dataset to return results.
  This option optimises storage usage.`;

const IndexSettings = ({
  dataset,
  jobs,
}: IndexSettingsProps): React.ReactElement | null => {
  const { client } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const indexManagementService = client.service('index-management');
  const [autoCreateIndexes, setAutoCreateIndexes] = React.useState<boolean>(dataset.autoCreateIndexes);
  const [searchAnyField, setSearchAnyField] = React.useState<boolean>(dataset.searchAnyField);
  const [hasUnsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
  const [indexRef, setIndexRef] = React.useState<Index[]>([]);
  const [indexes, setIndexes] = React.useState<Index[]>([]);
  const [selected, setSelected] = React.useState<readonly Index[]>([]);

  const {
    items: initialIndexes,
  } = usePaginatedService('indexes', {
    datasetId: dataset.id,
    $select: ['id', 'datasetId', 'fields'],
    $limit: -1,
  });

  const handleChangeCreateIndexes = () => {
    if (!autoCreateIndexes) {
      setSearchAnyField(true);
    }
    setAutoCreateIndexes(!autoCreateIndexes);
  };
  const handleChangeEnableFieldSearch = () => setSearchAnyField(!searchAnyField);

  const handleOnSave = async () => {
    try {
      const create = indexes.filter((d) => !d.id);
      const remove = differenceBy(indexRef, indexes, 'id');
      const payload = {
        action: 'modifyIndexes',
        datasetId: dataset.id,
        autoCreateIndexes,
        searchAnyField,
        remove,
        create,
      };
      await indexManagementService.create(payload);
      enqueueSnackbar('Indexes have been updated, re-indexing will begin shortly.', {
        variant: 'success',
        autoHideDuration: 5000,
      });
    } catch (error) {
      enqueueSnackbar(`There was an error: ${get(error, 'message', error)}`, {
        variant: 'error',
      });
    }
  };

  const getFormattedIndexes = React.useMemo(() => {
    return (data: Index[], callback: (data?: Index[]) => void) => {
      const formattedIndexes = map(data, (index: Index) => ({
        name: index.fields.join(', '),
        ...index,
      }));
      callback(formattedIndexes);
    };
  }, []);

  React.useEffect(() => {
    let active = true;
    getFormattedIndexes(initialIndexes, (data?: Index[]) => {
      if (active && data) {
        setIndexRef(data);
        setIndexes(data);
      }
    });
    return () => {
      active = false;
    };
  }, [initialIndexes, getFormattedIndexes]);

  React.useEffect(() => {
    const hasChanges = !isEqual(indexes, indexRef)
      || !isEqual(autoCreateIndexes, dataset.autoCreateIndexes)
      || !isEqual(searchAnyField, dataset.searchAnyField);
    setUnsavedChanges(hasChanges);
  }, [indexes, autoCreateIndexes, searchAnyField, indexRef, dataset]);

  const reset = React.useCallback(() => {
    setIndexes(indexRef);
    setSearchAnyField(dataset.searchAnyField);
    setSelected([]);
  }, [setIndexes, setSearchAnyField, indexRef, dataset.searchAnyField]);

  React.useEffect(() => {
    if (autoCreateIndexes) {
      reset();
    }
  }, [autoCreateIndexes, hasUnsavedChanges, reset]);

  if (!dataset) {
    return null;
  }

  const StyledTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: autoCreateIndexes || !!jobs.length
      ? theme.palette.text.disabled
      : theme.palette.text.primary,
  }));

  return (
    <>
      <Box sx={{ px: 2, mb: 3 }}>
        <Typography variant="h5">Index Settings</Typography>
        <Typography variant="body2" color="textSecondary">
          Changes to your index will take effect immediately.
        </Typography>
      </Box>
      <Box>
        <Toolbar
          sx={{
            '&.MuiToolbar-root': {
              px: 2,
            },
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              color: theme => !!jobs.length ? theme.palette.text.disabled : theme.palette.text.primary,
            }}
          >
            Auto Indexing
          </Typography>
          <Box sx={{ flex: 1 }} />
          <FormControlLabel
            control={
              <Switch
                disabled={!!jobs.length}
                color="primary"
                checked={autoCreateIndexes}
                onChange={handleChangeCreateIndexes}
              />
            }
            label={autoCreateIndexes ? 'ON' : 'OFF'}
            sx={{
              pr: autoCreateIndexes ? '6px' : 0,
            }}
          />
        </Toolbar>
        <Divider />
        <Box sx={{ p: 2 }}>
          <StyledTypography
            variant="h6"
            gutterBottom
          >
            Index
          </StyledTypography>
          <StyledTypography
            variant="body2"
            gutterBottom
          >
            Auto indexing must be turned off to make changes to your index.
          </StyledTypography>
          <FormControlLabel
            disabled={autoCreateIndexes || !!jobs.length}
            control={
              <Checkbox
                color="primary"
                checked={searchAnyField}
                onChange={handleChangeEnableFieldSearch}
              />
            }
            label={
              <Stack
                flexDirection="row"
                alignItems="center"
              >
                <Typography>Enable search without specifying field name</Typography>
                <Tooltip
                  title={
                    <>
                      <Typography variant="caption" display="block">{TOOLTIP_TOP}</Typography>
                      <Box sx={{ mb: 1 }} />
                      <Typography variant="caption">{TOOLTIP_BOTTOM}</Typography>
                    </>
                  }
                  placement="right"
                >
                  <IconButton>
                    <InfoIcon color={autoCreateIndexes || !!jobs.length ? 'disabled' : 'inherit'} />
                  </IconButton>
                </Tooltip>
              </Stack>
            }
          />
        </Box>
        <Divider sx={{ mx: 2 }} />
        <StyledTypography sx={{ m: 2 }}>Index Manager</StyledTypography>
        {!isEmpty(dataset?.schema)
          ? (
            <IndexManagement
              dataset={dataset}
              disabled={autoCreateIndexes || !!jobs.length}
              indexes={indexes}
              selected={selected}
              setIndexes={setIndexes}
              setSelected={setSelected}
            />
          )
          : (
            <Box sx={{ pt: 3, pb: 10 }}>
              <Stack
                flexDirection="column"
                alignItems="center"
              >
                <ListIcon
                  color={autoCreateIndexes ? 'disabled' : 'inherit'}
                  sx={{ fontSize: 60, mb: 1 }}
                />
                <StyledTypography variant="h6">Your index is empty</StyledTypography>
                <StyledTypography>Upload some data to manage your index.</StyledTypography>
              </Stack>
            </Box>
          )
        }
      </Box>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          py: 3,
          px: 2,
        }}
      >
        <Button
          color="primary"
          aria-label="navigate-back"
          onClick={() => history.push(`/datasets/${dataset.id}`)}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          aria-label="save"
          sx={{ ml: 2 }}
          onClick={handleOnSave}
          disabled={!hasUnsavedChanges}
        >
          Save
        </Button>
      </Stack>
      <Prompt
        when={hasUnsavedChanges}
        message={`
          Changes have been made to your indexing policy.
          Do you want to leave without saving your changes?
        `}
      />
    </>
  );
};

export default IndexSettings;
