import React from 'react';
import { DateTime } from 'luxon';
import get from 'lodash/get';
import times from 'lodash/times';
import { useSnackbar } from 'notistack';
import { useKoverse, usePaginatedService } from '@koverse/react';
import { useTranslation, Trans } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import SendIcon from '@mui/icons-material/Send';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from '../Dialogs/Confirmation';
import InviteUser from '../Dialogs/InviteUser';
import { WorkspaceInvitation } from '../../declarations';

const LIMIT = 5;

const InvitationsCard = (): React.ReactElement => {
  const { user, client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [inviteUsers, setInviteUsers] = React.useState<boolean>(false);
  const [invitationToRevoke, setInvitationToRevoke] = React.useState<WorkspaceInvitation | null>(null);

  const {
    items: invitations,
    loading,
    totalPages,
    currentPage,
    setCurrentPage,
  } = usePaginatedService('workspace-invitations', {
    userId: get(user, 'id'),
    $limit: LIMIT,
    workspaceId: workspace.id,
  });

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const revokeInvitation = async (invitation: WorkspaceInvitation | null) => {
    if (!invitation) { return; }
    try {
      await client.service('workspace-invitations').remove(invitation.id);
      enqueueSnackbar('The invitation has been revoked.', { variant: 'success' });
      setInvitationToRevoke(null);
    } catch (e) {
      enqueueSnackbar(get(e, 'message', e), { variant: 'error' });
      setInvitationToRevoke(null);
    }
  };

  const renderInviteButton = (
    <Button
      color="primary"
      variant="outlined"
      size="small"
      startIcon={<AddIcon />}
      onClick={() => setInviteUsers(true)}
    >
      {t('common.inviteUsers')}
    </Button>
  );

  const renderZeroState = (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <SendIcon
        sx={{
          fontSize: 42,
          transform: 'rotate(320deg)',
          color: theme => theme.palette.text.secondary,
        }}
      />
      <Typography sx={{ mt: 2 }}>{t('pages.workspace.invitations.zeroState.header')}</Typography>
      <Typography sx={{ mb: 2 }}>{t('pages.workspace.invitations.zeroState.subheader')}</Typography>
      {renderInviteButton}
    </Stack>
  );

  return (
    <>
      <Card>
        <CardHeader
          sx={{
            pb: 0,
            '& .MuiCardHeader-action': {
              alignSelf: 'center',
              margin: 0,
            },
          }}
          title={t('pages.workspace.invitations.title')}
          titleTypographyProps={{ variant: 'h6' }}
          action={!!invitations.length && renderInviteButton}
        />
        <CardContent sx={{ px: 0 }}>
          {loading ? (
            <List dense>
              {times(LIMIT, (index) => (
                <ListItem
                  disableGutters
                  dense
                  key={index}
                  aria-label="placeholder-list-item-while-loading">
                  <Skeleton
                    variant="rectangular"
                    height={60}
                    width="100%"
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <>
              {!loading && (
                <List dense>
                  {invitations.map((invitation, index) => {
                    return (
                      <ListItem
                        dense
                        sx={{
                          backgroundColor: 'action.disabledBackground',
                          mb: index !== invitations.length - 1 ? 1 : 0,
                        }}
                        key={invitation.id}
                      >
                        <ListItemText
                          primary={invitation.email}
                          primaryTypographyProps={{ noWrap: true }}
                          secondary={
                            t('pages.workspace.invitations.helper', {
                              workspace: workspace?.name,
                              date: DateTime.fromISO(invitation.createdAt).toLocaleString(),
                            })
                          }
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                        <ListItemSecondaryAction>
                          <Button
                            aria-label="revoke-invitation"
                            size="small"
                            color="inherit"
                            onClick={() => setInvitationToRevoke(invitation)}
                          >
                            {t('pages.workspace.invitations.revoke')}
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              )}
              {!loading && invitations.length === 0 && renderZeroState}
              {totalPages > 1 && (
                <Stack alignItems="center">
                  <Pagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    siblingCount={0}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{ my: 2 }}
                  />
                </Stack>
              )}
            </>
          )}
        </CardContent>
      </Card>
      {inviteUsers && (
        <InviteUser
          open={inviteUsers}
          onClose={() => setInviteUsers(false)}
        />
      )}
      <ConfirmationDialog
        open={!!invitationToRevoke}
        onClose={() => setInvitationToRevoke(null)}
        onConfirm={() => revokeInvitation(invitationToRevoke)}
        title={t('dialogs.invitations.revoke.title')}
        cancelButtonText={t('forms.cancel')}
        confirmButtonText={t('forms.revoke')}
        fullWidth
        maxWidth="md"
      >
        <Trans
          i18nKey='dialogs.invitations.revoke.subheader'
          values={{ user: get(invitationToRevoke, 'email') }}
          components={{ color: <Typography color="primary" display="inline" /> }}
        />
      </ConfirmationDialog>
    </>
  );
};

export default InvitationsCard;
