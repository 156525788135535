import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useKoverse } from '@koverse/react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import config from '../../config';
import { CircularProgress } from '@mui/material';
import { StripeHandlerEvent } from '../../declarations';

interface Props {
  onChange: (e: StripeHandlerEvent, stripe: Stripe, elements: StripeElements) => void,
}

const CardForm = ({ onChange }: Props): React.ReactElement => {
  const elements = useElements();
  const stripe = useStripe();
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    const paymentElement = elements?.getElement('payment');
    if (!stripe || !elements || !paymentElement) {
      return;
    }
    paymentElement.on('change', (e) => {
      onChange(e, stripe, elements);
    });
    paymentElement.on('ready', () => setReady(true));
  }, [elements, stripe, onChange]);

  return (
    <Box sx={{ position: 'relative' }}>
      {!ready && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: theme => theme.zIndex.appBar + 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <PaymentElement />
    </Box>
  );
};

const SetupPayment = ({ onChange }: Props): React.ReactElement => {
  const { client, theme } = useKoverse();
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const stripePromise = React.useMemo(() => {
    if (!config.stripePublishableKey) {
      return null;
    }
    return loadStripe(config.stripePublishableKey);
  }, []);

  const options = React.useMemo(() => ({
    appearance: {
      theme: 'night' as const,
      variables: {
        borderRadius: `${theme.shape.borderRadius}px`,
        colorPrimary: theme.palette.primary.main,
        colorBackground: theme.palette.background.paper,
        colorText: theme.palette.text.primary,
        colorDanger: theme.palette.error.main,
      },
    },
    clientSecret,
  }), [clientSecret, theme]);

  useEffect(() => {
    const createSetupIntent = async () => {
      setError(undefined);
      try {
        const clientSecret: string = await client.service('stripe').create({
          action: 'createSetupIntent',
        });
        setClientSecret(clientSecret);
      } catch (e) {
        setError('There was an error setting up the payment method');
      }
      setLoading(false);
    };
    createSetupIntent();
  }, [client]);

  return (
    <Box
      sx={{
        '.StripeElement iframe': {
          minHeight: 285,
        },
      }}
    >
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 6 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CardForm onChange={onChange} />
        </Elements>
      )}
      {!loading && error && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{
            width: '100%',
            mb: 2,
          }}
        >
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default SetupPayment;
