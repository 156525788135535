import { useKoverse } from '@koverse/react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Attribute, AttributeAssignment } from '../../../declarations';
import { useAutocomplete } from '../../../hooks';

interface Props {
  userId: string;
  assignments: AttributeAssignment[] | undefined;
}

const AttributeSearch = ({
  userId,
  assignments,
}: Props): React.ReactElement => {
  const { client, workspace } = useKoverse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    open,
    setOpen,
    value,
    setValue,
    inputValue,
    setInputValue,
    options,
    setCurrentIds,
    loading,
  } = useAutocomplete({
    serviceUrl: 'attributes',
    fields: ['token', 'name', 'description'],
    clearOnSelect: true,
    queryParams: { workspaceId: workspace.id, $sort: { token: 1 }, $limit: 1000 },
  });

  React.useEffect(() => {
    if (!!assignments) {
      setCurrentIds(assignments.map((d) => (d as AttributeAssignment).attributeId));
    }
  }, [assignments, setCurrentIds]);

  const handleAddToUser = async (attribute: Attribute | null) => {
    try {
      if (attribute) {
        await client.service('attribute-assignments').create({
          attributeId: attribute.id,
          userId,
        });
      }
    } catch (error) {
      enqueueSnackbar(`There was an error: ${error}`, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Typography>{t('glossary.attributes')}</Typography>
      {!!assignments && assignments.length === 0 && (
        <Typography variant="body2">
          {t('dialogs.editUser.noAttributes')}
        </Typography>
      )}
      <Autocomplete
        id="attributes-search"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options as Attribute[]}
        getOptionLabel={(options: Attribute) => options.token}
        filterOptions={(data) => data}
        noOptionsText={t('common.noResults')}
        value={value as Attribute}
        onChange={(_event: React.ChangeEvent<unknown>, newValue: Attribute | null) => {
          handleAddToUser(newValue);
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(_event: React.ChangeEvent<unknown>, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id="attributes-search"
            fullWidth
            InputLabelProps={{
              htmlFor: 'attributes-search',
            }}
            margin="normal"
            label={`${t('forms.labels.assignAttribute')} (${t('forms.labels.optional')})`}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        PaperComponent={(props) => <Paper {...props} elevation={4} />}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <ListItemText
              primary={option.token}
              secondary={option.name}
            />
          </ListItem>
        )}
      />
    </>
  );
};

export default AttributeSearch;
