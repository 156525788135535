import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import React from 'react';
import config from '../../config';

const AbacExpressionLink = (): React.ReactElement => {
  return (
    <FormHelperText>
      View our&nbsp;
      <Link
        href={`//${config.documentationDomain}/docs/datasets/abac-attributes/attr-expr-abac-label/`}
        target="_blank" rel="noreferrer noopener"
        sx={{ textDecoration: 'none' }}
      >
        Attribute Expression&nbsp;
      </Link>
      documentation for more information.
    </FormHelperText>
  );
};

export default AbacExpressionLink;
