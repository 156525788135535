import React, { ChangeEvent } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import slugify from 'slugify';
import { useForm, FieldError, useWatch } from 'react-hook-form';
import { useKoverse } from '@koverse/react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { validateWorkspaceId } from '../../validators';
import config from '../../config';

type Inputs = {
  name: string;
  id: string;
};

interface Props {
  handleBack?: () => void;
}

const CreateWorkspace = ({
  handleBack,
}: Props): React.ReactElement => {
  const { client } = useKoverse();
  const { t } = useTranslation();
  const workspaceService = client.service('workspaces');
  const [submissionError, setSubmissionError] = React.useState<string | null>(null);
  const {
    control,
    errors,
    setError,
    formState,
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useForm<Inputs>({
    mode: 'onChange',
  });
  const { isValid } = formState;
  const workspaceIdValue = useWatch({
    control,
    name: 'id',
  });
  const onSubmit = async (data: Inputs) => {
    try {
      await workspaceService.create(data, {
        query: {
          skipUpsert: true,
        },
      });
    } catch (e) {
      if (isArray(get(e, 'errors'))) {
        const errorList = get(e, 'errors') || [];
        errorList.forEach((error: FieldError) => {
          const path: any = get(error, 'path');
          setError(path, {
            type: 'manual',
            message: error.message,
          });
        });
      } else {
        setSubmissionError(`There was a server error: ${get(e, 'message')}`);
      }
    }
  };

  const workspaceUrl = workspaceIdValue
    ? t('pages.initialization.createWorkspace.url', {
      url: `${window.location.protocol}//${workspaceIdValue}.${config.domain}`,
    })
    : '';

  return (
    <Box sx={{ mt: 1 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {!!handleBack && (
            <Typography>
              {t('pages.initialization.createWorkspace.title')}
            </Typography>
          )}
          <TextField
            InputLabelProps={{
              htmlFor: 'name',
            }}
            autoFocus
            autoComplete="off"
            id="name"
            label={t('forms.labels.workspaceName')}
            name="name"
            variant="outlined"
            fullWidth
            error={!!errors.name}
            helperText={(errors.name && errors.name.message) || ''}
            disabled={formState.isSubmitting}
            inputRef={register({ required: t('forms.validation.required') as string })}
            margin='normal'
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (!formState.dirtyFields.id) {
                const slug = slugify(e.target.value, {
                  lower: true,
                  replacement: '_',
                  trim: true,
                });
                setValue('id', slug);
                trigger();
              }
            }}
          />
          <TextField
            InputLabelProps={{
              htmlFor: 'id',
              shrink: !!workspaceIdValue,
            }}
            autoComplete="off"
            id="id"
            label={t('forms.labels.workspaceId')}
            name="id"
            variant="outlined"
            fullWidth
            error={!!errors.id}
            helperText={(errors.id && errors.id.message) || workspaceUrl}
            disabled={formState.isSubmitting}
            margin='normal'
            inputRef={register({
              required: t('forms.validation.required') as string,
              validate: {
                value: (val) => validateWorkspaceId(val),
              },
              minLength: {
                value: 3,
                message: t('forms.validation.workspaceMinLength'),
              },
              maxLength: {
                value: 64,
                message: t('forms.validation.workspaceMaxLength'),
              },
            })}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setValue('id', e.target.value.toLowerCase());
              trigger();
            }}
          />
          {submissionError && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                mb: 2,
              }}
            >{submissionError}</Alert>
          )}
          <Alert
            variant="outlined"
            severity="info"
            aria-label={t('pages.initialization.createWorkspace.alert')}
            sx={{
              mt: 2,
            }}
          >
            {t('pages.initialization.createWorkspace.alert')}
          </Alert>
        </DialogContent>
        <DialogActions>
          {formState.isSubmitting ? (
            <CircularProgress
              size={30}
              sx={{
                mr: 2,
              }}
            />
          ) : (
            <>
              {!!handleBack && (
                <Button
                  color="primary"
                  onClick={handleBack}
                >
                  {t('common.back')}
                </Button>
              )}
              <Button
                disabled={!isValid}
                type="submit"
                color="primary"
                variant="contained"
              >
                {t('pages.initialization.createWorkspace.cta')}
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Box>
  );
};

export default CreateWorkspace;
