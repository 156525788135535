import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LineItem } from '../../declarations';
import useSubscription from '../../hooks/useSubscription';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';

interface Props {
  lineItem: LineItem;
}

const LineItemDetails = ({ lineItem }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { subscription } = useSubscription();
  const subscriptionItem = useMemo(() => {
    return find(subscription?.items?.data, { id: lineItem.subscription_item });
  }, [lineItem.subscription_item, subscription?.items?.data]);

  return (
    <Stack
      key={lineItem.id}
      direction="row"
      justifyContent="space-between"
      sx={{
        py: 2,
      }}
    >
      <Stack direction="column">
        <Typography>
          {capitalize(t(`subscriptionLevels.${subscriptionItem?.metadata?.subscriptionLevel}.title`))}
        </Typography>
        {lineItem?.price?.recurring?.interval === 'month' && (
          <Typography color="textSecondary">{lineItem.description}</Typography>
        )}
      </Stack>
      <Stack direction="column">
        <Typography>${(lineItem.amount / 100).toFixed(2)}</Typography>
      </Stack>
    </Stack>
  );
};

export default LineItemDetails;
