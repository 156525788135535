import React from 'react';
import get from 'lodash/get';
import toUpper from 'lodash/toUpper';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import CopyIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCopyToClipboard } from '../../../hooks';
import { WorkspaceInvitation } from '../../../declarations';
import { Tooltip } from '@mui/material';

interface Props {
  invitations: WorkspaceInvitation[];
  onChange: (event: SelectChangeEvent, membership: WorkspaceInvitation) => void;
  setInvitationToRevoke: React.Dispatch<React.SetStateAction<WorkspaceInvitation | null>>;
}

const InvitationsRoleForm = ({
  invitations,
  onChange,
  setInvitationToRevoke,
}: Props): React.ReactElement => {
  const { copyToClipboard } = useCopyToClipboard();
  const { t } = useTranslation();
  const {
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      roles: invitations.map(invitation => ({ role: invitation.role })),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'roles',
  });

  return (
    <form>
      {invitations.map((invitation, index) => {
        return (
          <Stack
            key={invitation.id}
            direction="row"
            alignContent="center"
            justifyContent="center"
            sx={{
              mb: index !== invitations.length - 1 ? 1 : 0,
            }}
          >
            <ListItem
              sx={{
                backgroundColor: 'action.disabledBackground',
                height: theme => theme.spacing(7.5),
              }}
            >
              <ListItemAvatar>
                <Avatar>{toUpper(invitation.email[0])}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary={invitation.email}
              />
              <Typography
                sx={{
                  mr: 2,
                  fontStyle: 'italic',
                }}
              >
                {t('dialogs.invitations.collaborators.pending')}
              </Typography>
              <Tooltip
                title="Copy invitation link"
              >
                <IconButton
                  color="primary"
                  sx={{ mr: 2 }}
                  onClick={() => copyToClipboard(
                    `${window.location.protocol}//${window.location.host}/join/${invitation.id}?email=${encodeURIComponent(invitation.email)}`,
                    'Invitation link has been copied to your clipboard',
                  )}
                >
                  <CopyIcon />
                </IconButton>
              </Tooltip>

              {!!fields.length && (
                <Controller
                  key={get(fields, `[${index}].id`)}
                  render={(props) => (
                    <FormControl
                      variant="standard"
                      sx={{ minWidth: 150 }}
                    >
                      <Select
                        {...props}
                        label={t('forms.labels.role')}
                        margin="none"
                        onChange={(e) => {
                          setValue(`roles[${index}].role`, e.target.value);
                          onChange(e, invitation);
                        }}
                      >
                        <MenuItem value="member">Member</MenuItem>
                        <MenuItem value="admin">Admin</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  name={`roles[${index}].role`}
                  control={control}
                  defaultValue={invitation.role || 'member'}
                  rules={{
                    required: t('forms.validation.required') as string,
                  }}
                />
              )}
            </ListItem>
            <IconButton
              aria-label="revoke-invitation"
              size="small"
              sx={{
                alignSelf: 'center',
                mr: -4.5,
                ml: 1,
              }}
              onClick={() => setInvitationToRevoke(invitation)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      })}
    </form>
  );
};

export default InvitationsRoleForm;
